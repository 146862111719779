import { Container } from '../container.global';
import { StorageService, StorageType } from '../storage.service';

export const shouldBeRemovedAfterLogout = [
	'onboardingSelection',
	'psychoPages',
	'hardBlocker',
	'adSelectedCourseAlias',
	'selectedCourseInfo',
	'dynamicOnboardingSelection',
];

export const cleanupStorage = (
	arr: string[],
	storageType: StorageType,
): void => {
	const storage = Container.take('global', StorageService);

	const stack = new Error().stack;

	fetch('https://api2.sololearn.com/v2/web-logs/client-error', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			error: `Cleanup Storage called for ${storage.load('accessToken', 'local')}`,
			userId: (storage.load('user', 'local') as any)?.id,
			location: window.location.href,
			stack: stack,
		}),
	}).catch(err => console.log);

	arr.forEach((key) => storage.remove(key, storageType));
};

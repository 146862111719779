import React, {
	useEffect, useMemo, useRef, useState,
} from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { filter, tap } from 'rxjs/operators';

import {
	useIcons,
	facebook,
	google,
	apple,
	innovativePurple,
} from '@sololearnorg/icons';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../symphony';

import { Login } from '../../private/containers/Login/Login';
import { Signup } from '../../private/containers/Signup/Signup';
import { ServiceContext, SlLoginContext } from '../../private/login-context';
import {
	IContextOptions,
	ILoginDefaultValues,
	ISlLoginResponse,
	LoginType,
} from '../../private/global.interface';
import { FACEBOOK_ID, GOOGLE_ID } from '../../private/constants';
import { ModalsWrapper } from '../../private/components/Modals/ModalsWrapper';
import { SlLoginViewService } from '../../private/services/sl-login-view.service';
import { SlSocialLogin } from '../../private/containers/SlSocialLogin/SlSocialLogin';
import { FlashMessage } from '../../private/components/shared/FlashMessage/FlashMessage';
import { GoogleOneTap } from '../../private/containers/GoogleOneTap/GoogleOneTap';

import s from './SlLogin.scss';
import { IAuthApiMock } from '../../../api/public/auth-api';
import { IError, Social } from '../../../api/private/global.interface';

export interface ISlLoginProps {
	loginCallback?: (res: ISlLoginResponse) => void;
	forgetPassCallback?: () => void;
	configs?: {
		useMock?: boolean;
		mock?: IAuthApiMock;
		facebookId?: string;
		googleId?: string;
		disableScrollToTop?: boolean;
		isTitleH1?: boolean;
	};
	environmentUrl?: string; // Todo delete this argument
	useRouter?: boolean;
	defaultRoute?: 'login' | 'signup';
	redirectTarget?: '_blank' | '_self';
	trackingPrefix?: string;
	cssClass?: string;
	defaultValues?: ILoginDefaultValues;
	loginErrorHandler?: {
		[key: string]: (err: IError) => void;
	};
	loginType?: LoginType;
	signupTitle?: string;
	loginTitle?: string;
	fontFamily?: string;
	description?: string;
	contextOptions?: IContextOptions;
	isLandingPage?: boolean;
	darkMode?: boolean;
	primaryColor?: string;
	isGoogleNewVersion?: boolean;
	socials?: Social[];
}

// Todo: Find better solution to multiple mf in same
let mountedComponentsCount = 0;

export const SlLogin: React.FC<ISlLoginProps> = React.memo(
	({
		loginCallback,
		configs = {},
		defaultRoute = 'login',
		trackingPrefix = '',
		useRouter = true,
		forgetPassCallback = () => { },
		redirectTarget = '_self',
		cssClass,
		defaultValues,
		loginErrorHandler,
		loginType = 'full',
		description,
		contextOptions = {
			shouldDefaultRedirect: true,
		},
		isLandingPage,
		darkMode,
		socials,
		isGoogleNewVersion = false,
		signupTitle,
		loginTitle,
		fontFamily,
		primaryColor,
	}) => {
		useStyles(s);
		const [currentView, setCurrentView] = useState<'login' | 'signup'>(
			defaultRoute || 'login',
		);

		useIcons([facebook, google, apple, innovativePurple]);

		const isReady = useRef(true);

		if (isReady.current) {
			const registerConfigs = {
				...configs,
				mock: configs.mock || null,
				disableScrollToTop: configs.disableScrollToTop || false,
				isTitleH1:
					configs.isTitleH1 === undefined ? true : configs.isTitleH1,
				facebookId: configs.facebookId || FACEBOOK_ID,
				googleId: configs.googleId || GOOGLE_ID,
				defaultValues,
				trackingPrefix,
				useRouter,
				forgetPassCallback,
				redirectTarget,
				loginErrorHandler: loginErrorHandler || null,
				loginCallback,
			};

			Object.keys(registerConfigs).forEach((key) => {
				Container.register(SlLoginContext, key, registerConfigs[key]);
			});

			isReady.current = false;
			mountedComponentsCount++;
		}

		useEffect(() => {
			const loginViewService = Container.take(
				SlLoginContext,
				SlLoginViewService,
			);

			if (!useRouter && defaultRoute === 'signup') {
				loginViewService.setSignupCurrentView();
			}

			const { currentView$ } = Container.take(
				SlLoginContext,
				SlLoginViewService,
			);

			currentView$
				.pipe(
					filter((a) => !!a),
					tap(setCurrentView),
				)
				.subscribe();

			return () => {
				if (mountedComponentsCount === 1) {
					Container.reset(SlLoginContext);
				}
				mountedComponentsCount--;
			};
		}, []);

		useEffect(() => {
			const disableScrollToTop = Container.take(
				SlLoginContext,
				'disableScrollToTop',
			);
			if (disableScrollToTop) {
				return;
			}
			window.scrollTo(0, 0);
		}, [currentView]);

		const renderContent = () => {
			if (loginType === 'googleOneTap') {
				return <GoogleOneTap />;
			}

			if (loginType === 'social') {
				return <SlSocialLogin darkMode={darkMode} socials={socials} isGoogleNewVersion={isGoogleNewVersion} />;
			}

			if (useRouter) {
				return (
					<div className={`sl-login-container ${cssClass || ''}`} style={{ fontFamily }}>
						<Routes>
							<Route
								element={<Login title={loginTitle} primaryColor={primaryColor} darkMode={darkMode} />}
								path="login"
							/>
							<Route
								element={(
									<Signup
										title={signupTitle}
										description={description}
										isTitleH1={configs.isTitleH1}
										isLandingPage={isLandingPage}
										darkMode={darkMode}
										primaryColor={primaryColor}
									/>
								)}
								path="signup"
							/>
							<Route
								path="/"
								element={<Navigate to={defaultRoute} replace />}
							/>
						</Routes>
					</div>
				);
			}

			return (
				<div className={`sl-login-container ${cssClass || ''}`}>
					{currentView === 'login' ? (
						<Login darkMode={darkMode} />
					) : (
						<Signup
							loginType={loginType}
							title={signupTitle}
							description={description}
							isTitleH1={configs.isTitleH1}
							isLandingPage={isLandingPage}
							darkMode={darkMode}
						/>
					)}
				</div>
			);
		};

		const contextValue: IContextOptions = useMemo(() => contextOptions, []);

		return (
			<ServiceContext.Provider value={contextValue}>
				{renderContent()}
				<ModalsWrapper />
				<FlashMessage />
			</ServiceContext.Provider>
		);
	},
);

export * from '../../private/global.interface';

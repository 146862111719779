import React, { useContext } from 'react';
import { GoogleLogin } from 'react-google-login-component';
import useStyles from 'isomorphic-style-loader/useStyles';

import classNames from 'classnames';
import { Container, i18n } from '../../../../symphony';

import { SlLoginActionsService } from '../../services/sl-login-actions.service';
import { SlLoginInputService } from '../../services/sl-login-inputs.service';
import { ServiceContext, SlLoginContext } from '../../login-context';
import { SlLoginActionTypes } from '../../global.interface';
import { SlLoginLoadingService } from '../../services/sl-login-loading.service';

import s from './GoogleSocialLogin.scss';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { GoogleColoredIcon } from './GoogleColoredIcon';
import { GoogleIcon } from '../../../../shared/public/assets/icons/GoogleIcon';

declare global {
	interface Window {
		FB: any;
		AppleID: any;
	}
}

interface IGoogleSocialLogin {
	darkMode?: boolean;
	isNewVersion?: boolean;
}

export const GoogleSocialLogin: React.FC<IGoogleSocialLogin> = React.memo(
	({
		darkMode, isNewVersion,
	}) => {
		useStyles(s);
		const googleId: string = Container.take(SlLoginContext, 'googleId');
		const { loading$: isLoading } = useContainerData(
			SlLoginContext,
			SlLoginLoadingService,
			['loading$'],
		);

		const { actions$ } = Container.take(
			SlLoginContext,
			SlLoginActionsService,
		);

		const { socialInputs$ } = Container.take(
			SlLoginContext,
			SlLoginInputService,
		);

		const serviceData = useContext(ServiceContext);

		const googleResponseHandler = (res) => {
			const { access_token: accessToken } = res.getAuthResponse();
			if (accessToken) {
				socialInputs$.next({
					accessToken,
					provider: 'google',
				});
				actions$.next({
					type: SlLoginActionTypes.socialLogin,
					clickedButton: 'googleSignup',
					requestType: 'cancelable',
					data: {
						accessToken,
						contextOptions: {
							trackingEventParams:
								serviceData.trackingEventParams,
							shouldDefaultRedirect:
								serviceData.shouldDefaultRedirect,
						},
					},
				});
			}
		};

		if (isNewVersion) {
			return (
				<GoogleLogin
					socialId={googleId}
					scope="profile email"
					className="sl-google-social-login__item__new-version"
					prompt="select_account"
					fetchBasicProfile={false}
					responseHandler={googleResponseHandler}
					buttonText={<span>{i18n.t('web-home.header-google-signup-cta')}</span>}
					disabled={isLoading}
				>
					<div className="sl-google-social-login__item__new-version__icon">
						<GoogleColoredIcon />
					</div>
				</GoogleLogin>
			);
		}

		return (
			<div className="sl-login-social-login__item">
				<GoogleIcon className="sl-login-social-login__item__icon" />
				<GoogleLogin
					socialId={googleId}
					className={
						classNames(
							'sl-login-social-login__item--google',
							'sl-login-social-login__item__social-button',
							{
								'dark-mode-button': darkMode && !isNewVersion,
							},
						)
					}
					scope="profile email"
					prompt="select_account"
					fetchBasicProfile={false}
					responseHandler={googleResponseHandler}
					buttonText={<span>Google</span>}
					disabled={isLoading}
				/>
			</div>
		);
	},
);

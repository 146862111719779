import { TrackingEventNames } from '../../../public/tracking/trackingEventNames';
import { TrackingEventsMappingType } from '../tracking.interface';

export const gtmEventsMapping: TrackingEventsMappingType = {
	[TrackingEventNames.slCompleteCodeCoach]: {
		eventName: 'CompleteCodeCoach',
		params: ['codeCoachId'],
	},
	[TrackingEventNames.slCodeCoachView]: {
		eventName: 'CodeCoachView',
		params: [],
	},
	[TrackingEventNames.clickToSignUp]: {
		eventName: 'click_to_signup',
		params: [['signup_cta', 'signupCta']],
	},
	[TrackingEventNames.appStoreClick]: {
		eventName: 'app_store_click',
		params: ['store', 'source'],
	},
	[TrackingEventNames.signup]: {
		eventName: 'sign_up',
		params: ['method'],
	},
	[TrackingEventNames.signupCompleted]: {
		eventName: 'sign_up',
		params: ['method'],
	},
	[TrackingEventNames.trialStarted]: {
		eventName: 'trial_started',
		params: [['payment_reccurency', 'paymentReccurency']],
	},
};

import { RefObject, useEffect } from 'react';

export const useOutsideClick = (
	ref: RefObject<HTMLElement>,
	attach: boolean,
	remove: boolean,
	callback: (event: MouseEvent) => void,
): void => {
	const listener = (event: MouseEvent): void => {
		if (!ref.current || ref.current.contains(event.target as Node)) return;
		callback(event);
	};

	useEffect(() => {
		if (attach) {
			document.addEventListener('click', listener);
		}
	}, [attach]);

	useEffect(() => {
		if (remove) {
			document.removeEventListener('click', listener);
		}
	}, [remove]);

	useEffect(() => () => {
		document.removeEventListener('click', listener);
	}, []);
};

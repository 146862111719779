import {
	Observable,
	of,
	tap,
	filter,
	distinctUntilChanged,
	merge,
	Subject,
	takeUntil,
} from 'rxjs';

import {
	AuthService,
	BitService,
	Container,
	CourseLevelId,
	CourseTrack,
	DataService,
	HeartModalSource,
	HeartService,
	history,
	i18n,
	ILearningExperience,
	LearningExperienceType,
	NavigationItem,
	Service,
	StreakService,
} from '../../../symphony';

import { SlActions } from './ActionsService/sl-actions';
import { SlNavigationDataService } from './sl-navigation-data.service';
import { SlNavigationTrackingService } from './sl-navigation-tracking.service';
import {
	SlNavigationContext,
	getFooterCatalogComunity,
	getNavConfigs,
} from '../global-constants';
import {
	ActionType,
	ClickElementTypeId,
	ICourseCatalogItem,
	ICoursesCatalog,
	IFooterCatalog,
	IFooterCatalogItem,
	INavConfig,
	INavItemConfig,
	ISegmentedCourses,
	NavigationBarExperiments,
	NavigationBarExperimentsFlowNames,
	NavItemActionType,
	RequestType,
} from '../global-interface';
import { getInitialServerSideData } from '../../../shared/public/SlUtils/getInitialServerSideData';
import { detectDevice, DeviceInfo } from '../../../shared/public/SlUtils/detectDevice';
import { getFingerprintFromCookies } from '../../../shared/public/utilsPublic/utilsPublic';
import { GamificationApi, IBitSourcesResponse } from '../../../api/public/gamification-api';

@Service()
export class SlNavigationBusiness {
	private dataService = Container.take(
		SlNavigationContext,
		SlNavigationDataService,
	);

	private trackingService = Container.take(
		SlNavigationContext,
		SlNavigationTrackingService,
	);

	private actions$ = Container.take(SlNavigationContext, SlActions).actions$;

	private heartService = Container.take('global', HeartService);

	private streakService = Container.take('global', StreakService);

	private bitService = Container.take('global', BitService);

	private authService = Container.take('global', AuthService);

	private globalDataService = Container.take('global', DataService);

	private gamificationApi = Container.take('global', GamificationApi);

	constructor() {
		const unsubscriber: Subject<void> = Container.take(
			SlNavigationContext,
			'unsubscriber',
		);

		merge(
			this.authService.userInfo$.pipe(
				distinctUntilChanged(
					(prev, curr) => (!prev && !curr) || prev?.id === curr?.id,
				),
				tap((user) => {
					this.setNavConfig(!!user);
				}),
			),
			this.globalDataService.learningExperiences$.pipe(
				filter((le) => !!le),
				tap(this.handleCoursesData),
			),
		)
			.pipe(takeUntil(unsubscriber))
			.subscribe();
	}

	private setNavConfig = (isLoggedIn: boolean): void => {
		const navConfig: INavConfig = getNavConfigs();
		navConfig.itemsConfig = navConfig.itemsConfig.filter((itemConfig) => (isLoggedIn ? itemConfig.loggedIn : itemConfig.nonLoggedIn));
		this.dataService.navConfig$.next(navConfig);
	};

	public handleNavbarMount = (): Observable<null> => {
		this.setNavConfig(!!this.authService.userInfo$?.value);

		const ssrCoursesCatalog = getInitialServerSideData('ssrCoursesCatalog');

		if (ssrCoursesCatalog) {
			this.handleCoursesData(ssrCoursesCatalog);
		}

		return of(null);
	};

	public handleHeartsIconClick = (source: HeartModalSource): Observable<null> => {
		const deviceInfo: DeviceInfo = detectDevice();
		if (deviceInfo.isMobile) {
			history.push(`/notallowed?returnUrl=${location.pathname}`, {
				replace: true,
			});
		} else {
			this.heartService.openModal$.next(source);
			if (source === HeartModalSource.NavIconClick) {
				this.heartService.trackIconClick();
			} else {
				this.heartService.trackIconProClick();
			}
		}

		this.dataService.navBarOpen$.next(false);

		return of(null);
	};

	public handleStreakIconClick = (): Observable<null> => {
		const { userMenuOpen$, bitsMenuOpen$, streakMenuOpen$ } = this.dataService;

		if (!streakMenuOpen$.value) {
			this.streakService.trackStreakIconClick();
		}

		streakMenuOpen$.next(!streakMenuOpen$.value);
		userMenuOpen$.next(false);
		bitsMenuOpen$.next(false);

		return of(null);
	};

	public handleStreakMenuMount = (): Observable<null> => {
		this.streakService.trackStreakImpression();
		return of(null);
	};

	public handleBitsIconClick = (): Observable<null> => {
		const { userMenuOpen$, bitsMenuOpen$, streakMenuOpen$ } = this.dataService;

		if (!bitsMenuOpen$.value) {
			this.bitService.trackBitIconClick();
		}

		bitsMenuOpen$.next(!bitsMenuOpen$.value);
		userMenuOpen$.next(false);
		streakMenuOpen$.next(false);
		return of(null);
	};

	public handleBitsMenuMount = (): Observable<IBitSourcesResponse> => {
		this.bitService.trackBitInfoImpression();
		if (!this.dataService.bitSources$.value) {
			this.dataService.bitSourcesLoading$.next(true);
			return this.gamificationApi.getBitSources().pipe(
				tap((bits) => {
					this.dataService.bitSources$.next(bits.data);
					this.dataService.bitSourcesLoading$.next(false);
				}),
			);
		}
		return of(null);
	};

	public setHeaderSignUpExperiments = (): Observable<null> => {
		const fingerPrint: string = getFingerprintFromCookies();
		const sum = fingerPrint.split('').reduce((acc, val) => acc + val.charCodeAt(0), 0);
		const mod = sum % 97;
		if (mod <= 32) {
			this.dataService.experiments$.next({
				...this.dataService.experiments$.value,
				[NavigationBarExperiments.homePagHeaderSignupControl]: true,
			});
		} else if (mod > 32 && mod <= 64) {
			this.dataService.experiments$.next({
				...this.dataService.experiments$.value,
				[NavigationBarExperiments.homePagHeaderSignup]: {
					[NavigationBarExperimentsFlowNames.homepageHeaderSignupModal]:
						true,
				},
			});
		} else {
			this.dataService.experiments$.next({
				...this.dataService.experiments$.value,
			});
		}
		return of(null);
	};

	private handleCoursesData = (courses: ILearningExperience[]): void => {
		if (!courses?.length) {
			return;
		}

		const segmentedCourses = this.getSegmentedCourses(courses);

		this.setupCoursesNavbarCatalog(segmentedCourses);
		this.setupCoursesFooterCatalog(segmentedCourses);
	};

	private getSegmentedCourses = (
		courses: ILearningExperience[],
	): ISegmentedCourses => {
		const beginnerCodding: ICourseCatalogItem[] = [];
		const intermediateCodding: ICourseCatalogItem[] = [];
		const advancedCodding: ICourseCatalogItem[] = [];
		const beginnerAI: ICourseCatalogItem[] = [];
		const intermediateAI: ICourseCatalogItem[] = [];
		const advancedAI: ICourseCatalogItem[] = [];
		const learningPaths: ICourseCatalogItem[] = [];

		courses.forEach((course) => {
			let coursesArray: ICourseCatalogItem[] = null;
			if (
				course.typeId === LearningExperienceType.LearningPath
				|| course.typeId === LearningExperienceType.FakeLearningPath
			) {
				coursesArray = learningPaths;
			} else if (course.levelId === CourseLevelId.Beginner) {
				coursesArray = course.track === CourseTrack.AI ? beginnerAI : beginnerCodding;
			} else if (course.levelId === CourseLevelId.Intermediate) {
				coursesArray = course.track === CourseTrack.AI ? intermediateAI : intermediateCodding;
			} else if (course.levelId === CourseLevelId.Advanced) {
				coursesArray = course.track === CourseTrack.AI ? advancedAI : advancedCodding;
			}
			if (coursesArray) {
				coursesArray.push(this.getCourseItem(course));
			}
		});

		return {
			beginnerCodding,
			intermediateCodding,
			advancedCodding,
			beginnerAI,
			intermediateAI,
			advancedAI,
			learningPaths,
		};
	};

	private getCourseItem = (
		course: ILearningExperience,
	): ICourseCatalogItem => ({
		title: course.name,
		courseData: {
			alias: course.alias,
			path: `/learn/${course.typeId === LearningExperienceType.LearningPath
				? 'learning-paths'
				: 'courses'
				}/${course.alias}`,
		},
	});

	private setupCoursesNavbarCatalog = ({
		beginnerCodding,
		intermediateCodding,
		advancedCodding,
		beginnerAI,
		intermediateAI,
		advancedAI,
		learningPaths,
	}: ISegmentedCourses): void => {
		const catalog: ICoursesCatalog = {
			items: [],
		};

		if (learningPaths.length) {
			catalog.items.push({
				title: i18n.t('web-navigation.nav-item-learning-paths'),
				items: learningPaths,
			});
		}

		const regularCoddingCourses: ICourseCatalogItem[] = [
			{
				title: i18n.t('web-navigation.nav-item-beginner'),
				items: beginnerCodding,
			},
			{
				title: i18n.t('web-navigation.nav-item-intermediate'),
				items: intermediateCodding,
			},
			{
				title: i18n.t('web-navigation.nav-item-advanced'),
				items: advancedCodding,
			},
		].filter((c) => c.items.length);

		catalog.items.push({
			title: i18n.t('web-navigation.nav-item-programming-languages'),
			items: regularCoddingCourses,
		});

		const regularAICourses: ICourseCatalogItem[] = [
			{
				title: i18n.t('web-navigation.nav-item-beginner'),
				items: beginnerAI,
			},
			{
				title: i18n.t('web-navigation.nav-item-intermediate'),
				items: intermediateAI,
			},
			{
				title: i18n.t('web-navigation.nav-item-advanced'),
				items: advancedAI,
			},
		].filter((c) => c.items.length);

		catalog.items.push({
			title: i18n.t('web-navigation.nav-item-generative-ai'),
			items: regularAICourses,
		});

		this.dataService.coursesCatalog$.next(catalog);
	};

	private getFooterItemFromCatalog = (
		catalogItems: ICourseCatalogItem[],
	): IFooterCatalogItem[] => catalogItems.map((item) => ({
		title: item.title,
		alias: item.courseData.alias,
		path: item.courseData.path,
	}));

	private setupCoursesFooterCatalog = ({
		beginnerCodding,
		intermediateCodding,
		advancedCodding,
		beginnerAI,
		intermediateAI,
		advancedAI,
		learningPaths,
	}: ISegmentedCourses): void => {
		const footerCatalogCourses: IFooterCatalog[] = [
			{
				title: i18n.t('web-footer.learning-paths-catalog-title'),
				items: this.getFooterItemFromCatalog(learningPaths),
			},
			{
				title: i18n.t('web-footer.introduction-courses-catalog-title'),
				items: this.getFooterItemFromCatalog([...beginnerCodding, ...beginnerAI]),
			},
			{
				title: i18n.t('web-footer.intermediate-courses-catalog-title'),
				items: this.getFooterItemFromCatalog([...intermediateCodding, ...intermediateAI]),
			},
			{
				title: i18n.t('web-footer.advanced-courses-catalog-title'),
				items: this.getFooterItemFromCatalog([...advancedCodding, ...advancedAI]),
			},
			getFooterCatalogComunity(),
		].filter((item) => item.items.length);

		this.dataService.footerCatalog$.next(footerCatalogCourses);
	};

	public closeNavbar = (): Observable<null> => {
		this.dataService.navBarOpen$.next(false);
		this.closeAllCatalogs();
		return of(null);
	};

	public handleNavLinkClick = (navItem: INavItemConfig): Observable<null> => {
		const { navigationTrackActions$ } = this.trackingService;

		this.actions$.next({
			type: ActionType.TrackNavigationElementsClick,
			requestType: RequestType.Parallel,
			data: {
				clickAlias: navItem.newTrackElement,
			},
		});

		if (navItem.key === NavigationItem.Leaderboard) {
			navigationTrackActions$.next({
				action: 'click',
				element: 'leaderboard_tab',
				entityId: this.globalDataService.navItemBadgeConfig$.value?.[
					navItem.name
				]
					? 1
					: 2,
			});
		} else {
			navigationTrackActions$.next({
				action: 'click',
				element: `navigation_${navItem.trackElement}`,
				entityId: null,
			});
		}
		return this.closeNavbar();
	};

	public handleNavLogoClick = (): Observable<null> => {
		this.actions$.next({
			type: ActionType.TrackNavigationElementsClick,
			requestType: RequestType.Parallel,
			data: {
				clickAlias: 'SOLOLEARN LOGO',
			},
		});
		return this.closeNavbar();
	};

	public handleNavCatalogClick = (
		navItem: INavItemConfig,
	): Observable<null> => {
		const expanded = !navItem.expanded;
		const config = this.dataService.navConfig$.value;
		config.itemsConfig.forEach((item) => {
			if (item.actionType === NavItemActionType.Catalog) {
				item.expanded = item.name === navItem.name ? expanded : false;
			}
		});
		this.dataService.navConfig$.next(config);
		this.dataService.isExpandedCatalog$.next(expanded);
		return of(null);
	};

	private closeAllCatalogs = (): void => {
		const config = this.dataService.navConfig$.value;
		config.itemsConfig.forEach((item) => {
			if (item.actionType === NavItemActionType.Catalog) {
				item.expanded = false;
			}
		});
		this.dataService.navConfig$.next(config);
		this.dataService.isExpandedCatalog$.next(false);
	};

	public handleCoursesItemClick = (
		dropdownLevel: number,
		alias: string,
	): Observable<null> => {
		this.closeAllCatalogs();
		this.actions$.next({
			type: ActionType.TrackNavigationBarDropdownClick,
			requestType: RequestType.Parallel,
			data: {
				clickType: 'ELEMENT',
				dropdownLevel,
				alias,
				elementType: ClickElementTypeId.Course,
			},
		});
		return of(null);
	};

	public handleCoursesSeeAllClick = (): Observable<null> => {
		this.closeAllCatalogs();
		this.actions$.next({
			type: ActionType.TrackNavigationBarDropdownClick,
			requestType: RequestType.Parallel,
			data: {
				clickType: 'SEE ALL',
				dropdownLevel: 1, // TODO: Check
			},
		});
		return of(null);
	};

	public handleCompilerItemClick = (): Observable<null> => {
		this.closeAllCatalogs();
		return of(null);
	};
}

import { Container, Service } from '../../../symphony';

import { SlNavigationDataService } from './sl-navigation-data.service';
import { SlNavbarState } from '../global-interface';
import { SlNavigationContext } from '../global-constants';
import {
	ISlNavbarInitialConfigs, ISlNavigationService, SlNavbarThemes, SlNavbarType,
} from '../../../shared/public/globalInterfaces/globalInterfaces';
import { PaywallsService } from '../../../_paywalls/private/services/paywalls.service';
import { Touchpoint } from '../../../api/public/subscription-api';

@Service()
export class SlNavigationService implements ISlNavigationService {
	private paywallsService = Container.take('paywalls', PaywallsService);

	private dataService = Container.take(
		SlNavigationContext,
		SlNavigationDataService,
	);

	public show(): void {
		this.dataService.navbarState$.next(SlNavbarState.shown);
	}

	public hide(): void {
		this.dataService.navbarState$.next(SlNavbarState.hidden);
	}

	public setInitialConfigs(initialConfigs: ISlNavbarInitialConfigs): void {
		this.dataService.navbarInitialConfigs$.next({
			...this.dataService.navbarInitialConfigs$.value,
			...initialConfigs,
		});
	}

	public setTheme(mode: SlNavbarThemes): void {
		this.dataService.navbarTheme$.next(mode);
	}

	public setType(type: SlNavbarType): void {
		this.dataService.navbarType$.next(type);
	}

	public openNavigationPaywall(): void {
		this.paywallsService.openPaywall({
			touchpoint: Touchpoint.navigation, props: {}
		});
	}
}

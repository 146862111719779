export type DeviceOS = 'Windows' | 'Linux' | 'MacOS' | 'iOS' | 'Android';

export interface DeviceInfo {
    isMobile: boolean;
    os: DeviceOS;
}

export function detectDevice(): DeviceInfo {
	if (typeof window === 'undefined') {
		return {
			isMobile: false,
			os: null,
		};
	}
	const userAgent = navigator.userAgent || navigator.vendor;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os: DeviceOS = null;
	let isMobile = false;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
		isMobile = true;
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
		isMobile = true;
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	return { isMobile, os };
}

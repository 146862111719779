import { useLocation, useMatch } from 'react-router-dom';

export const useCustomLocation = () => {
	const location = useLocation();

	const isOldCoursePage = useMatch(':language/learning/:courseId');
	const isCoursePage = useMatch(':language/learn/courses/:courseAlias');
	const isPathPage = useMatch(':language/learn/learning-paths/:alias');
	const isLanguageLandingPage = useMatch(
		':language/learn/languages/:languageAlias',
	);
	const isDiscussPage = useMatch(':language/discuss');
	const isCodeBitsPage = useMatch(':language/codes');
	const isDiscussQuestionPage = useMatch(':language/discuss/:id/:alias');
	const isPlaygroundCatalog = useMatch(':language/compiler-playground');
	const isTeams = useMatch(':language/teams');
	const isHomePage = useMatch(':language');

	if (isOldCoursePage) {
		return {
			...location,
			pathname: ':language/learning/:courseId',
		};
	}

	if (isCoursePage) {
		return {
			...location,
			pathname: ':language/learn/courses/:courseAlias',
		};
	}

	if (isPathPage) {
		return {
			...location,
			pathname: ':language/learn/learning-paths/:alias',
		};
	}

	if (isLanguageLandingPage) {
		return {
			...location,
			pathname: ':language/learn/languages/:languageAlias',
		};
	}

	if (isCodeBitsPage) {
		return {
			...location,
			pathname: ':language/codes',
		};
	}

	if (isDiscussQuestionPage) {
		return {
			...location,
			pathname: ':language/discuss/:id/:alias',
		};
	}

	if (isDiscussPage) {
		return {
			...location,
			pathname: ':language/discuss',
		};
	}

	if (isPlaygroundCatalog) {
		return {
			...location,
			pathname: ':language/compiler-playground',
		};
	}

	if (isTeams) {
		return {
			...location,
			pathname: ':language/teams',
		};
	}

	if (isHomePage) {
		return {
			...location,
			pathname: ':language',
		};
	}

	// Write other condition here

	return location;
};

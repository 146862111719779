import { Observable } from 'rxjs';

import { IResponse, IPaginationParams } from '../global.interface';
import { IUserCode } from '../code-api/code-api.interface';
import { IUserBadge } from '../achievement-api/achievement-api.interface';

export interface IProfileApiConfig {
	environmentUrl: string;
	useMock?: boolean;
	mock?: IProfileApiMock;
	versions?: IProfileVersions;
}

export interface IProfileApi {
	getProfile: (
		userId: number,
		sections: UserProfileSections[]
	) => Observable<IProfile>;
	deleteAccount: (userId: number) => Observable<IProfile>;
	getFollowers: (
		userId: number,
		params?: IPaginationParams
	) => Observable<IProfileCommunityResponse>;
	getFollowing: (
		userId: number,
		params?: IPaginationParams
	) => Observable<IProfileCommunityResponse>;
	follow: (profileId: number) => Observable<IProfileFollowResponse>;
	unfollow: (profileId: number) => Observable<IProfileFollowResponse>;
	updateInfo: (
		profileId: number,
		payload: IProfileUpdatePayload
	) => Observable<IProfileUpdateResponse>;
	uploadAvatar: (avatar: File) => Observable<IProfileAvatarResponse>;
	changePassword: (
		passwords: IProfileChangePasswordPayload
	) => Observable<IProfileChangePasswordResponse>;
	updateOnboardingInfo: (
		data: IProfileOnboardingDataUpdatePayload[]
	) => Observable<IProfileUpdateResponse>;
	connectAccount: (
		payload: IProfileConnectAccountPayload
	) => Observable<IProfileConnectAccountResponse>;
	updateConnectedAccount: (
		socialConnectionId: number,
		payload: IProfileUpdateConnectedAccountPayload
	) => Observable<IProfileUpdateConnectedAccountResponse>;
	deleteConnectedAccount: (
		socialConnectionId: number
	) => Observable<IProfileDeleteConnectedAccountResponse>;
	getUserData: () => Observable<IUserData>;
	getUserGoalProgress: () => Observable<IUserGoalProgress[]>;
	setUserGoal: (
		data: ISetUserGoalPayload
	) => Observable<IProfileUpdateResponse>;
	updateUserInfo: (
		profileId: number,
		payload: IUpdateUserInfoPayload
	) => Observable<IProfileUpdateResponse>;
}

export enum UserProfileSections {
	all = 0,
	userDetails = 1,
	coursesProgress = 2,
	certificates = 3,
	userGoals = 4,
	userStreak = 5,
	codeCoaches = 6,
	userBadges = 7,
	userCodes = 8,
}

export enum UserAccessLevel {
	None = 0,
	Basic = 1,
	Moderator = 2,
	GoldModerator = 4,
	PlatinumModerator = 8,
	MonitorUsers = 16,
	ManageClients = 32,
	ManageUsers = 64,
	PushServices = 128,
	Admin = 65520,
}

export enum UserBadge {
	platinum = 'platinum',
	platinumMod = 'platinum_mod',
	goldMod = 'gold_mod',
	gold = 'gold',
	bronze = 'bronze',
	pro = 'pro',
	mod = 'mod',
	silver = 'silver',
}

export enum UserConnectedAccountService {
	Apple = 'Apple',
	Facebook = 'Facebook',
	freecodecamp = 'freecodecamp',
	GitHub = 'GitHub',
	Google = 'Google',
	LinkedIn = 'LinkedIn',
	StackOverflow = 'StackOverflow',
	Twitter = 'Twitter',
}

export enum CodeCoachState {
	userIsPro,
	isOpen,
	unlocked,
	unlock,
	pro,
}

export interface IUserConnectedAccount {
	connectionId: number;
	service: UserConnectedAccountService;
	name: string;
	profileUrl: string;
	isVisible: boolean;
	syncDate?: string;
	avatarUrl: string;
}

export interface IUserDetails {
	id: number;
	name: string;
	avatarUrl: string;
	email: string;
	accessLevel: UserAccessLevel;
	badges: IBadge[];
	level: number;
	xp: number;
	countryCode: string;
	isPro: boolean;
	followers: number;
	following: number;
	isFollowing: boolean;
	bio: string;
	connectedAccounts: IUserConnectedAccount[];
}
export interface IUserInfo {
	userId: number;
	name: string;
	avatarURL: string;
	countryCode: string;
	level: number;
	isFollowing: boolean;
}

export interface IBadge {
	name: UserBadge;
	priority: number;
}

export interface IUserCourseProgress {
	courseId: number;
	courseName: string;
	courseIconURL: string;
	courseColor: string;
	isCompleted: boolean;
	lastProgressDate: string;
	progress: number;
	isLearnEngineCourse?: boolean;
	alias?: string;
}

export interface IUserCertificate {
	courseId: number;
	name: string;
	courseColor: string;
	iconURL: string;
	startDate: string;
	expireDate: string;
	url: string;
	imageUrl: string;
	shareUrl: string;
}

export interface IUserBadges {
	nextChallange: IUserBadge;
	badges: IUserBadge[];
}

export interface IProfile {
	userDetails?: IUserDetails;
	coursesProgress?: IUserCourseProgress[];
	certificates?: IUserCertificate[];
	userBadges?: IUserBadges;
	userCodes?: IUserCode[];
}

export interface IProfileApiMock {
	profile: IProfile;
}

export interface IProfileVersions {
	getProfile?: string;
	getFollowers?: string;
	getFollowing?: string;
	follow?: string;
	unfollow?: string;
	updateInfo?: string;
	uploadAvatar?: string;
	changePassword?: string;
	connectedAccounts?: string;
	getUserData?: string;
	getUserGoalProgress?: string;
	deleteAccount?: string;
	updateUserInfo?: string;
}

export interface IProfileCommunityResponse extends IResponse {
	data: IUserInfo[];
}

export interface IProfileFollowResponse extends IResponse {
	data: boolean;
}

export interface IUserDataResponse extends IResponse {
	data: IUserData;
}

export interface IUserGoalProgressResponse extends IResponse {
	data: IUserGoalProgress[];
}

export enum IUserDataCodeCoachType {
	codeCoach = 0,
	endOfModule = 1,
}

export interface IUserDataCodeCoachInfo {
	description: string;
	iconUrl: string;
	id: number;
	isFree: boolean;
	title: string;
	type: IUserDataCodeCoachType;
	xp: number;
}
export interface IUserData {
	codeCoachInfo?: IUserDataCodeCoachInfo;
	courseId?: number;
	courseName?: string;
	courseLanguage?: string;
	coursesCount?: number;
	hasCompletedPractice?: boolean;
	isCurrentCourseCompleted?: boolean;
	isFristLesson?: boolean;
	isLessonStarted?: boolean;
	isPro?: boolean;
	lessonId?: number;
	lessonName?: string;
	lessonsCount?: number;
	moduleId?: number;
	moduleName?: string;
	userLastActiveDate?: string;
	registerDate: string;
}

export interface IUserGoalProgress {
	id: number;
	userGoalId: number;
	currentValue: number;
	targetValue: number;
	localDate: string;
	date: string;
}

export interface IProfileUpdatePayload {
	name: string;
	email: string;
	bio: string;
	countryCode: string;
}

export interface IUpdateUserInfoPayload {
	name: string;
	email: string;
}

export interface IProfileOnboardingDataUpdatePayload {
	typeId: number;
	answerId: number;
}

export interface ISetUserGoalPayload {
	goalType: number;
	goalValue: number;
	origin: UserGoalOriginTypes;
	queryParams: {
		timezone?: number;
	};
}
export enum UserGoalOriginTypes {
	default = 0,
	onBorading = 1,
}

export interface IProfileUpdateResponse extends IResponse {
	data: IProfileUpdatePayload;
}

export interface IProfileAvatarResponse extends IResponse {
	data: { avatarUrl: string; };
}

export interface IProfileChangePasswordPayload {
	oldPassword: string;
	newPassword: string;
}

export interface IProfileConnectAccountPayload {
	service: string;
	code: string;
}

export interface IProfileUpdateConnectedAccountPayload {
	isVisible: boolean;
}

export interface IProfileChangePasswordResponse extends IResponse {
	data: boolean;
}

export interface IProfileConnectAccountResponse extends IResponse {
	data: IUserConnectedAccount;
}

export interface IProfileUpdateConnectedAccountResponse extends IResponse {
	data: any; // Todo change type after backend integration
}

export interface IProfileDeleteConnectedAccountResponse extends IResponse {
	data: any; // Todo change type after backend integration
}

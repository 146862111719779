import * as yup from 'yup';

import { i18n } from '../../../../../symphony';
import { validateEmail, validateUserName } from '../../../../../shared/public/utilsPublic/utilsPublic';

export const getSchema = () => yup.object().shape({
	name: validateUserName(),
	email: validateEmail(),
	password: yup
		.string()
		.required(i18n.t('web-auth.required-validation'))
		.min(6, i18n.t('web-auth.min-char-validation')),
});

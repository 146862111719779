/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Error } from './MicroFrontends/Error/Error';

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		const userIdLS = JSON.parse(localStorage.getItem('user'));
		const userId = userIdLS?.data?.id;

		fetch(
			this.hasSubdomain()
				? 'https://dev-api2.sololearn.com/web-logs/client-error'
				: 'https://api2.sololearn.com/v2/web-logs/client-error',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					error: `ErrorBoundary ---- ${error} ${info?.componentStack}`,
					userId,
					location: window.location.href,
					stack: 'Stack not available',
				}),
			},
		).catch(console.error);
	}

	private hasSubdomain = () => {
		const hostname = new URL(window.location.href).hostname;
		const parts = hostname.split('.');
		return parts.length > 2 && !(parts.length === 3 && parts[0] === 'www');
	};

	render() {
		if ((this.state as any).hasError) {
			return (
				<Error />
			);
		}

		return (this.props as any).children;
	}
}

import {
	catchError, filter, map, take,
} from 'rxjs/operators';
import {
	BehaviorSubject, finalize, from, Observable, of, tap,
} from 'rxjs';

import { Container, history, Service } from '../../../symphony';

import { AUTH_METHOD, IAction, ISlLoginResponse } from '../global.interface';
import { SlLoginContext } from '../login-context';
import { SlLoginApiService } from './sl-login-api.service';
import { SlLoginErrorsService } from './sl-login-errors.service';
import { SlLoginLoadingService } from './sl-login-loading.service';
import { SlDataService } from './sl-data.service';
import { SlModalService } from './sl-modal.service';
import { SlLoginInputService } from './sl-login-inputs.service';
import { SlLoginTrackingService } from './sl-login-tracking.service';
import { SlOneTapTrackingService } from './sl-one-tap-tracking.service';
import { SlFeatureToggle, SlMicroFrontends } from '../../../shared/public/globalInterfaces/globalInterfaces';
import { IFlashMessageTypes } from '../../../shared/public/SlFlashMessage/SlFlashMessage';
import { getAfUserIdFromCookies } from '../../../shared/public/utilsPublic/utilsPublic';
import { IAppleLoginResponse, ILoginResponse, ISocialLoginCredentials } from '../../../api/public/auth-api';
import { IError } from '../../../api/private/global.interface';
import { AppsFlyerApi, IAppsFlyerApi } from '../../../api/public/apps-flyer-api';

declare const AF: (
	pba: 'pba',
	event: 'setCustomerUserId' | 'getAfWebUserId',
	data?: string
) => void | string;

@Service()
export class SlLoginActionHandlersService {
	private api = Container.take(SlLoginContext, SlLoginApiService);

	private loading$: BehaviorSubject<boolean> = Container.take(
		SlLoginContext,
		SlLoginLoadingService,
	).loading$;

	private modalService = Container.take(SlLoginContext, SlModalService);

	private dataService = Container.take(SlLoginContext, SlDataService);

	private oneTapTrackingService = Container.take(
		SlLoginContext,
		SlOneTapTrackingService,
	);

	private socialInputs$: BehaviorSubject<ISocialLoginCredentials> = Container.take(SlLoginContext, SlLoginInputService).socialInputs$;

	private loginErrorService = Container.take(
		SlLoginContext,
		SlLoginErrorsService,
	);

	private tagManager$ = Container.take(SlLoginContext, SlLoginTrackingService)
		.tagManager$;

	private trackingService = Container.take(
		SlLoginContext,
		SlLoginTrackingService,
	);

	private featureToggle = Container.take('global', 'featureTogglingConfigs');

	private errorHandler = (errors: IError[]) => {
		if (!errors.length) {
			this.loading$.next(false);
			return of(null);
		}
		if (Array.isArray(errors)) {
			this.loginErrorService.errors$.next(errors[0].message);
		} else {
			this.loginErrorService.errors$.next('Unknown Error');
		}
		this.loading$.next(false);
		return of(null);
	};

	public appleAuthorizationHandler(action: IAction): Observable<unknown> {
		const data = {
			provider: 'Apple',
			code: action.data.code,
			user: action.data.user,
		};
		return this.api.authorizeApple(data).pipe(
			catchError(this.errorHandler),
			filter((a) => !!a),
			map((res: IAppleLoginResponse) => this.authResponseHandler(action, res, AUTH_METHOD.APPLE)),
		);
	}

	public signUp(action: IAction): Observable<IAction> {
		const {
			data: { formData },
		} = action;
		return this.api
			.signup({
				email: formData.email,
				password: formData.password,
				name: formData.name,
				...(formData.CaptchaToken && {
					CaptchaToken: formData.CaptchaToken,
				}),
				subject: Container.take('global', 'fingerprint'),
			})
			.pipe(
				catchError(this.errorHandler),
				filter((a) => !!a),
				map((res: ILoginResponse) => this.authResponseHandler(
					action,
					res,
					AUTH_METHOD.EMAIL,
				)),
			);
	}

	public signupWithCaptcha(
		actionProp: IAction,
	): Observable<Observable<IAction>> | Observable<null> {
		const { refCaptcha, formData } = actionProp.data;
		this.loading$.next(false);

		return from(refCaptcha.current.executeAsync()).pipe(
			map((token: string) => {
				const action = {
					...actionProp,
					data: {
						...actionProp.data,
						formData: {
							...formData,
							CaptchaToken: token,
						},
					},
				};
				refCaptcha.current.reset();

				if (token) {
					this.loading$.next(true);

					return this.signUp(action);
				}

				return of(null);
			}),
		);
	}

	public signUpHandler(
		action: IAction,
	): Observable<Observable<IAction>> | Observable<IAction> {
		if (
			this.featureToggle[SlMicroFrontends.mfLogin][
				SlFeatureToggle.recaptcha
			]?.state === 'enabled'
		) {
			return this.signupWithCaptcha(action);
		}

		return this.signUp(action);
	}

	private addBetaTesterErrorHandler = () => {
		this.loginErrorService.flashMessage$.next({
			type: IFlashMessageTypes.ERROR,
			message: 'Something went wrong.',
		});
	};

	private addBetaTester = () => {
		this.dataService.addBetaTesterLoading$.next(true);
		return this.api.addBetaTester().pipe(
			tap(() => {
				const { loginCallback$, loginData$ } = this.dataService;
				loginCallback$.value(loginData$.value);
				this.modalService.hideBetaTestingModal();
				return of(null);
			}),
			catchError(() => {
				this.addBetaTesterErrorHandler();
				return of(null);
			}),
			finalize(() => {
				this.dataService.addBetaTesterLoading$.next(false);
			}),
		);
	};

	public onBetaTesterModalAcceptClickHandler() {
		return this.addBetaTester();
	}

	public loginHandler(action: IAction) {
		return this.api
			.login({
				email: action.data.email,
				password: action.data.password,
				subject: Container.take('global', 'fingerprint'),
			})
			.pipe(
				catchError((err: IError[]) => {
					let filteredErr = [...err];
					const loginErrorHandler: (err) => void = Container.take(
						SlLoginContext,
						'loginErrorHandler',
					);

					if (loginErrorHandler) {
						err.forEach((item: IError) => {
							const code = item.code.toString();
							if (loginErrorHandler[code]) {
								loginErrorHandler[code](item);
								filteredErr = filteredErr.filter((member) => member.code !== item.code);
							}
						});
					}
					return this.errorHandler(filteredErr);
				}),
				filter((a) => !!a),
				map((res: ILoginResponse) => this.authResponseHandler(
					action,
					res,
					AUTH_METHOD.EMAIL,
				)),
			);
	}

	public socialLoginHandler(action: IAction) {
		return this.api
			.socialLogin({
				accessToken: action.data.accessToken,
				provider: this.socialInputs$.value.provider,
			})
			.pipe(
				take(1),
				catchError(this.errorHandler),
				filter((a) => !!a),
				map((res: ILoginResponse) => {
					this.authResponseHandler(
						action,
						res,
						this.getSocialAuthMethod(),
					);
				}),
			);
	}

	private getSocialAuthMethod(): AUTH_METHOD {
		if (this.socialInputs$.value.provider === 'googleId') {
			return AUTH_METHOD.GOOGLE;
		}
		return AUTH_METHOD[this.socialInputs$.value.provider.toUpperCase()];
	}

	private setUserAppsFlyerfId(userId: number) {
		const userMappingCall = () => {
			if (typeof AF !== 'undefined') {
				const appsFlyerUserId = getAfUserIdFromCookies();

				AF('pba', 'setCustomerUserId', userId.toString() || null);

				if (appsFlyerUserId) {
					const appsFlyerApi: IAppsFlyerApi = Container.take(
						'global',
						AppsFlyerApi,
					);

					appsFlyerApi.userMappings(appsFlyerUserId).subscribe();
				}
			}
		};

		if ((window as any).AFLoaded) {
			userMappingCall();
		} else {
			window.addEventListener('AFLoaded', () => {
				userMappingCall();
			});
		}
	}

	private authCallbackHandler(
		response,
		options: { shouldDefaultRedirect?: boolean; } = {},
	) {
		const { loading$ } = Container.take(
			SlLoginContext,
			SlLoginLoadingService,
		);
		const loginCallback: (res: ISlLoginResponse) => void = Container.take(
			SlLoginContext,
			'loginCallback',
		);

		this.setUserAppsFlyerfId(response.res.user.id);

		loading$.next(false);
		if (loginCallback) {
			loginCallback(response);
		}

		if (!options.shouldDefaultRedirect) {
			return;
		}

		if (response.res.user.isNewRegisteredUser) {
			history.push('/onboarding');
		} else {
			history.push('/profile');
		}
	}

	private authResponseHandler = (
		action: IAction,
		res: ILoginResponse | IAppleLoginResponse,
		authMethod: AUTH_METHOD,
	): ISlLoginResponse => {
		const isNewUser: boolean = (res as ILoginResponse).user
			?.isNewRegisteredUser;

		this.trackingService.authSuccessTracking(
			authMethod,
			action.data.contextOptions.trackingEventParams,
			isNewUser,
		);

		if (authMethod === AUTH_METHOD.EMAIL) {
			return this.authSuccessWithEmail(action, res as ILoginResponse);
		}

		if (authMethod === AUTH_METHOD.APPLE) {
			return this.authSuccessWithApple(
				action,
				res as IAppleLoginResponse,
			);
		}

		if (
			authMethod === AUTH_METHOD.GOOGLE
			|| authMethod === AUTH_METHOD.FACEBOOK
		) {
			this.authSuccessWithGoogleOrFacebook(action, res as ILoginResponse);
		}

		return null;
	};

	private authSuccessWithEmail(action: IAction, response: ILoginResponse) {
		if (action.clickedButton === 'signup') {
			this.dataService.loginData$.next({
				res: response,
				type: action.type,
				clickedButton: action.clickedButton,
			});
		}

		this.tagManager$.next({
			userId: response.user.id,
			event: action.type,
		});

		this.authCallbackHandler(
			{
				res: response,
				type: action.type,
				clickedButton: action.clickedButton,
			},
			{
				shouldDefaultRedirect:
					action.data.contextOptions.shouldDefaultRedirect,
			},
		);

		return {
			res: response,
			type: action.type,
			clickedButton: action.clickedButton,
		};
	}

	private authSuccessWithApple(
		action: IAction,
		response: IAppleLoginResponse,
	) {
		this.tagManager$.next({
			userId: (response as IAppleLoginResponse).user.id,
			event: response.user.isNewRegisteredUser
				? 'appleSignup'
				: 'appleLogin',
		});

		this.authCallbackHandler(
			{
				res: response,
				type: action.type,
				clickedButton: action.clickedButton,
			},
			{
				shouldDefaultRedirect:
					action.data.contextOptions.shouldDefaultRedirect,
			},
		);

		return null;
	}

	private authSuccessWithGoogleOrFacebook(action, response: ILoginResponse) {
		if (
			!response.user.isNewRegisteredUser
			&& this.socialInputs$.value.provider === 'googleId'
		) {
			this.oneTapTrackingService.trackOneTapSignInCompletion();
		}

		this.dataService.loginData$.next({
			res: response,
			type: action.type,
			clickedButton: action.clickedButton,
		});

		this.tagManager$.next({
			userId: response.user.id,
			event: response.user.isNewRegisteredUser
				? 'socialSignup'
				: 'socialLogin',
		});

		this.authCallbackHandler(
			{
				res: response,
				type: action.type,
				clickedButton: action.clickedButton,
			},
			{
				shouldDefaultRedirect:
					action.data.contextOptions.shouldDefaultRedirect,
			},
		);

		return {
			res: response,
			type: action.type,
			clickedButton: action.clickedButton,
		};
	}
}

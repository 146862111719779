import { Container, StorageService } from '../../features/symphony';

import { getInitialServerSideData } from '../../features/shared/public/SlUtils/getInitialServerSideData';
import { GlobalConfig, hostsConfigs } from '../shared/global-configs';
import { ApiUrls } from '../shared/api-urls';
import { getFingerprint } from '../shared/fingerprint-watcher';
import { featureTogglingConfigs } from '../shared/feature-toggling-configs';

export const initGlobalRegistrations = () => {
	let storageService: StorageService;

	if (typeof window !== 'undefined') {
		if (GlobalConfig.env === 'local') {
			(window as any).getContainerData = (context: string) => (Container.of(context) as any).services
				.map((service) => ({
					id: service.id,
					value: service.value,
				}))
				.filter((service) => typeof service.value !== 'symbol');
		}
		storageService = Container.take('global', StorageService);
		Container.register(
			'global',
			'countryCode',
			(window.initialData as any)?.countryCode,
		);
		Container.register('global', 'envUrl', ApiUrls);
		Container.register('global', 'authApiHost', ApiUrls.authApiHost);
		Container.register(
			'global',
			'authenticationEnvUrl',
			ApiUrls.authenticationApiHost,
		);
		Container.register('global', 'environmentUrl', ApiUrls.environmentUrl);
		Container.register('global', 'socketPath', ApiUrls.socketPath);
		Container.register('global', 'hostsConfigs', hostsConfigs);
		Container.register('global', 'env', GlobalConfig.env);
		Container.register('global', 'host', GlobalConfig.host);
		Container.register('global', 'appName', GlobalConfig.appName);
		Container.register('global', 'recaptchaKey', GlobalConfig.recaptchaKey);
		Container.register('global', 'redirectURI', GlobalConfig.redirectURI);
		Container.register('global', 'fbAppId', GlobalConfig.fbAppId);
		Container.register(
			'global',
			'publicTokenCaptchaKey',
			GlobalConfig.publicTokenCaptchaKey,
		);
		const guid = storageService.load('guidv2', 'local')
			|| getInitialServerSideData('guid');
		storageService.save('guidv2', guid, 'local');
		Container.register('global', 'guid', guid);
	}
	Container.register(
		'global',
		'fingerprint',
		getFingerprint((fingerprint: string) => {
			Container.register('global', 'fingerprint', fingerprint);
		}),
	);

	Container.register(
		'global',
		'featureTogglingConfigs',
		featureTogglingConfigs,
	);
};

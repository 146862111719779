import { useState } from 'react';

import { SlNavbarState } from '../global-interface';
import { useCustomLocation } from '../utils/useCustomLocation';
import { SlNavBarPositions, SlNavbarThemes, SlNavbarType } from '../../../shared/public/globalInterfaces/globalInterfaces';

export const useNavbarOptions = () => {
	const location = useCustomLocation();
	const navConfigs = {
		':language': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.dark,
			position: SlNavBarPositions.sticky,
		},
		':language/v2': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.dark,
			position: SlNavBarPositions.sticky,
		},
		':language/learn': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.relative,
		},
		':language/learning/:courseId': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.sticky,
		},
		':language/learn/courses/:courseAlias': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.sticky,
		},
		':language/learn/learning-paths/:alias': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.sticky,
		},
		':language/learn/languages/:languageAlias': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.relative,
		},
		':language/codes': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.relative,
		},
		':language/discuss': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.relative,
		},
		':language/discuss/:id/:alias': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.relative,
		},
		':language/compiler-playground': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.relative,
		},
		':language/teams': {
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.relative,
			type: SlNavbarType.teams,
		},
	};

	const [navbarState, setNavbarState] = useState<SlNavbarState>(
		navConfigs[location.pathname]?.state || SlNavbarState.hidden,
	);

	const [navbarMode, setNavbarMode] = useState<SlNavbarThemes>(
		navConfigs[location.pathname]?.theme || SlNavbarThemes.light,
	);
	const [navbarPosition, setNavbarPosition] = useState<SlNavBarPositions>(
		navConfigs[location.pathname]?.position || SlNavBarPositions.fixed,
	);

	const [navbarType, setNavbarType] = useState<SlNavbarType>(
		navConfigs[location.pathname]?.type || SlNavbarType.default,
	);

	return {
		navbarState,
		setNavbarState,
		navbarMode,
		setNavbarMode,
		navbarPosition,
		setNavbarPosition,
		navbarType,
		setNavbarType,
	};
};

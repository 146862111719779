import React, { useState, useEffect } from 'react';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, i18n } from '../../../../symphony';

import { SwitchPageLink } from '../../components/shared/SwitchPageLink/SwitchPageLink';
import { SocialLogin } from '../SocialLogin/SocialLogin';
import { Divider } from '../../components/global/Divider/Divider';
import { Error } from '../../components/global/Error/Error';
import { SignupForm } from '../../components/forms/SignupForm/SignupForm';
import { SlLoginErrorsService } from '../../services/sl-login-errors.service';
import { SlLoginContext } from '../../login-context';
import { SlLoginLoadingService } from '../../services/sl-login-loading.service';
import { SlLoginTrackingService } from '../../services/sl-login-tracking.service';
import { SlLoginViewService } from '../../services/sl-login-view.service';
import { TermsOfUseLink } from '../../components/shared/TermsOfUseLink/TermsOfUseLink';
import { LoginType, SlLoginActionTypes } from '../../global.interface';
import { SlLoginActionsService } from '../../services/sl-login-actions.service';

import s from './Signup.scss';

interface ISignupProps {
	loginType?: LoginType;
	title?: string;
	description?: string;
	isTitleH1?: boolean;
	isLandingPage?: boolean;
	darkMode?: boolean;
	primaryColor?: string;
}

export const Signup: React.FC<ISignupProps> = React.memo(
	({
		loginType,
		title = i18n.t('web-auth.signup-title'),
		description,
		isTitleH1,
		isLandingPage,
		darkMode,
		primaryColor,
	}) => {
		useStyles(s);
		const { actions$ } = Container.take(
			SlLoginContext,
			SlLoginActionsService,
		);

		const { errors$ } = Container.take(
			SlLoginContext,
			SlLoginErrorsService,
		);

		const { trackAction$ } = Container.take(
			SlLoginContext,
			SlLoginTrackingService,
		);
		const trackingPrefix: string = Container.take(
			SlLoginContext,
			'trackingPrefix',
		);
		const loginViewService = Container.take(
			SlLoginContext,
			SlLoginViewService,
		);

		const [isLoading, setLoading] = useState<boolean>(true);
		const [error, setError] = useState<string>(null);

		useEffect(() => {
			const unsubscriber = new Subject<void>();
			const { loading$ } = Container.take(
				SlLoginContext,
				SlLoginLoadingService,
			);

			loading$.pipe(takeUntil(unsubscriber)).subscribe(setLoading);
			errors$.pipe(takeUntil(unsubscriber)).subscribe(setError);

			trackAction$.next({
				actionName: 'view',
				element: 'signin_signup',
			});

			return () => {
				unsubscriber.next();
				unsubscriber.complete();
			};
		}, []);

		const handleOnBeforeViewChange = () => {
			errors$.next(null);
			loginViewService.setLoginCurrentView();
			trackAction$.next({
				actionName: 'click',
				element: `${trackingPrefix}_signuppage_signin`,
			});
		};

		const emitAction = (type: SlLoginActionTypes) => {
			actions$.next({
				type,
				clickedButton: 'termsOfUse',
				requestType: 'parallel',
			});
		};

		if (loginType === 'internal') {
			return (
				<div
					className="sl-login-signup"
					sl-test-data="cmpSlLoginSignupInternal"
				>
					{error && <Error errorText={error} />}
					<SignupForm
						isLandingPage={isLandingPage}
						darkMode={darkMode}
					/>
					<Divider />
					<SocialLogin type="signup" darkMode={darkMode} />
				</div>
			);
		}

		return (
			<div
				className={`sl-login-signup${darkMode ? ' dark-mode' : ''}`}
				sl-test-data="cmpSlLoginSignup"
			>
				<div className="sl-login-signup__title-block">
					{isTitleH1 ? (
						<h1
							className={`sl-login-signup__title${
								darkMode ? ' dark-mode' : ''
							}`}
						>
							{title}
						</h1>
					) : (
						<h2
							className={`sl-login-signup__title${
								darkMode ? ' dark-mode' : ''
							}`}
						>
							{title}
						</h2>
					)}
					{description && (
						<p className="sl-login-signup__description">
							{description}
						</p>
					)}
				</div>
				{error && <Error errorText={error} />}
				<SignupForm isLandingPage={isLandingPage} darkMode={darkMode} primaryColor={primaryColor} />
				<Divider />
				<SocialLogin type="signup" darkMode={darkMode} />

				<div className="sl-login-signup__switch-link dark-mode">
					<SwitchPageLink
						isLoading={isLoading}
						page="signup"
						onBeforeViewChange={handleOnBeforeViewChange}
						darkMode={darkMode}
						primaryColor={primaryColor}
					/>
				</div>

				<TermsOfUseLink
					onClick={
						!isLoading
							? () => emitAction(SlLoginActionTypes.termsOfUse)
							: () => {}
					}
					darkMode={darkMode}
					primaryColor={primaryColor}
				/>
			</div>
		);
	},
);

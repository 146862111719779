import { Observable } from 'rxjs';
import { IResponse } from '../global.interface';

export interface IAbTestingApiConfig {
    environmentUrl: string;
    useMock?: boolean;
    mock?: IAbTestingApiMock;
    versions?: IAbTestingVersions;
}

export interface IAbTestingApiMock {
    popups: IPopupsResponse;
    getScreenConfigs: IGetScreenConfigsDTO;
}

export interface IAbTestingApi {
    getPopups: (pupUpName: string) => Observable<IPopupsResponse>;
    getScreenConfigs: (
        screenNames: string[]
    ) => Observable<IGetScreenConfigsDTO>;
}

export interface IPopupsResponse extends IResponse {
    data: IPopUpData;
}

export interface IGetScreenConfigsDTO extends IResponse {
    data: IScreenContentData;
}

export enum PopUpDataKeys {
    acceptButtonText = 'accept_button_text',
    modalUrl = 'modal_url',
}
export interface IPopUpData {
    id: number;
    body: string;
    name: string;
    header: string;
    discriminator: string;
    [PopUpDataKeys.modalUrl]: string;
    [PopUpDataKeys.acceptButtonText]: string;
}

export interface IScreenContentData {
    appSetting: null;
    enabledSettings: null;
    experiments: null;
    screenContent: IScreenContent[];
}

export interface IScreenContent {
    id: number;
    name: string;
    title?: string;
    subtitle?: string;
    discriminator?: string;
    negativeButtonText?: string;
    positiveButtonText?: string;
}

export interface IAbTestingVersions {
    getPopups?: string;
}

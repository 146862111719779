import React, { useContext, useEffect } from 'react';
import { from, tap } from 'rxjs';
import useStyles from 'isomorphic-style-loader/useStyles';

import { Container } from '../../../../symphony';

import { SlLoginActionsService } from '../../services/sl-login-actions.service';
import { SlLoginInputService } from '../../services/sl-login-inputs.service';
import { Icon } from '../../components/shared/Icon/Icon';
import { ServiceContext, SlLoginContext } from '../../login-context';
import { SlLoginActionTypes } from '../../global.interface';
import { SlLoginLoadingService } from '../../services/sl-login-loading.service';
import { appleConfigs, appleScriptConfigs } from './configs';
import { SlLoginErrorsService } from '../../services/sl-login-errors.service';
import { DEFAULT_ERROR_MESSAGES } from '../../constants';

import s from './SocialLogin.scss';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { IFlashMessageTypes } from '../../../../shared/public/SlFlashMessage/SlFlashMessage';
import { GoogleSocialLogin } from '../GoogleSocialLogin/GoogleSocialLogin';
import { Social } from '../../../../api/private/global.interface';

export interface ISocialLoginProps {
	type: 'login' | 'signup';
	darkMode?: boolean;
	isGoogleNewVersion?: boolean;
	socials?: Social[];
}

declare global {
	interface Window {
		FB: any;
		AppleID: any;
	}
}

export const SocialLogin: React.FC<ISocialLoginProps> = React.memo(
	({
		type, darkMode, isGoogleNewVersion, socials = [Social.Google, Social.Facebook, Social.Apple],
	}) => {
		useStyles(s);
		const { loading$: isLoading } = useContainerData(
			SlLoginContext,
			SlLoginLoadingService,
			['loading$'],
		);

		const redirectURI = Container.take('global', 'redirectURI');

		const { actions$ } = Container.take(
			SlLoginContext,
			SlLoginActionsService,
		);

		const { socialInputs$ } = Container.take(
			SlLoginContext,
			SlLoginInputService,
		);

		const { flashMessage$ } = Container.take(
			SlLoginContext,
			SlLoginErrorsService,
		);

		const serviceData = useContext(ServiceContext);

		useEffect(() => {
			const script = document.createElement('script');
			script.src = appleScriptConfigs.src;
			script.type = appleScriptConfigs.type;
			script.onload = () => {
				window.AppleID?.auth?.init({ ...appleConfigs, redirectURI });
			};
			document.body.appendChild(script);
		}, []);

		const appleResponseHandler = (response) => {
			actions$.next({
				type: SlLoginActionTypes.appleLogin,
				requestType: 'cancelable',
				clickedButton: type === 'login' ? 'appleLogin' : 'appleSignup',
				data: {
					code: response.authorization.code,
					user: {
						firstName: response.user?.name?.firstName,
						lastName: response.user?.name?.lastName,
					},
					contextOptions: {
						trackingEventParams: serviceData.trackingEventParams,
						shouldDefaultRedirect:
							serviceData.shouldDefaultRedirect,
					},
				},
			});
		};

		const facebookResponseHandler = (res) => {
			if (res.accessToken) {
				socialInputs$.next({
					accessToken: res.accessToken,
					provider: 'facebook',
				});
				actions$.next({
					type: SlLoginActionTypes.socialLogin,
					clickedButton:
						type === 'login' ? 'facebookLogin' : 'facebookSignup',
					requestType: 'cancelable',
					data: {
						accessToken: res.accessToken,
						contextOptions: {
							trackingEventParams:
								serviceData.trackingEventParams,
							shouldDefaultRedirect:
								serviceData.shouldDefaultRedirect,
						},
					},
				});
			}
		};

		useEffect(() => {
			document.body.style.pointerEvents = isLoading ? 'none' : null;
			return () => {
				document.body.style.pointerEvents = null;
			};
		}, [isLoading]);

		const fbResponseApi = (authResponse) => {
			window.FB?.api('/me', { fields: 'id,email,name' }, (me) => {
				const newInstance = {
					...me,
					accessToken: authResponse.accessToken,
				};
				facebookResponseHandler(newInstance);
			});
		};

		const checkFbLoginState = (response: any) => {
			if (response.authResponse) {
				fbResponseApi(response.authResponse);
			} else if (facebookResponseHandler) {
				facebookResponseHandler({ status: response.status } as any);
			}
		};

		const onFbClick = () => {
			if (!window.FB || !window.FB?.login) {
				flashMessage$.next({
					type: IFlashMessageTypes.ERROR,
					message: DEFAULT_ERROR_MESSAGES.FB,
				});
			}

			window.FB?.getLoginStatus(() => {
				window.FB?.login(checkFbLoginState, {
					scope: 'public_profile,email',
				});
			});
		};

		const onAppleClick = () => {
			from(window.AppleID?.auth?.signIn())
				.pipe(
					tap((response) => {
						if (response !== null) {
							appleResponseHandler(response);
						}
					}),
				)
				.subscribe();
		};

		return (
			<div className="sl-login-social-login" sl-test-data="baseElement">
				{
					socials.includes(Social.Google)
					&& <GoogleSocialLogin darkMode={darkMode} isNewVersion={isGoogleNewVersion} />
				}

				{
					socials.includes(Social.Apple)
					&& (
						<div
							className="sl-login-social-login__item dark-mode"
							sl-test-data="appleButtonElement"
						>
							<Icon
								iconId="apple"
								className="sl-login-social-login__item__icon apple-icon sl-login-social-login__item__icon__dark-mode"
							/>
							<button
								type="button"
								onClick={onAppleClick}
								sl-test-data="apple-button"
								className="sl-login-social-login__item--apple sl-login-social-login__item__social-button dark-mode-button"
							>
								<span>Apple</span>
							</button>
						</div>
					)
				}

				{/* {
					socials.includes(Social.Facebook) && (
						<div className="sl-login-social-login__item">
							<Icon
								iconId="facebook"
								className="sl-login-social-login__item__icon"
							/>
							<button
								type="button"
								onClick={onFbClick}
								className={`sl-login-social-login__item--facebook sl-login-social-login__item__social-button${darkMode ? ' dark-mode-button' : ''
									}`}
							>
								<span>Facebook</span>
							</button>
						</div>
					)
				} */}
			</div>
		);
	},
);

import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { SlIcon, useIcons, close } from '@sololearnorg/icons';

import s from './SlFlashMessage.scss';

export enum IFlashMessageTypes {
    SUCCESS = 'success',
    INFO = 'info',
    ERROR = 'error',
}

export interface IFlashMessageData {
    message: string | React.ReactNode;
    type: IFlashMessageTypes;
}

export interface SlFlashMessageProps {
    onClose?: () => void;
    didClosed?: () => void;
    className?: string;
    data?: IFlashMessageData;
}

export const SlFlashMessage: React.FC<SlFlashMessageProps> = React.memo(
	({
		data, onClose, didClosed, className = '',
	}) => {
		useStyles(s);
		useIcons([close]);
		const [content, setContent] = useState<IFlashMessageData>(null);

		const getDisplayTime = (
			flashMessage: string | React.ReactNode,
		): number => {
			const min = 4000;
			let avarage = 0;
			if (typeof flashMessage === 'string') {
				avarage = ((60 * flashMessage.split(' ').length) / 150) * 1000 + 1000;
			}
			return avarage > min ? avarage : min;
		};

		useEffect(() => {
			const time = setTimeout(() => {
				if (didClosed) didClosed();
				setContent(null);
			}, getDisplayTime(data?.message));

			setContent(data);
			return () => {
				clearTimeout(time);
			};
		}, [data]);

		const onCloseFlashMessage = () => {
			if (onClose) onClose();
			setContent(null);
		};

		return content ? (
			<div
				className={`sl-shared-flash-message sl-shared-flash-message--${content.type
				} ${content.message
					? 'sl-shared-flash-message--shown'
					: 'sl-shared-flash-message--hidden'
				} ${className}`}
			>
				{content.message}
				<div
					onClick={onCloseFlashMessage}
					className="sl-shared-flash-message__close"
				>
					<SlIcon iconId="close" />
				</div>
			</div>
		) : null;
	},
);

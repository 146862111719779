import React from 'react';

export function BitcubeIcon() {
	return (
		<svg viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.3">
				<path
					d="M15.4204 15.6729L9.48494 25.9533L9.18137 25.9539C8.99305 25.9544 8.81925 25.854 8.72556 25.6908L3.09339 15.9026C2.99911 15.7402 2.99941 15.5402 3.09289 15.3783L8.87704 5.35986L14.8131 15.6741L15.4204 15.6729Z"
					fill="#7990B4"
				/>
				<path
					d="M26.7126 15.6578L20.9285 25.6761C20.835 25.838 20.6618 25.9383 20.475 25.9384L9.18121 25.9543C9.08661 25.9542 8.9968 25.929 8.91861 25.8839L14.813 15.6746L26.7126 15.6578Z"
					fill="#F2F6FB"
				/>
				<path
					d="M9.33775 5.09328L20.6223 5.07741C20.8132 5.07717 20.9897 5.17909 21.085 5.34459L26.7135 15.1254C26.9178 15.4805 26.6619 15.9237 26.2522 15.9243L14.9677 15.9402C14.7768 15.9404 14.6002 15.8385 14.5049 15.673L8.87649 5.89221C8.67225 5.53715 8.92814 5.09393 9.33775 5.09328Z"
					fill="#C9D3E3"
				/>
			</g>
		</svg>
	);
}

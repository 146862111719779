import React, { useEffect, useState } from 'react';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { IllustrationBits } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, i18n } from '../../../../symphony';

import { UserBitsItem } from '../UserBitsItem/UserBitsItem';
import { BitcubeIcon } from './BitCubeIcon';
import { SlActions } from '../../services/ActionsService/sl-actions';
import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlNavigationContext } from '../../global-constants';
import { ActionType, RequestType } from '../../global-interface';

import s from './UserBitsMenu.scss';
import { SlLoader } from '../../../../shared/public/SlLoader/SlLoader';
import { IBitSourcesData, IIntroToBitState, IUserBitSource } from '../../../../api/public/gamification-api';

interface IUserBitsMenuProps {
	bits: number;
}

export const UserBitsMenu: React.FC<IUserBitsMenuProps> = React.memo(
	({ bits }) => {
		useStyles(s);
		const [sources, setSources] = useState<IUserBitSource[]>(null);
		const [hotSource, setHotSource] = useState<IUserBitSource>(null);
		const [introToBits, setIntroToBits] = useState<IIntroToBitState>(null);
		const [loading, setLoading] = useState<boolean>(false);

		const setBitSourcesData = (bitSourcesData: IBitSourcesData): void => {
			if (bitSourcesData) {
				setHotSource(bitSourcesData.sources.find((s) => s.isHotToday));
				setSources(bitSourcesData.sources.filter((s) => !s.isHotToday));
				setIntroToBits(bitSourcesData.introToBitState);
			}
		};

		useEffect(() => {
			const { bitSources$, bitSourcesLoading$ } = Container.take(
				SlNavigationContext,
				SlNavigationDataService,
			);
			const unsubscriber = new Subject<void>();

			merge(
				bitSources$.pipe(tap(setBitSourcesData)),
				bitSourcesLoading$.pipe(tap(setLoading)),
			)
				.pipe(takeUntil(unsubscriber))
				.subscribe();

			const { actions$ } = Container.take(SlNavigationContext, SlActions);

			actions$.next({
				type: ActionType.OnBitsMenuMount,
				requestType: RequestType.Cancelable,
			});

			return () => {
				unsubscriber.next();
				unsubscriber.complete();
			};
		}, []);

		const renderSourcesContent = (): JSX.Element => {
			if (loading) {
				return (
					<div className="sl-nav-bit-sources-menu__body__loader-wrapper">
						<SlLoader backgroundColor="transparent" />
					</div>
				);
			}
			if (!hotSource && (!sources || !sources.length)) {
				return null;
			}
			return (
				<>
					<p className="sl-nav-bit-sources-menu__body__title">
						{i18n.t('web-navigation.bits-how-to-earn')}
					</p>
					{sources?.map((item: IUserBitSource) => (
						<UserBitsItem
							key={item.id}
							source={item}
							dataAttribute={`${item.id}`}
						/>
					))}
				</>
			);
		};

		return (
			<div className="sl-nav-bit-sources-menu">
				<div className="sl-nav-bit-sources-menu__title">
					<h3>{i18n.t('web-navigation.bits-title')}</h3>
				</div>
				{introToBits ? (
					<div className="sl-nav-bit-sources-menu__intro">
						<div className="sl-nav-bit-sources-menu__intro_cubes">
							<div className="sl-nav-bit-sources-menu__intro__icon__top-left">
								<BitcubeIcon />
							</div>
							<div className="sl-nav-bit-sources-menu__intro__icon__top-right">
								<BitcubeIcon />
							</div>
							<div className="sl-nav-bit-sources-menu__intro__icon__bottom-left">
								<BitcubeIcon />
							</div>
							<div className="sl-nav-bit-sources-menu__intro__icon__bottom-right">
								<BitcubeIcon />
							</div>
						</div>

						<div className="sl-nav-bit-sources-menu__subheader">
							<span className="sl-nav-bit-sources-menu__icon">
								<IllustrationBits />
							</span>
							<span className="sl-nav-bit-sources-menu__bits">
								{bits}
							</span>
						</div>

						<div className="sl-nav-bit-sources-menu__intro__text">
							{introToBits.text}
						</div>
					</div>
				) : null}

				{renderSourcesContent()}
			</div>
		);
	},
);

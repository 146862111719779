import React, { FC, ReactElement, RefObject, useEffect, useState } from "react";
import s from "./LongPaywallComparison.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container, i18n } from "../../../../../../symphony";
import { v4 as uuidv4 } from 'uuid';
import { Checkmark, KodieIcon, MaxIcon, ProIcon } from "../../../ui/icons";
import { CheckoutService } from "../../../../services/checkout.service";
import { useInView } from "../../../../paywalls.utils";
import { PaywallsService } from "../../../../services/paywalls.service";
import { PaywallClickTypes } from "../../../../paywalls.constants";

enum PlanNames {
	free = 'free',
	pro = 'pro',
	max = 'max'
};

enum PlanThemes {
	gold = 'gold',
	purple = 'purple'
}

type Row = {
	type: 'planNames' | 'buttons' | 'feature';
	cells: ReactElement[];
	style?: React.CSSProperties;
};

type Props = {
	data: {
		heading: string;
		plans: {
			name: PlanNames;
			productKey: string;
			cta: string;
			theme: PlanThemes;
		}[];
		features: {
			default: {
				[featureName: string]: PlanNames[];
			};
			ai: {
				[featureName: string]: PlanNames[];
			};
		};
	};
	style: React.CSSProperties;
	sectionOrder: number;
};

export const LongPaywallComparison: FC<Props> = ({ data, style, sectionOrder }) => {
	useStyles(s);
	const [ref, isInView] = useInView({
		threshold: 0.7
	});
	const [rows, setRows] = useState<Row[]>([]);
	const { setSelectedProductKey } = Container.take('paywalls', CheckoutService);
	const { trackSectionView, trackPaywallClick } = Container.take('paywalls', PaywallsService);

	useEffect(() => {
		isInView && trackSectionView('longComparison', sectionOrder);
	}, [isInView]);

	useEffect(() => {
		const bigCell = (children: React.ReactNode, className?: string) =>
			<div key={uuidv4()} className={`comparison-value big ${className ? className : ''}`}>{children}</div>;

		const smallCell = (children: React.ReactNode, className?: string) =>
			<div key={uuidv4()} className={`comparison-value small ${className ? className : ''}`}>{children}</div>;

		const getPlanNamesRow = () => {
			const getIcon = (name: PlanNames) => {
				switch (name) {
					case PlanNames.free:
						return <span className="comparison-icon text">{i18n.t('web-paywall.long.product.name-free')}</span>;
					case PlanNames.pro:
						return <div className="comparison-icon svg"><ProIcon /></div>;
					case PlanNames.max:
						return <div className="comparison-icon svg"><MaxIcon /></div>;
				}
			};
			return {
				type: 'planNames',
				cells: [
					bigCell(null),
					...data.plans.map(p => smallCell(getIcon(p.name)))
				],
				style: { border: 'none' }
			};
		};

		const onButtonClick = (productKey: string, postion: 'top' | 'bottom') => {
			setSelectedProductKey(productKey);
			trackPaywallClick(PaywallClickTypes.cta, `${postion}_${productKey}`);
		};

		const getButtonsRow = (postion: 'top' | 'bottom') => ({
			type: 'buttons',
			cells: [
				bigCell(null),
				...data.plans.map(p => smallCell(p.cta
					? <button
						onClick={() => onButtonClick(p.productKey, postion)}
						className={`comparison-cta ${p.theme}`}
					> {i18n.t(p.cta)}</button >
					: null)
				)
			],
			style: { padding: 0, border: 'none' }
		});

		const supportedPlans = (featureSet: 'ai' | 'default', key: string): Record<PlanNames, boolean> => {
			const supportedPlans = data.features[featureSet][key];
			return {
				[PlanNames.free]: supportedPlans.includes(PlanNames.free),
				[PlanNames.pro]: supportedPlans.includes(PlanNames.pro),
				[PlanNames.max]: supportedPlans.includes(PlanNames.max)
			};
		};

		const comparisonResult = (supported: Record<PlanNames, boolean>, planName: PlanNames, includeKodie: boolean = false) => {
			const theme = data.plans.find(p => p.name === planName).theme;
			const isSupported = supported[planName];

			return <>
				<div className={`comparison-result ${theme} ${isSupported ? 'checkmark' : 'dash'}`}>
					{supported[planName] ? <Checkmark /> : <div></div>}
				</div>
				{supported[planName] && includeKodie && <div className="comparison-kodie">
					<span>{i18n.t('web-paywall.long.ai-tutor')}</span>
					<KodieIcon />
				</div>}
			</>;
		};

		const getDefaultFeatures = () => (
			Object.keys(data.features.default).map((key) => {
				const supported = supportedPlans('default', key);
				return {
					type: 'feature',
					cells: [
						bigCell(i18n.t(key)),
						smallCell(comparisonResult(supported, PlanNames.free)),
						smallCell(comparisonResult(supported, PlanNames.pro)),
						smallCell(comparisonResult(supported, PlanNames.max))
					],
					style: {}
				};
			}));

		const getAIFeatures = () => (
			Object.keys(data.features.ai).map((key, i) => {
				const supported = supportedPlans('ai', key);
				const isFirst = i === 0;
				const isLast = i === Object.keys(data.features.ai).length - 1;
				const getClass = (plan: 'free' | 'pro' | 'max') => {
					return `${supported[plan] ? 'ai' : ''} ${isFirst ? 'first' : ''} ${isLast ? 'last' : ''}`;
				};
				return {
					type: 'feature',
					cells: [
						bigCell(i18n.t(key)),
						smallCell(comparisonResult(supported, PlanNames.free, isFirst), getClass('free')),
						smallCell(comparisonResult(supported, PlanNames.pro, isFirst), getClass('pro')),
						smallCell(comparisonResult(supported, PlanNames.max, isFirst), getClass('max'))
					],
					style: isLast ? {
						border: 'none'
					} : {}
				};
			}));

		const _rows: Row[] = [
			getPlanNamesRow() as Row,
			getButtonsRow('top') as Row,
			...getDefaultFeatures() as Row[],
			...getAIFeatures() as Row[],
			getButtonsRow('bottom') as Row
		];

		setRows(_rows);
	}, [data]);

	return <div ref={ref as RefObject<HTMLDivElement>} style={style} className="comparison">
		<h3>{i18n.t(data.heading)}</h3>
		<section>
			{rows.map((row, i) => (<div
				key={row.type + i}
				className={`comparison-row`}
				style={row.style || {}}
			>
				{row.cells.map(c => c)}
			</div>))}
		</section>
	</div>;
};
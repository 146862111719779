import { v4 as uuidv4 } from 'uuid';
import { Container } from '../../public/container.global';
import {
	AF_WEB_USER_ID_EVENTS, COUNTRY_CODE_EVENTS, DEVICE_TYPE_EVENTS, GU_ID_EVENTS, LOCALE_NAME_EVENTS, UUID_EVENTS, USER_AGENT_EVENTS
} from './tracking.constants';
import { i18n } from '../../public/localization';

export const generateUUID = (
	eventName: string,
	generateId = false,
): { id?: string; } => {
	if (generateId || UUID_EVENTS.includes(eventName)) {
		return {
			id: uuidv4(),
		};
	}
	return null;
};

export const getGuId = (eventName: string): { gu_id?: string; } => {
	if (GU_ID_EVENTS.includes(eventName)) {
		return {
			gu_id: Container.take('global', 'guid'),
		};
	}
	return null;
};

export const getAFUserId = (eventName: string): { af_web_user_id?: string; } => {
	if (AF_WEB_USER_ID_EVENTS.includes(eventName)) {
		const afWebUserId = document.cookie.replace(
			/(?:(?:^|.*;\s*)afUserId\s*=\s*([^;]*).*$)|^.*$/,
			'$1',
		);
		return {
			af_web_user_id: afWebUserId || "",
		};
	}
	return null;
};

export const getCountryCode = (eventName: string): { country_code?: string; } => {
	if (COUNTRY_CODE_EVENTS.includes(eventName)) {
		return {
			country_code: Container.take('global', 'countryCode') || '',
		};
	}
	return null;
};

export const getDeviceType = (eventName: string): { device_type?: string; } => {
	if (DEVICE_TYPE_EVENTS.includes(eventName)) {
		return {
			device_type: Container.take('global', 'deviceType'),
		};
	}
	return null;
};

export const getLocaleName = (eventName: string): { locale_name?: string; } => {
	if (LOCALE_NAME_EVENTS.includes(eventName)) {
		return {
			locale_name: i18n.language,
		};
	}
	return null;
};

export const getUserAgent = (eventName: string): { user_agent?: string; } => {
	if (USER_AGENT_EVENTS.includes(eventName)) {
		return {
			user_agent: window?.navigator?.userAgent
		};
	}
	return null;
};

export const setDeviceType = (): void => {
	const screenWidth = window.screen.width;
	let deviceType: string;

	if (screenWidth <= 768) {
		deviceType = 'MOBILE';
	} else if (screenWidth <= 1024) {
		deviceType = 'TABLET';
	} else {
		deviceType = 'DESKTOP';
	}

	Container.register('global', 'deviceType', deviceType);
};

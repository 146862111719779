import { Container } from '../../../public/container.global';
import { AuthService } from '../../../public/auth.service';
import { Tracking } from '../tracking';
import { appsFlyerEventsMapping } from './appsFlyerEventsMapping';
import { ITracking } from '../tracking.interface';
import { TrackingObjType } from '../../../public/tracking/tracking.interface';

declare const AF: (
    pba: 'pba',
    event: 'event',
    data: {
        eventType: string;
        eventName: string;
        eventValue?: unknown;
    }
) => void;

export class AppsFlyerTracking extends Tracking implements ITracking {
	private authService = Container.take('global', AuthService);

	private fingerprint = Container.take('global', 'fingerprint');

	constructor() {
		super(appsFlyerEventsMapping);
	}

	public handleTracking = (trackingObj: TrackingObjType): void => {
		if (typeof AF === 'undefined') return;

		const { params, eventName } = this.getEventParams(trackingObj);

		if (!eventName) return;

		AF('pba', 'event', {
			eventType: 'EVENT',
			eventValue: {
				sl_platform_name: 'web',
				sl_user_id: this.authService.getUser()?.id,
				sl_locale_name: 'en',
				sl_fingerprint: this.fingerprint,
				...params,
			},
			eventName,
		});
	};
}

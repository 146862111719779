import React, { useEffect, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { history } from '../../features/symphony';

export type IGuardFunction = (location: Location) => boolean;

export interface IRouteGuardProps {
	component: React.ReactElement;
	guards: IGuardFunction[];
}

export const RouteGuard: React.FC<IRouteGuardProps> = (
	routerProps,
): any => {
	const location = useLocation();

	const getActivationStatus = (guards) => {
		let activationStatus: boolean = true;
		for (const guard of guards) {
			if (!guard(location)) {
				activationStatus = false;
				break;
			}
		}
		return activationStatus;
	};

	const { component, guards, ...rest } = routerProps;
	const [canActivate, setCanActivate] = useState<boolean>(getActivationStatus(guards));
	useEffect(() => {
		setCanActivate(getActivationStatus(guards));
	}, [history.location]);

	return canActivate ? (
		<component.type {...component.props} {...rest} />
	) : null;
};

import { Observable, of } from 'rxjs';

import { BaseApi } from '../private/base-api';
import { constructUrl } from '../private/utils/httpParamsUtils';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { IApiUrls, IHostsConfigs } from '../private/global.interface';
import {
	IAbTestingApi,
	IPopupsResponse,
	IAbTestingApiMock,
	IAbTestingVersions,
	IGetScreenConfigsDTO,
} from '../private/abTesting-api/abTesting-api.interface';
import { SlApiContext } from '../private/api-context';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { Service, Container } from '../../symphony';

@Service()
export class AbTestingApi extends BaseApi implements IAbTestingApi {
	private environmentUrl: string;

	private useMock: boolean;

	private mock: IAbTestingApiMock;

	private versions: IAbTestingVersions;

	private axiosInstance: AxiosInstanceType;

	private env: string;

	constructor() {
		super();
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).abTestingApiHost;
		this.useMock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.abTestingApi?.useMock;
		this.mock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.abTestingApi?.mock;
		this.versions = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.abTestingApi?.version;
		this.env = Container.take('global', 'env');

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.authenticated)
	public getPopups(popupName: string): Observable<IPopupsResponse> {
		if (this.useMock) return of(this.mock.popups);
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				`api/Popups/${popupName}`,
				this.versions?.getPopups,
			),
			{
				params: {
					language: 'en',
					platform: '1',
				},
			},
		);
	}

	@Authorize(AuthLevel.public)
	public getScreenConfigs(
		screenNames: string[],
	): Observable<IGetScreenConfigsDTO> {
		let names = '';
		screenNames.forEach((item) => {
			names = `${names.concat(`names=${item}`)}&`;
		});
		if (this.useMock) return of(this.mock.getScreenConfigs);
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				`api/settings?${names}`,
				this.versions?.getPopups,
			),
			{
				params: {
					language: 'en',
					platform: '1',
					cached: false,
					buildVersion: 0,
					fields: 'popups',
					testerKey:
						this.env !== 'prod'
							? 'quCK0dKu5D5A1niUb342r3StWpesi3D4'
							: '',
				},
			},
		);
	}
}

export * from '../private/abTesting-api/abTesting-api.interface';

import React from 'react';
import { Button } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	AuthService, Container, history, i18n,
} from '../../../../symphony';

import { SlLoginActionsContext } from '../../login-context';
import { SlLoginActionsTrackingService } from '../../services/slLoginActions/sl-login-actions.tracking.service';

import s from './LoginActions.scss';

export interface ILoginActionsContainerProps {
	mode: 'dark' | 'light';
}

export const LoginActionsContainer: React.FC<ILoginActionsContainerProps> = React.memo(({ mode }) => {
	useStyles(s);
	const trackingPrefix = Container.take(
		SlLoginActionsContext,
		'trackingPrefix',
	);

	const onActionsClick = (
		element: 'login' | 'register',
		navigationUrl: string,
	): void => {
		const onLoginClick: () => void = Container.take(
			SlLoginActionsContext,
			'onLoginClick',
		);
		const onSignUpClick: () => void = Container.take(
			SlLoginActionsContext,
			'onSignUpClick',
		);

		if (element === 'login' && onLoginClick) {
			onLoginClick();
		} else if (element === 'register' && onSignUpClick) {
			onSignUpClick();
		} else {
			history.push(navigationUrl);
		}
		const { loginActionsTrackAction$ } = Container.take(
			SlLoginActionsContext,
			SlLoginActionsTrackingService,
		);
		loginActionsTrackAction$.next({
			actionName: 'click',
			element: `${trackingPrefix}_${element}`,
		});
	};

	const authService = Container.take('global', AuthService);

	const defineActions = () => {
		if (authService.getUser()) {
			return null;
		}
		return (
			<div className={`sl-navigation-actions__links-wrapper ${mode}`}>
				<Button
					className="sl-action-btn"
					buttonType="secondary"
					onClick={() => onActionsClick('login', '/users/login')}
					tabIndex="0"
					sl-test-data="actionButtonsecondary"
				>
					{i18n.t('web-auth.nav-login')}
				</Button>
				<Button
					className="sl-action-btn"
					onClick={() => onActionsClick('register', '/users/signup')}
					tabIndex="0"
					sl-test-data="actionButtonprimary"
				>
					{i18n.t('web-auth.nav-register')}
				</Button>
			</div>
		);
	};
	return <>{defineActions()}</>;
});

import { useRef, Ref } from 'react';

export const useComponentWillMount = (callback: () => void): Ref<boolean> => {
	const isInitialized = useRef(false);
	if (!isInitialized.current) {
		callback();
		isInitialized.current = true;
	}
	return isInitialized;
};

import { Subject, takeUntil, tap } from 'rxjs';
import { Container } from '../container.global';
import { DataService } from '../data.service';
import {
	IDailyStreakParams,
	IDailyStreakXpSource,
	IXpSource,
	IXpSourceParams,
	IXpSources,
	XpSourceName,
	XpSourceGroupType,
} from '../global-interfaces';

export function findXpBySource(
	sourceName: XpSourceName,
	sources: IXpSource[],
): number {
	const xp = sources.find((source) => source.sourceName === sourceName)?.xp;

	if (typeof xp === 'number') return Math.round(xp);

	return null;
}

export function findDailySteakXp(
	day: number,
	steakXps: IDailyStreakXpSource[],
): number {
	const xp = steakXps.find((steak) => steak.day === day)?.xp;

	if (typeof xp === 'number') return Math.round(xp);

	return null;
}

export function getXpCount(
	source: IXpSourceParams | IDailyStreakParams,
	callback: (xp: number) => void,
	unsubscriber: Subject<void>,
) {
	const dataService: DataService = Container.take('global', DataService);

	dataService.xpSourcesList$
		.pipe(
			takeUntil(unsubscriber),
			tap((data: IXpSources) => {
				if (data) {
					let xp: number;

					if (source.type === XpSourceGroupType.XpSources) {
						xp = findXpBySource(source.value, data.xpSources);
					}
					if (source.type === XpSourceGroupType.DailyStreak) {
						xp = findDailySteakXp(source.value, data.dailyStreak);
					}

					callback(xp);
				}
			}),
		)
		.subscribe();
}

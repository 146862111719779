import { Subject } from 'rxjs';

import { Container, Service } from '../container.global';
import { AppsFlyerTracking } from '../../private/tracking/appsFlyer/appsFlyerTracking';
import { GTMTracking } from '../../private/tracking/gtm/gtmTracking';
import { ITracking } from '../../private/tracking/tracking.interface';
import { SololearnTracking } from '../../private/tracking/sololearn/sololearnTracking';
import { TrackingObjType } from './tracking.interface';
import { IterableTracking } from '../../private/tracking/iterable/iterableTracking';
import { SololearnNewTracking } from '../../private/tracking/sololearnNew/sololearnNewTracking';
import { FBPixelTracking } from '../../private/tracking/fbPixel/fbPixelTracking';
import { setDeviceType } from '../../private/tracking/tracking.helpers';

@Service()
export class TrackingService {
	public tracking$: Subject<TrackingObjType> = new Subject();

	private symphonyToggle = Container.take('global', 'featureTogglingConfigs')[
		'mf-symphony'
	];

	private trackingServiceClasses: Array<new () => ITracking> = [
		AppsFlyerTracking,
		GTMTracking,
		SololearnTracking,
		SololearnNewTracking,
		FBPixelTracking,
		...(this.symphonyToggle.iterableTracking?.state === 'enabled'
			? [IterableTracking]
			: []),
	];

	private handleTracking = (trackingObj: TrackingObjType) => {
		this.trackingServiceClasses
			.map((TrackingServiceClass) => new TrackingServiceClass())
			.forEach((trackingService) => {
				trackingService.handleTracking(trackingObj);
			});
	};

	constructor() {
		if (typeof window === 'undefined') {
			return;
		}

		setDeviceType();

		this.tracking$.subscribe((trackingObj) => {
			this.handleTracking(trackingObj);
		});
	}
}

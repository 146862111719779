import { Observable, of, throwError } from 'rxjs';
import {
	delay, retryWhen, switchMap, take,
} from 'rxjs/operators';

import { SlApiContext } from '../private/api-context';
import {
	SlAxiosInstanceService,
	AxiosInstanceType,
} from '../private/services/sl-axios-instance.service';
import {
	IFreeHeartPayload,
	IKudosApi,
	IKudosApiMock,
	IKudosHeartConfigsResponse,
	IKudosResponse,
	IKudosVersions,
	IUseHeartPayload,
} from '../private/kudos-api/kudos-api.interface';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { constructUrl } from '../private/utils/httpParamsUtils';
import {
	IError,
	ResponseErrorCodes,
	IApiUrls,
	IHostsConfigs,
} from '../private/global.interface';
import { Service, Container, UserTrackService } from '../../symphony';

@Service()
export class KudosApi implements IKudosApi {
	private environmentUrl: string;

	private useMock: boolean;

	private mock: IKudosApiMock;

	private versions: IKudosVersions;

	private axiosInstance: AxiosInstanceType;

	private userTrackService = Container.take('global', UserTrackService);

	constructor() {
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).kudosApiHost;
		this.useMock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.kudosApi?.useMock;
		this.mock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.kudosApi?.mock;
		this.versions = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.kudosApi?.version;
		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.public)
	public getHearts(): Observable<IKudosResponse> {
		if (this.useMock) return of(this.mock.getHearts);

		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				'hearts',
				this.versions?.getHearts,
			), {
			params: {
				track: this.userTrackService.getUserTrack()
			}
		}
		);
	}

	@Authorize(AuthLevel.public)
	public freeHeartRefill(
		payload: IFreeHeartPayload,
	): Observable<IKudosResponse> {
		if (this.useMock) return of(this.mock.freeHeartRefill);

		return this.axiosInstance.post(
			constructUrl(
				this.environmentUrl,
				'hearts/shopfree',
				this.versions?.freeHeartRefill,
			),
			payload,
		);
	}

	@Authorize(AuthLevel.public)
	public getHeartConfigs(): Observable<IKudosHeartConfigsResponse> {
		if (this.useMock) return of(this.mock.getHeartConfigs);

		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				'heartconfigs',
				this.versions?.getHeartConfigs,
			),
		);
	}

	@Authorize(AuthLevel.public)
	public useHeart(payload: IUseHeartPayload[]): Observable<IKudosResponse> {
		if (this.useMock) return of(this.mock.useHeart);

		return this.axiosInstance
			.post(
				constructUrl(
					this.environmentUrl,
					'hearts/usages',
					this.versions?.useHeart,
				),
				payload,
			)
			.pipe(
				retryWhen<IKudosResponse>((errors) => errors.pipe(
					delay(1000),
					take(4),
					switchMap((err: IError[], index) => {
						let internalErr;
						let internalIndex = 3;
						if (
							err.find(
								(item) => item.code === ResponseErrorCodes.One,
							)
						) {
							internalIndex = 0;
							internalErr = err;
						} else {
							internalErr = 'Unable to push use hearts';
						}
						return index === internalIndex
							? throwError(internalErr)
							: of(null);
					}),
				)),
			);
	}
}

export * from '../private/kudos-api/kudos-api.interface';

import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { BitLocationType, IStreak, i18n } from '../../../symphony';

import { StreakWeek } from '../Streak/StreakWeek/StreakWeek';
import { StreakInfo } from '../Streak/StreakInfo/StreakInfo';
import { StreakSaver } from '../Streak/StreakSaver/StreakSaver';
import { StreakIconColor } from '../Streak/StreakIcon/StreakIcon';

import s from './SlStreakMenu.scss';

export const SlStreakMenu: React.FC<IStreak> = React.memo(
	({
		daysCount, maxDaysCount, weekDays, freezeAmount, maxFreezeAmount,
	}) => {
		useStyles(s);
		return (
			<div className="sl_streak_menu__container">
				{' '}
				<p className="sl_streak_menu__title">
					{i18n.t('web-streak.title')}
				</p>
				<div className="sl_streak_menu__week">
					<StreakWeek days={weekDays} />
					<div className="sl_streak_menu__divider" />
				</div>
				<div>
					<div className="sl_streak_menu__info">
						<StreakInfo
							id="Current"
							title={i18n.t('web-streak.details-current')}
							color={StreakIconColor.Reached}
							value={daysCount}
						/>
						<StreakInfo
							id="Longest"
							title={i18n.t('web-streak.details-longest')}
							color={StreakIconColor.Longest}
							value={maxDaysCount}
						/>
					</div>
				</div>
				<div className="sl_streak_menu__streakSaver">
					<StreakSaver
						freezeAmount={freezeAmount}
						maxFreezeAmount={maxFreezeAmount}
						location={BitLocationType.LearningPath}
					/>
				</div>
			</div>
		);
	},
);

import { BehaviorSubject } from "rxjs";
import { Service } from "typedi";
import { PlanConfigKey, SubscriptionPlanConfig } from "../../api/public/subscription-api";
import { Container } from "./container.global";
import { UserTrackService } from "./user-track.service";
import { CourseTrack } from "./global-interfaces";

@Service()
export class SubscriptionPlanService {
	private userTrackService = Container.take('global', UserTrackService);

	public subscriptionPlanConfigs = new BehaviorSubject<SubscriptionPlanConfig>(null);

	public getConfig = (key: PlanConfigKey) => {
		const userTrack = this.userTrackService.getUserTrack();
		const planConfigs = this.subscriptionPlanConfigs.value;
		if (planConfigs) {
			const trackConfig = userTrack === CourseTrack.AI ? planConfigs.tracks.ai : planConfigs.tracks.coding;
			const commonConfig = planConfigs.common;
			return key in trackConfig ? trackConfig[key] : commonConfig[key];
		}
		return null;
	};

	public getId = () => this.subscriptionPlanConfigs.value?.id.toString() || '';
}
import { IResponse, IExperimentResponse } from '../global.interface';

export interface IExperimentalCoursesExperimentResponse extends IResponse {
    data: IExperimentalCoursesData;
}

export enum IExperimentalCoursesPageNames {
    dailyDosePython = 'dailyDosePython',
    gameDevelopmentJS = 'gameDevelopmentJS',
    pythonForFinance = 'pythonForFinance',
    applyToAllCourses = 'applyToAllCourses',
}

export interface IExperimentalCoursesData extends IExperimentResponse {
    pages: {
        pageId: number;
        pageName: IExperimentalCoursesPageNames;
        version: string;
        data: IExperimentalCourseData;
    }[];
}

export interface IFreePracticeData {
    all: boolean;
    ids?: number[];
    count?: number;
}

/* eslint-disable camelcase */
export interface IExperimentalCoursePopupData {
    title: string;
    subtitle_1: string;
    subtitle_2: string;
    button_text: string;
    xp: number;
    version: string;
}

export interface IMandatoryPracticeStates {
    codeCoach: boolean;
    codeRepo: boolean;
}

export interface IExperimentalCourseData {
    experimentalCourseId: number;
    courseBlackListIds?: number[];
    freeTIY?: IFreePracticeData;
    freeCodeCoach?: IFreePracticeData;
    freeCodeRepo?: IFreePracticeData;
    goalCongratsPopup?: IExperimentalCoursePopupData;
    mandatory?: IMandatoryPracticeStates;
    isHeartEnabled?: boolean;
    applyToAllCourses?: boolean;
}

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Service } from '../../../../symphony';

declare const slTrack: (payload: {
	action: string;
	element: string;
	experiment?: string;
	redirectUrl?: string;
}) => void;

declare const dataLayer: Array<ITagManagerAction>;

export interface ITrackAction {
	actionName: string;
	element: string;
}

export interface ITagManagerAction {
	event: string;
	userId?: number;
}

@Service()
export class SlLoginActionsTrackingService {
	public loginActionsTrackAction$ = new BehaviorSubject<ITrackAction>(null);

	public tagManager$ = new BehaviorSubject(null);

	// todo suggest better name
	public loginActionsTrackActionHandler$: Observable<ITrackAction> = this.loginActionsTrackAction$.pipe(
		filter((a) => !!a),
		tap((action: ITrackAction) => {
			slTrack({
				action: action.actionName,
				element: action.element,
			});
		}),
	);

	public tagManagerHandler$: Observable<ITagManagerAction> = this.tagManager$.pipe(
		filter((a) => !!a),
		tap((action) => {
			dataLayer.push(action);
		}),
	);
}

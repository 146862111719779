import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { Button } from '@sololearnorg/sol';
import { i18n } from '../../../../../symphony';

import { IconHeartRefill } from '../../icons/IconHeartRefill';
import { IconBit } from '../../icons/IconBit';
import { IconBitSmall } from '../../icons/IconBitSmall';

import s from './BitsSection.scss';

export interface IBitsSection {
    bits: number;
    price: number;
    onClick: () => void;
}
export const BitsSection: React.FC<IBitsSection> = React.memo(
	({ bits, price, onClick }) => {
		useStyles(s);
		return (
			<div className="bits-section">
				<div
					className={`bits-section__icon${bits < price ? ' bits-section__icon__disabled' : ''
					}`}
				>
					<IconHeartRefill />
				</div>
				<div className="bits-section__content">
					<div className="bits-section__info">
						<div className="bits-section__info__label">
							{i18n.t('web-hearts.your-bits-label')}
						</div>
						<div
							className={`bits-section__info__icon${bits < price
								? ' bits-section__info__icon__disabled'
								: ''
							}`}
						>
							<IconBitSmall />
						</div>
						<div className="bits-section__info__bits-count">
							{bits}
						</div>
					</div>
					<Button
						className="bits-section__cta"
						buttonType="secondary"
						disabled={bits < price}
						onClick={onClick}
					>
						{i18n.t('web-hearts.one-refill')}
						<div className="bits-section__cta__icon">
							<IconBit />
						</div>
						{price}
					</Button>
				</div>
			</div>
		);
	},
);

import React, { useEffect, useState } from 'react';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';

import { IconChevronLeftSmall } from '@sololearnorg/sol';

import {
	Container,
	AuthService,
	DataService,
	StreakService,
	HeartService,
	BitService,
	IUser,
	IBits,
	IHearts,
	IStreak,
	TrackingEventNames,
	TrackingService,
	history,
} from '../../../../symphony';
import { UserBits } from '../UserBits/UserBits';
import { UserHeart } from '../UserHeart/UserHeart';
import { UserStreak } from '../UserStreak/UserStreak';
import { SlUserMenu } from '../SlUserMenu/SlUserMenu';
import { SlProButton } from '../SlProButton/SlProButton';
import { SlActions } from '../../services/ActionsService/sl-actions';
import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlNavigationContext } from '../../global-constants';
import { ActionType, RequestType } from '../../global-interface';
import { SlLoginActions } from '../../../../login/public/SlLoginActions/SlLoginActions';
import { SlNavbarThemes } from '../../../../shared/public/globalInterfaces/globalInterfaces';

import s from './SlNavigationActions.scss';

export interface ISlNavigationActionsProps {
	mode: SlNavbarThemes;
}

export const SlNavigationActions: React.FC<ISlNavigationActionsProps> = React.memo(({ mode }) => {
	useStyles(s);
	const [user, setUser] = useState<IUser>(null);
	const [bits, setBits] = useState<IBits>(null);
	const [hearts, setHearts] = useState<IHearts>(null);
	const [streak, setStreak] = useState<IStreak>(null);

	const [showNavItems, setShowNavItems] = useState<boolean>(false);
	const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
	const [bitsMenuOpen, setBitsMenuOpen] = useState<boolean>(false);
	const [streakMenuOpen, setStreakMenuOpen] = useState<boolean>(false);

	const {
		userMenuOpen$, bitsMenuOpen$, streakMenuOpen$, navBarOpen$,
	} = Container.take(SlNavigationContext, SlNavigationDataService);

	const { actions$ } = Container.take(SlNavigationContext, SlActions);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		const { userInfo$ } = Container.take('global', AuthService);
		const { bits$ } = Container.take('global', BitService);
		const { hearts$ } = Container.take('global', HeartService);
		const { streak$ } = Container.take('global', StreakService);
		const { showNavItems$ } = Container.take('global', DataService);

		merge(
			userInfo$.pipe(tap(setUser)),
			bits$.pipe(tap(setBits)),
			hearts$.pipe(tap(setHearts)),
			streak$.pipe(tap(setStreak)),
			showNavItems$.pipe(tap(setShowNavItems)),
			userMenuOpen$.pipe(tap(setUserMenuOpen)),
			bitsMenuOpen$.pipe(tap(setBitsMenuOpen)),
			streakMenuOpen$.pipe(tap(setStreakMenuOpen)),
		)
			.pipe(takeUntil(unsubscriber))
			.subscribe();

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const closeNavbarMenu = (): void => {
		navBarOpen$.next(false);
	};

	const onLoginClick = (): void => {
		closeNavbarMenu();
		actions$.next({
			type: ActionType.TrackNavigationElementsClick,
			requestType: RequestType.Parallel,
			data: {
				clickAlias: 'LOGIN',
			},
		});
		history.push('/users/login');
	};

	const onSignUpClick = (): void => {
		closeNavbarMenu();
		const { tracking$ } = Container.take('global', TrackingService);
		tracking$.next({
			eventName: TrackingEventNames.clickToSignUp,
			eventParams: {
				signupCta: 'navigation',
			},
		});

		actions$.next({
			type: ActionType.TrackNavigationElementsClick,
			requestType: RequestType.Parallel,
			data: {
				clickAlias: 'REGISTER',
			},
		});

		history.push('/users/signup');
	};

	const onBitsMenuBackClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
	): void => {
		e.stopPropagation();
		bitsMenuOpen$.next(false);
	};

	const onStreakMenuBackClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
	): void => {
		e.stopPropagation();
		streakMenuOpen$.next(false);
	};

	const onUserMenuBackClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
	): void => {
		e.stopPropagation();
		userMenuOpen$.next(false);
	};

	const renderGoForwardIcon = (): JSX.Element => (
		<div className="sl-navigation-actions__settings__back-icon">
			<IconChevronLeftSmall />
		</div>
	);

	const renderGoBackIcon = (
		onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
	): JSX.Element => (
		<div
			onMouseDown={onClick}
			className="sl-navigation-actions__settings__go-back"
		>
			<div className="sl-navigation-actions__settings__go-back__icon">
				<IconChevronLeftSmall />
			</div>
			<span>Back</span>
		</div>
	);

	const renderLoginActions = (): JSX.Element => (
		<div>
			<SlLoginActions
				mode={mode}
				trackingPrefix="navigation"
				onLoginClick={onLoginClick}
				onSignUpClick={onSignUpClick}
			/>
		</div>
	);

	const renderActions = () => (
		<>
			{showNavItems && (
				<>
					{streak && (
						<div
							className={classNames('sl-navigation-actions__settings', {
								active: streakMenuOpen,
								hide: userMenuOpen || bitsMenuOpen,
							})}
						>
							{streakMenuOpen && renderGoBackIcon(onStreakMenuBackClick)}
							<UserStreak />
							{!streakMenuOpen && renderGoForwardIcon()}
						</div>
					)}

					{hearts && (
						<div
							className={classNames('sl-navigation-actions__settings', {
								active: userMenuOpen,
								hide: userMenuOpen || bitsMenuOpen || streakMenuOpen,
							})}
						>
							<UserHeart />
							{renderGoForwardIcon()}
						</div>
					)}

					{bits && (
						<div
							className={classNames('sl-navigation-actions__settings', {
								active: bitsMenuOpen,
								hide: userMenuOpen || streakMenuOpen,
							})}
						>
							{bitsMenuOpen && renderGoBackIcon(onBitsMenuBackClick)}
							<UserBits />
							{!bitsMenuOpen && renderGoForwardIcon()}
						</div>
					)}
				</>
			)}

			<div
				className={classNames('sl-navigation-actions__settings', {
					active: userMenuOpen,
					hide: bitsMenuOpen || streakMenuOpen,
				})}
			>
				{userMenuOpen && renderGoBackIcon(onUserMenuBackClick)}
				<SlUserMenu user={user} />
				{!userMenuOpen && renderGoForwardIcon()}
			</div>

			<div className="sl-navigation-actions__pro-button-wrapper">
				<SlProButton />
			</div>
		</>
	);

	return (
		<div className="sl-navigation-actions">
			{user ? renderActions() : renderLoginActions()}
		</div>
	);
});

import { Observable } from 'rxjs';

import { CodeLanguages, IResponse } from '../global.interface';

export interface ICodeApiConfig {
    environmentUrl: string;
    useMock?: boolean;
    mock?: ICodeApiMock;
    versions?: ICodeVersions;
}

export interface ICodeApiMock {
    defaultCodes?: ICodeDefaultsResponse;
    defaultCode?: ICodeDefaultResponse;
    userCode?: IUserCodeResponse;
    codeTemplate?: ICodeTemplateResponse;
    compiledCode?: ICodeCompilationResponse;
    savedCode?: IUserCodeResponse;
    freeCodes?: IFreeCodesResponse;
}

export enum DefaultCodeLanguages {
    CPP = 'cpp',
    C = 'c',
    JAVA = 'java',
    PHP = 'php',
    JQUERY = 'jquery',
    CS = 'cs',
    KOTLIN = 'kt',
    RUBY = 'rb',
    PYTHON = 'py',
    SWIFT = 'swift',
    NODEJS = 'node',
    WEB = 'web',
    GO = 'go',
    R = 'r',
    TS = 'ts',
}

export interface ICodeBitsSearchParams {
    query: string;
    filter: CodeBitsSearchFilter;
    language?: string;
    index: number;
    count: number;
    profileId?: number;
}

export interface ICodeAPI {
    getDefaultCodes: () => Observable<ICodeDefaultsResponse>;
    getDefaultCode: (
        language: DefaultCodeLanguages
    ) => Observable<ICodeDefaultResponse>;
    getUserCode: (codeId: string) => Observable<IUserCodeResponse>;
    saveUserCode: (payload: IUserCodePayload) => Observable<IUserCodeResponse>;
    deleteUserCode: (id: number) => Observable<IDeleteCodeResponse>;
    getTemplate: (
        templateId: number,
        courseId?: number
    ) => Observable<ICodeTemplateResponse>;
    compileCode: (
        payload: ICodeCompilePayload
    ) => Observable<ICodeCompilationResponse>;
    getFreeCodes: () => Observable<IFreeCodesResponse>;
    getLanguagesCatalog: () => Observable<ILanguagesCatalogResponse>;
    voteUserCode: (
        payload: IVoteUserCodePayload
    ) => Observable<IVoteUserCodeResponse>;
    getCodeBits: (
        options: ICodeBitsSearchParams
    ) => Observable<ICodeBitsDetailsResponse>;
}

export interface IUserCode {
    userName: string;
    avatarUrl: string;
    createdDate: string;
    publicId: string;
    language: CodeLanguages;
    name: string;
    isPublic: boolean;
    sourceCode: string;
    cssCode: string;
    jsCode: string;
    id: number;
    userId: number;
    modifiedDate?: string;
    vote?: number;
    votes?: number;
}

export interface IUserCodeResponse extends IResponse {
    data: IUserCode;
}

export interface IUserCodePayload {
    publicId?: string;
    language: CodeLanguages;
    name: string;
    isPublic: boolean;
    sourceCode: string;
    cssCode?: string;
    jsCode?: string;
    id?: number;
}

export interface ICodeTemplate {
    code: string;
    jsCode: string;
    cssCode: string;
    language: CodeLanguages;
    id: number;
    vote?: number;
    votes?: number;
}

export interface IVoteUserCodePayload {
    id: number;
    vote: number;
}

export interface IDeleteCodeResponse extends IResponse {
    data: boolean;
}

export interface ICodeTemplateResponse extends IResponse {
    data: ICodeTemplate;
}

export interface ICodeDefaultsResponse extends IResponse {
    data: ICodeTemplate[];
}

export interface ICodeDefaultResponse extends IResponse {
    data: ICodeTemplate;
}

export interface ICodeCompilePayload {
    code: string;
    language: CodeLanguages;
    input: string;
    codeId?: string;
}

export interface ICodeCompilationResult {
    sourceCode: string;
    status: number;
    errorCode: number;
    output: string;
    outputStyle: {
        dark: string;
        light: string;
    };
    outputType: IOutputType;
    date: string;
    language: CodeLanguages;
    input: string;
    id: number;
}

export interface ICodeCompilationResponse extends IResponse {
    data: ICodeCompilationResult;
}

export enum IOutputType {
    raw = 0,
    html = 1,
}

export interface ICodeVersions {
    getDefaultCodes?: string;
    getDefaultCode?: string;
    getUserCode?: string;
    saveUserCode?: string;
    deleteUserCode?: string;
    getTemplate?: string;
    compileCode?: string;
    freeCodes?: string;
}

export interface IFreeCodesData {
    isAllFree: boolean;
    ids: number[];
}

export interface IFreeCodesResponse extends IResponse {
    data: IFreeCodesData;
}

export interface ILanguagesData {
    isAllFree: boolean;
    ids: number[];
}

export interface ILanguage {
    key: string;
    displayName: string;
    name: string;
    color: string;
}

export type LanguagesCatalogType = {
    category: {
        name: string;
        id: number;
    };
    languageDetails: ILanguage[];
}[];

export interface ILanguagesCatalogResponse extends IResponse {
    data: LanguagesCatalogType;
}

export enum CodeBitsSearchFilter {
    MostRecent = 1,
    MostPopular = 2,
    YourNetwork = 3,
    HotToday = 4,
    Trending = 5,
    OwnData = 6,
}

export interface ICodeBitsDetailsResponse {
    count: number;
    projects: IProjectData[];
}

export interface IProjectData {
    id: number;
    name: string;
    language: string;
    votes: number;
    comments: number;
    createdDate: string;
    modifiedDate: string;
    viewCount: number;
    publicId: string;
    isPublic: boolean;
    userId: number;
    userName: string;
    avatarUrl: string | null;
    badge: string | null;
}

export interface IVoteUserCodeResponse extends IResponse {
    votes: number;
}

import React, { useEffect, useState } from 'react';
import {
	merge, tap, Subject, takeUntil,
} from 'rxjs';

import {
	Container,
	HeartService,
	IHeartData,
	ILeaveLessonData,
	StreakService,
} from '../../features/symphony';

import { HeartModal } from './HeartModal';
import { LeaveLessonModal } from './LeaveLessonModal';
import { BuyStreakSaverModal } from './BuyStreakSaverModal';

export const SolModalsWrapper: React.FC = (): JSX.Element => {
	const [heartModal, setHeartModal] = useState<IHeartData>(null);
	const [leaveLessonModal, setLeaveLessonModal] = useState<ILeaveLessonData>(null);
	const [streakSaverModal, setStreakSaverModal] = useState<boolean>(null);

	useEffect(() => {
		const unsubscriber = new Subject<void>();
		const { heartModal$, leaveLessonModal$ } = Container.take('global', HeartService);
		const { openStreakSaverModal$ } = Container.take('global', StreakService);

		merge(
			heartModal$.pipe(tap(setHeartModal)),
			leaveLessonModal$.pipe(tap(setLeaveLessonModal)),
			openStreakSaverModal$.pipe(tap(setStreakSaverModal)),
		).pipe(takeUntil(unsubscriber)).subscribe();

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	});

	const renderModals = (): JSX.Element => {
		if (streakSaverModal) {
			return <BuyStreakSaverModal />;
		}

		if (heartModal && heartModal.source) {
			return leaveLessonModal.isActive
				? <LeaveLessonModal {...leaveLessonModal} />
				: <HeartModal {...heartModal} />;
		}

		return null;
	};

	return renderModals();
};

import { HeartModalSource, NavigationItem } from '../../symphony';

export enum ActionType {
	// events
	OnHeartsIconClick,
	OnStreakIconClick,
	OnStreakMenuMount,
	OnBitsIconClick,
	OnBitsMenuMount,
	OnNavbarMount,
	OnNavbarClose,
	OnNavLinkClick,
	OnNavLogoClick,
	OnNavCatalogClick,
	OnNavCatalogClose,
	OnCoursesItemClick,
	OnCoursesSeeAllClick,
	OnCompilerItemClick,

	// track
	TrackNavigationElementsClick,
	TrackNavigationBarDropdownClick,
	TrackFooterElementsClick,

	// Other
	SetHeaderSignUpExperiments,
}

export enum RequestType {
	Parallel,
	Cancelable,
}

export interface IActionData {
	clickAlias?: string;
	dropdownLevel?: number;
	clickType?: string;
	alias?: string;
	elementType?: ClickElementTypeId | string;
	navItem?: INavItemConfig;
	heartModalSource?: HeartModalSource;
}

export interface IAction {
	type: ActionType;
	requestType: RequestType;
	data?: IActionData;
}

export interface INavConfig {
	logoConfig: INavLogoConfig;
	itemsConfig: INavItemConfig[];
}

export interface INavLogoConfig {
	link: string;
}

export interface INavItemConfig {
	key: NavigationItem;
	name: string;
	actionType: NavItemActionType;
	link?: string; // InternalLink, ExternalLink
	expanded?: boolean; // Catalog
	loggedIn: boolean;
	nonLoggedIn: boolean;
	trackElement?: string;
	newTrackElement?: string;
}

export enum SlNavigationModalIds {
	loginModal = 'loginModal',
	bitSourcesModal = 'bitSourcesModal',
}

export interface ILogOutConfig {
	pathname: string;
	type: 'external' | 'internal';
	callback?: () => void;
}

export enum NavItemActionType {
	InternalLink,
	ExternalLink,
	Catalog,
}

export enum NavigationBarExperiments {
	homePagHeaderSignup = 'homepage_header_signup',
	homePagHeaderSignupControl = 'homepage_header_signup_control',
}

export enum NavigationBarExperimentsFlowNames {
	homepageHeaderSignupModal = 'homepage_header_signup_modal',
	homepageHeaderSignupOnHeader = 'homepage_header_signup_on_header',
}

export enum SlNavbarState {
	hidden = 'hidden',
	shown = 'shown',
}

export enum ClickElementTypeId {
	Course = '1',
	LearningPath = '2',
	Language = '3',
	Community = '4',
	SocialMedia = '5',
	Mobile = '6',
}

export interface ICourseCatalogItem {
	title: string;
	items?: ICourseCatalogItem[];
	courseData?: {
		alias: string;
		path: string;
	};
}

export interface ICoursesCatalog {
	items: ICourseCatalogItem[];
}

export interface IFooterCatalogItem {
	title: string;
	path: string;
	isExternalNavigation?: boolean;
	alias: string;
	typeId?: string;
}

export interface IFooterCatalog {
	title: string;
	items: IFooterCatalogItem[];
}

export interface ISegmentedCourses {
	beginnerCodding: ICourseCatalogItem[];
	intermediateCodding: ICourseCatalogItem[];
	advancedCodding: ICourseCatalogItem[];
	beginnerAI: ICourseCatalogItem[];
	intermediateAI: ICourseCatalogItem[];
	advancedAI: ICourseCatalogItem[];
	learningPaths: ICourseCatalogItem[];
}

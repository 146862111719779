import { PaywallsService } from "../../_paywalls/private/services/paywalls.service";
import { Touchpoint } from "../../api/public/subscription-api";
import { AuthService, Container, Service, history } from "../../symphony";

@Service()
export class DiscountCampaignService {
	private authService = Container.take('global', AuthService);
	private paywallService = Container.take('paywalls', PaywallsService);

	public showDiscountPaywall = (campaignId: string, disountHash: string) => {
		if (this.authService.isLoggedIn()) {
			this.paywallService.openPaywall({
				touchpoint: campaignId as Touchpoint,
				props: {
					'Discount-Code': disountHash
				},
				styles: {
					container: {
						padding: 0,
						background: 'transparent',
						position: 'unset'
					},
					header: {
						display: 'none'
					},
					inner: {
						maxWidth: 'unset'
					},
					loader: {
						display: 'none'
					},
					loaderContainer: {
						width: '100vw',
						height: '100vh',
						maxWidth: '100%',
						borderRadius: 0
					}
				}
			}, {
				onClose: () => {
					history.push(`/learn`);
				}
			});
		} else {
			history.push(
				`/users/login?returnUrl=${encodeURIComponent(window.location.pathname)}`
			);
		}
	};

	public closeDiscountPaywall = () => {
		this.paywallService.closePaywall(false);
	}
}
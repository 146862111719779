import { ILoginResponse } from '../../api/public/auth-api';
import { IFlashMessageTypes } from '../../shared/public/SlFlashMessage/SlFlashMessage';
import { ITrackingEventParams } from '../../shared/public/globalInterfaces/trackingInterface';

export enum SlLoginActionTypes {
	login = 'login',
	forget = 'forget',
	socialLogin = 'socialLogin',
	termsOfUse = 'termsOfUse',
	signUpHandler = 'signUpHandler',
	appleLogin = 'appleLogin',
	onBetaTesterModalAcceptCLick = 'onBetaTesterModalAcceptCLick',
}

export type SlLoginClickedButtonTypes =
    | 'login'
    | 'signup'
    | 'googleLogin'
    | 'googleSignup'
    | 'facebookLogin'
    | 'facebookSignup'
    | 'appleLogin'
    | 'appleSignup'
    | 'forget'
    | 'googleOneTap'
    | 'termsOfUse';

export interface ISlLoginResponse {
	type: SlLoginActionTypes;
	clickedButton: SlLoginClickedButtonTypes;
	res: ILoginResponse;
}

export interface ILoginDefaultValues {
	signInEmail?: string;
	signInPassword?: string;
	signUpEmail?: string;
	signUpPassword?: string;
	signUpName?: string;
}

export interface IAction {
	type: SlLoginActionTypes;
	requestType?: 'cancelable' | 'parallel';
	clickedButton?: SlLoginClickedButtonTypes;
	// todo make data generic
	data?: {
		provider?: string;
		code?: string;
		user?: {
			firstName: string;
			lastName: string;
		};
		name?: string;
		email?: string;
		password?: string;
		refCaptcha?: any;
		formData?: {
			CaptchaToken?: string;
			name?: string;
			email?: string;
			password?: string;
		};
		contextOptions?: IContextOptions;
		accessToken?: string;
	};
}

export type LoginType = 'full' | 'social' | 'internal' | 'googleOneTap';

export interface IFlashMessage {
	type: IFlashMessageTypes;
	message: string;
}

export enum AUTH_METHOD {
	EMAIL = 'EMAIL',
	FACEBOOK = 'FACEBOOK',
	GOOGLE = 'GOOGLE',
	APPLE = 'APPLE',
	OTHER = 'OTHER',
}

export interface IContextOptions {
	trackingEventParams?: ITrackingEventParams;
	shouldDefaultRedirect?: boolean;
}

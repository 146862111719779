import React, { useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './SlLoader.scss';

export interface ISlLoaderProps {
	fullScreen?: boolean;
	backgroundColor?: string;
	diameter?: string;
	navbarMode?: boolean;
}

export const SlLoader: React.FC<ISlLoaderProps> = React.memo(
	({
		fullScreen = false,
		backgroundColor = '#fff',
		diameter = '15px',
		navbarMode = false,
	}) => {
		useStyles(s);
		useEffect(() => {
			const bodyFix = 'body-fix';

			if (fullScreen) {
				document.body.classList.add(bodyFix);
				return () => {
					document.body.classList.remove(bodyFix);
				};
			}
			return () => { };
		}, []);
		return (
			<div
				className={`sl-loader ${fullScreen ? 'sl-loader--full-screen' : ''
					}
                ${navbarMode ? 'sl-loader--navbar-mode' : ''}`}
				style={{
					backgroundColor,
				}}
			>
				<div
					style={{
						width: diameter,
						height: diameter,
					}}
				/>
				<div
					style={{
						width: diameter,
						height: diameter,
					}}
				/>
				<div
					style={{
						width: diameter,
						height: diameter,
					}}
				/>
			</div>
		);
	},
);

import React from 'react';

export const IconPro = () => (
	<svg
		width="52"
		height="22"
		viewBox="0 0 52 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.3523 16H12.6483C13.1283 16 13.3043 15.84 13.3043 15.344V12.304H15.6403C18.0563 12.304 19.5603 10.88 19.5603 8.48C19.5603 6.08 18.0243 4.64 15.6403 4.64H11.3523C10.8723 4.64 10.6963 4.8 10.6963 5.296V15.344C10.6963 15.84 10.8723 16 11.3523 16ZM13.3043 9.92V7.008H15.3683C16.3123 7.008 16.8563 7.568 16.8563 8.48C16.8563 9.392 16.3123 9.92 15.3683 9.92H13.3043ZM21.7116 16H23.0076C23.4876 16 23.6636 15.84 23.6636 15.344V11.968H24.3196C25.2476 11.968 25.5516 12.176 25.9036 12.912L27.0876 15.376C27.3276 15.872 27.6156 16 28.4316 16H29.7116C30.1916 16 30.2556 15.696 30.0476 15.312L28.3996 12.16C28.2876 11.968 28.1916 11.776 28.0796 11.6C29.2476 11.056 29.9196 9.92 29.9196 8.32C29.9196 6 28.4636 4.64 26.1596 4.64H21.7116C21.2316 4.64 21.0556 4.8 21.0556 5.296V15.344C21.0556 15.84 21.2316 16 21.7116 16ZM23.6636 9.632V7.008H25.8876C26.7516 7.008 27.2156 7.488 27.2156 8.32C27.2156 9.152 26.7356 9.632 25.8876 9.632H23.6636ZM36.1126 16.24C39.1366 16.24 40.8326 14.592 40.8326 11.936V8.704C40.8326 6.048 39.1366 4.4 36.1126 4.4C33.0726 4.4 31.3766 6.048 31.3766 8.704V11.936C31.3766 14.592 33.0726 16.24 36.1126 16.24ZM36.1126 13.872C34.7526 13.872 33.9846 13.232 33.9846 11.984V8.64C33.9846 7.408 34.7526 6.768 36.1126 6.768C37.4566 6.768 38.2406 7.408 38.2406 8.64V11.984C38.2406 13.232 37.4566 13.872 36.1126 13.872Z"
			fill="#FFCA75"
		/>
		<rect
			x="0.419922"
			y="0.0800781"
			width="51.1617"
			height="21.5938"
			rx="10.7969"
			fill="#FFA310"
		/>
		<path
			d="M15.7979 5.45736H11.2871V16.6514H13.5177V12.8955H15.7731C18.202 12.8955 19.6065 11.3209 19.6065 9.18872C19.6065 7.08932 18.2185 5.45736 15.7979 5.45736ZM17.3345 9.18872C17.3345 10.7059 16.401 11.1569 14.8478 11.1569H13.5095V7.22053H15.3683C16.5993 7.22053 17.3345 7.934 17.3345 9.18872Z"
			fill="#000A23"
		/>
		<path
			d="M25.687 5.45736H21.1844V16.6514H23.382V12.4772H25.3895L27.364 16.6514H29.859L27.5871 12.141C28.4463 11.8048 29.6442 10.8371 29.6442 9.0001C29.6442 7.02371 28.3637 5.45736 25.687 5.45736ZM23.382 10.8125V7.19593H25.3648C26.5131 7.19593 27.3393 7.81099 27.3393 9.04111C27.3393 10.1974 26.5875 10.8125 25.3317 10.8125H23.382Z"
			fill="#000A23"
		/>
		<path
			d="M40.8056 11.813V10.3778C40.8056 7.21233 39.0045 5.24414 35.9808 5.24414C32.9736 5.24414 31.1396 7.19593 31.1396 10.3778V11.813C31.1396 14.9703 32.9488 16.8647 35.9643 16.8647C38.9798 16.8647 40.8056 14.9703 40.8056 11.813ZM38.5419 10.3614V11.8212C38.5419 13.978 37.4679 15.0195 35.9726 15.0195C34.4855 15.0195 33.4115 13.978 33.4115 11.8212V10.3614C33.4115 8.20462 34.4855 7.08932 35.9726 7.08932C37.4679 7.08932 38.5419 8.20462 38.5419 10.3614Z"
			fill="#000A23"
		/>
	</svg>
);

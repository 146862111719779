/* eslint-disable camelcase */
import { useContext, useEffect } from 'react';

import { AuthService, Container } from '../../../../symphony';

import { ServiceContext, SlLoginContext } from '../../login-context';
import { SlLoginInputService } from '../../services/sl-login-inputs.service';
import { SlLoginActionsService } from '../../services/sl-login-actions.service';
import { SlLoginActionTypes } from '../../global.interface';
import { SlOneTapTrackingService } from '../../services/sl-one-tap-tracking.service';
import { GSA_CLIENT_SCRIPT_SOURCE } from '../../constants';
import { asyncFileLoader } from '../../../../shared/public/SlUtils/asyncFileLoader';

declare const google: any;

export const GoogleOneTap = () => {
	const serviceData = useContext(ServiceContext);

	const handleCredentialResponse = (data) => {
		const { socialInputs$ } = Container.take(
			SlLoginContext,
			SlLoginInputService,
		);
		const { actions$ } = Container.take(
			SlLoginContext,
			SlLoginActionsService,
		);

		socialInputs$.next({
			accessToken: data.credential,
			provider: 'googleId',
		});

		actions$.next({
			type: SlLoginActionTypes.socialLogin,
			clickedButton: 'googleOneTap',
			requestType: 'cancelable',
			data: {
				accessToken: data.credential,
				contextOptions: {
					trackingEventParams: serviceData.trackingEventParams,
					shouldDefaultRedirect: serviceData.shouldDefaultRedirect,
				},
			},
		});
	};

	const initializeGoogleOneTap = () => {
		if (!google?.accounts) {
			return;
		}

		google.accounts.id.initialize({
			client_id: Container.take(SlLoginContext, 'googleId'),
			callback: handleCredentialResponse,
		});
		google.accounts.id.prompt((notification) => {
			if (notification.isDisplayed()) {
				Container.take(
					SlLoginContext,
					SlOneTapTrackingService,
				).trackOneTapImpression();
			}
		});
	};

	useEffect(() => {
		const authService = Container.take('global', AuthService);

		if (
			authService.isLoggedIn()
            || Container.take('global', 'isGoogleOneTapTriggered')
		) {
			return;
		}

		Container.register('global', 'isGoogleOneTapTriggered', true);

		asyncFileLoader(
			`${Container.take('global', 'host')}${GSA_CLIENT_SCRIPT_SOURCE}`,
			initializeGoogleOneTap,
		);
	}, []);

	return null;
};

import { IFeatureTogglingConfigs, SlFeatureToggle, SlMicroFrontends } from '../../features/shared/public/globalInterfaces/globalInterfaces';

export const featureTogglingConfigs: IFeatureTogglingConfigs = {
	[SlMicroFrontends.mfProfile]: {
		[SlFeatureToggle.welcomeBack]: {
			state: 'enabled',
		},
		[SlFeatureToggle.freeClickCC]: {
			state: 'enabled',
		},
		[SlFeatureToggle.dailyDose]: {
			state: 'disabled',
		},
		[SlFeatureToggle.experimentalCoursesExperiment]: {
			state: 'enabled',
		},
	},
	[SlMicroFrontends.mfLearn]: {
		[SlFeatureToggle.userGuidanceExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.lessonProgressBarRedesignExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.welcomeBack]: {
			state: 'enabled',
		},
		[SlFeatureToggle.codeRepo]: {
			state: 'enabled',
		},
		[SlFeatureToggle.landToLessonExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.lessonNewCCLogic]: {
			state: 'enabled',
		},
		[SlFeatureToggle.highlightFirstCCExperiment]: {
			state: 'disabled',
		},
		[SlFeatureToggle.dailyDose]: {
			state: 'disabled',
		},
		[SlFeatureToggle.freeCodeReposExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.CRProgressHintExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.completeCelebrationExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.codeRepoInLesson]: {
			state: 'enabled',
		},
		[SlFeatureToggle.freeClickCC]: {
			state: 'enabled',
		},
		[SlFeatureToggle.ccMandatoryExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.quizBackToTheoryExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.experimentalCoursesExperiment]: {
			state: 'enabled',
		},
	},
	[SlMicroFrontends.mfCodeCoach]: {
		[SlFeatureToggle.startPrompt]: {
			state: 'disabled',
		},
		[SlFeatureToggle.codeCoachSolution]: {
			state: 'enabled',
		},
		[SlFeatureToggle.ccSolutionGamificationExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.quitPrompt]: {
			state: 'disabled',
		},
		[SlFeatureToggle.ccCommentsExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.completeCelebrationExperiment]: {
			state: 'enabled',
		},
	},
	[SlMicroFrontends.mfCodeRepo]: {
		[SlFeatureToggle.crCommentsExperiment]: {
			state: 'enabled',
		},
	},
	[SlMicroFrontends.mfOnboarding]: {
		[SlFeatureToggle.dailyDose]: {
			state: 'disabled',
		},
		[SlFeatureToggle.surveyExperiment]: {
			state: 'enabled',
		},
	},
	[SlMicroFrontends.mfNavigation]: {
		[SlFeatureToggle.homepageHeaderSignupModalExperiment]: {
			state: 'disabled',
		},
		[SlFeatureToggle.nonLoggedInProPage]: {
			state: 'enabled',
		},
	},
	[SlMicroFrontends.mfSSRContainer]: {
		[SlFeatureToggle.leaderBoard]: {
			state: 'enabled',
		},
		[SlFeatureToggle.appsFlyer]: {
			state: 'enabled',
		},
		[SlFeatureToggle.thermOfConditionsModal]: {
			state: 'enabled',
		},
		[SlFeatureToggle.privacyPolicyModal]: {
			state: 'enabled',
		},
	},
	[SlMicroFrontends.mfHome]: {
		[SlFeatureToggle.homepageHeaderSignupModalExperiment]: {
			state: 'disabled',
		},
		[SlFeatureToggle.homepageSocialSignUpExperiment]: {
			state: 'enabled',
		},
		[SlFeatureToggle.nonLoggedInProPage]: {
			state: 'enabled',
		},
	},
	[SlMicroFrontends.mfPayments]: {
		[SlFeatureToggle.nonLoggedInProPage]: {
			state: 'enabled',
		},
		[SlFeatureToggle.seriousLearner]: {
			state: 'enabled',
		},
	},
	[SlMicroFrontends.mfLogin]: {
		[SlFeatureToggle.recaptcha]: {
			state: 'disabled',
		},
		[SlFeatureToggle.publicTokenCaptcha]: {
			state: 'enabled',
		},
		[SlFeatureToggle.betaTestLearnEngine]: {
			state: 'enabled',
		},
	},
	[SlMicroFrontends.mfSymphony]: {
		[SlFeatureToggle.iterableTracking]: {
			state: 'enabled',
		},
	},
};

import { BehaviorSubject } from 'rxjs';
import { Service } from 'typedi';

import { Container } from './container.global';
import { DataService } from './data.service';
import { ShopItemType } from './global-interfaces';
import { track } from './tracking/data-tracking';

export interface IBits {
    bits: number;
    userId?: number;
    acknowledgement?: AcknowledgementType;
    hasSeenUnlockPopup?: boolean;
}

export enum AcknowledgementType {
    None,
    Unaware,
    Aware,
    Acknowledged,
}

export enum BitLocationType {
    Onboarding = '1',
    Profile = '2',
    CourseList = '3',
    LearningPath = '4',
    LandingPage = '5',
    Lesson = '6',
}

enum BitTypeId {
    Info = 'INFO',
    ItemUnlock = 'ITEM_UNLOCK',
}

enum BitItemType {
    StreakFreeze = 'STREAK_FREEZE',
    QuestionAnswer = 'QUESTION_ANSWER',
    Undefined = 'UNDEFINED',
}

enum BitButtonType {
    Unlock = '0',
    BitIcon = '1',
    Close = '2',
}

interface IBitTrackingData {
    event_name: string;
    version: string;
    type_id: BitTypeId;
    bits_amount: number;
    location_type: BitLocationType;
    location_details?: string;
    relation_id?: string;
    unlock_price?: number;
    button_type?: BitButtonType;
    item_type?: BitItemType;
    dynamic_id?: string;
    static_id?: string;
    material_id?: string;
}

interface IBitTracking {
	itemType?: BitItemType;
	buttonType?: BitButtonType;
	unlockPrice?: number;
}

@Service()
export class BitService {
	public bits$ = new BehaviorSubject<IBits>(null);

	private shopItems$ = Container.take('global', DataService).shopItemsList$;

	private locationType: BitLocationType;

	private locationDetails: string;

	private relationId: string;

	private materialHeader: any;

	private getPrice(itemType: ShopItemType): number {
		return this.shopItems$.value?.find(({ id }) => id === itemType)?.price;
	}

	public setLocation(
		type: BitLocationType,
		details: string = null,
		materialHeader: any = null,
	): void {
		this.locationType = type;
		this.locationDetails = details;
		this.relationId = materialHeader?.materialRelationId?.toString();
		this.materialHeader = materialHeader;
	}

	public trackBitIconClick = (): void => {
		this.trackClick({
			buttonType: BitButtonType.BitIcon,
		});
	};

	public trackBitInfoImpression = (): void => {
		this.trackImpression({});
	};

	public trackUnlockQuizImpression = (): void => {
		this.trackImpression({
			unlockPrice: this.getPrice(ShopItemType.QuizAnswer),
		});
	};

	public trackUnlockQuizButtonClick = (): void => {
		this.trackClick({
			buttonType: BitButtonType.Unlock,
			itemType: BitItemType.QuestionAnswer,
			unlockPrice: this.getPrice(ShopItemType.QuizAnswer),
		});
	};

	public trackUnlockQuizClose = (): void => {
		this.trackClick({
			buttonType: BitButtonType.Close,
			itemType: BitItemType.QuestionAnswer,
			unlockPrice: this.getPrice(ShopItemType.QuizAnswer),
		});
	};

	public trackStreakSaverModalImpression = (): void => {
		this.trackImpression({
			itemType: BitItemType.StreakFreeze,
			unlockPrice: this.getPrice(ShopItemType.StreakFreeze),
		});
	};

	private trackImpression(data: IBitTracking): void {
		track(this.getTrackingData('bit_impression', data));
	}

	private trackClick(data: IBitTracking): void {
		track(this.getTrackingData('bit_click', data));
	}

	private getTrackingData(
		eventName: string,
		{ itemType, buttonType, unlockPrice }: IBitTracking,
	): IBitTrackingData {
		const data: IBitTrackingData = {
			event_name: eventName,
			type_id: BitTypeId.Info,
			item_type: itemType || BitItemType.Undefined,
			location_type: this.locationType,
			bits_amount: this.bits$.value?.bits,
			version: '6-0-0',
			dynamic_id: String(this.materialHeader?.dynamicId || ''),
			material_id: String(this.materialHeader?.materialId || ''),
			static_id: String(this.materialHeader?.staticId || ''),
		};
		if (unlockPrice) {
			data.unlock_price = unlockPrice;
			data.type_id = BitTypeId.ItemUnlock;
		}
		if (buttonType) {
			data.button_type = buttonType;
		}
		if (this.locationDetails) {
			data.location_details = this.locationDetails;
		}
		return data;
	}
}

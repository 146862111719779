import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import {
	Modal,
	Button,
	IconColorBit,
	IllustrationBits,
} from '@sololearnorg/sol';
import { i18n } from '../../../../symphony';

import s from './SlBuyStreakSaverModal.scss';

interface ISlBuyStreakSaverModal {
    streakFreezePrice: number;
    bits: number;
    onClose: () => void;
    onStreakSaverBuy: () => void;
    loading: boolean;
}

export function SlBuyStreakSaverModal({
	streakFreezePrice,
	bits,
	onClose,
	onStreakSaverBuy,
	loading,
}: ISlBuyStreakSaverModal): JSX.Element {
	useStyles(s);
	const renderBitIcon = (): JSX.Element => (
		<span className="sl-streak-freeze__bit-icon-wrapper">
			<IconColorBit className="sl-streak-freeze__bit-icon" />
		</span>
	);

	const renderModalDescription = (): JSX.Element => (
		<>
			<IllustrationBits />
			<p className="sl-streak-freeze__title">
				{i18n.t('web-streak.freeze-title')}
			</p>
			<p className="sl-streak-freeze__description sl-streak-freeze__pre">
				{bits >= streakFreezePrice ? (
					<>{i18n.t('web-streak.buy-saver.enabled.description')}</>
				) : (
					<>
						{i18n.t(
							'web-streak.buy-saver.disabled.description.before-icon',
						)}
						{' '}
						{renderBitIcon()}
						<br />
						{' '}
						{i18n.t(
							'web-streak.buy-saver.disabled.description.after-icon',
						)}
					</>
				)}
			</p>
			<p>
				{i18n.t('web-streak.currently-title')}
				{' '}
				{renderBitIcon()}
				{' '}
				{bits}
			</p>
		</>
	);

	return (
		<div
			className="sl-streak-freeze__container"
			style={{
				minWidth: '367px',
			}}
		>
			<Modal.Default
				dataTestid="buy_streak_saver"
				onClose={onClose}
				className="sl-streak-freeze__container"
				actions={[
					<Button
						buttonType="secondary"
						onClick={onStreakSaverBuy}
						loading={loading}
						key={1}
						disabled={bits < streakFreezePrice}
						className="sl-streak-freeze__button"
					>
						{i18n.t('web-streak.saver-get-for')}
						{renderBitIcon()}
						{streakFreezePrice}
					</Button>,
				]}
				description={renderModalDescription()}
			/>
		</div>
	);
}

declare global {
    interface Window {
        initialData: unknown;
    }
}

const getInitialDataFromWindow = (cacheKey: string): unknown => {
	if (
		typeof window !== 'undefined'
        && window.initialData
        && window.initialData[cacheKey]
	) {
		return window.initialData[cacheKey];
	}
	return null;
};

const getInitialDataFromSessionStorage = (cacheKey: string) => {
	if (typeof window !== 'undefined') {
		try {
			return JSON.parse(sessionStorage.getItem('initialData'))[cacheKey];
		} catch (error) {
			return null;
		}
	}
	return null;
};

export const getFromCache = <T>(cacheKey: string): T => {
	if (getInitialDataFromWindow(cacheKey)) {
		return getInitialDataFromWindow(cacheKey) as T;
	}
	const storageData = getInitialDataFromSessionStorage(cacheKey);
	return storageData || null;
};

export const deleteFromCache = (cacheKey: string): void => {
	if (typeof window !== 'undefined' && window.initialData) {
		window.initialData[cacheKey] = null;
	}
	if (
		typeof window !== 'undefined'
        && sessionStorage.getItem('initialData')
	) {
		const newCache = {
			...JSON.parse(sessionStorage.getItem('initialData')),
			[cacheKey]: null,
		};
		sessionStorage.setItem('initialData', JSON.stringify(newCache));
	}
};

import React, { useEffect } from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, IStreak } from '../../../../symphony';

import { SlActions } from '../../services/ActionsService/sl-actions';
import { SlNavigationContext } from '../../global-constants';
import { ActionType, RequestType } from '../../global-interface';

import s from './StreakMenu.scss';
import { SlStreakMenu } from '../../../../shared/public/SlStreakMenu/SlStreakMenu';

interface IStreakMenu {
	streak: IStreak;
}

export function StreakMenu({ streak }: IStreakMenu) {
	useStyles(s);
	const { actions$ } = Container.take(SlNavigationContext, SlActions);

	useEffect(() => {
		actions$.next({
			type: ActionType.OnStreakMenuMount,
			requestType: RequestType.Cancelable,
		});
	}, []);

	return (
		<div className="nav-streak-menu">
			<SlStreakMenu {...streak} />
		</div>
	);
}

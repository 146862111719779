import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
	AuthService,
	Container,
	history,
	Service,
} from '../../../symphony';
import { ErrorCodes, IError } from '../global.interface';

type INetworkErrorHandlers = {
    [key in ErrorCodes]?: () => void;
};

@Service()
export class SlErrorHandlerService {
	private logoutAndRedirect() {
		Container.take('global', AuthService).logout();
		history.replace(
			`/users/login?returnUrl=${encodeURIComponent(
				`${window.location.pathname}${window.location.search}`,
			)}`,
		);
	}

	private readonly defaultErrorHandlers: INetworkErrorHandlers = {
		[ErrorCodes.BadRequest]: () => {
			history.replace('/error');
		},
		[ErrorCodes.Unauthorized]: () => {
			this.logoutAndRedirect();
		},
		[ErrorCodes.Forbidden]: () => {
			this.logoutAndRedirect();
		},
		[ErrorCodes.NotFound]: () => {
			history.replace('/pagenotfound');
		},
		[ErrorCodes.InternalServeError]: () => {
			history.replace('/error');
		},
		[ErrorCodes.ServiceUnavailable]: () => {
			// in case on maintenance mode and we get 503 we need to reload the page so
			// backend sends back maintenance page (e.g. maintenance.html) instead of node-ssr
			window.location.reload();
		},
	};

	public handleError = (
		errors: IError[],
		handlers: INetworkErrorHandlers = {},
	): void => {
		if (errors && errors.length) {
			const errorHandlers = { ...this.defaultErrorHandlers, ...handlers };
			const errorHandler = errorHandlers[errors[0].code] || (() => history.replace('/error'));
			errorHandler();
		} else {
			history.replace('/error');
		}
	};

	public handleErrorCatching = (
		isErrorHandlingEnabled = true,
		customErrorHandlers: INetworkErrorHandlers = {},
		returnData: unknown = null,
		callback?: (err?: IError[] | string) => void,
	): ReturnType<typeof catchError> => catchError((err: IError[]) => {
		if (isErrorHandlingEnabled) {
			this.handleError(err, customErrorHandlers);
		}

		callback?.(err);

		return of(returnData);
	});
}

import React, { FC, RefObject, useEffect } from "react";
import s from "./LongPaywallHeadings.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container, i18n } from "../../../../../../symphony";
import { useInView } from "../../../../paywalls.utils";
import { PaywallsService } from "../../../../services/paywalls.service";

type Props = {
	data: {
		heading: string;
		subHeading: string;
	};
	style: React.CSSProperties;
	sectionOrder: number;
};

export const LongPaywallHeadings: FC<Props> = ({ data, style, sectionOrder }) => {
	useStyles(s);
	const { trackSectionView } = Container.take('paywalls', PaywallsService);
	const [ref, isInView] = useInView({
		threshold: 0.7
	});

	useEffect(() => {
		isInView && trackSectionView('longHeadings', sectionOrder);
	}, [isInView]);

	return <div ref={ref as RefObject<HTMLDivElement>} style={style} className="headings">
		<h1>{i18n.t(data.heading)}</h1>
		<h2>{i18n.t(data.subHeading)}</h2>
	</div>;
};

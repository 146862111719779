import React, { useEffect, useRef } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Container } from '../../../symphony';

import { NavigationBarContainer } from '../../private/containers/NavigationBarContainer';
import { SlActions } from '../../private/services/ActionsService/sl-actions';
import { SlNavigationService } from '../../private/services/sl-navigation.service';
import { SlNavigationTrackingService } from '../../private/services/sl-navigation-tracking.service';
import { SlNavigationContext } from '../../private/global-constants';
import { SlActionsService } from '../../private/services/ActionsService/sl-actions.service';

export function SlNavigationBar() {
	const isInit = useRef(true);

	if (isInit.current) {
		const unsubscriber = new Subject<void>();
		Container.register(SlNavigationContext, 'unsubscriber', unsubscriber);
		Container.register(
			'global',
			'slNavigationService',
			SlNavigationService,
		);
		Container.take(SlNavigationContext, SlActionsService);
		isInit.current = false;
	}

	useEffect(() => {
		const { actions$ } = Container.take(SlNavigationContext, SlActions);

		const unsubscriber: Subject<void> = Container.take(
			SlNavigationContext,
			'unsubscriber',
		);

		const { navigationTrackAction$ } = Container.take(
			SlNavigationContext,
			SlNavigationTrackingService,
		);

		navigationTrackAction$.pipe(takeUntil(unsubscriber)).subscribe();

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
			actions$.unsubscribe();
		};
	}, []);

	return <NavigationBarContainer />;
}

export * from '../../private/global-interface';


import React, { FC, RefObject, useEffect } from "react";
import s from "./LongPaywallHeader.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { SlIcon } from "@sololearnorg/icons";
import { Container } from "../../../../../../symphony";
import { PaywallsService } from "../../../../services/paywalls.service";
import { useInView } from "../../../../paywalls.utils";

type Props = {
	sectionOrder: number;
};

export const LongPaywallHeader: FC<Props> = ({ sectionOrder }) => {
	useStyles(s);
	const { closePaywall } = Container.take('paywalls', PaywallsService);
	const { trackSectionView } = Container.take('paywalls', PaywallsService);
	const [ref, isInView] = useInView({
		threshold: 0.7
	});

	useEffect(() => {
		isInView && trackSectionView('longHeader', sectionOrder);
	}, [isInView]);

	const logo = <div className="lp-header-logo">
		<SlIcon iconId="sololearnMono" />
	</div>;
	const closeButton = <div className="lp-header-close" onClick={() => closePaywall()}>
		<SlIcon iconId="close" />
	</div>;

	return <div ref={ref as RefObject<HTMLDivElement>} className="lp-header">
		<div className="lp-header-inner">
			{logo}{closeButton}
		</div>
	</div>;
};
import { merge, of, Subject } from 'rxjs';
import {
	filter, mergeMap, switchMap, takeUntil,
} from 'rxjs/operators';

import { Container, Service } from '../../../../symphony';

import { SlActions } from './sl-actions';
import { SlActionsController } from './sl-actions.controller';
import { IAction, ActionType, RequestType } from '../../global-interface';
import { SlNavigationContext } from '../../global-constants';

@Service()
export class SlActionsService {
	private actions$ = Container.take(SlNavigationContext, SlActions).actions$;

	private controller = Container.take(
		SlNavigationContext,
		SlActionsController,
	);

	private cancelableActionsMap = {
		[ActionType.OnHeartsIconClick]:
            this.controller.onHeartsIconClickHandler,
		[ActionType.OnStreakIconClick]:
            this.controller.onStreakIconClickHandler,
		[ActionType.OnStreakMenuMount]:
            this.controller.onStreakMenuMountHandler,
		[ActionType.OnBitsIconClick]: this.controller.onBitsIconClickHandler,
		[ActionType.OnBitsMenuMount]: this.controller.onBitsMenuMountHandler,
	};

	private parallelActionsMap = {
		[ActionType.SetHeaderSignUpExperiments]:
            this.controller.setHeaderSignUpExperimentsHandler,
		[ActionType.TrackNavigationElementsClick]:
            this.controller.trackNavigationElementsClickHandler,
		[ActionType.TrackNavigationBarDropdownClick]:
            this.controller.trackNavigationBarDropdownClickHandler,
		[ActionType.TrackFooterElementsClick]:
            this.controller.trackFooterElementsClickHandler,
		[ActionType.OnNavbarMount]: this.controller.onNavbarMountHandler,
		[ActionType.OnNavbarClose]: this.controller.onNavbarCloseHandler,
		[ActionType.OnNavLinkClick]: this.controller.onNavLinkClickHandler,
		[ActionType.OnNavLogoClick]: this.controller.onNavLogoClickHandler,
		[ActionType.OnNavCatalogClick]:
            this.controller.onNavCatalogClickHandler,
		[ActionType.OnCoursesItemClick]:
            this.controller.onCoursesItemClickHandler,
		[ActionType.OnCoursesSeeAllClick]:
            this.controller.onCoursesSeeAllClickHandler,
		[ActionType.OnCompilerItemClick]:
            this.controller.onCompilerItemClickHandler,
	};

	constructor() {
		const unsubscriber: Subject<void> = Container.take(
			SlNavigationContext,
			'unsubscriber',
		);

		const cancelable = this.actions$.pipe(
			filter((a) => !!a && a.requestType === RequestType.Cancelable),
			switchMap((action: IAction) => {
				const handler = this.cancelableActionsMap[action.type];
				return handler ? handler(action.data) : of(action);
			}),
		);

		const parallel = this.actions$.pipe(
			filter((a) => !!a && a.requestType === RequestType.Parallel),
			mergeMap((action: IAction) => {
				const handler = this.parallelActionsMap[action.type];
				return handler ? handler(action.data) : of(action);
			}),
		);

		merge(cancelable, parallel)
			.pipe(
				filter((a) => !!a),
				takeUntil(unsubscriber),
			)
			.subscribe();
	}
}

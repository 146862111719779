import React from 'react';

export const GoogleIcon = ({ className }: { className?: string }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="17"
		height="18"
		viewBox="0 0 17 18"
		fill="none"
		className={className}
	>
		<path d="M17.0002 9.19019C17.0002 8.59686 16.947 8.02638 16.8481 7.47865H8.96753V10.7153H13.4707C13.2767 11.7612 12.6872 12.6474 11.801 13.2407V15.3402H14.5052C16.0874 13.8835 17.0002 11.7384 17.0002 9.19019Z" fill="#4285F4" />
		<path d="M8.96763 17.3673C11.2268 17.3673 13.1208 16.6181 14.5052 15.3402L11.8011 13.2407C11.0519 13.7428 10.0934 14.0394 8.96763 14.0394C6.78827 14.0394 4.94369 12.5675 4.28568 10.5898H1.49023V12.7577C2.86708 15.4923 5.69675 17.3673 8.96763 17.3673Z" fill="#34A853" />
		<path d="M4.2855 10.5898C4.11815 10.0878 4.0231 9.55149 4.0231 9C4.0231 8.44851 4.11815 7.91225 4.2855 7.41021V5.24231H1.49005C0.923412 6.3719 0.600098 7.64985 0.600098 9C0.600098 10.3502 0.923412 11.6281 1.49005 12.7577L4.2855 10.5898Z" fill="#FBBC04" />
		<path d="M8.96763 3.9606C10.196 3.9606 11.299 4.38274 12.1662 5.21186L14.5661 2.81194C13.1171 1.46178 11.223 0.63266 8.96763 0.63266C5.69675 0.63266 2.86708 2.5077 1.49023 5.24231L4.28568 7.41021C4.94369 5.4325 6.78827 3.9606 8.96763 3.9606Z" fill="#E94235" />
	</svg>
);

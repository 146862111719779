import React, { FC, RefObject, useEffect, useState } from "react";
import s from "./LongPaywallFAQ.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container, i18n } from "../../../../../../symphony";
import { useInView } from "../../../../paywalls.utils";
import { PaywallsService } from "../../../../services/paywalls.service";
import { PaywallClickTypes } from "../../../../paywalls.constants";

type Props = {
	data: {
		heading: string;
		questions: {
			question: string;
			answer: string;
		}[];
	};
	style: React.CSSProperties;
	sectionOrder: number;
};

export const LongPaywallFAQ: FC<Props> = ({ data, style, sectionOrder }) => {
	useStyles(s);
	const [expandedFaq, setExpandedFaq] = useState<number[]>([0]);
	const [ref, isInView] = useInView({
		threshold: 0.7
	});
	const { trackSectionView, trackPaywallClick } = Container.take('paywalls', PaywallsService);

	useEffect(() => {
		isInView && trackSectionView('longFAQ', sectionOrder);
	}, [isInView]);

	const onFaqItemClick = (index: number) => {
		const expandedIds = expandedFaq.includes(index)
			? expandedFaq.filter(i => i !== index)
			: [...expandedFaq, index];
		trackPaywallClick(PaywallClickTypes.faqItem, i18n.t(data.questions[index].question));
		setExpandedFaq(expandedIds);
	};

	return <div ref={ref as RefObject<HTMLDivElement>} style={style} className="faq">
		<h3>{i18n.t(data.heading)}</h3>
		<div className="faq-list">
			{data.questions.map((q, i) => {
				return <div className="faq-item" key={q.question}>
					<div
						className="faq-item-header"
						onClick={() => { onFaqItemClick(i); }}
					>
						<span>{i18n.t(q.question)}</span>
						<div className={`faq-item-icon ${expandedFaq.includes(i) ? 'minus' : 'plus'}`}></div>
					</div>
					<div className={`faq-item-body ${expandedFaq.includes(i) ? 'expanded' : ''}`}>
						{i18n.t(q.answer)}
					</div>
				</div>;
			})}
		</div>
	</div>;
};
import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './Error.scss';

export interface IErrorProps {
	errorText: string;
}

export const Error: React.FC<IErrorProps> = React.memo(({ errorText }) => {
	useStyles(s);

	return (
		<div className="sl-login-error">
			<span>{errorText}</span>
		</div>
	);
});

import { Observable, of } from 'rxjs';

import {
	constructConfig,
	constructUrl,
} from '../private/utils/httpParamsUtils';
import { Authorize, AuthLevel } from '../private/authorize.decorator';
import {
	ILanguageLandingPageResponse,
	IStaticPageResponse,
	IStaticPagesAPI,
	IStaticPagesVersions,
	LanguagesResponseType,
} from '../private/static-pages-api/static-pages-api';
import { deleteFromCache, getFromCache } from '../private/get-from-cache';
import {
	HeadersAuthorizationType,
	IApiUrls,
	IHostsConfigs,
} from '../private/global.interface';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { Service, Container } from '../../symphony';

@Service()
export class StaticPagesAPI implements IStaticPagesAPI {
	private environmentUrl: string;

	private cdnApiHostV2: string;

	private monolithApiHost: string;

	private versions: IStaticPagesVersions;

	private axiosInstance: AxiosInstanceType;

	constructor() {
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).staticPagesApiHost;
		this.cdnApiHostV2 = (
			Container.take('global', 'envUrl') as IApiUrls
		).cdnApiHostV2;
		this.monolithApiHost = (
			Container.take('global', 'envUrl') as IApiUrls
		).monolithApiHost;
		this.versions = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.staticPagesApi?.version;
		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.public)
	public getPageContent(page: string): Observable<IStaticPageResponse> {
		const cacheValue = getFromCache<IStaticPageResponse>('getPageContent');
		if (cacheValue) {
			deleteFromCache('getPageContent');
			return of(cacheValue);
		}
		const url = constructUrl(
			this.environmentUrl,
			`api/static-page/${page}`,
			this.versions?.getPageContent,
		);
		return this.axiosInstance.get(url);
	}

	@Authorize(AuthLevel.public)
	public getStaticLandingPage(alias: string): Observable<unknown> {
		return this.axiosInstance.get(
			constructUrl(
				this.cdnApiHostV2,
				`flexible-onboarding/${alias}.json`,
			),
			constructConfig({
				authorization: HeadersAuthorizationType.None,
			}),
		);
	}

	@Authorize(AuthLevel.public)
	public getLanguageLandingPage(
		languageAlias: string,
	): Observable<ILanguageLandingPageResponse> {
		const env = Container.take('global', 'env');
		return this.axiosInstance.get(
			constructUrl(
				this.cdnApiHostV2,
				`language-static-pages-${env}/language_landing_${languageAlias}.json?ver=${+new Date()}`,
			),
			constructConfig({
				authorization: HeadersAuthorizationType.None,
			}),
		);
	}

	@Authorize(AuthLevel.public)
	public getLanguages(): Observable<LanguagesResponseType> {
		const env = Container.take('global', 'env');
		return this.axiosInstance.get(
			constructUrl(
				this.cdnApiHostV2,
				`language-static-pages-${env}/languages.json`,
			),
			constructConfig({
				authorization: HeadersAuthorizationType.None,
			}),
		);
	}

	@Authorize(AuthLevel.public)
	public sendWaitlistEmail(email: string): Observable<unknown> {
		return this.axiosInstance.post(constructUrl(
			this.monolithApiHost,
			'landing-page/wait-list',
		), {
			email,
			configs: {},
		});
	}
}

export * from '../private/static-pages-api/static-pages-api';

import { TrackingObjType } from '../../../public/tracking/tracking.interface';
import { Tracking } from '../tracking';
import { ITracking } from '../tracking.interface';
import { sololearnNewEventsMapping } from './sololearnNewEventsMapping';
import { track } from '../../../public/tracking/data-tracking';

export class SololearnNewTracking extends Tracking implements ITracking {
	constructor() {
		super(sololearnNewEventsMapping);
	}

	public handleTracking = (trackingObj: TrackingObjType): void => {
		const { params, eventName } = this.getEventParams(trackingObj);

		if (!eventName) return;

		track({
			event_name: eventName,
			...params,
		});
	};
}

import { Observable } from 'rxjs';

import { BaseApi } from '../private/base-api';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import {
	IAppsFlyerApi,
	IAppsFlyerApiData,
} from '../private/apps-flyer-api/apps-flyer-api.interface';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { IApiUrls } from '../private/global.interface';
import { Service, Container } from '../../symphony';

@Service()
export class AppsFlyerApi extends BaseApi implements IAppsFlyerApi {
	private environmentUrl: string;

	private axiosInstance: AxiosInstanceType;

	constructor() {
		super();
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).appsFlyerApiHost;

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.authenticated)
	public userMappings(appsFlyerId: string): Observable<IAppsFlyerApiData> {
		return this.axiosInstance.post(
			`${this.environmentUrl}/appsflyer-sync/user-mappings`,
			{ appsFlyerId },
		);
	}
}

export * from '../private/apps-flyer-api/apps-flyer-api.interface';

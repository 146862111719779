import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { i18n } from '../../../../../symphony';
import s from './Divider.scss';

export const Divider: React.FC = React.memo(() => {
	useStyles(s);

	return (
		<div className="sl-login-divider">
			<span className="sl-login-divider__line" />
			<span className="sl-login-divider__name">
				{i18n.t('web-auth.divider')}
			</span>
			<span className="sl-login-divider__line" />
		</div>
	);
});

import { PaymentProduct, PaywallComponentType, PaywallPage, PaywallPageTypes, Touchpoint } from "../../api/public/subscription-api";

export enum PaywallState {
	open = 'open',
	closed = 'closed'
}

export type PaywallArguments = {
	touchpoint: Touchpoint;
	props: { [key: string]: string; };
	styles?: PaywallExternalStyles;
};

export type PaywallCallbacks = {
	onClose?: (method?: 'back' | 'x') => void;
	onSubscribe?: () => void;
	onCountdownEnd?: () => void;
};

export enum PaywallNavigationPage {
	paywall = 'paywall',
	checkout = 'checkout',
	congrats = 'congrats'
}

export type PaywallNavigation = {
	page: PaywallNavigationPage;
	step: string;
};

export type PaywallExternalStyles = {
	container?: React.CSSProperties;
	header?: React.CSSProperties;
	inner?: React.CSSProperties;
	body?: React.CSSProperties;
	loaderContainer?: React.CSSProperties;
	loader?: React.CSSProperties;
};

export enum PaymentMethods {
	applePay = 'applePay',
	googlePay = 'googlePay',
	payPal = 'payPal',
	card = 'card'
};

export type PaymentMethodItem = {
	method: PaymentMethods;
	title: string;
	icon: React.ReactElement;
};

export enum LongPaywallProductNames {
	proMonthlyNoTrial = 'pro-monthly-no-trial',

	proAnnualTrial = 'pro-annual-trial',
	proAnnualNoTrial = 'pro-annual-no-trial',

	maxAnnualTrial = 'max-annual-trial',
	maxAnnualNoTrial = 'max-annual-no-trial'
}

export type LongPaywallProductMeta = {
	name: LongPaywallProductNames;
	key: string;
	originalPrice: number;
	discountedPrice?: number;
	priceSuffix: string;
	billingInfo: string;
	features: string[];
	cta: string;
	tag: string;
	secondaryTag: string;
	period: number; 
};

export type LongPaywallProduct = {
	meta: LongPaywallProductMeta;
	product: PaymentProduct;
};

export const CardFields = ['number', 'cvv', 'expirationDate', 'postalCode'];

export class PaywallErroredData { }

export const PaywallOfferModalId = 'PaywallOfferModalId';

export enum PaywallClickTypes {
	close = 'CLOSE',
	cta = 'CTA',
	freeBlock = 'FREE_BLOCK',
	monthlyBlock = 'MONTHLY_BLOCK',
	annualBlock = 'ANNUAL_BLOCK',
	quarterlyBlock = 'QUARTERLY_BLOCK',
	faqItem = 'FAQ_ITEM',
	moneyBackGuaranteeLink = 'MONEY_BACK_GUARANTEE_LINK',
	paymentMethod = 'PAYMENT_METHOD',
	productChange = 'PRODUCT_CHANGE',
	subscribe = 'SUBSCRIBE'
}

export const MOCK_LONG_PAYWALL: PaywallPage = {
	"order": 1,
	"type": "long",
	"title": null,
	"subtitle": null,
	"components": [
		{
			"type": "lpHeader",
			"data": {},
			"style": {}
		},
		{
			"type": "lpCampaign",
			"data": {
				"campaignName": "blackFriday"
			},
			"style": {}
		},
		{
			"type": "lpHeadings",
			"data": {
				"heading": "web-paywall.long.heading",
				"subHeading": "web-paywall.long.sub-heading"
			},
			"style": {}
		},
		{
			"type": "lpProducts",
			"data": {
				"products": [
					{
						"name": "pro-monthly-no-trial",
						"key": "coding-monthly",
						"originalPrice": 7.99,
						"priceSuffix": "web-paywall.long.product.monthly-suffix",
						"billingInfo": "web-paywall.long.billing-cycle.pro-monthly",
						"features": [
							"web-paywall.long.features.1",
							"web-paywall.long.features.2",
							"web-paywall.long.features.3",
							"web-paywall.long.features.4"
						],
						"cta": "web-paywall.long.cta.pro-monthly",
						"tag": null,
						"secondaryTag": null
					},
					{
						"name": "pro-annual-trial",
						"key": "coding-annual-trial",
						"originalPrice": 7.99,
						"discountedPrice": 2.88,
						"priceSuffix": "web-paywall.long.product.monthly-suffix",
						"billingInfo": "web-paywall.long.billing-cycle.pro-annual",
						"features": [
							"web-paywall.long.features.1",
							"web-paywall.long.features.2",
							"web-paywall.long.features.3",
							"web-paywall.long.features.4"
						],
						"cta": "web-paywall.long.cta.pro-annual",
						"tag": "web-paywall.long.product.tag",
						"secondaryTag": "web-paywall.long.product.pro-annual.secondary-tag"
					},
					{
						"name": "max-annual-no-trial",
						"key": "max",
						"originalPrice": 7.99,
						"priceSuffix": "web-paywall.long.product.monthly-suffix",
						"billingInfo": "web-paywall.long.billing-cycle.max",
						"features": [
							"web-paywall.long.features.1",
							"web-paywall.long.features.2",
							"web-paywall.long.features.3",
							"web-paywall.long.features.4",
							"web-paywall.long.features.11",
							"web-paywall.long.features.8",
							"web-paywall.long.features.9"
						],
						"cta": "web-paywall.long.cta.max",
						"tag": null,
						"secondaryTag": null
					}
				]
			},
			"style": {}
		},
		{
			"type": "lpAwards",
			"data": {
				"heading": "web-paywall.long.awards.heading"
			},
			"style": {}
		},
		{
			"type": "lpComparison",
			"data": {
				"heading": "web-paywall.long.comparison.heading",
				"plans": [
					{
						"name": "free",
						"productKey": null,
						"cta": null,
						"theme": "gold"
					},
					{
						"name": "pro",
						"productKey": "coding-annual-trial",
						"cta": "web-paywall.long.cta.pro-annual",
						"theme": "gold"
					},
					{
						"name": "max",
						"productKey": "max",
						"cta": "web-paywall.long.cta.max",
						"theme": "purple"
					}
				],
				"features": {
					"default": {
						"web-paywall.long.features.1": [
							"free",
							"pro",
							"max"
						],
						"web-paywall.long.features.2": [
							"pro",
							"max"
						],
						"web-paywall.long.features.3": [
							"pro",
							"max"
						],
						"web-paywall.long.features.4": [
							"pro",
							"max"
						],
						"web-paywall.long.features.5": [
							"pro",
							"max"
						],
						"web-paywall.long.features.6": [
							"pro",
							"max"
						]
					},
					"ai": {
						"web-paywall.long.features.7": [
							"max"
						],
						"web-paywall.long.features.8": [
							"max"
						],
						"web-paywall.long.features.9": [
							"max"
						],
						"web-paywall.long.features.10": [
							"max"
						],
						"web-paywall.long.features.11": [
							"max"
						],
						"web-paywall.long.features.12": [
							"max"
						]
					}
				}
			},
			"style": {}
		},
		{
			"type": "lpReviews",
			"data": {
				"heading": "web-paywall.long.reviews.heading",
				"reviews": [
					{
						"reviewer": "web-paywall.long.reviews.1.name",
						"avatarUrl": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSikTnenhtNKR6q-OWxTRGvrVHLxdDU8ZCaI6SWaUovcrcbBA5uiZ5ZF53IvUMBKWAZJ1k&usqp=CAU",
						"review": "web-paywall.long.reviews.1.review"
					},
					{
						"reviewer": "web-paywall.long.reviews.2.name",
						"avatarUrl": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSikTnenhtNKR6q-OWxTRGvrVHLxdDU8ZCaI6SWaUovcrcbBA5uiZ5ZF53IvUMBKWAZJ1k&usqp=CAU",
						"review": "web-paywall.long.reviews.2.review"
					},
					{
						"reviewer": "web-paywall.long.reviews.3.name",
						"avatarUrl": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSikTnenhtNKR6q-OWxTRGvrVHLxdDU8ZCaI6SWaUovcrcbBA5uiZ5ZF53IvUMBKWAZJ1k&usqp=CAU",
						"review": "web-paywall.long.reviews.3.review"
					}
				]
			},
			"style": {}
		},
		{
			"type": "lpFAQ",
			"data": {
				"heading": "web-paywall.long.faq.heading",
				"questions": [
					{
						"question": "web-paywall.long.faq.1.question",
						"answer": "web-paywall.long.faq.1.answer"
					},
					{
						"question": "web-paywall.long.faq.2.question",
						"answer": "web-paywall.long.faq.2.answer"
					},
					{
						"question": "web-paywall.long.faq.3.question",
						"answer": "web-paywall.long.faq.3.answer"
					},
					{
						"question": "web-paywall.long.faq.4.question",
						"answer": "web-paywall.long.faq.4.answer"
					},
					{
						"question": "web-paywall.long.faq.5.question",
						"answer": "web-paywall.long.faq.5.answer"
					},
					{
						"question": "web-paywall.long.faq.6.question",
						"answer": "web-paywall.long.faq.6.answer"
					}
				]
			},
			"style": {}
		},
		{
			"type": "lpPartnership",
			"data": {
				"heading": "web-paywall.long.partnership.heading",
				"partnerLogos": [
					"https://blob.sololearn.com/web-assets/long-paywalls/amazon.svg",
					"https://blob.sololearn.com/web-assets/long-paywalls/google.svg",
					"https://blob.sololearn.com/web-assets/long-paywalls/apple.svg",
					"https://blob.sololearn.com/web-assets/long-paywalls/glovo.svg",
					"https://blob.sololearn.com/web-assets/long-paywalls/space-x.svg"
				]
			},
			"style": {}
		},
		{
			"type": "lpHeadings",
			"data": {
				"heading": "web-paywall.long.heading",
				"subHeading": "web-paywall.long.sub-heading"
			},
			"style": {}
		},
		{
			"type": "lpProducts",
			"data": {
				"products": [
					{
						"name": "pro-monthly-no-trial",
						"key": "coding-monthly",
						"originalPrice": 7.99,
						"priceSuffix": "web-paywall.long.product.monthly-suffix",
						"billingInfo": "web-paywall.long.billing-cycle.pro-monthly",
						"features": [
							"web-paywall.long.features.1",
							"web-paywall.long.features.2",
							"web-paywall.long.features.3",
							"web-paywall.long.features.4"
						],
						"cta": "web-paywall.long.cta.pro-monthly",
						"tag": null,
						"secondaryTag": null
					},
					{
						"name": "pro-annual-trial",
						"key": "coding-annual-trial",
						"originalPrice": 7.99,
						"discountedPrice": 2.88,
						"priceSuffix": "web-paywall.long.product.monthly-suffix",
						"billingInfo": "web-paywall.long.billing-cycle.pro-annual",
						"features": [
							"web-paywall.long.features.1",
							"web-paywall.long.features.2",
							"web-paywall.long.features.3",
							"web-paywall.long.features.4"
						],
						"cta": "web-paywall.long.cta.pro-annual",
						"tag": "web-paywall.long.product.tag",
						"secondaryTag": "web-paywall.long.product.pro-annual.secondary-tag"
					},
					{
						"name": "max-annual-no-trial",
						"key": "max",
						"originalPrice": 7.99,
						"priceSuffix": "web-paywall.long.product.monthly-suffix",
						"billingInfo": "web-paywall.long.billing-cycle.max",
						"features": [
							"web-paywall.long.features.1",
							"web-paywall.long.features.2",
							"web-paywall.long.features.3",
							"web-paywall.long.features.4",
							"web-paywall.long.features.11",
							"web-paywall.long.features.8",
							"web-paywall.long.features.9"
						],
						"cta": "web-paywall.long.cta.max",
						"tag": null,
						"secondaryTag": null
					}
				]
			},
			"style": {}
		}
	],
	"footerButtons": [],
	"headerVersion": 1,
	"headerComponents": [],
	"styles": {}
} as PaywallPage;
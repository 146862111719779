import React, { useEffect, useState } from 'react';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import useStyles from 'isomorphic-style-loader/useStyles';

import { StaticPagesContext } from '../../private/contexts';
import {
	SlStaticPagesActionTypes,
	StaticPagesActionsService,
} from '../../private/services/static-pages/sl-static-pages-actions.service';
import { SlStaticPagesDataService } from '../../private/services/static-pages/sl-static-pages-data.service';

import s from './SlPrivacyPolicy.scss';
import { IStaticPageData } from '../../../api/public/static-pages-api';
import { SlHelmet } from '../../../shared/public/SlHelmet/SlHelmet';
import { Container } from '../../../symphony';

export interface ISLPrivacyPolicyProps {
	staticPagesApiHost: string;
}

export const SLPrivacyPolicy: React.FC<ISLPrivacyPolicyProps> = React.memo(
	({ staticPagesApiHost }) => {
		useStyles(s);
		const [isReady, setIsReady] = useState<boolean>(false);
		const [data, setData] = useState<IStaticPageData>(null);

		useEffect(() => {
			Container.register(
				StaticPagesContext,
				'staticPagesApiHost',
				staticPagesApiHost,
			);
			setIsReady(true);
			const unsubscriber$ = new Subject<void>();

			const { actionsStream, actions$ } = Container.take(
				StaticPagesContext,
				StaticPagesActionsService,
			);

			const { content$ } = Container.take(
				StaticPagesContext,
				SlStaticPagesDataService,
			);

			merge(
				content$.pipe(
					filter((a) => !!a),
					tap(setData),
				),
				actionsStream,
			)
				.pipe(takeUntil(unsubscriber$))
				.subscribe();

			actions$.next({
				type: SlStaticPagesActionTypes.getPrivacyPolicyPageContent,
				requestType: 'cancelable',
			});

			return () => {
				unsubscriber$.next();
				unsubscriber$.complete();
				Container.reset(StaticPagesContext);
			};
		}, []);

		return isReady ? (
			<div className="sl-privacy-policy">
				{data?.pageContent && (
					<>
						<SlHelmet
							title={data.title}
							description={data.description}
						/>
						<section
							className="sl-privacy-policy__section"
							dangerouslySetInnerHTML={{
								__html: data.pageContent,
							}}
						/>
					</>
				)}
			</div>
		) : null;
	},
);

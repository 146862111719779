import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, i18n } from '../../../../symphony';

import { SlActions } from '../../services/ActionsService/sl-actions';
import { ActionType, RequestType } from '../../global-interface';
import { SlNavigationContext } from '../../global-constants';

import s from './NavBarCompiler.scss';
import { LanguageNames, LanguageRoutes } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';

export function NavBarCompiler(): JSX.Element {
	useStyles(s);
	const allLanguageNames = Object.keys(LanguageNames).filter(
		(name) => name !== 'SQL' && name !== 'WEB',
	);
	const size = Math.ceil(allLanguageNames.length / 3);
	const languageNames: string[][] = [
		allLanguageNames.slice(0, size),
		allLanguageNames.slice(size, size * 2),
		allLanguageNames.slice(size * 2),
	];

	const onItemClick = (): void => {
		const { actions$ } = Container.take(SlNavigationContext, SlActions);
		actions$.next({
			type: ActionType.OnCoursesSeeAllClick,
			requestType: RequestType.Parallel,
		});
	};

	const renderLanguageItem = (languageNameKey: string): React.JSX.Element => (
		<li
			className="sl-navbar-compiler-dropdown__item"
			key={languageNameKey}
		>
			<LocalizedLink
				key={languageNameKey}
				to={`/compiler-playground/${LanguageRoutes[languageNameKey]}`}
				onClick={onItemClick}
				target="_blank"
			>
				{LanguageNames[languageNameKey]}
			</LocalizedLink>
		</li>
	);

	return (
		<div
			sl-test-data="cmpNavBarCompiler"
			className="sl-navbar-compiler-dropdown"
		>
			<div className="sl-navbar-compiler-dropdown__languages">
				{languageNames.map((languages, i) => (
					<ul className="sl-navbar-compiler-dropdown__list" key={i}>
						{languages.map(renderLanguageItem)}
					</ul>
				))}
			</div>
			<div className="sl-navbar-compiler-dropdown__footer">
				<LocalizedLink
					className="sl-courses-catalog-dropdown__footer__see-all"
					to="/compiler-playground"
					onClick={onItemClick}
				>
					{i18n.t('web-navigation.view-all-compilers')}
				</LocalizedLink>
			</div>
		</div>
	);
}

import { TrackingEventNames } from '../../../public/tracking/trackingEventNames';
import { TrackingEventsMappingType } from '../tracking.interface';

export const sololearnEventsMapping: TrackingEventsMappingType = {
	[TrackingEventNames.slCelebCCComplete]: {
		eventName: 'celeb_cc_complete',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slContCelebCCComplete]: {
		eventName: 'cont_celeb_cc_complete',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCPromptQuitAttempted]: {
		eventName: 'ccprompt_quit_attempted',
		params: ['action'],
	},
	[TrackingEventNames.slCCPromptQuitNotAttempted]: {
		eventName: 'ccprompt_quit_notattempted',
		params: ['action'],
	},
	[TrackingEventNames.slCCPromptQuitNotAttempted]: {
		eventName: 'ccprompt_quit_notattempted',
		params: ['action'],
	},
	[TrackingEventNames.slCCPromptQuitAttemptedNextTime]: {
		eventName: 'ccprompt_quit_attemptedcc_nexttime',
		params: ['action'],
	},
	[TrackingEventNames.slCCPromptQuitNotAttemptedNextTime]: {
		eventName: 'ccprompt_quit_notattemptedcc_nexttime',
		params: ['action'],
	},
	[TrackingEventNames.slCCPromptQuitAttemptedGoBack]: {
		eventName: 'ccprompt_quit_attemptedcc_goback',
		params: ['action'],
	},
	[TrackingEventNames.slCCPromptQuitNotAttemptedGoBack]: {
		eventName: 'ccprompt_quit_notattemptedcc_goback',
		params: ['action'],
	},
	[TrackingEventNames.slCCPromptQuitAttemptedCC]: {
		eventName: 'ccprompt_quit_attemptedcc',
		params: ['action'],
	},
	[TrackingEventNames.slCCPromptQuitNotAttemptedCC]: {
		eventName: 'ccprompt_quit_notattemptedcc',
		params: ['action'],
	},
	[TrackingEventNames.slCCPromptStartLetsGo]: {
		eventName: 'ccprompt_start_letsgo',
		params: ['action'],
	},
	[TrackingEventNames.slCCPromptStart]: {
		eventName: 'ccprompt_start',
		params: ['action'],
	},
	[TrackingEventNames.slCCCourseView]: {
		eventName: 'codecoach_course',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCLessonView]: {
		eventName: 'codecoach_lesson',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCProfileView]: {
		eventName: 'codecoach_profile',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slEomProjectView]: {
		eventName: 'eom_project',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCCodeSolution]: {
		eventName: 'cc_code_Solution',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCFailSolution]: {
		eventName: 'cc_fail_Solution',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCSolveSolution]: {
		eventName: 'cc_solve_Solution',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCCodeSeeSolutionBlock]: {
		eventName: 'cc_code_seeSolution_block',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCFailSeeSolutionBlock]: {
		eventName: 'cc_fail_seeSolution_block',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCSolveSeeSolutionBlock]: {
		eventName: 'cc_solve_seeSolution_block',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCCodeSeeSolutionBlockUnlock]: {
		eventName: 'cc_code_seeSolution_block_unlock',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCFailSeeSolutionBlockUnlock]: {
		eventName: 'cc_fail_seeSolution_block_unlock',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCSolveSeeSolutionBlockUnlock]: {
		eventName: 'cc_solve_seeSolution_block_unlock',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCCodeSeeSolution]: {
		eventName: 'cc_code_seeSolution',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCFailSeeSolution]: {
		eventName: 'cc_fail_seeSolution',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slCCSolveSeeSolution]: {
		eventName: 'cc_solve_seeSolution',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slBitPopupCCSolutionUnlock]: {
		eventName: 'bitpopup_ccSolution_unlock',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slBitPopupCCSolution]: {
		eventName: 'bitpopup_ccSolution',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slBitPopupCCSolutionUnlockBits]: {
		eventName: 'bitpopup_ccSolution_unlock_bits',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slBitPopupCCSolutionUnlockPro]: {
		eventName: 'bitpopup_ccSolution_unlock_pro',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slBitPopupCCSolutionPro]: {
		eventName: 'bitpopup_ccSolution_pro',
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.slTermOfConditionsPopUpView]: {
		eventName: 'termsCondsUpdatePopup',
		params: ['action'],
	},
	[TrackingEventNames.slTermOfConditionsAccept]: {
		eventName: 'termsCondsUpdatePopup_accept',
		params: ['action'],
	},
	[TrackingEventNames.slPrivacyPolicyPopUpView]: {
		eventName: 'privacyPolicyUpdatePopup',
		params: ['action'],
	},
	[TrackingEventNames.slPrivacyPolicyAccept]: {
		eventName: 'privacyPolicyUpdatePopup_accept',
		params: ['action'],
	},

	// leaderboard events
	[TrackingEventNames.leaderboardWelcome]: {
		eventName: TrackingEventNames.leaderboardWelcome,
		params: ['action'],
	},
	[TrackingEventNames.leaderboardWelcomeIamstar]: {
		eventName: TrackingEventNames.leaderboardWelcomeIamstar,
		params: ['action'],
	},
	[TrackingEventNames.leaderboardTab]: {
		eventName: TrackingEventNames.leaderboardTab,
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.leaderboardPageState]: {
		eventName: TrackingEventNames.leaderboardPageState,
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.leaderboardDisabled]: {
		eventName: TrackingEventNames.leaderboardDisabled,
		params: ['action'],
	},
	[TrackingEventNames.leaderboardDisabledJoin]: {
		eventName: TrackingEventNames.leaderboardDisabledJoin,
		params: ['action'],
	},
	[TrackingEventNames.leaderboardResult]: {
		eventName: TrackingEventNames.leaderboardResult,
		params: ['action', 'entityId'],
	},
	[TrackingEventNames.leaderboardEarnxpItem]: {
		eventName: TrackingEventNames.leaderboardEarnxpItem,
		params: ['action', 'entityId'],
	},
};

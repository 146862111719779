import { Observable } from 'rxjs';
import {
	IHeartConfiguration,
	IHeartDeductionUnit,
} from '../../../symphony';

export interface ISocketApi {
    connect(): void;
    disconnect(): void;
    isConnected(): boolean;
    getBitChangeStream(): Observable<BitChangeModel>;
    getHeartChangeStream(): Observable<HeartChangeModel>;
}

export interface ISocketApiConfig {
    socketPath: string;
    environmentUrl: string;
    callbacks?: {
        onConnect?: () => void;
        onDisconnect?: () => void;
        onError?: () => void;
    };
    options?: {
        reconnectionAttempts?: number;
        reconnectionDelay?: number;
    };
}

export enum EventTypes {
    connect = 'connect',
    connectError = 'connect_error',
    disconnect = 'disconnect',
    bitChange = 'BitChange',
    heartChange = 'HeartChange',
}

export interface BitChangeModel {
    UserId: string;
    PreviousValue: string;
    CurrentValue: string;
}

export interface HeartChangeModel {
    HeartsCount?: number;
    PreviousHeartsCount?: number;
    LastUpdateDate?: string;
    HasInfiniteHearts: boolean;
    MaxHeartsCount?: number;
    Configurations?: [IHeartConfiguration];
    DeductionUnits?: [IHeartDeductionUnit];
}

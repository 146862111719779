import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './BraintreeInputField.scss';
import { useContainerData } from '../../../../../shared/public/SlHooks/useContainerData';
import { CheckoutStore } from '../../../checkout.store';

type BraintreeInputFieldProps = {
	label: string;
	id: string;
};

export const BraintreeInputField: FC<BraintreeInputFieldProps> = ({
	label, id
}) => {
	useStyles(s);

	const {
		transactionInProcess
	} = useContainerData('paywalls', CheckoutStore, [
		'transactionInProcess'
	]);

	return <div className="sl-br-input-wrapper">
		<div className="sl-br-input-wrapper__label">{label}</div>
		<div
			className={`sl-br-input-wrapper__input ${transactionInProcess ? 'disabled' : ''}`}
			id={id}
		></div>
	</div>;
};

import { Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import {
	Container,
	Service,
	TrackingEventNames,
	TrackingService,
} from '../../../symphony';

import { SlPaymentTrackingService } from './sl-payment-tracking.service';
import { SlPaymentContext } from '../payment-context';
import { ISubscriptionHistory } from '../global.interface';
import { forExperimentsMockedData } from '../global.constants';
import { SubscriptionPlanId } from '../../../shared/public/globalInterfaces/globalInterfaces';
import {
	IGatewayTokenResponse, IPaymentConfirmPayload, IPaymentConfirmationResponse, IPaymentPlanQuery, IPaymentPlanResponse, PaymentApi,
} from '../../../api/public/payment-api';
import { ExperimentApi, ExperimentNames, IDynamicProPageExperimentResponse } from '../../../api/public/experiment-api';
import { SlApiContext } from '../../../api/private/api-context';
import { SlErrorHandlerService } from '../../../api/public/error-handling';
import { IExperimentsDataResponse } from '../../../api/private/global.interface';

@Service()
export class SlPaymentApiService {
	private paymentApi = Container.take('global', PaymentApi);

	private experimentApi = Container.take('global', ExperimentApi);

	private gatewayTokens: Observable<IGatewayTokenResponse>;

	private paymentPlans: Observable<IPaymentPlanResponse>;

	private tracking$ = Container.take('global', TrackingService).tracking$;

	private tagManager$ = Container.take(
		SlPaymentContext,
		SlPaymentTrackingService,
	).tagManager$;

	private handleErrorCatching = Container.take(
		SlApiContext,
		SlErrorHandlerService,
	).handleErrorCatching;

	private paymentPlan = {};

	public gatewayTokenStream(): Observable<IGatewayTokenResponse> {
		if (!this.gatewayTokens) {
			this.gatewayTokens = this.paymentApi
				.getGatewayToken()
				.pipe(shareReplay(4), this.handleErrorCatching(true, {}, null));
		}
		return this.gatewayTokens;
	}

	public getPaymentPlans(): Observable<IPaymentPlanResponse> {
		if (!this.paymentPlans) {
			this.paymentPlans = this.paymentApi
				.getPaymentPlans()
				.pipe(shareReplay({ refCount: true }));
		}
		return this.paymentPlans;
	}

	public getPaymentPlan(
		id: string,
		query?: IPaymentPlanQuery,
	): Observable<IPaymentPlanResponse> {
		if (!this.paymentPlan[id]) {
			this.paymentPlan[id] = this.paymentApi
				.getPaymentPlan(id, query)
				.pipe(shareReplay({ refCount: true }));
		}
		return this.paymentPlan[id];
	}

	public subscribe(
		payload: IPaymentConfirmPayload,
	): Observable<IPaymentConfirmationResponse> {
		return this.paymentApi.subscribe(payload).pipe(
			tap((response: IPaymentConfirmationResponse) => {
				const subscriptionHistory = response?.data as ISubscriptionHistory[];

				const length: number = subscriptionHistory?.length;

				if (length === 1) {
					this.tagManager$.next({
						event: 'firstSubscription',
					});
					if (
						subscriptionHistory[0].paymentPlanId
						=== SubscriptionPlanId.yearly
					) {
						this.tagManager$.next({
							event: 'freeTrial',
						});
						this.tracking$.next({
							eventName: TrackingEventNames.trialStarted,
							eventParams: {
								paymentReccurency: 'annually',
							},
						});
					} else {
						this.tracking$.next({
							eventName: TrackingEventNames.subscribe,
						});
					}
				} else if (length > 1) {
					this.tagManager$.next({
						event: 'reactivateSubscription',
					});

					const isLastSubscriptionYearly = subscriptionHistory[length - 1]?.paymentPlanId
						=== SubscriptionPlanId.yearly;
					const isOnlyYearlySub = subscriptionHistory.filter(
						(sub) => sub?.paymentPlanId === SubscriptionPlanId.yearly,
					).length === 1;
					const isFirstTrial: boolean = isLastSubscriptionYearly && isOnlyYearlySub;

					if (isFirstTrial) {
						this.tagManager$.next({
							event: 'freeTrial',
						});
					} else {
						this.tracking$.next({
							eventName: TrackingEventNames.subscribe,
						});
					}
				}
			}),
		);
	}

	public getExperimentsData(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		userId: number,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		experimentsArray: ExperimentNames[],
	): Observable<IExperimentsDataResponse> {
		return of(forExperimentsMockedData);
	}

	public getDynamicProPageExperimentData(): Observable<IDynamicProPageExperimentResponse> {
		return this.experimentApi.getDynamicProPageExperimentData();
	}
}

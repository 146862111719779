import { BehaviorSubject } from 'rxjs';
import { Service } from '../../../symphony';

import { IFlashMessage } from '../global.interface';

@Service()
export class SlLoginErrorsService {
	public errors$ = new BehaviorSubject<string>(null);

	public flashMessage$ = new BehaviorSubject<IFlashMessage>(null);
}

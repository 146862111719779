import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Select, Option } from '@sololearnorg/sol';
import { i18n, Container, CookieService } from '../../../../symphony';
import s from './LanguageSwitcher.scss';

export function LanguageSwitcher() {
	useStyles(s);
	const supportedLanguages: { code: string; language: string; }[] = typeof window === 'undefined'
		? Container.take('global', 'supportedLanguages')
		: (window as any).initialData?.supportedLanguages;

	const switchLanguage = (newLang: string) => {
		const cookieService = Container.take('global', CookieService);
		cookieService.setCookie('sl-language', newLang);
		const currentPath = window.location.pathname;
		const pathSegments = currentPath.split('/');

		if (pathSegments[1]) {
			pathSegments[1] = newLang;
		}

		const newPath = pathSegments.join('/');
		window.location.href = newPath;
	};

	return (
		<div className="sl-footer-catalog__list">
			<p className="sl-footer-catalog__title">
				{i18n.t('web-footer.language-switcher-title')}
			</p>
			<Select
				value={i18n.language}
				className="sl-language-switcher"
				onValueChange={(value) => {
					switchLanguage(value);
				}}
				size="s"
			>
				{supportedLanguages?.map((supportedLanguage) => (
					<Option
						value={supportedLanguage.code}
						key={supportedLanguage.code}
					>
						{supportedLanguage.language}
					</Option>
				))}
			</Select>
		</div>
	);
}

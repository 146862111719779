import React, { FC, useEffect, useState } from "react";
import s from "./LongPaywallPaymentMethods.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useContainerData } from "../../../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../../../checkout.store";
import { PaymentMethodItem, PaymentMethods, PaywallClickTypes } from "../../../../../paywalls.constants";
import { BraintreeLogo, NewTab, PaymentMethodIcon, ShieldIcon } from "../../../../ui/icons";
import { Container, i18n } from "../../../../../../../symphony";
import { CheckoutService } from "../../../../../services/checkout.service";
import { CardPayment } from "../../../../checkout/CardPayment/CardPayment";
import { PaywallsService } from "../../../../../services/paywalls.service";

const allPaymentMethods: PaymentMethodItem[] = [
	{
		method: PaymentMethods.card, title: 'web-paywall.long.checkout.payment-method.card', icon: <>{PaymentMethodIcon.visa}{PaymentMethodIcon.master}{PaymentMethodIcon.amex}{PaymentMethodIcon.discover}{PaymentMethodIcon.jcb}</>
	},
	{
		method: PaymentMethods.applePay, title: 'web-paywall.long.checkout.payment-method.apple-pay', icon: PaymentMethodIcon.applePay
	},
	{
		method: PaymentMethods.payPal, title: 'web-paywall.long.checkout.payment-method.paypal', icon: PaymentMethodIcon.payPal
	}
];

type Props = {};

export const LongPaywallPaymentMethods: FC<Props> = () => {
	useStyles(s);
	const [supportedMethods, setSupportedMethods] = useState<PaymentMethodItem[]>([]);
	const { trackPaywallClick } = Container.take('paywalls', PaywallsService);
	const { selectPaymentMethod } = Container.take('paywalls', CheckoutService);
	const {
		supportsApplePay,
		selectedPaymentMethod
	} = useContainerData('paywalls', CheckoutStore, [
		'supportsApplePay',
		'selectedPaymentMethod'
	]);

	useEffect(() => {
		if (supportsApplePay) {
			setSupportedMethods(allPaymentMethods);
		} else {
			setSupportedMethods(allPaymentMethods.filter(p => p.method !== PaymentMethods.applePay));
		}
	}, [supportsApplePay]);

	const getBody = (method: PaymentMethods) => {
		if (method === PaymentMethods.card) {
			return <CardPayment layout="vertical" />;
		} else {
			const methodInfo = allPaymentMethods.find(m => m.method === method);

			return <div className="payment-feedback">
				<div className="payment-feedback-icon">
					{methodInfo.icon}
				</div>
				<h6 className="payment-feedback-title">
					{i18n.t(methodInfo.title)} {i18n.t('web-paywall.long.checkout.payment-method.selected')}
				</h6>
				<hr className="payment-feedback-divider" />
				<div className="payment-feedback-next-step">
					<div><NewTab /></div>
					{i18n.t('web-paywall.long.checkout.payment-method.next-step')}.
				</div>
			</div>;
		}
	};

	const onMethodChange = (method: PaymentMethods) => {
		if (selectedPaymentMethod === method) {
			return;
		}
		selectPaymentMethod(method);
		trackPaywallClick(PaywallClickTypes.paymentMethod, method, 'CHECKOUT');
	};

	return <div className="payment-methods">
		{supportedMethods.map(m => <div
			key={m.method}
			onClick={() => onMethodChange(m.method)}
			className={`payment-method ${selectedPaymentMethod === m.method ? 'selected' : ''}`}
		>
			<div className="payment-method-header">
				<div className={`payment-method-radio ${selectedPaymentMethod === m.method ? 'selected' : ''}`}></div>
				<span className="payment-method-title">{i18n.t(m.title)}</span>
				<div className="payment-method-icon">{m.icon}</div>
			</div>
			<div className={`payment-method-body ${selectedPaymentMethod === m.method ? 'visible' : 'hidden'}`}>
				{getBody(m.method)}
			</div>
		</div>)}
		<div className="payment-secure-payment">
			<div className="payment-secure-payment-shield">
				<ShieldIcon />
				<span>{i18n.t('web-paywall.long.checkout.payment-method.secure-payments')}</span>
			</div>
			<div className="payment-secure-payment-powered">
				<span>{i18n.t('web-paywall.long.checkout.payment-method.powered-by')}</span>
				<BraintreeLogo />
			</div>
		</div>
	</div>;
};

import { useEffect } from 'react';

import { Container } from '../../features/symphony';

import { asyncFileLoader } from '../../features/shared/public/SlUtils/asyncFileLoader';

declare const FB: any;

export const useFBInit = () => {
	useEffect(() => {
		asyncFileLoader(
			'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0',
			() => {
				const fbAppId = Container.take('global', 'fbAppId');

				FB?.init({
					appId: fbAppId,
					autoLogAppEvents: true,
					xfbml: true,
					version: 'v15.0',
				});
			},
		);
	}, []);
};

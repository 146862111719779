import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import { i18n } from '../../../../symphony';

import s from './UserBitsItem.scss';
import { IUserBitSource } from '../../../../api/public/gamification-api';

export interface IBitSourceItemProps {
	source: IUserBitSource;
	dataAttribute: string;
}

export const UserBitsItem: React.FC<IBitSourceItemProps> = React.memo(
	({ source, dataAttribute }) => {
		useStyles(s);
		return (
			<div
				className="sl-nav-bit-source-item"
				data-attribute={dataAttribute}
				sl-test-data="cmpUserBitsItem"
			>
				<p className="sl-nav-bit-source-item__description">
					{i18n.t(source.description)}
				</p>
				<span className="sl-nav-bit-source-item__bit">
					{source.bit}
					{' '}
					Bits
					{' '}
					{source.isHotToday ? (
						<span className="sl-nav-bit-source-item__bit__multiplayer">
							{`x${source.multiplayer}`}
						</span>
					) : null}
				</span>
			</div>
		);
	},
);

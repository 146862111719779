import 'core-js';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { history } from '../features/symphony';
import App from './App';
import { ErrorBoundary } from './ErrorBoundary';

const insertCss = (...styles) => {
	const removeCss = styles.map((style) => style._insertCss());
	return () => removeCss.forEach((dispose) => dispose());
};

loadableReady(() => {
	const root = document.getElementById('main');
	hydrateRoot(
		root,
		<StyleContext.Provider value={{ insertCss }}>
			<HistoryRouter history={history as any}>
				<ErrorBoundary>
					<App />
				</ErrorBoundary>
			</HistoryRouter>
		</StyleContext.Provider>
	);
});

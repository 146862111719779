export enum TrackingSource {
    GoogleWidget = 'google_widget',
    SignUpForm = 'sign_up_form',
    StartLearningNow1 = 'start_learning_now_1',
    StartLearningNow2 = 'start_learning_now_2',
    StartLearningLikeAPro = 'start_learning_like_a_pro',
    Register = 'register',
    Login = 'login',
    SignIn = 'sign_in',
    SelectPlan = 'select_plan',
    TryProForFree14Days = 'try_pro_for_free_14_days',
    TargetLandingPage = 'target_landing_page',
    NewQuestion = 'new_question',
    Upvote = 'upvote',
    Other = 'other',
    Google = 'google',
    Apple = 'apple',
    Facebook = 'facebook',
    Save = 'save',
    Public = 'public',
    Lesson = 'lesson',
    ChangeName = 'change_name',
    VoteCode = 'vote_code',
}

export enum TrackingSourceDetail {
    Default = 'default',
    OnBoarding = 'on_boarding',
    Home = 'home',
    Codes = 'codes',
    LandingPage = 'landing_page',
    Blog = 'blog',
    Profile = 'profile',
    Discuss = 'discuss',
    Payments = 'payments',
    Courses = 'courses',
    Playground = 'playground',
    TargetedLanding = 'targeted_landing',
    LanguageLanding = 'language_landing',
    Other = 'other',
    AICourseEarlyBird = 'learn-how-to-use-ai',
}

export enum TrackingSearchParam {
    Source = 'tr_source',
    SourceDetail = 'tr_source_detail',
    SourceLandingPageAlias = 'tr_source_landing_page_alias',
    CodeId = 'tr_code_id',
    Language = 'tr_language',
}

export interface ITrackingEventParams {
    source?: TrackingSource;
    sourceDetail?: TrackingSourceDetail;
    sourceLandingPageAlias?: string;
    codeId?: string;
    language?: string;
}

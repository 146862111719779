import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import {
	Container, CourseTrack, DataService, HeartModalSource, HeartService,
} from '../../../../symphony';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { SlActions } from '../../services/ActionsService/sl-actions';
import { SlNavigationContext } from '../../global-constants';
import { ActionType, RequestType } from '../../global-interface';
import { SlInfiniteHeart } from '../../../../shared/public/SlInfiniteHeart/SlInfiniteHeart';
import { SlUserHeart } from '../../../../shared/public/SlUserHeart/SlUserHeart';

import s from './UserHeart.scss';

export function UserHeart(): JSX.Element {
	useStyles(s);

	const { hearts$: hearts } = useContainerData('global', HeartService, ['hearts$']);

	const { learningExperience$: learningExperience } = useContainerData('global', DataService, ['learningExperience$']);

	if (!hearts || !learningExperience) {
		return null;
	}

	const onClick = () => {
		let source = HeartModalSource.NavIconClick;
		if (hearts.hasInfiniteHearts) {
			source = HeartModalSource.InfiniteIconClick;
		}

		const { actions$ } = Container.take(SlNavigationContext, SlActions);
		actions$.next({
			type: ActionType.OnHeartsIconClick,
			requestType: RequestType.Cancelable,
			data: {
				heartModalSource: source,
			},
		});
	};

	return hearts.hasInfiniteHearts ? (
		<SlInfiniteHeart onClick={onClick} />
	) : (
		<div className="user-heart__container">
			<SlUserHeart heartsCount={hearts.heartsCount} onClick={onClick} />
		</div>
	);
}

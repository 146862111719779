import React, { FC, useEffect } from "react";
import { Container } from "../../../../../symphony";
import { CheckoutService } from "../../../services/checkout.service";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaypalPayment.scss';
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";

export const PaypalPayment: FC = () => {
	useStyles(s);

	const { initPaypal } = Container.take('paywalls', CheckoutService);

	const { transactionInProcess } = useContainerData('paywalls', CheckoutStore, ['transactionInProcess']);

	useEffect(() => {
		initPaypal();
	}, []);

	return <div className={`paypal-button ${transactionInProcess ? 'disabled' : ''}`} id="paypal-button"></div>;
};
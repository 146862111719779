import React, { memo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { backArrow, SlIcon, useIcons } from '@sololearnorg/icons';

import { SL_ERROR_404 } from './constants';
import { SlIErrorProps } from './interfaces';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';

import s from './SlError.scss';

export const SlError: React.FC<SlIErrorProps> = memo(
	({ configs = SL_ERROR_404 }) => {
		useStyles(s);
		useIcons([backArrow]);

		const {
			title, info, imgUrl, linkText, linkTo, hasBackButton,
		} = configs;

		const goBackHandler = () => {
			window.history.back();
		};

		return (
			<section className="sl-error">
				<div className="sl-error__header">
					{hasBackButton && (
						<div onClick={goBackHandler} className="sl-error__back">
							<SlIcon iconId="backArrow" />
						</div>
					)}
					<h2 className="sl-error__title">{title}</h2>
				</div>

				<div className="sl-error__content">
					<img
						alt="error_image"
						className="sl-error__content__image"
						src={imgUrl}
					/>
					<p className="sl-error__content__info-main">{info}</p>
				</div>
				{linkText
                    && (linkTo ? (
	<LocalizedLink
	to={linkTo}
	reloadDocument
	className="sl-error__link"
                    	>
	{linkText}
                    	</LocalizedLink>
                    ) : (
	<div onClick={goBackHandler} className="sl-error__link">
	{linkText}
                    	</div>
                    ))}
			</section>
		);
	},
);

import { Container, DataService, NavigationItem } from '../../features/symphony';

import { SlNavBarPositions, SlNavbarThemes, SlNavbarType } from '../../features/shared/public/globalInterfaces/globalInterfaces';
import { SlNavbarState } from '../../features/navigation/private/global-interface';

export interface NavigationConfigs {
	theme?: SlNavbarThemes,
	state?: SlNavbarState;
	position?: SlNavBarPositions;
	type?: SlNavbarType;
	hasBadgeOn?: {
		[NavigationItem.Leaderboard]?: boolean;
	};
}

export const setNavigationConfigs = (configs: NavigationConfigs): void => {
	// todo: the service should be moved to symphony, and remove the new SlNavigationService() statement
	const SlNavigationService: any = Container.take(
		'global',
		'slNavigationService',
	);

	const globalDataService = Container.take('global', DataService);

	const service = new SlNavigationService();

	service.setType(configs.type || SlNavbarType.default);
	if (configs.state === SlNavbarState.shown) {
		service.show();
	}
	if (configs.state === SlNavbarState.hidden) {
		service.hide();
	}
	if (configs.theme) {
		service.setTheme(configs.theme);
	}
	if (configs.position) {
		service.setInitialConfigs({
			navbarPosition: configs.position,
		});
	}
	if (configs.hasBadgeOn) {
		globalDataService.navItemBadgeConfig$.next({
			...(globalDataService.navItemBadgeConfig$?.value || {}),
			...configs.hasBadgeOn,
		});
	}
};

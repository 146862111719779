import { Location } from 'react-router-dom';
import { AuthService, Container, history } from '../../features/symphony';

const authService: AuthService = Container.take('global', AuthService);

export interface IUseAuth {
	redirect?: boolean;
	redirectUrl?: string;
	callbackBeforeRedirect?: () => void;
	guardType?: 'onlyAuthorized' | 'onlyNotAuthorized';
	navigationType?: 'internal' | 'window';
}

const useAuthDefaultConfigs: IUseAuth = {
	redirect: true,
	navigationType: 'window',
	callbackBeforeRedirect: null,
	guardType: 'onlyAuthorized',
};

export const authGuard = (inputConfigs?: IUseAuth) => (location: Location): boolean => {
	const configs: IUseAuth = {
		...useAuthDefaultConfigs,
		redirectUrl: `/users/login?ReturnUrl=${encodeURI(location.pathname + location.search)}`,
		...inputConfigs,
	};
	const isLoggedIn = authService.isLoggedIn(true);

	if (configs.redirect && (configs.guardType === 'onlyAuthorized' ? !isLoggedIn : isLoggedIn)) {
		if (configs.callbackBeforeRedirect) {
			configs.callbackBeforeRedirect();
		}
		if (configs.navigationType === 'window') {
			window.location.href = configs.redirectUrl;
		} else {
			history.replace(configs.redirectUrl);
		}
		return false;
	}
	return true;
};

import { BehaviorSubject } from 'rxjs';
import { Service } from '../../../symphony';
import { ISocialLoginCredentials } from '../../../api/public/auth-api';

@Service()
export class SlLoginInputService {
	public socialInputs$ = new BehaviorSubject<ISocialLoginCredentials>({
		accessToken: '',
		provider: 'facebook',
	});
}

import React, { FC, useEffect } from 'react';
import { useContainerData } from '../../../../../shared/public/SlHooks/useContainerData';
import { PaywallsStore } from '../../../paywalls.store';
import { PageWrapper } from '../../PageWrapper/PageWrapper';
import { CardPayment } from '../CardPayment/CardPayment';
import { PaypalPayment } from '../PaypalPayment/PaypalPayment';
import { PlanDetails } from '../PlanDetails/PlanDetails';
import { Container, i18n } from '../../../../../symphony';
import { CheckoutService } from '../../../services/checkout.service';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './CheckoutWrapper.scss';
import { CheckoutStore } from '../../../checkout.store';
import { PaywallPageButton } from '../../PaywallPageButton/PaywallPageButton';
import { PaywallButtonAction, PaywallButtonType } from '../../../../../api/public/subscription-api';
import { ApplePay } from '../ApplePay/ApplePay';

export const CheckoutWrapper: FC = () => {
	useStyles(s);

	const {
		selectedProductKey,
		paymentProducts
	} = useContainerData('paywalls', PaywallsStore, [
		'selectedProductKey',
		'paymentProducts',
	]);
	const {
		paypalInitialized,
		transactionInProcess,
		cardInitialized
	} = useContainerData('paywalls', CheckoutStore, [
		'paypalInitialized',
		'transactionInProcess',
		'cardInitialized'
	]);

	const { setSelectedProductKey, setSelectedProduct } = Container.take('paywalls', CheckoutService);

	useEffect(() => {
		setSelectedProductKey(selectedProductKey);
		setSelectedProduct(paymentProducts.find(p => p.key === selectedProductKey));
	}, []);

	return <PageWrapper
		title='web-checkout.title'
		styles={{
			header: {
				paddingTop: '40px',
				paddingBottom: '32px'
			}
		}}
		headerVersion={1}
		headerComponents={[]}
	>
		<div className={`checkout ${!cardInitialized || !paypalInitialized ? 'loading' : ''}`}>
			<PlanDetails paymentProduct={paymentProducts.find(p => p.key === selectedProductKey)} />
			<ApplePay />
			<PaypalPayment />
			<div className="checkout-separator">
				<span>{i18n.t('web-auth.divider')}</span>
			</div>
			<CardPayment />
			<PaywallPageButton
				type={PaywallButtonType.primary}
				action={PaywallButtonAction.subscribe}
				text='paywalls.ai-pro.subscribe-now.cta'
				order={null}
				disabled={!cardInitialized}
				loading={transactionInProcess}
				loadingText={'paywalls.long-flow.purchase-button.purchasing'}
			/>
		</div>
	</PageWrapper>;
}; 
import React from 'react';
import { useParams } from 'react-router-dom';
import { SlHelmet } from '../../../shared/public/SlHelmet/SlHelmet';

export const SlQuizTest = () => {
	const quizId = useParams<{ quizId: string }>().quizId;
	return (
		<>
			<SlHelmet title="Sololearn: Quiz" />
			<iframe
				title="SlQuizTest"
				src={`https://sololearn990.outgrow.us/${quizId}`}
				style={{ width: '100%', height: '100vh', border: 'none' }}
			/>
		</>
	);
};

import { Observable } from 'rxjs';

import { IHearts, HeartUsageType } from '../../../symphony';

import { IResponse } from '../global.interface';

export interface IKudosApi {
    getHearts: () => Observable<IKudosResponse>;
    useHeart: (payload: IUseHeartPayload[]) => Observable<IKudosResponse>;
    getHeartConfigs: () => Observable<IKudosHeartConfigsResponse>;
    freeHeartRefill: (payload: IFreeHeartPayload) => Observable<IKudosResponse>;
}

export enum EngineType {
    oldEngine = 1,
    newEngine = 2,
}

export interface IKudosApiConfig {
    environmentUrl: string;
    useMock?: boolean;
    mock?: IKudosApiMock;
    versions?: IKudosVersions;
}

export interface IKudosResponse extends IResponse {
    data: IHearts;
}

export interface IHeartConfigs {
    firstLessonFreeHeartReceived: boolean;
    isUserEligableForFirstLessonFreeHeart: boolean;
    isUserEligableForHeartIntro: boolean;
}

export interface IKudosHeartConfigsResponse extends IResponse {
    data: IHeartConfigs;
}
export interface IKudosApiMock {
    getHearts: IKudosResponse;
    useHeart: IKudosResponse;
    getHeartConfigs: IKudosHeartConfigsResponse;
    freeHeartRefill: IKudosResponse;
}

export interface IKudosVersions {
    getHearts?: string;
    useHeart?: string;
    getHeartConfigs?: string;
    freeHeartRefill?: string;
}

export interface IUseHeartPayload {
    usageTypeId: HeartUsageType;
    entityId: number;
}

export interface IFreeHeartPayload {
    entityId: number;
    entityType: number;
    engineType: EngineType;
}

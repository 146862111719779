import { useEffect, useState } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Container } from '../../../symphony';

type Class<T> = new (...args: unknown[]) => T;
type ExtractGeneric<Type> = Type extends BehaviorSubject<infer X> ? X : null;

// Todo find better place for this custom hook
export const useContainerData = <
    T extends Record<L, BehaviorSubject<unknown>>,
    L extends keyof T,
    M extends ExtractGeneric<T[L]>,
>(
		Context: string,
		Service: Class<T>,
		propertyNames: L[],
		defaultValues?: {
        [K in L]: ExtractGeneric<T[K]>;
    },
	):
    | {
          [K in L]: ExtractGeneric<T[K]>;
      }
    | Record<string, never> => {
	const modifiedDefaultValues = defaultValues
        || ({} as {
            [K in L]: ExtractGeneric<T[K]>;
        });
	const service = Container.take(Context, Service);

	propertyNames.forEach((propertyName) => {
		if (service[propertyName]?.value !== undefined) {
			modifiedDefaultValues[propertyName] = service[propertyName]
				.value as ExtractGeneric<T[L]>;
		}
	});

	const [containerData, setContainerData] = useState<{
        [K in L]: ExtractGeneric<T[K]>;
    }>(modifiedDefaultValues);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		propertyNames.forEach((propertyName) => {
			service[propertyName]
				.pipe(
					takeUntil(unsubscriber),
					tap((data: M) => {
						setContainerData((oldData) => ({
							...oldData,
							[propertyName]: data,
						}));
					}),
				)
				.subscribe();
		});

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	return containerData || {};
};

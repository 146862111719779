interface IGlobalConfig {
	githubClientId: string;
	stackoverflowClientId: string;
}

const localConfig: IGlobalConfig = {
	githubClientId: '66904c50ee6e44430dda',
	stackoverflowClientId: '14353',
};

export const hostsConfigs = {
	learnEngineApi: {
		useMock: null,
		version: {
			getMaterial: '2.0',
			getLessonSubtree: '3.0',
			getCourseSubtree: '2.0',
			solveMaterial: '2.0',
			enrollCourse: '2.0',
			runCode: '2.0',
			getMaterialSolution: '2.0',
			addBetaTester: '2.0',
			removeCourse: '2.0',
			getCourseStructure: '2.0',
		},
		mock: null,
	},
	paymentApi: {
		useMock: null,
		version: {
			getPaymentPlans: 'v1',
		},
		mock: null,
	},
	commentsApi: {
		useMock: null,
		mock: null,
	},
	codeCoachApi: {
		useMock: null,
		version: null,
		mock: null,
	},
	homeApi: {
		useMock: null,
		version: null,
		mock: null,
	},
	profileApi: {
		useMock: null,
		version: {
			getProfile: 'v3',
			getFollowers: 'v3',
			getFollowing: 'v3',
			updateInfo: 'v3',
			follow: 'v3',
			unfollow: 'v3',
			uploadAvatar: 'v3',
			changePassword: 'v3',
			deleteAccount: 'v3',
			updateUserInfo: 'v3',
		},
		mock: null,
	},
	codeApi: {
		useMock: null,
		version: {
			compileCode: 'v2',
		},
		mock: null,
	},
	gamificationApi: {
		useMock: null,
		version: null,
		mock: null,
	},
	authApi: {
		useMock: null,
		mock: null,
	},
	staticPagesApi: {
		version: null,
	},
	userSettingsApi: {
		useMock: null,
		version: null,
		mock: null,
	},
	onboardingApi: {
		useMock: null,
		mock: null,
	},
	kudosApi: {
		useMock: null,
		version: null,
		mock: null,
	},
	lookupApi: {
		version: null,
	},
	mailingApi: {
		version: null,
	},
	abTestingApi: {
		version: null,
	},
	streakApi: {
		getStreaks: null,
	},
};

export const GlobalConfig = typeof window !== 'undefined'
	? (window as any).globalConfig || localConfig
	: localConfig;

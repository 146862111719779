import { Observable, of } from 'rxjs';

import {
	IGatewayTokenResponse,
	IPaymentPlanResponse,
	IPaymentConfirmationResponse,
	IPaymentConfirmPayload,
	IPaymentApi,
	IPaymentApiMock,
	IPaymentPlanQuery,
	IPaymentsVersions,
	IPaymentStatusResponse,
	ICancelSubscriptionResponse,
	IPurchaseItemResponse,
	IPurchasePayload,
} from '../private/payment-api/payment-api.interface';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { IApiUrls, IHostsConfigs } from '../private/global.interface';
import { constructUrl } from '../private/utils/httpParamsUtils';
import { Service, Container } from '../../symphony';

@Service()
export class PaymentApi implements IPaymentApi {
	private environmentUrl: string;

	private subscriptionApiHost: string;

	private useMock: boolean;

	private mock: IPaymentApiMock;

	private axiosInstance: AxiosInstanceType;

	private versions: IPaymentsVersions;

	constructor() {
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).paymentApiHost;
		this.subscriptionApiHost = (
			Container.take('global', 'envUrl') as IApiUrls
		).subscriptionApiHost;
		this.useMock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.paymentApi?.useMock;
		this.mock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.paymentApi?.mock;
		this.versions = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.paymentApi?.version;
		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.public)
	public getPaymentPlans(): Observable<IPaymentPlanResponse> {
		if (this.useMock) return of(this.mock.paymentPlan);
		return this.axiosInstance.get(
			constructUrl(
				`${this.environmentUrl}/api`,
				'PaymentPlans',
				this.versions?.getPaymentPlans,
			),
		);
	}

	@Authorize(AuthLevel.public)
	public getPaymentPlan(
		id: string,
		query?: IPaymentPlanQuery,
	): Observable<IPaymentPlanResponse> {
		if (this.useMock) return of(this.mock.paymentPlan);
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				`api/PaymentPlans/${id}`,
				this.versions?.getPaymentPlan,
			),
			{
				params: query,
			},
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getGatewayToken(): Observable<IGatewayTokenResponse> {
		if (this.useMock) return of(this.mock.gatewayToken);
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				'api/Payments/gateway-authorization',
				this.versions?.getGatewayToken,
			),
		);
	}

	@Authorize(AuthLevel.authenticated)
	public purchase(
		payload: IPurchasePayload,
	): Observable<IGatewayTokenResponse> {
		return this.axiosInstance.post(
			constructUrl(
				this.subscriptionApiHost,
				'api/in-app-purchases/web',
			),
			payload,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public subscribe(
		payload: IPaymentConfirmPayload,
	): Observable<IPaymentConfirmationResponse> {
		if (this.mock) return of(this.mock.paymentConfirmation);
		return this.axiosInstance.post(
			constructUrl(
				this.environmentUrl,
				'api/Payments/subscribe',
				this.versions?.subscribe,
			),
			payload,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getPaymentStatus(): Observable<IPaymentStatusResponse> {
		if (this.useMock) return of(this.mock.paymentStatus);
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				'api/payments/status/all',
				this.versions?.getPaymentStatus,
			),
		);
	}

	@Authorize(AuthLevel.authenticated)
	public cancelSubscription(): Observable<ICancelSubscriptionResponse> {
		return this.axiosInstance.post(
			constructUrl(
				this.environmentUrl,
				'api/payments/cancel',
				this.versions?.cancelSubscription,
			),
			null,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getPurchaseItem(itemId: string): Observable<IPurchaseItemResponse> {
		return this.axiosInstance.get(
			constructUrl(this.subscriptionApiHost, `api/in-app-purchases/${itemId}/price`),
		);
	}
}

export * from '../private/payment-api/payment-api.interface';

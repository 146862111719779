import { Container, LearningExperienceType, StorageService } from '../../../symphony';
import { SlLEContext } from '../global-constants';
import { SlDataService } from '../services/sl-data.service';
import { CompletionStatus, IMaterial, ILEHeaders } from '../../../api/private/learn-engine-api/learn-engine-api.interface';
import { SlStorageKeys } from '../../../shared/public/globalInterfaces/globalInterfaces';

export const isJustCompleted = (): boolean => {
	const { lesson$, lessonStatus } = Container.take(
		SlLEContext,
		SlDataService,
	);

	// TODO here is a bug, sometimes lessonStatus is undefined
	if (
		lessonStatus.completion !== CompletionStatus.Completed
		&& lessonStatus.completion !== CompletionStatus.ByPassed
	) {
		return (
			lesson$.value.materialInfo.status.completion
			=== CompletionStatus.Completed
			|| lesson$.value.materialInfo.status.completion
			=== CompletionStatus.ByPassed
		);
	}
	return false;
};

export const isLastLesson = (): boolean => {
	const { lesson$, lessonPageIndex$ } = Container.take(
		SlLEContext,
		SlDataService,
	);
	const lessonPageIndex: number = lessonPageIndex$.value;
	const lessonChildren: IMaterial[] = lesson$.value.children;

	return lessonPageIndex === lessonChildren.length;
};

export const getLEHeaders = (
	includeLELocale: boolean = false, includeSession: boolean = false
): ILEHeaders => {
	const { routeData$, course$, isSkipAhead } = Container.take(SlLEContext, SlDataService);
	const storageService = Container.take('global', StorageService);
	const { pathAlias, courseAlias } = routeData$.value;

	const config = {
		'le-exp-type': pathAlias ? LearningExperienceType.LearningPath : LearningExperienceType.Course,
		'le-exp-alias': pathAlias ? pathAlias : courseAlias,
	};

	if (includeLELocale) {
		config['le-locale'] = includeLELocale
			? course$?.value?.supportedLocales?.find(l => l.isSelected)?.locale || null
			: null;
	}

	if (includeSession) {
		const session: string = storageService.load(
			SlStorageKeys.Sl_LE_SESSION,
			'session',
		);
		const key = isSkipAhead.value ? 'sl-le-dynamic-session' : 'sl-le-session';
		if (session) {
			config[key] = session;
		}
	}

	return config;
};

import { TrackingObjType } from '../../../public/tracking/tracking.interface';
import { Tracking } from '../tracking';
import { ITracking } from '../tracking.interface';
import { sololearnEventsMapping } from './sololearnEventsMapping';

declare const slTrack: (payload: {
	action: string;
	element: string;
	entityId: string;
	redirectUrl?: string;
}) => void;

export class SololearnTracking extends Tracking implements ITracking {
	constructor() {
		super(sololearnEventsMapping);
	}

	public handleTracking = (trackingObj: TrackingObjType): void => {
		if (typeof slTrack === 'undefined') return;

		const { params, eventName } = this.getEventParams(trackingObj);

		if (!eventName) return;

		slTrack({
			action: params.action as string,
			element: eventName,
			entityId: (params.entityId as string) || null,
			redirectUrl: (params.redirectUrl as string) || null,
		});
	};
}

import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { IStreakWeekDay, i18n } from '../../../../symphony';

import {
	StreakIconColor,
	StreakIconType,
	StreakIcon,
} from '../StreakIcon/StreakIcon';

import s from './StreakWeekDay.scss';

export const StreakWeekDay: React.FC<IStreakWeekDay> = React.memo(
	({
		id,
		weekDay,
		isReached = false,
		isMilestone = false,
		isToday = false,
		isFreeze = false,
	}) => {
		useStyles(s);
		const getIconType = (): StreakIconType => {
			if (isMilestone) {
				return StreakIconType.Milestone;
			}
			if (isFreeze) {
				return StreakIconType.Saver;
			}
			if (isReached) {
				if (isToday) {
					return StreakIconType.Today;
				}
				return StreakIconType.Default;
			}
			return null;
		};

		const getIconColor = (): StreakIconColor => {
			if (isFreeze) {
				return StreakIconColor.Freeze;
			}
			if (isReached) {
				return StreakIconColor.Reached;
			}
			if (isMilestone) {
				return StreakIconColor.Milestone;
			}
			if (isToday) {
				return StreakIconColor.Today;
			}
			return StreakIconColor.Future;
		};

		return (
			<div
				sl-test-data={`cmpStreakWeekDay${id.toString()}`}
				className="streak-week-day"
			>
				<StreakIcon
					id={id?.toString()}
					type={getIconType()}
					color={getIconColor()}
					border={isToday}
				/>
				<div className={`week-day${isToday ? ' today' : ''}`}>
					{i18n.t(`web-streak.week-day.${weekDay}`)}
				</div>
			</div>
		);
	},
);

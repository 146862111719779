import { Observable } from 'rxjs';

import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { constructUrl } from '../private/utils/httpParamsUtils';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { IApiUrls, IHostsConfigs } from '../private/global.interface';
import {
	IGroupInfo,
	IGroupInfoParams,
	IGroupStatisticsParams,
	IGroupUserData,
	IXpApi,
	IXpVersions,
} from '../private/xp-api/xp-api.interface';
import { Service, Container, IXpSources } from '../../symphony';

@Service()
export class XpApi implements IXpApi {
	private environmentUrl: string;

	private axiosInstance: AxiosInstanceType;

	private versions: IXpVersions;

	constructor() {
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).xpApiHost;
		this.versions = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.xpApi?.version;
		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.public)
	public getXpSources(): Observable<IXpSources> {
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				'api/xp',
				this.versions?.getXpSources,
			),
		);
	}

	@Authorize(AuthLevel.public)
	public getGroupInfo(params: IGroupInfoParams): Observable<IGroupInfo> {
		return this.axiosInstance.get(
			constructUrl(this.environmentUrl, 'api/xp/group'),
			{
				params,
			},
		);
	}

	@Authorize(AuthLevel.public)
	public getGroupStatistics(
		params: IGroupStatisticsParams,
	): Observable<IGroupUserData[]> {
		return this.axiosInstance.get(
			constructUrl(this.environmentUrl, 'api/xp/statistics'),
			{
				params,
			},
		);
	}

	@Authorize(AuthLevel.authenticated)
	public joinGroupLeaderboard(groupName: string): Observable<null> {
		return this.axiosInstance.post(
			constructUrl(this.environmentUrl, `api/xp/group/join/${groupName}`)
		);
	};
}

export * from '../private/xp-api/xp-api.interface';

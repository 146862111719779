import { Observable } from 'rxjs';

import {
	IHearts,
	ILearningExperience,
	IOwnershipStatus,
	IUiConfigurations,
	LearningExperienceType,
	OwnershipRequirementType,
	ProgrammingLanguage,
	ProgressionStatus,
	CourseCategory,
} from '../../../symphony';

import { CodeLanguages } from '../global.interface';
import {
	IAIChatData, Message,
} from '../../../aIChatPlayground/private/aIChatPlaygroundInterface';

export interface ILearnEngineApi {
	getMaterial: (
		materialRelationId: number,
		session?: string
	) => Observable<IMaterialInfo>;
	getLessonSubtree: (
		materialRelationId: number,
		session?: string,
		headers?: ILEHeaders
	) => Observable<ILessonSubtree>;
	getSkipAheadSubTree: (
		materialRelationId: number,
		session?: string,
		headers?: ILEHeaders
	) => Observable<ILessonSubtree>;
	getCourseSubtree: (courseAlias: string) => Observable<IMaterial>;
	getLearningPathSubtree: (alias: string) => Observable<ILearningPathSubtree>;
	getLearningExperiences: (
		useCache?: boolean
	) => Observable<ILearningExperience[]>;
	getLearningExperiencesById: (
		userId: number
	) => Observable<ILearningExperience[]>;
	solveMaterial: (
		payload: ISolveMaterialPayload,
		session?: string,
		headers?: ILEHeaders,
		isDynamicSession?: boolean
	) => Observable<ISolveMaterialDTO>;
	enrollCourse: (payload: IEnrollCoursePayload) => Observable<null>;
	runCode: (payload: ICodeRunPayload) => Observable<null>;
	compileCode: (payload: ICodeCompilePayload) => Observable<ICodeCompileResponse>;
	getMaterialSolution: (
		materialRelationId: number,
		session?: string
	) => Observable<IMaterialSolutionDTO>;
	removeCourse: (alias: string) => Observable<null>;
	addBetaTester: () => Observable<null>;
	getCourseStructure: (
		alias: string,
		type: LearningExperienceType
	) => Observable<ICourseStructureDTO>;
	getScreensConfig: (source: ScreenSource) => Observable<IScreenConfig[]>;
	getCategories: () => Observable<ICategorizedCourse[]>;
	getCodeAssistantConfig: () => Observable<CodeAssistantConfigType[]>;
	deleteSession: (
		materialRelationId: number,
		sessionQuitType: LessonSessionQuitType
	) => Observable<null>;
	resetProgress: (alias: string) => Observable<null>;
	updateSelectedLocale: (
		alias: string,
		selectedLocale: string
	) => Observable<null>;
	regenerateAIChatPlaygroundLastMessage: (
		materialRelationId: number,
		conversationId: string,
		configs: IModelConfig[],
		session?: string,
	) => Observable<Message>;
	explainAsnwer: (
		body: {
			dynamicId: number;
			answers: IAnswerOption[];
			isCorrect: boolean;
		}
	) => Promise<{ body: ReadableStream; }>;

}

export enum ConfigMode {
	Hidden = 'hidden',
	Edit = 'edit',
	ViewOnly = 'viewonly',
}

export interface IModelConfig {
	key: string,
	value: string,
	mode: ConfigMode;
}

export enum StructureType {
	Material = 1,
	MaterialGroup = 2,
	Meta = 3,
}

export enum MaterialType {
	None = 0,
	Theory = 1,
	Question = 2,
	TryItYourself = 3,
	CodeCoach = 4,
	CodeRepo = 5,
	LessonPage = 6,
	Lesson = 7,
	Module = 8,
	Course = 9,
	ModuleQuiz = 10,
	CodeProject = 11,
	Booster = 12,
	ExternalResource = 13,
	SkipAhead = 15,
	AIChat = 16,
}

export enum UIComponentType {
	RichText = 1,
	CodeSnippet = 2,
	Note = 3,
	Image = 4,
	TypeIn = 5,
	MultiTypeIn = 6,
	DragAndDrop = 7,
	Rearrange = 8,
	SingleChoice = 9,
	MultiChoice = 10,
	Gif = 11,
	ImageSingleChoice = 12,
	RiveAnimation = 13,
}

export enum AnswerType {
	SingleChoice = 1,
	MultiChoice = 2,
	TypeIn = 3,
	MultiTypeIn = 4,
	Rearrange = 5,
	DragAndDrop = 6,
	ImageSingleChoice = 7,
}

export enum CodeSnippetLanguage {
	Python = 'PY',
	HTML = 'HTML',
	CSS = 'CSS',
	JS = 'JS',
	NodeJS = 'NODEJS',
	Java = 'JAVA',
	Kotlin = 'KOTLIN',
	CPP = 'C++',
	CSHARP = 'C#',
	C = 'C',
	Go = 'GO',
	R = 'R',
	SQL = 'SQL',
	Ruby = 'RUBY',
	Swift = 'SWIFT',
	PHP = 'PHP',
	WEB = 'WEB',
	TS = 'TS',
}

export enum NoteComponentLevel {
	Info = 1,
	Warning = 2,
	Error = 3,
	Success = 4,
}

export enum QuestionPartType {
	Text = 1,
	NewLine = 2,
	InputBox = 3,
}

export enum MaterialRequirementType {
	Optional = 1,
	Required = 2,
	RequiredForParentCompletion = 3,
}

export enum VisibilityStatus {
	Locked = 1,
	Unlocked = 2,
}

export enum CompletionStatus {
	NotCompleted = 0,
	InProgress = 1,
	Completed = 2,
	ByPassed = 3,
}

export enum LocationType {
	Onboarding = 1,
	Profile = 2,
	CourseList = 3,
	LearningPath = 4, // Unused
	LandingPage = 5,
}

export enum OwnershipAvailabilityType {
	Free,
	Hearts,
}

export enum PlaygroundOutputType {
	Console = 0,
	Web = 1,
}

export enum ScreenName {
	LessonCelebration = 'LessonCelebration',
	Paywall = 'Paywall',
	StreakCelebration = 'StreakCelebration',
	StreakGoal = 'StreakGoal',
	LeaderboardCelebration = 'LeaderboardCelebration',
}

export enum ScreenSource {
	All = 0,
	LessonComplete = 1,
	CourseComplete = 2,
}

// Local using
export enum AnswerOptionStatus {
	Initial = 'initial',
	Selected = 'selected',
	Correct = 'correct',
	Incorrect = 'incorrect',
}

// Interfaces
export interface ISolveMaterialDTO {
	solutions: IMaterialSolutionSubmission[];
	statusChanges: IMaterialStatus[];
	heartsState: IHearts;
}

export type ICourseStructureDTO = IMaterial;

export interface IMaterialSolutionDTO {
	materialId: number;
	typeId: MaterialType;
	answerSolutions?: IAnswerOption[];
	codeSolution?: ICodeSolution;
}

export interface ISupportedLocale {
	locale: string;
	isVerified: boolean;
	isSelected: boolean;
}

export interface IMaterial {
	materialInfo: IMaterialInfo;
	children?: IMaterial[];
	supportedLocales?: ISupportedLocale[];
}

export interface ICCMaterialData {
	problem: IMaterialBody[];
	codes: ICodeSolution[];
	userCodes: ICodeSolution[];
	solution: {
		encryptedCode: string;
		languageId: number;
	};
}

export interface ICPMaterialData {
	problem: IMaterialBody[];
	initialCode: string;
	userSolution: string;
}

export interface ITIYMaterialData {
	codes: ICodeSolution[];
}

export interface ICRMaterialData {
	problem: IMaterialBody[];
	codes: ICodeSolution[];
	userCodes: ICodeSolution[];
}

export type IMaterialData =
	| ICCMaterialData
	| ICPMaterialData
	| ITIYMaterialData
	| ICRMaterialData;

export interface IMaterialInfo {
	commentContainerId?: number;
	header: IMaterialHeader;
	body?: IMaterialBody[];
	data?: IMaterialData;
	answer?: IQuestionAnswer;
	status: IMaterialStatus;
	requirementTypeId: MaterialRequirementType;
	orderNumber: number;
	statusChanges?: IMaterialChange[];
	// extended properties
	options?: IMaterialOptions;
	defaultLanguage?: string;
	courseProgress?: ICourseProgress;
	childrenHeight?: number;
	chat?: IAIChatData;
}

export interface IMaterialHeader {
	id: number;
	materialRelationId: number;
	title: string;
	description: string;
	typeId: MaterialType;
	structureTypeId: StructureType;
	uiConfigurations?: IUiConfigurations;
	context?: IHeaderContext;
	alias?: string;
	parentRelationId?: number;
	labels?: { name: string; }[];
	dynamicId: number;
	staticId?: number;
	materialId: number;
}

export interface IHeaderContext {
	id: number;
	name: string;
	orderNumber: number;
	count: number;
	title?: string;
}

export enum IUIType {
	Text = 0,
	Code = 1,
}

export interface IMaterialBody {
	componentTypeId: UIComponentType;
	content: IMaterialBodyContent;
	orderNumber: number;
	config?: {
		uiType?: IUIType;
		assetUrl?: string;
		rive?: {
			webAssetUrl: string;
			webRatio: string;
		};
	};
}

export interface IMaterialBodyContent {
	data?: string;
	language?: CodeSnippetLanguage;
	parts?: IQuestionPart[];
	part?: IQuestionPart;
	level?: NoteComponentLevel;
	ratio?: number;
	instructions?: string;
}

export interface ILearningPathUnit {
	courseSubtree: IMaterial;
	alias: string;
	typeId: LearningExperienceType;
	title: string;
	orderNumber: number;
}

export interface ILearningPathSubtree {
	units: ILearningPathUnit[];
	alias: string;
	bundleId: number;
	progressionStatus: ProgressionStatus;
	typeId: LearningExperienceType;
	title: string;
}

export interface ILessonSubtree {
	tree: IMaterial;
	session?: string;
}

export interface ICodeSolution {
	code: string;
	languageId: ProgrammingLanguage;
}

export interface IQuestionAnswer {
	id: number;
	options: IAnswerOption[];
	answerTypeId: AnswerType;
	supportsFeedback: boolean;
}

export interface IQuestionPart {
	type: QuestionPartType;
	content?: string;
	maxLength: number;
	optionId?: number; // local property
}

export interface IAnswerOption {
	id: number;
	text: string;
	orderNumber: number;
	data?: IAnswerOptionData; // exists when answerTypeId = 7
	isCorrect?: boolean; // valid only for the response
	status?: AnswerOptionStatus; // local property
}

export interface IAnswerOptionData {
	assetUrl?: string;
	ratio?: number;
}

export interface IMaterialStatus {
	visibility: VisibilityStatus;
	completion: CompletionStatus;
	isCompleted: boolean;
	materialRelationId?: number;
	availabilityTypeId?: OwnershipAvailabilityType;
	ownership?: IOwnershipStatus;
}

export interface IMaterialChange {
	userMaterialId: number;
	requirementTypeId: MaterialRequirementType;
	isOwned: boolean;
	ownershipRequirementsTypeId: OwnershipRequirementType;
}

export interface ILECourseData {
	name: string;
	alias: string;
	userAlreadyEnrolled: boolean;
	uiConfigurations: IUiConfigurations;
}

export enum ResourceType {
	Static = 1,
	Dynamic = 2,
}

export interface ISolveMaterialPayload {
	solutions: IMaterialSolutionSubmission[];
	resourceType?: ResourceType;
	experience_type_id: LearningExperienceType;
	bundleId?: number;
}

export interface ILEHeaders {
	'le-exp-alias': string;
	'le-exp-type': LearningExperienceType;
	'le-locale'?: string;
	'sl-le-session'?: string;
	'sl-le-dynamic-session'?: string;
}

export interface IEnrollCoursePayload {
	alias: string;
	locationTypeId: LocationType;
}

export interface IMaterialSolutionSubmission {
	typeId: MaterialType;
	materialRelationId: number;
	codes?: IMaterialCodeSubmission[]; // if typeId == (tiy | cc | cp | cr)
	answer?: IMaterialAnswerSubmission; // if -> typeId == (question)
	isCorrect?: boolean; // valid only for the response
	completion?: CompletionStatus;
	chat?: IMaterialChatSubmission;
	aiChatResponse?: {
		conversationId: string,
		messages: Message[];
	};
}

export interface IMaterialChatSubmission {
	conversationId?: string;
	promptId?: string;
	message?: string;
	system?: string;
	configs?: {
		key: string,
		value: string;
	}[];
}

export interface IMaterialCodeSubmission {
	source: string;
	languageId: ProgrammingLanguage;
	inputs?: string[]; // optional, used for running codes with user inputs
	results?: ICodeTestResult; // valid only for the response
	codeOutput?: ICodeOutput; // valid only for the response
}

export interface ICodeTestResult {
	failedTestCount: number;
	compileError: string;
	explanation: string | null;
	explanationId: string;
	testCases: ICodeTestCaseResult[];
}

export interface ICodeTestCaseResult {
	isCorrect: boolean;
	isPublic: boolean;
	title: string;
	input: string;
	output: string;
	actualOutput: string;
}

export interface IMaterialAnswerSubmission {
	answerTypeId: AnswerType;
	selectedOptions: IAnswerOption[];
	feedback?: string;
}

export interface ILearnEngineApiVersions {
	getMaterial?: string;
	getCourseSubtree?: string;
	getLearningPathSubtree?: string;
	getLessonSubtree?: string;
	solveMaterial?: string;
	enrollCourse?: string;
	runCode?: string;
	getMaterialSolution?: string;
	removeCourse?: string;
	addBetaTester?: string;
	getCourseStructure?: string;
	getCategories?: string;
	deleteSession?: string;
}

export interface ICodeRunPayload {
	codes: {
		source: string;
		languageId: ProgrammingLanguage;
		inputs?: string[];
	}[];
}

export interface ICodeCompilePayload {
	materialRelationId: number;
	code: string;
	session?: string;
}

export interface ICodeCompileResponse {
	error: string;
	explanation: string;
	explanationId: string;
}

export interface ICodeOutput {
	error?: string;
	language?: CodeLanguages;
	output: string;
	outputStyle?: {
		dark: string;
		light: string;
	};
	outputType: PlaygroundOutputType;
}

export interface IScreenConfig {
	name: ScreenName;
	order: number;
	isEnabled: boolean;
	source?: ScreenSource;
}

// Local using
export interface IMaterialOptions {
	parentType?: MaterialType;
	xp?: number;
}

export interface ICourseProgress {
	allRequiredMaterialsLength: number;
	lastCompletedMaterialIndex: number;
}

export interface ICategorizedCourse {
	id: CourseCategory;
	name: string;
	orderNumber: number;
}

export enum LessonSessionQuitType {
	LessonQuizModal = 1,
	HeartsRunOut,
}

export type CodeAssistantConfigType = {
	materialType: number;
	actions: {
		action: number;
		order: number;
		isVisible: boolean;
		proFeature: boolean;
	}[];
};

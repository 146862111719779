import { fromEvent, Observable } from 'rxjs';
import { Socket, io } from 'socket.io-client';

import {
	ISocketApi,
	ISocketApiConfig,
	EventTypes,
	BitChangeModel,
	HeartChangeModel,
} from '../private/socket-api/socket-api.interface';
import { Container, AuthService, TokenKey } from '../../symphony';

export class SocketApi implements ISocketApi {
	private socket: Socket;

	private socketPath: string;

	private environmentUrl: string;

	private callbacks: {
        onConnect?: () => void;
        onDisconnect?: () => void;
        onError?: () => void;
    };

	private options: {
        reconnectionAttempts?: number;
        reconnectionDelay?: number;
    };

	constructor(config: ISocketApiConfig) {
		this.socketPath = config.socketPath;
		this.environmentUrl = config.environmentUrl;
		this.callbacks = config.callbacks;
		this.options = config.options;
	}

	public connect(): void {
		if (!this.socket) {
			this.socket = io(this.environmentUrl, {
				extraHeaders: {
					Authorization: Container.take(
						'global',
						AuthService,
					).getToken(TokenKey.accessToken),
				},
				path: this.socketPath,
				...this.options,
			});

			if (this.callbacks) {
				if (this.callbacks.onConnect) {
					this.socket.on(
                        EventTypes.connect as string,
                        this.callbacks.onConnect,
					);
				}
				if (this.callbacks.onDisconnect) {
					this.socket.on(
                        EventTypes.disconnect as string,
                        this.callbacks.onDisconnect,
					);
				}
				if (this.callbacks.onError) {
					this.socket.on(
                        EventTypes.connectError as string,
                        this.callbacks.onError,
					);
				}
			}
		} else if (!this.socket.connected) {
			this.socket.connect();
		}
	}

	public disconnect(): void {
		if (this.socket && !this.socket.disconnected) {
			this.socket.disconnect();
			this.socket = null;
		}
	}

	public isConnected(): boolean {
		return this.socket ? this.socket.connected : false;
	}

	private getNotificationStream(name: EventTypes) {
		return fromEvent(this.socket, name);
	}

	public getBitChangeStream(): Observable<BitChangeModel> {
		return this.getNotificationStream(
			EventTypes.bitChange,
		) as Observable<BitChangeModel>;
	}

	public getHeartChangeStream(): Observable<HeartChangeModel> {
		return this.getNotificationStream(
			EventTypes.heartChange,
		) as Observable<HeartChangeModel>;
	}
}

export * from '../private/socket-api/socket-api.interface';

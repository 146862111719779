import { Observable, of } from 'rxjs';

import { constructUrl } from '../private/utils/httpParamsUtils';
import {
	IUserSettingsApi,
	IUserSettingsApiMock,
	IUserSettingsResponse,
	IUserSettingsDataResponse,
	IUserSettingsVersions,
	IAppSettingsResponse,
} from '../private/user-settings-api/user-settings-api.interface';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { IApiUrls, IHostsConfigs } from '../private/global.interface';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from './error-handling';
import { Service, Container, IUserSetting } from '../../symphony';

@Service()
export class UserSettingsApi implements IUserSettingsApi {
	private environmentUrl: string;

	private useMock: boolean;

	private mock: IUserSettingsApiMock;

	private versions: IUserSettingsVersions;

	private axiosInstance: AxiosInstanceType;

	constructor() {
		this.environmentUrl = (
            Container.take('global', 'envUrl') as IApiUrls
		).userSettingsApiHost;
		this.useMock = (
            Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.userSettingsApi?.useMock;
		this.mock = (
            Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.userSettingsApi?.mock;
		this.versions = (
            Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.userSettingsApi?.version;

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

    @Authorize(AuthLevel.authenticated)
	public getUserSettings(): Observable<IUserSettingsResponse> {
		if (this.useMock) return of(this.mock.userSettings);
		const url = constructUrl(
			this.environmentUrl,
			'api/settings/userdata',
			this.versions?.getUserSettings,
		);
		return this.axiosInstance.get(url, {
			params: {
				fields: 'user_settings',
			},
		});
	}

    @Authorize(AuthLevel.authenticated)
    public getUserSettingsData(): Observable<IUserSettingsDataResponse> {
    	const url = constructUrl(
    		this.environmentUrl,
    		'api/usersettings',
    		this.versions?.getUserSettingsData,
    	);
    	return this.axiosInstance.get(url);
    }

    @Authorize(AuthLevel.authenticated)
    public updateUserSettings(
    	data: IUserSetting[],
    ): Observable<IUserSettingsResponse> {
    	if (this.useMock) return of(this.mock.userSettings);
    	const url = constructUrl(
    		this.environmentUrl,
    		'api/usersettings',
    		this.versions?.updateUserSettings,
    	);
    	return this.axiosInstance.post(url, data);
    }

    @Authorize(AuthLevel.authenticated)
    public updateUserSettingsData(
    	data: IUserSetting[],
    ): Observable<IUserSettingsResponse> {
    	const url = constructUrl(
    		this.environmentUrl,
    		'api/usersettings',
    		this.versions?.updateUserSettingsData,
    	);
    	return this.axiosInstance.post(url, data);
    }

    @Authorize(AuthLevel.public)
    public getAppSettings(): Observable<IAppSettingsResponse> {
    	if (this.useMock) return of(this.mock.userSettings);
    	const url = constructUrl(
    		this.environmentUrl,
    		'api/settings',
    		this.versions?.getAppSettings,
    	);
    	return this.axiosInstance.get(url, {
    		params: {
    			language: 'en',
    			platform: '1',
    			fields: 'app_settings',
    		},
    	});
    }
}

export * from '../private/user-settings-api/user-settings-api.interface';

import { TrackingObjType } from '../../public/tracking/tracking.interface';
import { TrackingEventsMappingType } from './tracking.interface';

export class Tracking {
	private eventsMapping: TrackingEventsMappingType;

	constructor(eventsMapping: TrackingEventsMappingType) {
		this.eventsMapping = eventsMapping;
	}

	protected getEventParams = (
		trackingObj: TrackingObjType,
	): {
		params?: Record<string, string | number | boolean>;
		eventName?: string;
	} => {
		const eventData = this.eventsMapping[trackingObj.eventName];
		const modifiedParams: Record<string, string | number | boolean> = {};

		if (!eventData) return {};

		if (trackingObj.eventParams) {
			eventData.params.forEach((param) => {
				if (
					typeof param === 'object'
					&& !Array.isArray(param)
					&& param !== null
				) {
					// case where we pass function to modify the attribute values on the fly
					const key = Object.keys(param)[0];
					modifiedParams[key] = param[key](
						(trackingObj as any).eventParams[key],
					);
				} else if (Array.isArray(param)) {
					// case where concrete service attribute name differs
					// param[0] is what concrete service wants, and param[1] is what we recieve
					(modifiedParams as any)[param[0]] = trackingObj.eventParams[param[1]];
				} else if (typeof param === 'string') {
					// simple case where param name and value don't need modification
					(modifiedParams as any)[param] = trackingObj.eventParams[param];
				}
			});
		}

		Object.keys(modifiedParams).forEach((key) => {
			if (modifiedParams[key] === undefined) {
				delete modifiedParams[key];
			}
		});

		return {
			params: modifiedParams,
			eventName: eventData.eventName,
		};
	};
}

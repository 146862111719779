// import axios from 'axios';

// import { TrackingObjType } from '../../../public/tracking/tracking.interface';
import { Tracking } from '../tracking';
import { ITracking } from '../tracking.interface';
import { iterableEventsMapping } from './iterableEventsMapping';
// import { Container } from '../../../public/container.global';
// import { AuthService, TokenKey } from '../../../public/auth.service';

export class IterableTracking extends Tracking implements ITracking {
	constructor() {
		super(iterableEventsMapping);
	}

	public handleTracking = (): void => {
		// public handleTracking = (trackingObj: TrackingObjType): void => {
		// const { params, eventName } = this.getEventParams(trackingObj);
		// const authService = new AuthService();
		// const environmentUrl = (Container.take('global', 'envUrl') as any)
		//     .iterableSyncApi;
		// // Need to define place for helper function to get cookies (currently we have circular dependency problem)
		// const campaignId = Number(
		//     document.cookie.replace(
		//         /(?:(?:^|.*;\s*)iterableEmailCampaignId\s*=\s*([^;]*).*$)|^.*$/,
		//         '$1'
		//     )
		// );
		// const templateId = Number(
		//     document.cookie.replace(
		//         /(?:(?:^|.*;\s*)iterableTemplateId\s*=\s*([^;]*).*$)|^.*$/,
		//         '$1'
		//     )
		// );
		// if (!eventName) return;
		// // To do - need to find proper place for this
		// axios.post(
		//     `${environmentUrl}/iterablesync/api/events`,
		//     {
		//         event: eventName,
		//         data: {
		//             ...params,
		//             platform: 'WEB',
		//             ...(campaignId && { campaignId }),
		//             ...(templateId && { templateId }),
		//         },
		//         date: new Date().toISOString(),
		//     },
		//     {
		//         headers: {
		//             'content-type': 'application/json',
		//             Authorization: `Bearer ${authService.getToken(
		//                 TokenKey.accessToken
		//             )}`,
		//         },
		//     }
		// );
	};
}

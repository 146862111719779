import { BehaviorSubject, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Container, Service } from './container.global';
import { StorageService } from './storage.service';
import { history } from './history';

export enum SessionRouteEnums {
    lessonSessionRoutes = 'lessonSessionRoutes',
}

export interface ISessionRoutesInfo {
    [SessionRouteEnums.lessonSessionRoutes]?: boolean;
}

@Service()
export class SessionRoutesService {
	public sessionRoutesInfo = new BehaviorSubject<ISessionRoutesInfo>(null);

	private storageService: StorageService = Container.take(
		'global',
		StorageService,
	);

	private sessionRoutesKey = null;

	private sessionRoutesData = null;

	private unlisteners = {};

	constructor() {
		this.sessionRoutesInfo
			.pipe(
				filter((d) => !!d),
				tap((sessionInfo) => {
					if (sessionInfo) {
						const shouldTrackCurrentSessionRoutes = sessionInfo[this.sessionRoutesKey];

						if (shouldTrackCurrentSessionRoutes) {
							this.setRoutesData(
								this.sessionRoutesKey,
								location.pathname + location.search,
								this.sessionRoutesData,
							);
							this.unlisteners[this.sessionRoutesKey] = history.listen(() => {
                                	const currentRoute = location.pathname + location.search;
                                	this.setRoutesData(
                                		this.sessionRoutesKey,
                                		currentRoute,
                                		this.sessionRoutesData,
								);
							});
						} else if (
							typeof this.unlisteners[this.sessionRoutesKey]
                            === 'function'
						) {
							// stop curront listener
							this.unlisteners[this.sessionRoutesKey]();
							this.removeRoutesData(this.sessionRoutesKey);
						}
					}
				}),
			)
			.subscribe();
	}

	public getRoutesData = (
		sessionRoutesKey: SessionRouteEnums,
	): {
        routes: string[];
        sessionData?: any;
    } => (
		this.storageService.load(sessionRoutesKey, 'session') || {
			routes: [],
		}
	);

	public setRoutesData = (
		sessionRoutesKey: SessionRouteEnums,
		currentRoute?: string,
		sessionData?: any,
	): void => {
		const routesData: {
            routes: string[];
            sessionData?: any;
        } = this.getRoutesData(sessionRoutesKey);

		if (
			currentRoute
            && !routesData.routes.find((item) => item === currentRoute)
		) {
			routesData.routes.push(currentRoute);
		}
		if (sessionData) {
			routesData.sessionData = {
				...routesData.sessionData,
				...sessionData,
			};
		}
		this.storageService.save(sessionRoutesKey, routesData, 'session');
	};

	public removeRoutesData = (sessionRoutesKey: SessionRouteEnums): void => {
		this.storageService.remove(sessionRoutesKey, 'session');
	};

	public startSessionRoutesRecording = (
		sessionRoutesKey: SessionRouteEnums,
		data?: any,
	) => {
		this.sessionRoutesKey = sessionRoutesKey;
		this.sessionRoutesData = data;
	};
}

import React from 'react';

import { SlModal } from '../../features/shared/public/SlModal/SlModal';
import { PrivacyPolicy } from './PrivacyPolicy';
import { SlModalIds } from '../shared/constants';
import { TermAndCondition } from './TermAndCondition';
import { IPopUpData } from '../../features/api/public/abTesting-api';

export interface ModalsWrapperProps {
	modalsData: {
		[key: string]: {
			staticData: IPopUpData;
			version: number;
			htmlContent: string;
		}
	}
}
export const ModalsWrapper: React.FC<ModalsWrapperProps> = ({ modalsData }): JSX.Element => (
	<>
		<SlModal
			id={SlModalIds.privacyPolicyModal}
			cssClass="no-padding hide-close blocker"
			keepOnOverlay

		>
			<PrivacyPolicy
				{...modalsData?.[SlModalIds.privacyPolicyModal]}
			/>
		</SlModal>
		<SlModal
			id={SlModalIds.termsAndConditionModal}
			cssClass="no-padding hide-close blocker"
			keepOnOverlay
		>
			<TermAndCondition
				{...modalsData?.[SlModalIds.termsAndConditionModal]}
			/>
		</SlModal>
	</>
);

import 'reflect-metadata';
import {
	Container as C,
	Service,
	Inject,
	InjectMany,
	ContainerInstance,
	Token,
	ServiceOptions,
	ServiceIdentifier,
	Constructable,
} from 'typedi';
import { AbstractConstructable } from 'typedi/types/types/abstract-constructable.type';

export class Container extends C {
	private static contextUseCounter: Record<string, number> = {};

	static register(
		context: string,
		type: () => unknown,
		value: unknown
	): Container;

	static register<T = unknown>(
		context: string,
		type: Constructable<T>,
		value: unknown
	): Container;

	static register<T = unknown>(
		context: string,
		type: AbstractConstructable<T>,
		value: unknown
	): Container;

	static register(context: string, type: string, value: unknown): Container;

	static register<T = unknown>(
		context: string,
		token: Token<T>,
		value: unknown
	): Container;

	static register<T = unknown>(
		context: string,
		value: ServiceOptions<T>
	): Container;

	static register<T = unknown>(
		context: string,
		values: ServiceOptions<T>[]
	): Container;

	static register(
		context: string,
		identifierOrServiceMetadata:
			| ServiceIdentifier
			| ServiceOptions<unknown>
			| ServiceOptions<unknown>[],
		value?: unknown,
	): Container {
		if (context === 'global') {
			C.set(identifierOrServiceMetadata as unknown, value);
		} else {
			C.of(context).set(identifierOrServiceMetadata as unknown, value);
		}
		return this;
	}

	static take<T>(context: string, type: Constructable<T>): T;

	static take<T>(context: string, type: AbstractConstructable<T>): T;

	static take<T>(context: string, id: string): T;

	static take<T>(context: string, id: Token<T>): T;

	static take<T>(context: string, identifier: ServiceIdentifier<T>): T {
		try {
			if (context === 'global') {
				return C.get(identifier as unknown);
			}
			return C.of(context).get(identifier as unknown);
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}

	static use(context: string): void {
		this.contextUseCounter[context] = (this.contextUseCounter[context] || 0) + 1;
	}

	static reset(context: string): Container {
		if ((this.contextUseCounter[context] || 0) <= 1) {
			this.contextUseCounter[context] = 0;
			return super.reset(context);
		}

		this.contextUseCounter[context]--;
		return this;
	}
}

export {
	Service, Inject, InjectMany, ContainerInstance, Token,
};

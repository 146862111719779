import { Observable, of } from 'rxjs';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { constructUrl } from '../private/utils/httpParamsUtils';
import {
	IGamificationApi,
	IBitResponse,
	IGamificationBitApiMock,
	IGamificationVersions,
	IBitSourcesResponse,
	IShopItemsResponse,
	IBuyShopItemResponse,
	IBuyShopItemPayload,
	IBitSetAcknowledgementStatusResponse,
	IBitSetUnlockPopupSeenResponse,
	IBitHistoryResponse,
	ActivityTypes,
	ShopItemTypes,
	ContextTypes,
	IBitSourcesListResponse,
	IShopItemsListResponse,
} from '../private/gamification-api/gamification-api.interface';
import { IApiUrls, IHostsConfigs } from '../private/global.interface';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { Service, Container } from '../../symphony';

@Service()
export class GamificationApi implements IGamificationApi {
	private environmentUrl: string;

	private useMock: boolean;

	private mock: IGamificationBitApiMock;

	private versions: IGamificationVersions;

	private axiosInstance: AxiosInstanceType;

	constructor() {
		this.environmentUrl = (
            Container.take('global', 'envUrl') as IApiUrls
		).gamificationApiHost;
		this.useMock = (
            Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.gamificationApi?.useMock;
		this.mock = (
            Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.gamificationApi?.mock;
		this.versions = (
            Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.gamificationApi?.version;

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

    @Authorize(AuthLevel.authenticated)
	public getBits(): Observable<IBitResponse> {
		if (this.useMock) return of(this.mock.bits);
		return this.axiosInstance.get(
			constructUrl(this.environmentUrl, 'bits', this.versions?.getBits),
		);
	}

    @Authorize(AuthLevel.authenticated)
    public getBitSources(): Observable<IBitSourcesResponse> {
    	if (this.useMock) return of(this.mock.bitSources);
    	return this.axiosInstance.get(
    		constructUrl(
    			this.environmentUrl,
    			'bits/sources',
    			this.versions?.getBitSources,
    		),
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public getBitHistory(
    	activityType: ActivityTypes,
    ): Observable<IBitHistoryResponse> {
    	if (this.useMock) return of(this.mock.bitHistory);
    	return this.axiosInstance.get(
    		constructUrl(
    			this.environmentUrl,
    			`bits/history/${activityType}`,
    			this.versions?.getBitHistory,
    		),
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public setBitAcknowledgementStatus(): Observable<IBitSetAcknowledgementStatusResponse> {
    	if (this.useMock) return of(this.mock.bitAcknowledgementStatus);
    	return this.axiosInstance.put(
    		constructUrl(
    			this.environmentUrl,
    			'bits/setacknowledgementstatus',
    			this.versions?.setBitAcknowledgementStatus,
    		),
    		{},
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public setUnlockPopupSeen(): Observable<IBitSetUnlockPopupSeenResponse> {
    	if (this.useMock) return of(this.mock.bitSetUnlockPopupSeen);
    	return this.axiosInstance.put(
    		constructUrl(
    			this.environmentUrl,
    			'bits/unlockpopupseen',
    			this.versions?.setUnlockPopupSeen,
    		),
    		{},
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public getShopItems(
    	context: ContextTypes,
    	contextId: number,
    	itemTypes?: ShopItemTypes[],
    ): Observable<IShopItemsResponse> {
    	return this.axiosInstance.get(
    		constructUrl(
    			this.environmentUrl,
    			'shop/items',
    			this.versions?.getShopItems,
    		),
    		{
    			params: {
    				context,
    				contextId,
    				itemTypes: itemTypes?.join(),
    			},
    		},
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public buyShopItem(
    	payload: IBuyShopItemPayload,
    ): Observable<IBuyShopItemResponse> {
    	return this.axiosInstance.post(
    		constructUrl(
    			this.environmentUrl,
    			'shop/buy',
    			this.versions?.buyShopItem,
    		),
    		payload,
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public getBitSourcesList(): Observable<IBitSourcesListResponse> {
    	return this.axiosInstance.get(
    		constructUrl(
    			this.environmentUrl,
    			'bitsources',
    			this.versions?.getBitSourcesList,
    		),
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public getShopItemsList(): Observable<IShopItemsListResponse> {
    	return this.axiosInstance.get(
    		constructUrl(
    			this.environmentUrl,
    			'shopitems',
    			this.versions?.getShopItemsList,
    		),
    	);
    }
}

export * from '../private/gamification-api/gamification-api.interface';

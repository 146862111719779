import React, { FC, RefObject, useEffect } from "react";
import s from "./LongPaywallCampaign.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useInView } from "../../../../paywalls.utils";
import { Container } from "../../../../../../symphony";
import { PaywallsService } from "../../../../services/paywalls.service";

type Props = {
	data: {
		campaignName: 'blackFriday';
		posterUrl: string;
	};
	style: React.CSSProperties;
	sectionOrder: number;
};

export const LongPaywallCampaign: FC<Props> = ({
	data, style, sectionOrder
}) => {
	useStyles(s);
	const { trackSectionView } = Container.take('paywalls', PaywallsService);
	const [ref, isInView] = useInView({
		threshold: 0.7
	});

	useEffect(() => {
		isInView && trackSectionView('campaignPoster', sectionOrder);
	}, [isInView]);

	return <div ref={ref as RefObject<HTMLDivElement>} className="campaign" style={style || {}}>
		<img src={data.posterUrl} className="campaign-poster" />
	</div>;
};
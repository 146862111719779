import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import {
	Container,
	Service,
	TrackingEventNames,
	TrackingService,
} from '../../../symphony';

import { AUTH_METHOD } from '../global.interface';
import { generateTrackingEventParams } from '../utils/helpers';
import { ITrackingEventParams } from '../../../shared/public/globalInterfaces/trackingInterface';

declare const slTrack: (payload: {
	action: string;
	element: string;
	experiment?: string;
	redirectUrl?: string;
}) => void;

declare const dataLayer: Array<ITagManagerAction>;

export interface ITrackAction {
	actionName: string;
	element: string;
}

export interface ITagManagerAction {
	event: string;
	userId?: number;
}

@Service()
export class SlLoginTrackingService {
	// todo suggest better name
	public trackAction$ = new BehaviorSubject<ITrackAction>(null);

	private symphonyTracking$ = Container.take('global', TrackingService)
		.tracking$;

	public tagManager$ = new BehaviorSubject(null);

	public trackActionHandler$: Observable<ITrackAction> = this.trackAction$.pipe(
		filter((a) => !!a),
		tap((action: ITrackAction) => {
			slTrack({
				action: action.actionName,
				element: action.element,
			});
		}),
	);

	public tagManagerHandler$: Observable<ITagManagerAction> = this.tagManager$.pipe(
		filter((a) => !!a),
		tap((action) => {
			dataLayer.push({ ...action });
		}),
	);

	public authSuccessTracking(
		authMethod: AUTH_METHOD,
		trackingEventParams: ITrackingEventParams,
		isNewUser,
	) {
		const trackingData = {
			method: authMethod,
			version: '2-0-0',
			...generateTrackingEventParams(trackingEventParams),
		};

		this.symphonyTracking$.next({
			eventName: isNewUser
				? TrackingEventNames.signupCompleted
				: TrackingEventNames.signinCompleted,
			eventParams: trackingData,
		});
	}

	constructor() {
		this.tagManagerHandler$.subscribe();
		this.trackActionHandler$.subscribe();
	}
}

import {
	BrowserHistory,
	createBrowserHistory,
	createMemoryHistory,
} from 'history';
import { To } from 'react-router-dom';
import { Container } from './container.global';

const getLocalizedUrl = (url: To) => {
	if (
		typeof url !== 'string'
		|| url.includes('http://')
		|| url.includes('https://')
	) {
		return url;
	}

	const activeLanguage = typeof window === 'undefined'
		? Container.take('global', 'activeLanguage')
		: window.location.pathname?.split('/')?.[1] || 'en';

	if (url.split('/')?.[1] === activeLanguage) {
		return url;
	}

	return `/${activeLanguage}${url}`;
};

const browserHistory = (typeof window !== 'undefined'
	&& createBrowserHistory()) as BrowserHistory;

export const history = typeof window === 'undefined'
	? {
		...createMemoryHistory(),
		replace: () => { },
	}
	: {
		...browserHistory,
		replace: function replace(to: To, state?: any) {
			browserHistory.replace(getLocalizedUrl(to), state);
		},
		push: function push(to: To, state?: any) {
			browserHistory.push(getLocalizedUrl(to), state);
		}
	};

import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { i18n } from '../../../../symphony';

import {
	StreakIcon,
	StreakIconColor,
	StreakIconType,
} from '../StreakIcon/StreakIcon';

import s from './StreakInfo.scss';

interface IStreakInfoData {
    id: string;
    title: string;
    color: StreakIconColor;
    value: number;
}
export const StreakInfo: React.FC<IStreakInfoData> = React.memo(
	({
		id, title, color, value,
	}) => {
		useStyles(s);
		return (
			<div
				sl-test-data={`cmpStreakInfo${id}`}
				className="streak-info-container"
			>
				<div className="streak-info-header">{title}</div>
				<div className="streak-info">
					<div className="streak-info__content">
						<StreakIcon
							id={id}
							type={StreakIconType.Default}
							color={color}
							small
						/>
						<div className="streak-info-value">{value}</div>
						<div className="streak-info-text">
							{i18n.t('web-streak.streak-info-day', {
								count: value,
							})}
						</div>
					</div>
				</div>
			</div>
		);
	},
);

import React, { useEffect, useState } from 'react';

import { Modal, Button } from '@sololearnorg/sol';
import { filter, Subject } from 'rxjs';
import {
	Container,
	HeartService,
	ILeaveLessonData,
	LeaveLessonModalType,
	i18n,
} from '../../features/symphony';

export function LeaveLessonModal({
	leaveBtnCallback,
	stayBtnCallback,
	onClose,
}: ILeaveLessonData): JSX.Element {
	const [modalData, setModalData] = useState<ILeaveLessonData>(null);

	useEffect(() => {
		const unsubscriber: Subject<void> = new Subject<void>();

		const { trackLeaveLessonImpression, leaveLessonModal$ } = Container.take('global', HeartService);
		trackLeaveLessonImpression();

		leaveLessonModal$.pipe(filter((d) => !!d)).subscribe(setModalData);

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	return modalData?.type === LeaveLessonModalType.LOSE_PROGRESS ? (
		<Modal.Default
			className="sl-lesson-quit-prompt"
			onClose={onClose}
			onClickOutside={() => {}}
			actions={[
				<Button
					sl-test-data="btnLeaveLessonSecondary"
					key="btnLeaveLessonSecondary"
					buttonType="secondary"
					onClick={stayBtnCallback}
				>
					{i18n.t('web-le.lose-progress-modal-submit')}
				</Button>,
				<Button
					sl-test-data="btnLeaveLessonPrimary"
					key="btnLeaveLessonPrimary"
					onClick={leaveBtnCallback}
				>
					{i18n.t('web-le.lose-progress-modal-quit')}

				</Button>,
			]}
			headline={i18n.t('web-le.lose-progress-modal-title')}
			description={i18n.t('web-le.lose-progress-modal-description')}
		/>
	) : (
		<Modal.Default
			dataTestid="mdlLeaveLesson"
			onClose={onClose}
			headline={i18n.t('web-le.leave-lesson-modal-title')}
			actions={[
				<Button
					sl-test-data="btnLeaveLessonSecondary"
					key="btnLeaveLessonSecondary"
					buttonType="secondary"
					onClick={stayBtnCallback}
				>
					{i18n.t('web-le.leave-lesson-modal-stay')}
				</Button>,
				<Button
					sl-test-data="btnLeaveLessonPrimary"
					key="btnLeaveLessonPrimary"
					onClick={leaveBtnCallback}
				>
					{i18n.t('web-le.leave-lesson-modal-leave')}
				</Button>,
			]}
		/>
	);
}

import { SlIcon } from "@sololearnorg/icons";
import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './AppleAward.scss';
import { PaywallTheme } from "../../../../../api/public/subscription-api";

type AppleAwardProps = {
	props: {
		theme: PaywallTheme;
	};
};

export const AppleAward: FC<AppleAwardProps> = ({
	props
}) => {
	useStyles(s);
	return <div className={`apple-award ${props.theme}`}>
		<SlIcon iconId={'appleAward'} />
	</div>;
};

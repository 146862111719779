import { Service } from 'typedi';

@Service()
export class CookieService {
	public getCookie(cname: string) {
		if (typeof window === 'undefined') return null;

		const name = `${cname}=`;
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}

	public setCookie(cname: string, cvalue: string, exdays = 365) {
		if (typeof window === 'undefined') return;

		const d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		const expires = `expires=${d.toUTCString()}`;
		document.cookie = `${cname}=${cvalue};${expires};path=/`;
	}
}

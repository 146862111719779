import React, { FC } from "react";
import { PaywallHeaderComponent, PaywallHeaderComponentType } from "../../../../api/public/subscription-api";
import { HeaderRating } from "../ui/HeaderRating/HeaderRating";
import { Countdown } from "../ui/Countdown/Countdown";

type Props = {
	component: PaywallHeaderComponent;
};

export const PaywallHeaderComponentsDrawer: FC<Props> = ({ component }) => {
	const getComponent = () => {
		switch (component.type) {
			case PaywallHeaderComponentType.ratings:
				return <HeaderRating text={component.text} />;
			case PaywallHeaderComponentType.countdown:
				return <Countdown text={component.text} countdownSeconds={component.countdownSeconds} />;
		}
	};

	return <>{getComponent()}</>;
};
import React from 'react';
import { useMatch } from 'react-router-dom';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Button } from '@sololearnorg/sol';
import { IconHeartInfinitePro } from '../../icons/IconHeartInfinitePro';
import { IconPro } from '../../icons/IconPro';
import s from './ProSection.scss';

export interface IProSection {
	title: string;
	description: string[];
	cta: string[];
	onClick: (from: 'lesson' | 'course', closeModal?: boolean) => void;
}
export const ProSection: React.FC<IProSection> = React.memo(
	({
		title, description, cta, onClick,
	}) => {
		useStyles(s);
		const isLesson = useMatch(':language/learn/courses/:courseAlias/lesson/:id/*');
		const renderCtaText = (val: string) => (val === '<pro>' ? <IconPro /> : val);
		const onButtonClick = () => {
			onClick(isLesson ? 'lesson' : 'course', false);
		};

		return (
			<div className="pro-section">
				<div className="pro-section__header">{title}</div>
				<div className="pro-section__content">
					<div className="pro-section__icon">
						<IconHeartInfinitePro />
					</div>
					<div className="pro-section__description">
						<div className="pro-section__description__bold">
							{description[0]}
						</div>
						<div className="pro-section__description__regular">
							{description[1]}
						</div>
					</div>
					<Button className="pro-section__cta" onClick={onButtonClick}>
						{cta.map(renderCtaText)}
					</Button>
				</div>
			</div>
		);
	},
);

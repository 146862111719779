import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { LogoAppStore, LogoGooglePlay } from '@sololearnorg/sol';

import { isNode } from '../utilsPublic/utilsPublic';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';

import s from './SlMobileStores.scss';

export enum MobileStore {
    AppStore,
    PlayMarket,
}

interface ISlMobileStores {
    onStoreClick?: (store: MobileStore) => void;
    classNames?: {
        appStore?: string;
        playMarket?: string;
    };
}

export function SlMobileStores({
	onStoreClick,
	classNames = {
		appStore: '',
		playMarket: '',
	},
}: ISlMobileStores): JSX.Element {
	useStyles(s);
	const [smartScriptUrl, setSmartScriptUrl] = useState<string>('');

	useEffect(() => {
		const setUrl = () => {
			setSmartScriptUrl((window as any).AF_SMART_SCRIPT_RESULT?.clickURL);
		};

		if (!isNode()) {
			if ((window as any).AFSmartScriptLoaded) {
				setUrl();
			} else {
				window.addEventListener('AFSmartScriptLoaded', setUrl);
			}
		}

		return () => {
			window.removeEventListener('AFSmartScriptLoaded', setUrl);
		};
	}, []);

	return (
		<>
			<LocalizedLink
				className={classNames.appStore}
				target="_blank"
				to={smartScriptUrl}
				onClick={() => onStoreClick?.(MobileStore.AppStore)}
				reloadDocument
			>
				<LogoAppStore className="sl-mobile-store" />
			</LocalizedLink>
			<LocalizedLink
				className={classNames.playMarket}
				target="_blank"
				to={smartScriptUrl}
				onClick={() => onStoreClick?.(MobileStore.PlayMarket)}
				reloadDocument
			>
				<LogoGooglePlay className="sl-mobile-store" />
			</LocalizedLink>
		</>
	);
}

import { BehaviorSubject } from 'rxjs';

import {
	IDisabledActions,
	IFlashMessage,
	IFooterButton,
	ILoadingState,
	ILpComments,
	IModals,
	IRouteData,
	QuizFeedbackType,
	ILeaderboardStates,
	ILessonQuitPromptInfo,
	ILockedCardModalData,
	OnboardingState,
	ICourseCurrentItems,
} from '../global-interface';
import { DeviceInfo } from '../../../shared/public/SlUtils/detectDevice';
import { ILandingPageResponse } from '../../../api/private/learn-engine-api/landing-page-api.interface';
import {
	IMaterial, ILearningPathSubtree, IMaterialInfo, IAnswerOption, ICourseStructureDTO, IScreenConfig, ICategorizedCourse, IMaterialStatus,
} from '../../../api/private/learn-engine-api/learn-engine-api.interface';
import { ICertificate } from '../../../api/public/certificates-api';
import { ILessonQuitPromptExperiment } from '../../../api/public/experiment-api';
import { IHeartConfigs } from '../../../api/public/kudos-api';
import { LanguagesResponseType } from '../../../api/public/static-pages-api';
import {
	Service, IUserSetting, LeagueRank, LearningExperienceType,
} from '../../../symphony';
import { getLETypeFromRoute } from '../utils/helpers';

@Service()
export class SlDataService {
	public alias$ = new BehaviorSubject<string>(null);

	public isSkipAhead = new BehaviorSubject<boolean>(false);

	public course$ = new BehaviorSubject<IMaterial>(null);

	public justStatusUpdatedCourse$ = new BehaviorSubject<IMaterial>(null);

	public learningPath$ = new BehaviorSubject<ILearningPathSubtree>(null);

	public isCourseAvailable$ = new BehaviorSubject<boolean>(null);

	public lesson$ = new BehaviorSubject<IMaterial>(null);

	public lessonBackup$ = new BehaviorSubject<IMaterial>(null);

	public certificate$ = new BehaviorSubject<ICertificate>(null);

	public lessonPageIndex$ = new BehaviorSubject<number>(null);

	public currentItems$ = new BehaviorSubject<ICourseCurrentItems[]>([]);

	public question$ = new BehaviorSubject<IMaterialInfo>(null);

	public answerOptions$ = new BehaviorSubject<IAnswerOption[]>(null);

	public lessonFooterPrmBtn$ = new BehaviorSubject<IFooterButton>(null);

	public lessonFooterSecBtn$ = new BehaviorSubject<IFooterButton>(null);

	public lessonFooterContBtn$ = new BehaviorSubject<IFooterButton>(null);

	public openUnlockQuizAnswerModal$ = new BehaviorSubject<boolean>(false);

	public unlockQuizAnswerBtn$ = new BehaviorSubject<IFooterButton>(null);

	public quizFeedback$ = new BehaviorSubject<QuizFeedbackType>(null);

	public lessonOrderInCourse$ = new BehaviorSubject<number>(null);

	public correctAnswersCountInARow$ = new BehaviorSubject<number>(0);

	public progressBarCelebration$ = new BehaviorSubject<boolean>(false);

	public loading$ = new BehaviorSubject<ILoadingState>(null);

	public onboarding$ = new BehaviorSubject<OnboardingState>(null);

	public isShownLPComments$ = new BehaviorSubject<boolean>(null);

	public disabledActions$ = new BehaviorSubject<IDisabledActions>(null);

	public routeData$ = new BehaviorSubject<IRouteData>(null);

	public landingPageData$ = new BehaviorSubject<ILandingPageResponse>(null);

	public flashMessage$ = new BehaviorSubject<IFlashMessage>(null);

	public solutions$ = new BehaviorSubject<IMaterialInfo[]>([]);

	public deviceInfo$ = new BehaviorSubject<DeviceInfo>(null);

	public lpComments$ = new BehaviorSubject<ILpComments>(null);

	public courseStructure$ = new BehaviorSubject<ICourseStructureDTO>(null);

	public modals$ = new BehaviorSubject<IModals>(null);

	public heartConfigs$ = new BehaviorSubject<IHeartConfigs>(null);

	public lessonCompleteScreens$ = new BehaviorSubject<IScreenConfig[]>(null);

	public lessonQuitPromptExperiment$ = new BehaviorSubject<ILessonQuitPromptExperiment>(null);

	public lessonQuitPromptInfo$ = new BehaviorSubject<ILessonQuitPromptInfo>(
		null,
	);

	public lockedCardModalData$ = new BehaviorSubject<ILockedCardModalData>(
		null,
	);

	public courseCategories$ = new BehaviorSubject<ICategorizedCourse[]>(null);

	public programmingLanguages$ = new BehaviorSubject<LanguagesResponseType>(
		null,
	);

	public kodieIntroIsClosed$ = new BehaviorSubject<boolean>(false);

	public isKodieInNavigationAnimationShowed$ = new BehaviorSubject<boolean>(
		false,
	);

	public userSettings$ = new BehaviorSubject<IUserSetting[]>([]);

	public externalResourceUrl$ = new BehaviorSubject<string>(null);

	public lessonStatus: IMaterialStatus = null;

	public leaderboardStates: ILeaderboardStates = null;

	public leagueRank: LeagueRank = null;

	public failedSolutionsCount: Record<number, number> = null;

	public isLeaderboardAvailable = false;

	public isStreakCelebrationAvailable = true;

	public isTranslationModalOpened$ = new BehaviorSubject<boolean>(false);

	public lastCompletedMaterialIdInCurrentSession$ = new BehaviorSubject<number>(null);

	public lastAvailableMaterialIdInCurrentSession$ = new BehaviorSubject<number>(null);

	public lessonSessionGuId$ = new BehaviorSubject<string>(null);

	public quizFeedbackFromBackend$ = new BehaviorSubject<string>(null);

	public getCourseIdAsync = async (): Promise<string> => {
		if (getLETypeFromRoute(this.routeData$.value) !== LearningExperienceType.Course) {
			return Promise.resolve('');
		}
		if (this.course$.value) {
			return Promise.resolve(String(this.course$.value.materialInfo.header.materialRelationId));
		}
		return new Promise((resolve) => {
			const subscription = this.course$.subscribe({
				next: (course) => {
					if (course) {
						resolve(String(course.materialInfo.header.materialRelationId));
						subscription.unsubscribe();
					}
				},
			});
		});
	};

	public getBundleIdAsync = async (): Promise<string> => {
		if (getLETypeFromRoute(this.routeData$.value) !== LearningExperienceType.LearningPath) {
			return Promise.resolve('');
		}
		if (this.learningPath$.value) {
			return Promise.resolve(String(this.learningPath$.value.bundleId));
		}
		return new Promise((resolve) => {
			const subscription = this.learningPath$.subscribe({
				next: (path) => {
					if (path) {
						resolve(String(path.bundleId));
						subscription.unsubscribe();
					}
				},
			});
		});
	};
}

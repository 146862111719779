import React, { useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, Trans, i18n } from '../../../../../symphony';
import { ServiceContext, SlLoginContext } from '../../../login-context';
import { SlLoginActionsService } from '../../../services/sl-login-actions.service';
import { SlLoginLoadingService } from '../../../services/sl-login-loading.service';
import {
	ILoginDefaultValues,
	SlLoginActionTypes,
} from '../../../global.interface';
import { FormField, FormFieldType } from '../../shared/FormField/FormField';
import { getSchema } from './validationSchema';

import s from './SignupForm.scss';
import { useContainerData } from '../../../../../shared/public/SlHooks/useContainerData';
import { SlFeatureToggle, SlMicroFrontends } from '../../../../../shared/public/globalInterfaces/globalInterfaces';
import { LocalizedLink } from '../../../../../shared/public/LocalizedLink/LocalizedLink';

interface ISignupFormInputs {
	name: string;
	email: string;
	password: string;
}

interface ISignUpFormProps {
	isLandingPage?: boolean;
	darkMode?: boolean;
	primaryColor?: string;
}
export function SignupForm({
	isLandingPage,
	darkMode,
	primaryColor,
}: ISignUpFormProps): JSX.Element {
	useStyles(s);
	const { loading$: isLoading } = useContainerData(
		SlLoginContext,
		SlLoginLoadingService,
		['loading$'],
	);

	const defaultValues: ILoginDefaultValues = Container.take(
		SlLoginContext,
		'defaultValues',
	);

	const featureToggle = Container.take('global', 'featureTogglingConfigs');
	const publicTokenCaptchaState = featureToggle[SlMicroFrontends.mfLogin][
		SlFeatureToggle.publicTokenCaptcha
	]?.state;

	const refCaptcha = useRef(null);
	const serviceData = useContext(ServiceContext);

	const emitSignupAction = async (formData: ISignupFormInputs) => {
		const { actions$ } = Container.take(
			SlLoginContext,
			SlLoginActionsService,
		);
		actions$.next({
			type: SlLoginActionTypes.signUpHandler,
			clickedButton: 'signup',
			requestType: 'cancelable',
			data: {
				formData,
				refCaptcha,
				contextOptions: {
					trackingEventParams: serviceData.trackingEventParams,
					shouldDefaultRedirect: serviceData.shouldDefaultRedirect,
				},
			},
		});
	};

	const {
		register,
		handleSubmit,
		formState: { isValid, touchedFields, errors },
	} = useForm<ISignupFormInputs>({
		mode: 'onChange',
		reValidateMode: 'onChange',
		resolver: yupResolver(getSchema()),
		defaultValues: {
			name: defaultValues?.signUpName || '',
			email: defaultValues?.signUpEmail || '',
			password: defaultValues?.signUpPassword || '',
		},
	});

	return (
		<form
			onSubmit={handleSubmit(emitSignupAction)}
			className="sl-login-signup-form"
		>
			<FormField
				type={FormFieldType.input}
				label={i18n.t('web-auth.name-label')}
				id="name"
				name="name"
				register={register}
				error={touchedFields?.name && errors.name?.message}
				disabled={isLoading}
				autoComplete="off"
				darkMode={darkMode}
			/>
			<FormField
				type={FormFieldType.input}
				label={i18n.t('web-auth.email-label')}
				id="email"
				name="email"
				register={register}
				error={touchedFields?.email && errors.email?.message}
				disabled={isLoading}
				autoComplete="off"
				darkMode={darkMode}
			/>
			<FormField
				type={FormFieldType.password}
				label={i18n.t('web-auth.password-label')}
				id="password"
				name="password"
				register={register}
				error={touchedFields?.password && errors.password?.message}
				disabled={isLoading}
				autoComplete="current-password"
				darkMode={darkMode}
			/>
			<Button
				type="submit"
				loading={isLoading}
				className="sl-login-signup-form__action"
				disabled={!isValid}
				style={{ backgroundColor: primaryColor }}
			>
				{isLandingPage
					? i18n.t('web-auth.landing-page-signup-submit-button')
					: i18n.t('web-auth.signup-submit-button')}
			</Button>

			{publicTokenCaptchaState === 'enabled' && (
				<p className={`recaptcha-info ${darkMode ? ' dark-mode' : ''}`}>
					<Trans
						i18nKey="web-auth.signup-secondary-info"
						components={[
							<LocalizedLink
								to="https://policies.google.com/privacy"
								reloadDocument
								style={{
									color: primaryColor,
								}}
							/>,
							<LocalizedLink
								to="https://policies.google.com/terms"
								reloadDocument
								style={{
									color: primaryColor,
								}}
							/>,
						]}
					/>
				</p>
			)}
		</form>
	);
}

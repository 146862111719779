import {
	catchError, combineLatest, of, Subject, takeUntil, tap,
} from 'rxjs';
import {
	Container,
	DataService,
	ILeaderboardInfo,
	LeaderboardService,
	LeaderboardState,
	NavigationItem,
} from '../../features/symphony';
import { LeaderboardApi } from '../../features/api/public/leaderboard-api';
import { UserSettingKeys } from '../../features/api/public/user-settings-api';

export function triggerLeaderboardBadge(unsubscriber: Subject<void>) {
	const dataService: DataService = Container.take('global', DataService);

	const leaderboardApi = Container.take('global', LeaderboardApi);

	const findSettingByKey = (settings, key: string) => JSON.parse(
		settings.find((item) => item?.key === key)?.value || null,
	);

	const isLeaderboardStateChanged = (
		lbinfo: ILeaderboardInfo,
		lastLeaderboardInfo: ILeaderboardInfo,
	): boolean =>
	// open-ended, startedAndOpen-startedAndClosed, the combinations are identical,
	// return true if lbinfo === (0 or 1) && lastLeaderboardInfo === (2 or 3) or vise versa
		(
			((!lbinfo?.state || lbinfo?.state === LeaderboardState.Open)
                && (lastLeaderboardInfo?.state
                    === LeaderboardState.StartedAndOpen
                    || lastLeaderboardInfo?.state
                        === LeaderboardState.StartedAndClosed))
            || ((!lastLeaderboardInfo?.state
                || lastLeaderboardInfo?.state === LeaderboardState.Open)
                && (lbinfo?.state === LeaderboardState.StartedAndOpen
                    || lbinfo?.state === LeaderboardState.StartedAndClosed))
		);
	const isLessThan48Hours = (
		lbinfo: ILeaderboardInfo,
		lastLeaderboardInfo: ILeaderboardInfo,
	): boolean => {
		if (lbinfo?.endDate && lastLeaderboardInfo.endDate) {
			const currentDate = new Date().getTime();
			const endDate = new Date(lbinfo.endDate).getTime();
			const limit = 48 * 60 * 60 * 1000; // 48 hours in milliseconds

			return endDate - currentDate <= limit;
		}
		return false;
	};

	combineLatest([
		leaderboardApi.getLeaderboardInfo(),
		dataService.userSettings$,
	])
		.pipe(
			takeUntil(unsubscriber),
			tap(([lbinfo, settings]) => {
				let showBadgeCasesCount: number = 0;
				const lastLeaderboardInfo: ILeaderboardInfo = findSettingByKey(
					settings,
					'lastLeaderboardInfo',
				);
				const leaderboardService: LeaderboardService = Container.take(
					'global',
					LeaderboardService,
				);
				leaderboardService.leaderboardInfo$.next(lbinfo);

				const isLeaderboardEnabled = findSettingByKey(
					settings,
					UserSettingKeys.joinedLeaderboard,
				);
				const leaderboardBadgeSeen = findSettingByKey(
					settings,
					UserSettingKeys.navItemLeaderboardBadgeSeenOnWeb,
				);
				const noLeaderboardCreated = !lbinfo || Number(lbinfo?.id) === 0;

				// if the key exists but the value is false, then the leaderboard is disabled.
				// if there is no created leaderboard(id="0000000")
				// do not show red badge in these cases
				if (
					(isLeaderboardEnabled !== null && !isLeaderboardEnabled)
                    || (noLeaderboardCreated
                        && Number(lastLeaderboardInfo?.id) === 0)
				) {
					return;
				}

				if (!leaderboardBadgeSeen) {
					showBadgeCasesCount++;
				}

				// if there is no data(tbd)
				if (noLeaderboardCreated || !lastLeaderboardInfo) {
					showBadgeCasesCount++;
				}

				// if leaderboard id-s are different
				if (lbinfo?.id !== lastLeaderboardInfo?.id) {
					showBadgeCasesCount++;
				}

				// if states have changed (suspended/open/closed)
				if (isLeaderboardStateChanged(lbinfo, lastLeaderboardInfo)) {
					showBadgeCasesCount++;
				}

				if (isLessThan48Hours(lbinfo, lastLeaderboardInfo)) {
					showBadgeCasesCount++;
				}

				dataService.navItemBadgeConfig$.next({
					...(dataService.navItemBadgeConfig$?.value || {}),
					[NavigationItem.Leaderboard]: showBadgeCasesCount > 0,
				});
			}),
			catchError(() => of(null)),
		)
		.subscribe();

	return null;
}

import { BehaviorSubject } from "rxjs";
import { Service } from "typedi";
import { CourseTrack } from "./global-interfaces";

@Service()
export class UserTrackService {
	public userTrack = new BehaviorSubject<CourseTrack>(null);

	public setUserTrack = (track: CourseTrack) => {
		if (track === this.userTrack.value) return;
		this.userTrack.next(track);
	};

	public getUserTrack = (): CourseTrack => {
		return this.userTrack.value;
	};
}
import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { i18n } from '../../../../../symphony';
import s from './FormField.scss';

export enum FormFieldType {
	input = 'input',
	password = 'password',
}

export interface IFormFieldProps {
	label?: string;
	type: FormFieldType;
	id: string;
	name: string;
	register: (
		name: string,
		RegisterOptions?
	) => { onChange; onBlur; name; ref; };
	error?: string;
	disabled?: boolean;
	autoComplete?: string;
	darkMode?: boolean;
}

export const FormField: React.FC<IFormFieldProps> = React.memo(
	({
		label,
		type,
		id,
		name,
		register,
		error,
		disabled,
		autoComplete,
		darkMode,
	}) => {
		useStyles(s);
		const [isPasswordShown, setIsPasswordShown] = useState<boolean>();

		return (
			<div className="sl-p-form-field">
				{label ? (
					<label
						className={`sl-p-form-field__label ${darkMode ? ' dark-mode' : ''
							}`}
						htmlFor={id}
					>
						{label}
					</label>
				) : null}
				{type === FormFieldType.input ? (
					<input
						className={`sl-p-form-field__input${error ? ' sl-p-form-field__input--error' : ''
							}`}
						type="text"
						id={id}
						{...register(`${name}`)}
						disabled={disabled}
						autoComplete={autoComplete}
					/>
				) : null}

				{type === FormFieldType.password ? (
					<div className="sl-p-form-field__wrapper">
						<input
							className={`sl-p-form-field__input${error ? ' sl-p-form-field__input--error' : ''
								}`}
							type={isPasswordShown ? 'text' : 'password'}
							id={id}
							{...register(`${name}`)}
							disabled={disabled}
							autoComplete={autoComplete}
						/>
						<span
							className="sl-p-form-field__password-toggle"
							onClick={() => setIsPasswordShown(!isPasswordShown)}
						>
							{isPasswordShown
								? i18n.t('web-auth.password-hide')
								: i18n.t('web-auth.password-show')}
						</span>
					</div>
				) : null}

				{error ? (
					<p className="sl-p-form-field__error">{error}</p>
				) : null}
			</div>
		);
	},
);

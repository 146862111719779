import { Container, Service } from '../../../symphony';

import { SlLoginContext } from '../login-context';
import { SlDataService } from './sl-data.service';

@Service()
export class SlModalService {
	private dataService = Container.take(SlLoginContext, SlDataService);

	public showBetaTestingModal(): void {
		this.dataService.betaTestingModal$.next(true);
	}

	public hideBetaTestingModal(): void {
		this.dataService.betaTestingModal$.next(false);
	}
}

import {
	BehaviorSubject, Observable, of, merge,
} from 'rxjs';
import { filter, switchMap, mergeMap } from 'rxjs/operators';
import { Service, Container } from '../../../symphony';

import { SlLoginContext } from '../login-context';
import { SlLoginErrorsService } from './sl-login-errors.service';
import { SlLoginLoadingService } from './sl-login-loading.service';
import {
	IAction,
	ISlLoginResponse,
	SlLoginActionTypes,
} from '../global.interface';
import { SlLoginActionHandlersService } from './sl-login-action.handlers.service';

@Service()
export class SlLoginActionsService {
	public actions$ = new BehaviorSubject<IAction>(null);

	private actionHandlers = Container.take(
		SlLoginContext,
		SlLoginActionHandlersService,
	);

	private errors$: BehaviorSubject<string> = Container.take(
		SlLoginContext,
		SlLoginErrorsService,
	).errors$;

	private loading$: BehaviorSubject<boolean> = Container.take(
		SlLoginContext,
		SlLoginLoadingService,
	).loading$;

	private environmentUrl: string = Container.take('global', 'host');

	private redirectTarget: '_self' | '_blank' = Container.take(
		SlLoginContext,
		'redirectTarget',
	);

	public cancelable: Observable<ISlLoginResponse | any> = this.actions$.pipe(
		filter((a) => a && (!a.requestType || a.requestType === 'cancelable')),
		switchMap((action: IAction) => {
			this.errors$.next(null);
			this.loading$.next(true);
			switch (action.type) {
			case SlLoginActionTypes.appleLogin:
				return this.actionHandlers.appleAuthorizationHandler(
					action,
				);
			case SlLoginActionTypes.login:
				return this.actionHandlers.loginHandler(action);
			case SlLoginActionTypes.signUpHandler:
				return this.actionHandlers.signUpHandler(action);
			case SlLoginActionTypes.socialLogin:
				return this.actionHandlers.socialLoginHandler(action);
			default:
				return of(null);
			}
		}),
	);

	private parallel = this.actions$.pipe(
		filter((a) => a && a.requestType === 'parallel'),
		mergeMap((action) => {
			switch (action.type) {
			case SlLoginActionTypes.termsOfUse:
				window.open(
					`${this.environmentUrl}/Terms-of-Use/`,
					this.redirectTarget,
				);
				return of(null);
			case SlLoginActionTypes.onBetaTesterModalAcceptCLick:
				return this.actionHandlers.onBetaTesterModalAcceptClickHandler();
			default:
				return of(action);
			}
		}),
	);

	constructor() {
		merge(this.cancelable, this.parallel)
			.pipe(filter((a) => !!a))
			.subscribe();
	}
}

import { Observable } from 'rxjs';

import {
	IStreakApi,
	IStreakApiVersions,
} from '../private/streak-api/streak-api.interface';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { IApiUrls, IHostsConfigs } from '../private/global.interface';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { SlApiContext } from '../private/api-context';
import { constructUrl } from '../private/utils/httpParamsUtils';
import {
	Service, Container, IStreakDTO, IStreakConfigurationDTO,
} from '../../symphony';

@Service()
export class StreakApi implements IStreakApi {
	private environmentUrl: string;

	private versions: IStreakApiVersions;

	private axiosInstance: AxiosInstanceType;

	constructor() {
		this.environmentUrl = (
            Container.take('global', 'envUrl') as IApiUrls
		)?.streakApiHost;

		this.versions = (
            Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.streakApi?.version;

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

    @Authorize(AuthLevel.authenticated)
	public getStreaks(userId: number, timeZone = 0): Observable<IStreakDTO> {
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				`api/streaks/${userId}`,
				this.versions?.getStreaks,
			),
			{
				headers: {
					'SL-Time-Zone': timeZone.toString(),
				},
			},
		);
	}

    @Authorize(AuthLevel.authenticated)
    public getStreakConfiguration(): Observable<IStreakConfigurationDTO> {
    	return this.axiosInstance.get(
    		constructUrl(
    			this.environmentUrl,
    			'api/configuration',
    			this.versions?.getStreakConfiguration,
    		),
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public getPredictedStreaks(
    	userId: number,
    	timeZone = 0,
    ): Observable<IStreakDTO> {
    	return this.axiosInstance.get(
    		constructUrl(
    			this.environmentUrl,
    			`api/streaks/${userId}/prediction`,
    			this.versions?.getPredictedStreaks,
    		),
    		{
    			headers: {
    				'SL-Time-Zone': timeZone.toString(),
    			},
    		},
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public setStreakGoal(userId: number, goalDays: number): Observable<null> {
    	return this.axiosInstance.patch(
    		constructUrl(
    			this.environmentUrl,
    			`api/streaks/${userId}`,
    			this.versions?.setStreakGoal,
    		),
    		{
    			goalDays,
    		},
    	);
    }
}

export * from '../private/streak-api/streak-api.interface';

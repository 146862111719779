import React from 'react';

export const IconHeartInfinite = () => (
	<svg
		width="56"
		height="56"
		viewBox="0 0 56 56"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.75 26.25C15.75 24.317 17.317 22.75 19.25 22.75C19.5868 22.75 20.16 22.893 20.9635 23.2766C21.7328 23.6438 22.5668 24.1598 23.3794 24.7297C24.1152 25.2457 24.8008 25.7825 25.3653 26.25C24.8008 26.7175 24.1152 27.2543 23.3794 27.7703C22.5668 28.3402 21.7328 28.8562 20.9635 29.2234C20.16 29.607 19.5868 29.75 19.25 29.75C17.317 29.75 15.75 28.183 15.75 26.25Z"
			fill="#F20057"
		/>
		<path
			d="M32.6206 27.7703C31.8848 27.2543 31.1992 26.7175 30.6347 26.25C31.1992 25.7825 31.8848 25.2457 32.6206 24.7297C33.4332 24.1598 34.2672 23.6438 35.0365 23.2766C35.84 22.893 36.4132 22.75 36.75 22.75C38.683 22.75 40.25 24.317 40.25 26.25C40.25 28.183 38.683 29.75 36.75 29.75C36.4132 29.75 35.84 29.607 35.0365 29.2234C34.2672 28.8562 33.4332 28.3402 32.6206 27.7703Z"
			fill="#F20057"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M30.5097 53.1777L51.6355 31.3344C57.7732 24.9938 57.4347 14.5085 50.6372 8.6252C44.7009 3.48731 35.617 4.41438 30.154 10.0543L28.0007 12.2772L25.8475 10.0543C20.3843 4.41427 11.3002 3.48731 5.36385 8.62553C-1.43325 14.5089 -1.77155 24.994 4.35575 31.3344L25.4815 53.1777C26.8642 54.6074 29.127 54.6074 30.5097 53.1777ZM25.389 21.8641C26.3812 22.5599 27.2948 23.2911 28 23.8923C28.7052 23.2911 29.6188 22.5599 30.611 21.8641C31.5141 21.2308 32.5223 20.5984 33.5287 20.118C34.501 19.6538 35.637 19.25 36.75 19.25C40.616 19.25 43.75 22.384 43.75 26.25C43.75 30.116 40.616 33.25 36.75 33.25C35.637 33.25 34.501 32.8461 33.5287 32.382C32.5223 31.9016 31.5141 31.2692 30.611 30.6359C29.6188 29.9401 28.7052 29.2089 28 28.6077C27.2948 29.2089 26.3812 29.9401 25.389 30.6359C24.4859 31.2692 23.4777 31.9016 22.4713 32.382C21.499 32.8461 20.363 33.25 19.25 33.25C15.384 33.25 12.25 30.116 12.25 26.25C12.25 22.384 15.384 19.25 19.25 19.25C20.363 19.25 21.499 19.6538 22.4713 20.118C23.4777 20.5984 24.4859 21.2308 25.389 21.8641Z"
			fill="#F20057"
		/>
	</svg>
);

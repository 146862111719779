import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@sololearnorg/sol';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, i18n } from '../../../../../symphony';
import { SlLoginActionsService } from '../../../services/sl-login-actions.service';
import { SlLoginLoadingService } from '../../../services/sl-login-loading.service';
import { ServiceContext, SlLoginContext } from '../../../login-context';
import {
	IAction,
	ILoginDefaultValues,
	SlLoginActionTypes,
} from '../../../global.interface';
import { FormField, FormFieldType } from '../../shared/FormField/FormField';

import s from './LoginForm.scss';
import { LocalizedLink } from '../../../../../shared/public/LocalizedLink/LocalizedLink';

export interface ILoginFormInputs {
	email: string;
	password: string;
}

const getSchema = () => yup.object().shape({
	email: yup
		.string()
		.required(i18n.t('web-auth.required-validation'))
		.email(i18n.t('web-auth.email-validation')),
	password: yup
		.string()
		.required(i18n.t('web-auth.required-validation'))
		.min(6, i18n.t('web-auth.min-char-validation')),
});

interface ILoginFormProps {
	darkMode?: boolean;
	primaryColor?: string;
}
export const LoginForm: React.FC<ILoginFormProps> = React.memo(
	({ darkMode, primaryColor }) => {
		useStyles(s);
		const actions$: BehaviorSubject<IAction> = Container.take(
			SlLoginContext,
			SlLoginActionsService,
		).actions$;

		const loading$: BehaviorSubject<boolean> = Container.take(
			SlLoginContext,
			SlLoginLoadingService,
		).loading$;

		const serviceData = useContext(ServiceContext);

		const [isLoading, setLoading] = useState<boolean>(true);

		const unsubscriber = new Subject<void>();

		const emitLoginAction = (values: ILoginFormInputs) => {
			actions$.next({
				type: SlLoginActionTypes.login,
				clickedButton: 'login',
				requestType: 'cancelable',
				data: {
					...values,
					contextOptions: {
						trackingEventParams: serviceData.trackingEventParams,
						shouldDefaultRedirect:
                            serviceData.shouldDefaultRedirect,
					},
				},
			});
		};

		useEffect(() => {
			loading$
				.pipe(takeUntil(unsubscriber))
				.subscribe((data: boolean) => {
					setLoading(data);
				});
			return () => {
				unsubscriber.next();
				unsubscriber.complete();
			};
		}, []);

		const defaultValues: ILoginDefaultValues = Container.take(
			SlLoginContext,
			'defaultValues',
		);

		const {
			register,
			handleSubmit,
			formState: { isValid, touchedFields, errors },
		} = useForm<ILoginFormInputs>({
			mode: 'onChange',
			reValidateMode: 'onChange',
			resolver: yupResolver(getSchema()),
			defaultValues: {
				email: defaultValues?.signInEmail || '',
				password: defaultValues?.signInPassword || '',
			},
		});

		return (
			<form
				onSubmit={handleSubmit(emitLoginAction)}
				className="sl-login-login-form"
			>
				<FormField
					type={FormFieldType.input}
					label={i18n.t('web-auth.email-label')}
					id="email"
					name="email"
					register={register}
					error={touchedFields?.email && errors.email?.message}
					disabled={isLoading}
					autoComplete="off"
					darkMode={darkMode}
				/>

				<FormField
					type={FormFieldType.password}
					label={i18n.t('web-auth.password-label')}
					id="password"
					name="password"
					register={register}
					error={touchedFields?.password && errors.password?.message}
					disabled={isLoading}
					autoComplete="current-password"
					darkMode={darkMode}
				/>

				<LocalizedLink
					className="sl-login-login-form__forgot-pass"
					to="/users/forgot-password"
					style={{
						color: primaryColor,
					}}
				>
					{i18n.t('web-auth.login-forgot-password')}
				</LocalizedLink>

				<Button
					className="sl-login-login-form__action"
					type="submit"
					loading={isLoading}
					disabled={!isValid}
					style={{ backgroundColor: primaryColor }}
				>
					{i18n.t('web-auth.signin-submit')}
				</Button>
			</form>
		);
	},
);

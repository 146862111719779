import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SlStaticPagesDataService } from './sl-static-pages-data.service';
import { StaticPagesContext } from '../../contexts';
import { IProfileData } from '../../global-interfaces';
import {
	ILanguageLandingPageResponse, IStaticPageResponse, IStaticPagesAPI, StaticPagesAPI,
} from '../../../../api/public/static-pages-api';
import { IProfileApi, ProfileApi } from '../../../../api/public/profile-api';
import { ISubscriptionApi, IUserStatsResponse, SubscriptionApi } from '../../../../api/public/subscription-api';
import { CertificatesApi, ICertificate, ICertificatesApi } from '../../../../api/public/certificates-api';
import { getInitialServerSideData } from '../../../../shared/public/SlUtils/getInitialServerSideData';
import {
	Service, AuthService, Container, IUser, history,
} from '../../../../symphony';

@Service()
export class SlStaticPagesApiService {
	private staticPagesApi: IStaticPagesAPI;

	private profileApi: IProfileApi;

	private subscriptionApi: ISubscriptionApi;

	private certificateApi: ICertificatesApi;

	private dataService: SlStaticPagesDataService;

	private authService: AuthService;

	constructor() {
		this.staticPagesApi = Container.take('global', StaticPagesAPI);
		this.certificateApi = Container.take('global', CertificatesApi);
		this.profileApi = Container.take('global', ProfileApi);
		this.authService = Container.take('global', AuthService);
		this.subscriptionApi = Container.take('global', SubscriptionApi);

		this.dataService = Container.take(
			StaticPagesContext,
			SlStaticPagesDataService,
		);
	}

	public getPageContentHandler(
		page: string,
	): Observable<IStaticPageResponse> {
		return this.staticPagesApi.getPageContent(page).pipe(
			tap((res) => {
				this.dataService.content$.next(res.data);
			}),
			catchError(() => of(null)),
		);
	}

	public getCertificateDataHandler(
		id: string,
	): Observable<ICertificate | null> {
		const certificateData = getInitialServerSideData('ssrCertificate');

		if (certificateData) {
			this.dataService.certificateData$.next(certificateData);
			return of(null);
		}
		return this.certificateApi.getCertificate(id).pipe(
			tap((res: ICertificate) => {
				this.dataService.certificateData$.next(res);
			}),
			catchError(() => of(null)),
		);
	}

	public getTargetedLandingPageHandler(alias: string): Observable<unknown> {
		return this.staticPagesApi.getStaticLandingPage(alias).pipe(
			tap((res) => {
				this.dataService.targetedLandingPage$.next(res);
			}),
			catchError(() => of(null)),
		);
	}

	public getLanguageLandingPageDataHandler(
		languageAlias: string,
	): Observable<ILanguageLandingPageResponse> {
		const ssrLanguageLandingPage = getInitialServerSideData(
			'ssrLanguageLandingPage',
		);

		if (ssrLanguageLandingPage) {
			this.dataService.languageLandingPageData$.next(
				ssrLanguageLandingPage,
			);
			return of(null);
		}

		return this.staticPagesApi.getLanguageLandingPage(languageAlias).pipe(
			tap((res) => {
				this.dataService.languageLandingPageData$.next(res);
			}),
			catchError(() => {
				history.replace('/pagenotfound');
				return of(null);
			}),
		);
	}

	public getUserDataHandler(userId: number): Observable<IProfileData> {
		const authUser: IUser = this.authService.getUser();

		this.dataService.isProfileDataLoading$.next(true);

		return this.profileApi.getProfile(userId, [1]).pipe(
			tap((profile: IProfileData) => {
				this.dataService.isProfileDataLoading$.next(false);
				const isOwnProfile = authUser?.id === profile.userDetails?.id;

				this.dataService.profileData$.next({
					...profile,
					isOwnProfile,
				});
			}),
			catchError(() => of(null)),
		);
	}

	public getUserStatsHandler(): Observable<IUserStatsResponse> {
		return this.subscriptionApi.getUserStats().pipe(
			tap((data) => {
				const dataService = Container.take(
					StaticPagesContext,
					SlStaticPagesDataService,
				);
				dataService.userStats$.next(data);
			}),
			catchError(() => of(null)),
		);
	}

	public sendAICourseWaitListEmail(id: string, email: string): Observable<unknown> {
		return this.staticPagesApi.sendWaitlistEmail(email).pipe(
			tap(() => {
				this.dataService.aiCourseWaitlistEmailResult$.next({
					id,
					email,
					result: true,
				});
			}),
			catchError(() => {
				this.dataService.aiCourseWaitlistEmailResult$.next({
					id,
					email,
					result: false,
				});
				return of(null);
			}),
		);
	}
}

import { RefObject, useEffect, useRef, useState } from "react";
export const injectPrice = (value: string, label: string): string => {
	return label?.replace(/{\{[^}]*\}\}/, `$${value} USD`);
};

export const injectPercentage = (value: string, label: string): string => {
	return label?.replace(/{\{[^}]*\}\}/, `${value}`);
};

export const injectMonthlyAndAnnualPrices = (monthly: string, annual: string, label: string): string => {
	return label
		.replace('${{discountedMonthly}}', `$${monthly} USD`)
		.replace('${{discountedPrice}}', `$${annual} USD`);
};

export const injectTrialDays = (value: string, label: string) => {
	return label?.replace("{{trial_days}}", `${value}`);
};

export const calculateSalePercentage = (originalPrice: number, discountedPrice: number): string => {
	const discountAmount = originalPrice - discountedPrice;
	const salePercentage = (discountAmount / originalPrice) * 100;
	return salePercentage.toFixed(0);
};

export const getDayFromToday = (days) => {
	const result = new Date();
	result.setDate(result.getDate() + days);
	return result.getDate();
};

export const useInView = <T extends HTMLElement>(options: {
	root?: HTMLElement;
	rootMargin?: string;
	threshold?: number;
} = {
		root: null,
		rootMargin: '0px',
		threshold: 0.1
	}): [RefObject<T>, boolean] => {
	const [isInView, setIsInView] = useState(false);
	const elementRef = useRef<HTMLElement>(null);

	useEffect(() => {
		const observerCallback = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setIsInView(true);
					observer.unobserve(entry.target);
				} else {
					setIsInView(false);
				}
			});
		};

		const observerOptions = {
			root: options.root || null,
			rootMargin: options.rootMargin || '0px',
			threshold: options.threshold || 0.1,
		};
		const observer = new IntersectionObserver(observerCallback, observerOptions);
		const currentElement = elementRef.current;
		if (currentElement) {
			observer.observe(currentElement);
		}
		return () => {
			if (currentElement) {
				observer.unobserve(currentElement);
			}
		};
	}, [options]);

	return [elementRef as RefObject<T>, isInView];
};
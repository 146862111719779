import {
	BehaviorSubject,
	timer,
	Subscription,
	filter,
	combineLatest,
	Observable,
	of,
	tap,
	merge,
} from 'rxjs';
import { Service } from 'typedi';

import { track } from './tracking/data-tracking';
import { Touchpoint } from '../../api/public/subscription-api';
import { Container } from './container.global';
import { PaywallsService } from '../../_paywalls/private/services/paywalls.service';

export enum HeartConfigurationName {
	TimeInterval = 'timeInterval',
}

export enum HeartUsageType {
	Quiz = 1,
	QuestionMaterial = 2,
}

export enum HeartModalSource {
	NavIconClick = 1,
	LessonIconClick,
	SpentLastHeart,
	LessonOpenFail,
	SpentLastHeartInPassedLesson,
	SpentLastHeartInLastQuiz,
	InfiniteIconClick,
	AIInfiniteIconClick,
}

export interface IHeartData {
	source: HeartModalSource;
	price: number;
	proBtnCallback?: (from: 'course' | 'lesson') => void;
	refillBtnCallback?: () => void;
	onClose: () => void;
}

export enum LeaveLessonModalType {
	LOSE_PROGRESS = 'lose progress',
	LEAVE_LESSON = 'leave lesson',
}

export interface ILessonDetailsData {
	session?: string;
}

export interface ILeaveLessonData {
	isActive: boolean;
	stayBtnCallback: () => void;
	leaveBtnCallback: () => void;
	onClose: () => void;
	type?: LeaveLessonModalType;
}

export interface IHeartConfiguration {
	name: HeartConfigurationName;
	refillDurationBySecond: number;
	firstDeductionDate: string; // unused
	nextRefillDate: string; // unused
}

export interface IHeartDeductionUnit {
	title: string;
	unit: number;
	usageTypeId: HeartUsageType;
}

export interface IHearts {
	heartsCount?: number;
	previousHeartsCount?: number;
	lastUpdateDate?: string;
	hasInfiniteHearts: boolean; // is true when user is pro
	maxHeartsCount?: number; // 3
	configurations?: IHeartConfiguration[];
	deductionUnits?: IHeartDeductionUnit[];
}

export interface IHeartsCallbacks {
	refillByBits: () => Observable<null>;
	navigateToCourse: () => void;
	openLesson: (id: number) => void;
	onClose?: () => void;
	toNextPage?: () => void;
	refillByBitsCallback?: () => void;
}

enum LocationType {
	Course = '6',
	Lesson = '7',
}

enum TypeId {
	Null = '',
	Info = '1',
	LeaveLesson = '2',
	FirstRefill = '3',
	Intro = '4',
	InfoPro = '5',
}

enum ButtonType {
	Null = '',
	Icon = '1',
	Continue = '2',
	Pro = '3',
	Refill = '5',
	LeaveLesson = '6',
	Stay = '7',
	Leave = '8',
	Referral = '9',
	Close = '10',
	IconPro = '11',
}

interface IHeartsTrackingData {
	version: string;
	event_name: string;
	location_type: LocationType;
	type_id: TypeId;
	button_type?: ButtonType;
	hearts_amount: number;
	experience_alias: string;
	material_id: string;
}

@Service()
export class HeartService {
	// Input & Output
	public hearts$ = new BehaviorSubject<IHearts>(null);

	// Input
	public openModal$ = new BehaviorSubject<HeartModalSource>(null);

	// Output
	public heartModal$ = new BehaviorSubject<IHeartData>(null);

	public leaveLessonModal$ = new BehaviorSubject<ILeaveLessonData>(null);

	public nextRefillDateTimer$ = new BehaviorSubject<boolean>(false);

	public hoursToRefill$ = new BehaviorSubject<number>(null);

	public minutesToRefill$ = new BehaviorSubject<number>(null);

	private paywallService = Container.take('paywalls', PaywallsService);

	// Inner
	private callbacks: IHeartsCallbacks = {
		refillByBits: () => of(null),
		navigateToCourse: () => { },
		openLesson: () => { },
		onClose: () => { },
		toNextPage: () => { },
		refillByBitsCallback: () => { },
	};

	private isInitialized$ = new BehaviorSubject<boolean>(false);

	private courseAlias: string = null;

	private lessonId: number = null;

	private lessonIdToOpen: number = null;

	private timerSubscription: Subscription = null;

	private countdownSubscription: Subscription = null;

	private session: string = null;

	constructor() {
		merge(
			this.hearts$.pipe(
				filter(
					(h) => !!h
						&& !h.hasInfiniteHearts
						&& h.heartsCount < h.maxHeartsCount,
				),
				tap(({ configurations }) => this.setRefillDetails(configurations)),
			),
			combineLatest([this.openModal$, this.isInitialized$]).pipe(
				filter(([open, init]) => !!open && init),
				tap(([source]) => this.openModal(source)),
			),
		).subscribe();

		this.heartModal$.next({
			source: null,
			price: null,
			proBtnCallback: this.proBtnCallback,
			refillBtnCallback: this.refillBtnCallback,
			onClose: this.onHeartModalClose,
		});

		this.leaveLessonModal$.next({
			isActive: false,
			leaveBtnCallback: this.leaveBtnCallback,
			stayBtnCallback: this.stayBtnCallback,
			onClose: this.onLeaveModalClose,
		});
	}

	public openPaywall = (from: 'course' | 'lesson') => {
		const { heartsCount } = this.hearts$.value;
		let touchpoint: Touchpoint;
		if (from === 'course') {
			touchpoint = heartsCount === 0 ? Touchpoint.heartsCourseOut : Touchpoint.heartsCourseEnough;
		} else {
			touchpoint = heartsCount === 0 ? Touchpoint.heartsLessonOut : Touchpoint.heartsLessonEnough;
		}
		this.paywallService.openPaywall({
			touchpoint, props: { heartsCount: heartsCount.toString() }
		});
	};

	public isRanOutOfHearts(value: IHearts = null): boolean {
		const hearts = value || this.hearts$?.value;
		return hearts && !hearts.hasInfiniteHearts && hearts.heartsCount === 0;
	}

	public setup(price: number, callbacks: IHeartsCallbacks): void {
		this.heartModal$.next({
			...this.heartModal$.value,
			price,
		});
		this.callbacks = callbacks;
		this.isInitialized$.next(true);
	}

	public setCourse(alias: string): void {
		this.courseAlias = alias;
	}

	public setLessonId(id: number): void {
		this.lessonId = id;
	}

	public setLessonDetails(data: ILessonDetailsData): void {
		const { session } = data;
		this.session = session;
	}

	public setLessonIdToOpen(id: number): void {
		this.lessonIdToOpen = id;
	}

	public refillHearts(): void {
		const hearts = this.hearts$.value;
		if (hearts) {
			this.hearts$.next({
				...hearts,
				heartsCount: hearts.maxHeartsCount,
			});
		}
	}

	public setRefillByBitsCallback(callback: () => void): void {
		if (callback) {
			this.callbacks.refillByBitsCallback = callback;
		}
	}

	private openModal(source: HeartModalSource): void {
		this.heartModal$.next({
			...this.heartModal$.value,
			source,
		});
		this.openModal$.next(null);
	}

	private setRefillDetails(configurations: IHeartConfiguration[]): void {
		const secondsToRefill = configurations?.find(
			(c) => c.name === HeartConfigurationName.TimeInterval,
		)?.refillDurationBySecond;

		if (secondsToRefill && secondsToRefill > 0) {
			this.setupNextRefillDateTimer(secondsToRefill);
			this.setupHoursAndMinutes(secondsToRefill);
		}
	}

	private setupNextRefillDateTimer(secondsToRefill: number): void {
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
		}
		this.timerSubscription = timer(secondsToRefill * 1000).subscribe(() => {
			this.nextRefillDateTimer$.next(true);
			this.timerSubscription.unsubscribe();
			this.timerSubscription = null;
		});
	}

	private setupHoursAndMinutes(secondsToRefill: number): void {
		const delay = (secondsToRefill % 60) * 1000;
		const totalMinutes = Math.ceil(secondsToRefill / 60) + (delay === 0 ? 1 : 0);
		this.hoursToRefill$.next(Math.floor(totalMinutes / 60));
		this.minutesToRefill$.next(totalMinutes % 60);

		if (this.countdownSubscription) {
			this.countdownSubscription.unsubscribe();
		}
		this.countdownSubscription = timer(delay, 60000).subscribe(() => {
			this.decrementMinute();
		});
	}

	private decrementMinute(): void {
		const minutesToRefill = this.minutesToRefill$.value;
		const hoursToRefill = this.hoursToRefill$.value;
		if (minutesToRefill === 0 && hoursToRefill > 0) {
			this.hoursToRefill$.next(hoursToRefill - 1);
			this.minutesToRefill$.next(59);
		} else {
			if (hoursToRefill === 0 && minutesToRefill === 1) {
				this.countdownSubscription.unsubscribe();
				this.countdownSubscription = null;
			}
			this.minutesToRefill$.next(minutesToRefill - 1);
		}
	}

	private refillBtnCallback = (): void => {
		this.trackClick(TypeId.Info, ButtonType.Refill);
		this.callbacks.refillByBits().subscribe(() => {
			const { source } = this.heartModal$.value;
			this.closeHeartModal();
			if (this.callbacks.refillByBitsCallback) {
				this.callbacks.refillByBitsCallback();
				this.callbacks.refillByBitsCallback = null;
			} else if (
				source === HeartModalSource.LessonOpenFail
				&& this.lessonIdToOpen
			) {
				this.callbacks.openLesson(this.lessonIdToOpen);
			}
		});
	};

	private toNextPageCallback = (): void => {
		this.closeHeartModal();
		this.callbacks.toNextPage();
	};

	private proBtnCallback = (from: 'course' | 'lesson', closeModal: boolean = false): void => {
		this.trackClick(TypeId.Info, ButtonType.Pro);
		this.openPaywall(from);
		closeModal && this.closeHeartModal();
	};

	private onHeartModalClose = (): void => {
		this.trackClick(TypeId.Info, ButtonType.Close);
		this.tryCloseHeartModal();
	};

	private leaveBtnCallback = (): void => {
		this.trackClick(TypeId.LeaveLesson, ButtonType.Leave);
		this.closeHeartModal();
		this.closeLeaveLessonModal();
		this.callbacks.navigateToCourse();
	};

	private stayBtnCallback = (): void => {
		this.trackClick(TypeId.LeaveLesson, ButtonType.Stay);
		this.closeLeaveLessonModal();
	};

	private onLeaveModalClose = (): void => {
		this.trackClick(TypeId.LeaveLesson, ButtonType.Close);
		this.closeLeaveLessonModal();
	};

	private tryCloseHeartModal(trackButtonClick = false): void {
		if (trackButtonClick) {
			this.trackClick(TypeId.Info, ButtonType.LeaveLesson);
		}
		if (this.isRanOutOfHearts()) {
			if (
				this.heartModal$.value?.source
				=== HeartModalSource.SpentLastHeart
			) {
				this.openLeaveLessonModal();
			} else if (
				this.heartModal$.value?.source
				=== HeartModalSource.SpentLastHeartInLastQuiz
			) {
				this.toNextPageCallback();
				this.closeHeartModal();
			} else {
				this.closeHeartModal();
			}
		} else {
			this.closeHeartModal();
		}
	}

	private closeHeartModal(): void {
		if (this.callbacks?.refillByBitsCallback) {
			this.callbacks.refillByBitsCallback = null;
		}
		if (this.callbacks?.onClose) {
			this.callbacks.onClose();
		}
		this.heartModal$.next({
			...this.heartModal$.value,
			source: null,
		});
	}

	private openLeaveLessonModal(): void {
		this.leaveLessonModal$.next({
			...this.leaveLessonModal$.value,
			isActive: true,
			type: this.session
				? LeaveLessonModalType.LOSE_PROGRESS
				: LeaveLessonModalType.LEAVE_LESSON,
		});
	}

	private closeLeaveLessonModal(): void {
		this.leaveLessonModal$.next({
			...this.leaveLessonModal$.value,
			isActive: false,
		});
	}

	public trackHeartModalImpression = (): void => {
		this.trackImpression(TypeId.Info);
	};

	public trackInfiniteModalImpression = (): void => {
		this.trackImpression(TypeId.InfoPro);
	};

	public trackLeaveLessonImpression = (): void => {
		this.trackImpression(TypeId.LeaveLesson);
	};

	public trackFirstRefillImpression = (): void => {
		this.trackImpression(TypeId.FirstRefill);
	};

	public trackHeartIntroImpression = (): void => {
		this.trackImpression(TypeId.Intro);
	};

	public trackIconClick = (): void => {
		this.trackClick(TypeId.Null, ButtonType.Icon);
	};

	public trackIconProClick = (): void => {
		this.trackClick(TypeId.Null, ButtonType.IconPro);
	};

	public trackFirstRefillClick = (): void => {
		this.trackClick(TypeId.FirstRefill, ButtonType.Null);
	};

	public trackHeartIntroClick = (): void => {
		this.trackClick(TypeId.Intro, ButtonType.Null);
	};

	private trackImpression(typeId: TypeId): void {
		track(this.getTrackingData('heart_impression', typeId, null));
	}

	private trackClick(typeId: TypeId, buttonType: ButtonType): void {
		track(this.getTrackingData('heart_click', typeId, buttonType));
	}

	private getTrackingData(
		eventName: string,
		typeId: TypeId,
		buttonType?: ButtonType,
	): IHeartsTrackingData {
		const data: IHeartsTrackingData = {
			event_name: eventName,
			type_id: typeId,
			version: '2-0-0',
			hearts_amount: this.hearts$.value?.hasInfiniteHearts
				? 333
				: this.hearts$.value?.heartsCount,
			experience_alias: this.courseAlias,
			material_id: this.lessonId?.toString() || '',
			location_type: this.lessonId
				? LocationType.Lesson
				: LocationType.Course,
		};
		if (buttonType || buttonType === ButtonType.Null) {
			data.button_type = buttonType;
		}
		return data;
	}
}

import { TrackingEventNames } from '../../../public/tracking/trackingEventNames';
import { TrackingEventsMappingType } from '../tracking.interface';

export const iterableEventsMapping: TrackingEventsMappingType = {
	[TrackingEventNames.codecoachRun]: {
		eventName: 'codecoach_run',
		params: ['course_name', 'codecoach_id'],
	},
	[TrackingEventNames.slEomComplete]: {
		eventName: 'endmodule_complete',
		params: [
			['id', 'sl_problem_id'],
			['courseName', 'sl_course_name'],
		],
	},
	[TrackingEventNames.eomStart]: {
		eventName: 'endmodule_start',
		params: ['id', 'course_name'],
	},
	[TrackingEventNames.lessonComplete]: {
		eventName: 'lesson_complete',
		params: [
			['lesson_id', 'sl_lesson_id'],
			['course_name', 'sl_course_name'],
		],
	},
	[TrackingEventNames.lessonProgressUpdate]: {
		eventName: 'lesson_progress_update',
		params: ['lesson_id', 'course_name'],
	},
	[TrackingEventNames.heartLost]: {
		eventName: 'heart_lost',
		params: ['heart_after', 'amount'],
	},
	[TrackingEventNames.coursePageView]: {
		eventName: 'course_page_view',
		params: ['course_id', 'course_name'],
	},
	[TrackingEventNames.slCodeCoachView]: {
		eventName: 'codecoach_page_view',
		params: ['course_name', 'codecoach_id'],
	},
	[TrackingEventNames.codeCoachStart]: {
		eventName: 'codecoach_start',
		params: ['course_name', 'codecoach_id'],
	},
	[TrackingEventNames.goalComplete]: {
		eventName: 'goal_complete',
		params: ['type', 'period', 'amount'],
	},
	[TrackingEventNames.proPageView]: {
		eventName: 'pro_page_view',
		params: [{ sl_source: (val) => val.toString().toUpperCase() }],
	},
	[TrackingEventNames.courseComplete]: {
		eventName: 'course_complete',
		params: [
			['course_name', 'sl_course_name'],
			['course_id', 'sl_course_id'],
		],
	},
	[TrackingEventNames.courseProgressUpdate]: {
		eventName: 'course_progress_update',
		params: ['course_name', 'course_id'],
	},
	[TrackingEventNames.courseStart]: {
		eventName: 'course_start',
		params: ['course_id', 'course_name'],
	},

	// leaderboard events
	[TrackingEventNames.leaderboardCtaViewed]: {
		eventName: 'leaderboard_cta_viewed',
		params: ['XP_threshold_OK'],
	},
	[TrackingEventNames.leaderboardCtaClicked]: {
		eventName: 'leaderboard_cta_clicked',
		params: [],
	},
	[TrackingEventNames.leaderboardViewed]: {
		eventName: 'leaderboard_viewed',
		params: ['XP_threshold_OK'],
	},
	[TrackingEventNames.leaderboardTestUser]: {
		eventName: 'leaderboard_test_user',
		params: [],
	},
};

import React, { FC, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaywallOffer.scss';
import { useContainerData } from "../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../paywalls.store";
import { OfferHeader } from "../assets/OfferHeader";
import { PaymentProduct } from "../../../../api/public/subscription-api";
import { calculateSalePercentage, injectMonthlyAndAnnualPrices, injectPercentage, injectPrice, injectTrialDays } from "../../paywalls.utils";
import { Container, i18n } from "../../../../symphony";
import { PaywallsService } from "../../services/paywalls.service";

export const PaywallOffer: FC = () => {
	useStyles(s);
	const [offerProduct, setOfferProduct] = useState<PaymentProduct>();
	const [originalProduct, setOriginalProduct] = useState<PaymentProduct>();
	const { selectProduct, navigateToCheckout } = Container.take('paywalls', PaywallsService);

	const {
		offerProductKey,
		paymentProducts,
		selectedProductKey
	} = useContainerData('paywalls', PaywallsStore, [
		'offerProductKey',
		'paymentProducts',
		'selectedProductKey'
	]);

	useEffect(() => {
		setOfferProduct(paymentProducts.find(p => p.key === offerProductKey));
		setOriginalProduct(paymentProducts.find(p => p.key === selectedProductKey));
	}, []);

	const monthlyPrice = (type: 'original' | 'discounted') => {
		const price = type === 'original' ? offerProduct.amount : offerProduct.discountedAmount;
		return price / offerProduct.period;
	};

	const onOfferAccept = () => {
		selectProduct({ productId: offerProduct.key });
		navigateToCheckout();
	};

	const onOfferDecline = () => {
		selectProduct({ productId: selectedProductKey });
		navigateToCheckout();
	};

	return (offerProduct ? <div className="offer">
		<div
			className="offer-header"
			style={{
				backgroundImage: `url(${OfferHeader})`,
			}}
		>
			<div className="offer-prices">
				<span className="offer-old-price">{
					injectPrice(
						monthlyPrice('original').toFixed(2),
						i18n.t('paywalls.long-flow.view-all-plans-bottom-sheet.price.month')
					)
				}</span>
				<span className="offer-new-price">
					{injectPrice(
						monthlyPrice('discounted').toFixed(2),
						i18n.t('paywalls.long-flow.view-all-plans-bottom-sheet.price.month')
					)}
				</span>
			</div>
		</div>
		<div className="offer-footer">
			<span className="offer-title">
				{injectPercentage(
					calculateSalePercentage(offerProduct.amount, offerProduct.discountedAmount),
					i18n.t('web-paywall.offer-modal-title')
				)}
			</span>
			<span className="offer-description">
				{injectMonthlyAndAnnualPrices(
					monthlyPrice('discounted').toFixed(2),
					offerProduct.discountedAmount.toFixed(2),
					i18n.t('web-paywall.offer-modal-info')
				)}
			</span>
			<div className="offer-buttons">
				<button
					className="offer-button offer-button-primary"
					onClick={onOfferAccept}
				>
					{injectPercentage(
						calculateSalePercentage(offerProduct.amount, offerProduct.discountedAmount),
						i18n.t('web-paywall.offer-modal-subscribe-now-button')
					)}
				</button>
				<button
					className="offer-button offer-button-secondary"
					onClick={onOfferDecline}
				>
					{injectTrialDays(
						originalProduct.trialDays.toString(),
						i18n.t('paywalls.web.serious-learner.cta-secondary')
					)}
				</button>
			</div>
		</div>
	</div> : null);
};

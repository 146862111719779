import React, { useEffect, useRef } from 'react';

import {
	LogoPrimary,
	LogoSimplified,
	LogoMonochromeSimplifiedWhite,
	LogoMonochromeWhite,
	LogoTeams,
	LogoTeamsSimplified,
	IconChevronDownSmall,
	Button,
} from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	AuthService,
	Container,
	DataService,
	NavigationItem,
} from '../../../../symphony';

import { CoursesCatalog } from '../NavBarCatalog/CoursesCatalog';
import { NavBarCompiler } from '../NavBarCompiler/NavBarCompiler';
import { SlHamburger } from '../SlHamburger/SlHamburger';
import { SlProButton } from '../SlProButton/SlProButton';
import { SlNavigationActions } from '../SlNavigationActions/SlNavigationActions';
import { SlActions } from '../../services/ActionsService/sl-actions';
import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlNavigationContext } from '../../global-constants';
import {
	ActionType,
	RequestType,
	NavItemActionType,
	INavItemConfig,
} from '../../global-interface';

import s from './Navbar.scss';
import { SlNavBarPositions, SlNavbarThemes, SlNavbarType } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { useComponentWillMount } from '../../../../shared/public/SlUtils/useComponentWillMount';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { useOutsideClick } from '../../../../shared/public/SlHooks/useOutsideClick';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';

export interface INavBarProps {
	navbarPosition?: SlNavBarPositions;
	navbarMode?: SlNavbarThemes;
	navbarType?: SlNavbarType;
}

export const NavBar: React.FC<INavBarProps> = React.memo(
	({
		navbarPosition = SlNavBarPositions.relative,
		navbarMode = SlNavbarThemes.light,
		navbarType = SlNavbarType.default,
	}) => {
		useStyles(s);
		const { actions$ } = Container.take(SlNavigationContext, SlActions);

		useComponentWillMount(() => {
			actions$.next({
				type: ActionType.OnNavbarMount,
				requestType: RequestType.Parallel,
			});
		});

		const {
			navConfig$: navConfig,
			navBarOpen$: navBarOpen,
			userMenuOpen$: userMenuOpen,
			bitsMenuOpen$: bitsMenuOpen,
			streakMenuOpen$: streakMenuOpen,
		} = useContainerData(SlNavigationContext, SlNavigationDataService, [
			'navConfig$',
			'navBarOpen$',
			'userMenuOpen$',
			'bitsMenuOpen$',
			'streakMenuOpen$',
		]);

		const { navItemBadgeConfig$: navItemBadgeConfig } = useContainerData(
			'global',
			DataService,
			['navItemBadgeConfig$'],
		);
		const element = useRef(null);

		const authService = Container.take('global', AuthService);

		const onNavbarClose = () => {
			actions$.next({
				type: ActionType.OnNavbarClose,
				requestType: RequestType.Parallel,
			});
		};

		useOutsideClick(element, true, false, onNavbarClose);

		const onNavLinkClick = (navItem: INavItemConfig): void => {
			actions$.next({
				type: ActionType.OnNavLinkClick,
				requestType: RequestType.Parallel,
				data: {
					navItem,
				},
			});
		};

		const onLogoClick = (): void => {
			actions$.next({
				type: ActionType.OnNavLogoClick,
				requestType: RequestType.Parallel,
			});
		};

		const onNavCatalogClick = (navItem: INavItemConfig): void => {
			actions$.next({
				type: ActionType.OnNavCatalogClick,
				requestType: RequestType.Parallel,
				data: {
					navItem,
				},
			});
		};

		const renderLogo = () => (
			<>
				<div className="sl-navbar__main-logo--desktop">
					{navbarType === SlNavbarType.teams ? (
						<LogoTeams />
					) : (
						<LocalizedLink
							to={navConfig.logoConfig.link}
							onClick={onLogoClick}
						>
							{navbarMode === SlNavbarThemes.light ? (
								<LogoPrimary />
							) : (
								<LogoMonochromeWhite />
							)}
						</LocalizedLink>
					)}
				</div>
				<div className="sl-navbar__main-logo--mobile">
					{navbarType === SlNavbarType.teams ? (
						<LogoTeamsSimplified />
					) : (
						<LocalizedLink
							to={navConfig.logoConfig.link}
							onClick={onLogoClick}
						>
							{navbarMode === SlNavbarThemes.light ? (
								<LogoSimplified />
							) : (
								<LogoMonochromeSimplifiedWhite />
							)}
						</LocalizedLink>
					)}
				</div>
			</>
		);

		const renderItem = (navItem: INavItemConfig) => {
			switch (navItem.actionType) {
				case NavItemActionType.ExternalLink:
					return (
						<LocalizedLink
							onClick={() => {
								onNavLinkClick(navItem);
							}}
							to={navItem.link}
							reloadDocument
						>
							{navItem.name}
						</LocalizedLink>
					);
				case NavItemActionType.InternalLink:
					return (
						<LocalizedLink
							to={navItem.link}
							onClick={() => {
								onNavLinkClick(navItem);
							}}
						>
							{navItem.name}
						</LocalizedLink>
					);
				case NavItemActionType.Catalog:
					if (navbarType === SlNavbarType.teams) {
						return (
							<LocalizedLink
								to={navItem.link}
								onClick={() => {
									onNavLinkClick(navItem);
								}}
							>
								{navItem.name}
							</LocalizedLink>
						);
					}
					return (
						<>
							<button
								type="button"
								className="sl-navbar__content-list__wrapper__item__btn"
								onClick={() => onNavCatalogClick(navItem)}
							>
								{navItem.name}
								<div
									className={`sl-navbar__content-list__wrapper__thick ${navItem.expanded
										? 'sl-navbar__content-list__wrapper__thick__rotated'
										: ''
										}`}
								>
									<IconChevronDownSmall />
								</div>
							</button>
							{navItem.expanded
								&& navItem.key === NavigationItem.Courses && (
									<CoursesCatalog />
								)}
							{navItem.expanded
								&& navItem.key === NavigationItem.CodeCompiler && (
									<NavBarCompiler />
								)}
							<LocalizedLink
								className="sl-navbar__content-list__wrapper__item__link"
								to={navItem.link}
								onClick={() => {
									onNavLinkClick(navItem);
								}}
							>
								{navItem.name}
							</LocalizedLink>
						</>
					);
				default:
					return null;
			}
		};

		const renderItems = (
			navItems: INavItemConfig[],
		) => {
			const items = navbarType === SlNavbarType.teams
				? navItems.filter(({ key }) => [
					NavigationItem.Courses,
					NavigationItem.Discuss,
					NavigationItem.Blog,
				].includes(key))
				: navItems;

			return items.map((item) => (
				<div
					className="sl-navbar__content-list__wrapper"
					key={item.name}
				>
					<li className="sl-navbar__content-list__wrapper__item">
						{renderItem(item)}
					</li>
					{navItemBadgeConfig?.[item.name] && (
						<span className="sl-navbar__content-list__wrapper__item-badge" />
					)}
				</div>
			));
		};

		return navConfig ? (
			<nav
				className={`sl-navbar ${navbarMode}`}
				style={{ position: navbarPosition }}
			>
				<div
					className={`sl-navbar__container${navbarType === SlNavbarType.teams
						? ' sl-navbar__container__teams'
						: ''
						}`}
					ref={element}
				>
					{renderLogo()}
					<div
						className={`sl-navbar__content${navBarOpen ? ' active' : ''
							}`}
					>
						<ul
							className={`sl-navbar__content-list${userMenuOpen || bitsMenuOpen || streakMenuOpen
								? ' hide'
								: ''
								}`}
						>
							{renderItems(navConfig.itemsConfig)}
							{navbarType === SlNavbarType.teams && (
								<LocalizedLink to="/">
									<Button
										className="sl-navbar__homepage-button"
										buttonType="secondary"
										onClick={onLogoClick}
									>
										Go to homepage
									</Button>
								</LocalizedLink>
							)}
						</ul>
						{navbarType !== SlNavbarType.teams && (
							<>
								{authService?.isLoggedIn() && (
									<div className="sl-navbar__pro-button-wrapper">
										<SlProButton />
									</div>
								)}
								<div className="sl-navbar__action-wrapper">
									<SlNavigationActions mode={navbarMode} />
								</div>
							</>
						)}
					</div>
					<SlHamburger mode={navbarMode} />
				</div>
			</nav>
		) : null;
	},
);

import React, { useEffect, useState } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';

import { Button, Modal } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../../symphony';

import { SlLoginContext } from '../../login-context';
import { SlDataService } from '../../services/sl-data.service';
import { SlModalService } from '../../services/sl-modal.service';
import { IAction, SlLoginActionTypes } from '../../global.interface';
import { SlLoginActionsService } from '../../services/sl-login-actions.service';
import { Icon } from '../shared/Icon/Icon';

import s from './BetaTesting.scss';

export const BetaTesting = (): JSX.Element => {
	useStyles(s);
	const [betaTestingModal, setBetaTestingModal] = useState<boolean>(false);
	const [addBetaTesterLoading, setAddBetaTesterLoading] = useState<boolean>(false);

	const modalService = Container.take(SlLoginContext, SlModalService);
	const dataService = Container.take(SlLoginContext, SlDataService);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		const { betaTestingModal$, addBetaTesterLoading$ } = dataService;

		betaTestingModal$.subscribe((data) => {
			setBetaTestingModal(data);
		});
		addBetaTesterLoading$.subscribe((state) => {
			setAddBetaTesterLoading(state);
		});
		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const onClose = () => {
		modalService.hideBetaTestingModal();
	};

	const onRejectSuggestion = () => {
		const { loginCallback$, loginData$ } = dataService;
		loginCallback$.value(loginData$.value);
		onClose();
	};

	const onAcceptSuggestion = () => {
		const actions$: BehaviorSubject<IAction> = Container.take(
			SlLoginContext,
			SlLoginActionsService,
		).actions$;
		actions$.next({
			type: SlLoginActionTypes.onBetaTesterModalAcceptCLick,
			requestType: 'parallel',
		});
	};

	return betaTestingModal ? (
		<Modal.Custom
			dataTestid="mdlBetaTesting"
			onClose={onClose}
			dark={false}
			fullscreen
			className="mdl-beta-testing-wrapper"
		>
			<div className="mdl-beta-testing">
				<Icon
					iconId="innovativePurple"
					className="mdl-beta-testing__solik"
				/>
				<div className="mdl-beta-testing_title">
					Want early access to new features?
				</div>
				<div className="mdl-beta-testing_desc1">
					{' '}
					A new and improved learning experience is available in our
					Beta version! Exciting, huh? We’re looking for volunteers to
					try it out.
				</div>
				<div className="mdl-beta-testing_desc2">
					A few things to note:
					<ul className="mdl-beta-testing_desc2_uls">
						<li className="mdl-beta-testing_desc2_bold">
							Some things may not work as expected.
							<span>
								You can help us improve by telling us about any
								problems.
							</span>
						</li>
						<li className="mdl-beta-testing_desc2_bold">
							Your course progress won’t be synced across mobile
							and desktop until the Beta version is launched.
							<span>
								{' '}
								So if you’d like to learn on both devices right
								away, we recommend using the current experience.
								{' '}
							</span>
						</li>
					</ul>
				</div>
				<div className="mdl-beta-testing_footer">
					<Button
						className="mdl-beta-testing_footer_btn1"
						size="m"
						buttonType="secondary"
						onClick={onRejectSuggestion}
						display="full"
					>
						Continue with current version
					</Button>
					<Button
						className="mdl-beta-testing_footer_btn2"
						size="m"
						buttonType="secondary"
						onClick={onAcceptSuggestion}
						loading={addBetaTesterLoading}
						display="full"
					>
						Join Beta Testing group
					</Button>
				</div>
			</div>
		</Modal.Custom>
	) : null;
};

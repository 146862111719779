import { Service } from 'typedi';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { Container } from './container.global';
import { BlockerModalIds } from '../private/constants';
import { history } from './history';

export enum KeyboardKeyCodes {
    enter = 13,
    tab = 9,
}

@Service()
export class ModalService {
	constructor() {
		history.listen(() => {
			const authService = Container.take('global', AuthService);
			if (
				this.openedModals.find((item) => BlockerModalIds[item])
                && authService.isLoggedIn()
			) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'auto';
				this.closeAll();
			}
		});
	}

	private modals: { [key: string]: (state: boolean) => void } = {};

	private openedModals: string[] = [];

	public openedModalIds$ = new BehaviorSubject<string[]>([]);

	private shouldDisableScroll = true;

	public register = (
		id: string,
		openStateSetter: (state: boolean) => void,
	): void => {
		this.modals[id] = openStateSetter;
	};

	public open = (id: string, keepTabEnter?: boolean): void => {
		if (this.modals[id]) {
			this.modals[id](true);
			this.openedModals = [...this.openedModals, id];
			this.openedModalIds$.next(this.openedModals);
			this.disableScroll();
			if (!keepTabEnter) {
				this.disableTabEnter();
			}
		}
	};

	public openAtTime = (id: string, milliseconds = 100): void => {
		if (!this.openedModals.length) {
			this.open(id);
		} else if (!this.openedModals.includes(id)) {
			const subscription = this.openedModalIds$.subscribe(
				(modals: string[]) => {
					if (!modals.length) {
						setTimeout(() => {
							this.open(id);
							subscription.unsubscribe();
						}, milliseconds);
					}
				},
			);
		}
	};

	public close = (id: string): void => {
		const openedModals = this.openedModals.filter(
			(item) => BlockerModalIds[item],
		);
		if (openedModals.length === 1) {
			document.body.style.overflow = 'auto';
		}
		if (this.modals[id]) {
			this.modals[id](false);
			this.removeModal(id);
		}
	};

	public removeModal = (id: string): void => {
		this.openedModals = this.openedModals.filter(
			(modalId: string) => modalId !== id,
		);
		this.openedModalIds$.next(this.openedModals);
		if (!this.openedModals.length) {
			this.enableScroll();
			this.enableTabEnter();
		}
		this.shouldDisableScroll = true;
	};

	public closeAll = (): void => {
		Object.keys(this.modals).forEach((id) => {
			if (!BlockerModalIds?.[id]) {
				this.modals[id](false);
			} else {
				document.body.style.overflow = 'hidden';
			}
		});
		const openedModals = this.openedModals.filter(
			(item) => !BlockerModalIds[item],
		);
		this.openedModalIds$.next(openedModals);
		this.enableScroll();
		this.enableTabEnter();
		this.shouldDisableScroll = true;
	};

	private disableScroll = (): void => {
		if (document.body.style.overflow === 'hidden') {
			this.shouldDisableScroll = false;
		}
		if (this.shouldDisableScroll) {
			document.body.style.overflow = 'hidden';
		}
	};

	private enableScroll = (): void => {
		if (this.shouldDisableScroll) {
			document.body.style.overflow = 'auto';
		}
	};

	private preventTabEnter = (e) => {
		if (
			e.keyCode === KeyboardKeyCodes.enter
            || e.keyCode === KeyboardKeyCodes.tab
		) {
			e.preventDefault();
		}
	};

	private enableTabEnter = (): void => {
		document.removeEventListener('keydown', this.preventTabEnter);
	};

	private disableTabEnter = (): void => {
		document.addEventListener('keydown', this.preventTabEnter);
	};
}

import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Container } from '../../../symphony';

export const LocalizedLink: React.FC<LinkProps> = React.forwardRef(({
	children,
	...restProps
}, ref) => {
	const activeLanguage = typeof window === 'undefined'
		? Container.take('global', 'activeLanguage')
		: window.location.pathname?.split('/')?.[1] || 'en';

	let newUrl = typeof restProps?.to !== 'string'
		|| restProps?.to?.includes('http://')
		|| restProps?.to?.includes('https://')
		|| restProps?.to?.includes('../')
		? restProps.to
		: `/${activeLanguage}${restProps.to}`;

	if (
		typeof restProps?.to === 'string'
		&& restProps?.to?.split('/')?.[1] === activeLanguage
	) {
		newUrl = restProps?.to;
	}

	return (
		<Link {...restProps} to={newUrl}>
			{children}
		</Link>
	);
});

import { SlIcon } from "@sololearnorg/icons";
import React, { FC } from "react";
import { i18n } from "../../../../../symphony";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './HeaderRating.scss';

type Props = {
	text: string;
};

export const HeaderRating: FC<Props> = ({ text }) => {
	useStyles(s);
	return <div className="header-rating">
		<div className="header-rating-stars">
			<SlIcon iconId="stars" />
		</div>
		<p className="header-rating-text">{i18n.t(text)}</p>
	</div>;
};

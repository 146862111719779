import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, history } from '../../features/symphony';

export function usePreviousPath() {
	const { pathname } = useLocation();

	const prevPath = useRef(pathname);

	useEffect(() => {
		history.listen((data) => {
			const pathname = data.location.pathname;
			if (pathname !== prevPath.current) {
				Container.register('global', 'previousPath', prevPath.current);
				prevPath.current = pathname;
			}
		});
	}, []);

	return null;
}

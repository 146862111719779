import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { SlIcon, useIcons, heart } from '@sololearnorg/icons';

import s from './SlUserHeart.scss';

export interface IUserHeartProps {
    heartsCount: number;
    onClick: () => void;
    disabled?: boolean;
}

export const SlUserHeart: React.FC<IUserHeartProps> = React.memo(
	({ heartsCount, onClick, disabled = false }) => {
		useStyles(s);
		useIcons([heart]);

		return heartsCount !== null ? (
			<div className="sl-user-heart__heart" sl-test-data="cmpUserHeart">
				<button
					type="button"
					className="sl-user-heart__heart-button"
					sl-test-data="heartButton"
					onClick={onClick}
					disabled={disabled}
				>
					<span
						className={`sl-user-heart__heart-icon ${heartsCount === 0
							? 'sl-user-heart__heart-icon-zero'
							: ''
						}`}
					>
						<SlIcon iconId="heart" />
					</span>
					<span
						className={`sl-user-heart__heart-count ${heartsCount === 0 ? '-zero' : ''
						}`}
					>
						{heartsCount}
					</span>
				</button>
			</div>
		) : null;
	},
);

import React, { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, i18n } from '../../../../symphony';

import { LoginForm } from '../../components/forms/LoginForm/LoginForm';
import { Divider } from '../../components/global/Divider/Divider';
import { Error } from '../../components/global/Error/Error';
import { SocialLogin } from '../SocialLogin/SocialLogin';
import { SwitchPageLink } from '../../components/shared/SwitchPageLink/SwitchPageLink';
import { SlLoginErrorsService } from '../../services/sl-login-errors.service';
import { SlLoginContext } from '../../login-context';
import { SlLoginLoadingService } from '../../services/sl-login-loading.service';
import { SlLoginTrackingService } from '../../services/sl-login-tracking.service';
import { SlLoginViewService } from '../../services/sl-login-view.service';

import s from './Login.scss';

interface ILoginProps {
	darkMode?: boolean;
	title?: string;
	primaryColor?: string;
}
export const Login: React.FC<ILoginProps> = React.memo(({ darkMode, title, primaryColor }) => {
	useStyles(s);
	const { errors$ } = Container.take(SlLoginContext, SlLoginErrorsService);
	const { loading$ } = Container.take(SlLoginContext, SlLoginLoadingService);
	const { trackAction$ } = Container.take(
		SlLoginContext,
		SlLoginTrackingService,
	);
	const trackingPrefix: string = Container.take(
		SlLoginContext,
		'trackingPrefix',
	);
	const loginViewService = Container.take(SlLoginContext, SlLoginViewService);
	const isTitleH1 = Container.take(SlLoginContext, 'isTitleH1');

	const [error, setError] = useState<string>(null);
	const [isLoading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		loading$.pipe(takeUntil(unsubscriber)).subscribe(setLoading);
		errors$.pipe(takeUntil(unsubscriber)).subscribe(setError);

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const handleOnBeforeViewChange = () => {
		errors$.next(null);
		loginViewService.setSignupCurrentView();
		trackAction$.next({
			actionName: 'click',
			element: `${trackingPrefix}_newsignin_createaccount`,
		});
	};

	return (
		<div className="sl-login-login">
			{isTitleH1 ? (
				<h1
					className={`sl-login-login__title${
						darkMode ? ' dark-mode' : ''
					}`}
				>
					{title || i18n.t('web-auth.signin-title')}
				</h1>
			) : (
				<h2
					className={`sl-login-login__title${
						darkMode ? ' dark-mode' : ''
					}`}
				>
					{title || i18n.t('web-auth.signin-title')}
				</h2>
			)}
			{error && <Error errorText={error} />}
			<LoginForm darkMode={darkMode} primaryColor={primaryColor} />
			<Divider />
			<SocialLogin type="login" darkMode={darkMode} />

			<div className="sl-login-login__switch-link">
				<SwitchPageLink
					isLoading={isLoading}
					page="login"
					onBeforeViewChange={handleOnBeforeViewChange}
					primaryColor={primaryColor}
				/>
			</div>
		</div>
	);
});

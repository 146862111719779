import React from 'react';

import {
	Container, ModalService, StorageService, TrackingEventNames, TrackingService,
} from '../../features/symphony';

import { SlModalIds } from '../shared/constants';
import { SlStorageKeys } from '../../features/shared/public/globalInterfaces/globalInterfaces';
import { SlBlockerModal } from '../../features/shared/public/SlBlockerModal/SlBlockerModal';
import { IPopUpData, PopUpDataKeys } from '../../features/api/public/abTesting-api';
import { IUserSettingsApi, UserSettingsApi, AppSettingKeys } from '../../features/api/public/user-settings-api';

export interface TermAndConditionProps {
	staticData: IPopUpData;
	version: number;
	htmlContent: string;
}

export const TermAndCondition: React.FC<TermAndConditionProps> = ({ staticData, version, htmlContent }): JSX.Element => {
	const onAccept = (): void => {
		const modalService = Container.take('global', ModalService);
		const storageService = Container.take('global', StorageService);
		const { tracking$ }: any = Container.take('global', TrackingService);
		const hardBlocker: {
			[key: string]: IPopUpData
		} = storageService.load(SlStorageKeys.hardBlocker, 'session');

		const userSettings: IUserSettingsApi = new UserSettingsApi();
		modalService.close(SlModalIds.termsAndConditionModal);

		storageService.save(SlStorageKeys.hardBlocker, {
			...hardBlocker,
			termOfConditions: version,
		}, 'session');
		const updatedData = {
			version,
			timestamp: Math.floor(Date.now() / 1000),
		};
		userSettings.updateUserSettings([
			{
				key: AppSettingKeys.termsAndConditionsVersion,
				value: JSON.stringify(updatedData),
			},
		]).subscribe();
		tracking$.next({
			eventName: TrackingEventNames.slTermOfConditionsAccept,
			eventParams: {
				action: 'click',
			},
		});
	};

	return staticData ? (
		<SlBlockerModal
			onAccept={onAccept}
			title={staticData.header}
			subtitle={staticData.body}
			pageContent={htmlContent}
			actionButtonText={staticData?.[PopUpDataKeys.acceptButtonText]}
		/>
	) : null;
};

import React, { ReactNode } from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../../symphony';

import { SlActions } from '../../services/ActionsService/sl-actions';
import { SlNavigationTrackingService } from '../../services/sl-navigation-tracking.service';
import {
	ClickElementTypeId,
	ActionType,
	RequestType,
} from '../../global-interface';
import { SlNavigationContext } from '../../global-constants';

import s from './SlFooterSocial.scss';
import { MobileStore, SlMobileStores } from '../../../../shared/public/SlMobileStores/SlMobileStores';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';

export interface ILinkConfigs {
	id: number;
	link: string;
	icon: ReactNode;
	text?: string;
	color?: string;
	trackElement?: string;
}

export interface ISlFooterSocialProps {
	socialLinks: ILinkConfigs[];
}

export const SlFooterSocial: React.FC<ISlFooterSocialProps> = React.memo(
	({ socialLinks }) => {
		useStyles(s);
		const { footerTrackAction$ } = Container.take(
			SlNavigationContext,
			SlNavigationTrackingService,
		);

		const { actions$ } = Container.take(SlNavigationContext, SlActions);

		const onSocialLinksClick = (element: string): void => {
			actions$.next({
				type: ActionType.TrackFooterElementsClick,
				requestType: RequestType.Parallel,
				data: {
					alias: element,
					elementType: ClickElementTypeId.SocialMedia,
				},
			});

			footerTrackAction$.next({
				action: 'click',
				element: `footer_${element}`,
				entityId: null,
			});
		};

		const onStoresLinksClick = (store: MobileStore): void => {
			const storeMap = {
				[MobileStore.AppStore]: 'appstore',
				[MobileStore.PlayMarket]: 'googleplay',
			};

			actions$.next({
				type: ActionType.TrackFooterElementsClick,
				requestType: RequestType.Parallel,
				data: {
					alias: storeMap[store],
					elementType: ClickElementTypeId.Mobile,
				},
			});

			footerTrackAction$.next({
				action: 'click',
				element: `footer_${storeMap[store]}`,
				entityId: null,
			});
		};

		return (
			<div className="sl-social-links" sl-test-data="cmpSlFooterSocial">
				<div className="sl-social-links__container">
					<div className="sl-social-links__links-block">
						{socialLinks.map((el) => (
							<LocalizedLink
								className="sl-social-links__item"
								sl-test-data={`lnkSocialLink_${el.id}`}
								key={el.id}
								to={el.link}
								target="_blank"
								onClick={() => onSocialLinksClick(el.trackElement)}
							>
								<div
									className="sl-social-links__icon"
									style={
										el.color ? { color: el.color } : null
									}
								>
									{el.icon}
								</div>
								{el.text && (
									<span className="sl-social-links__text">
										{el.text}
									</span>
								)}
							</LocalizedLink>
						))}
					</div>
					<div className="sl-social-links__app-block">
						<SlMobileStores
							onStoreClick={onStoresLinksClick}
							classNames={{
								appStore: 'sl-social-links__item--app',
								playMarket: 'sl-social-links__item--app',
							}}
						/>
					</div>
				</div>
			</div>
		);
	},
);

import React, { FC, useEffect } from "react";
import s from "./Pricing.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container } from "../../symphony";
import { PricingService } from "../private/pricing.service";

type Props = {};

export const Pricing: FC<Props> = () => {
	useStyles(s);

	const { showPricingPaywall, closePricingPaywall } = Container.take('global', PricingService);

	useEffect(() => {
		showPricingPaywall();
		return () => {
			setTimeout(() => {
				closePricingPaywall();
			}, 0);
		};
	}, []);

	return null;
};
import { TrackingObjType } from '../../../public/tracking/tracking.interface';
import { Tracking } from '../tracking';
import { ITracking } from '../tracking.interface';
import { fbPixelEventsMapping } from './fbPixelEventsMapping';

declare const fbq: (
	trackCustom: 'trackCustom',
	eventName: string,
	params: any
) => void;

export class FBPixelTracking extends Tracking implements ITracking {
	constructor() {
		super(fbPixelEventsMapping);
	}

	public handleTracking = (trackingObj: TrackingObjType): void => {
		if (typeof fbq === 'undefined') return;

		const { params, eventName } = this.getEventParams(trackingObj);

		if (!eventName) return;

		fbq('trackCustom', eventName, params);
	};
}

import { firstValueFrom } from "rxjs";
import { SlPaymentApiService } from "../../../payments/private/services/sl-payment-api.service";
import { AuthService, Container, Service, SubscriptionPlanService, i18n } from "../../../symphony";
import { CheckoutStore } from "../checkout.store";
import { CardFields, LongPaywallProduct, LongPaywallProductMeta, PaymentMethods } from "../paywalls.constants";
import { Client, create } from "braintree-web/client";
import { HostedFields, HostedFieldsHostedFieldsFieldName, HostedFieldsTokenizePayload, create as createHostedFields } from "braintree-web/hosted-fields";
import { DataCollector, create as createDataCollector } from "braintree-web/data-collector";
import { PayPalCheckout, create as createPaypalCheckout } from 'braintree-web/paypal-checkout';
import { ApplePay, create as createApplePay } from "braintree-web/apple-pay";
import { IPaymentConfirmPayload } from "../../../api/public/payment-api";
import { FlowType } from "paypal-checkout-components";
import { PaywallsService } from "./paywalls.service";
import { PaymentProduct, SubscriptionApi } from "../../../api/public/subscription-api";

type PayPalSDK = {
	Buttons: (options: unknown) => { render: (selector: string) => unknown; };
	FUNDING: {
		PAYPAL: 'paypal';
	};
};

@Service()
export class CheckoutService {
	private store = Container.take('paywalls', CheckoutStore);
	private authService = Container.take('global', AuthService);
	private api = Container.take('global', SlPaymentApiService);
	private paywallService = Container.take('paywalls', PaywallsService);
	private subscriptionApi = Container.take('global', SubscriptionApi);
	private subscriptionPlanService = Container.take('global', SubscriptionPlanService);
	private deviceData = { card: null, paypal: null, applepay: null };
	private gatewayToken: string;
	private clientInstance: Client;
	private hostedFieldsInstance: HostedFields;
	private paypalCheckoutInstance: PayPalCheckout;
	private applePayInstance: ApplePay;
	private applePaySession: any;

	public initCard = async () => {
		await this.getGatewayToken();
		await this.createBraintreeClient();
		this.hostedFieldsInstance = await createHostedFields({
			client: this.clientInstance,
			fields: {
				number: {
					container: "#card-number",
					placeholder: '1111 2222 3333 4444',
					supportedCardBrands: {
						visa: true,
						mastercard: true,
						"american-express": true,
						"diners-club": true,
						discover: true
					}
				},
				cvv: {
					container: '#cvv',
					placeholder: '***',
					type: 'password',
				},
				expirationDate: {
					container: '#expiration-date',
					placeholder: 'MM/YY',
				},
				postalCode: {
					container: '#postal-code',
					placeholder: '0020',
				},
			},
			styles: {
				input: {
					'font-size': '16px',
					color: '#6B7F99',
					padding: '16px 13px',
					height: '48px',
				},
				'.number': {
					'font-family': 'monospace',
				},
				':focus': {
					color: '#6B7F99',
				},
				'.invalid': {
					color: '#f35843',
				},
				'::placeholder': {
					color: '#989da4'
				}
			},
		});
		const dataCollector: DataCollector = await createDataCollector({ client: this.clientInstance });
		if (dataCollector) this.deviceData.card = dataCollector.deviceData;
		this.store.cardInitialized.next(true);
	};

	public initPaypal = async () => {
		await this.getGatewayToken();
		await this.createBraintreeClient();
		const dataCollector: DataCollector = await createDataCollector({ client: this.clientInstance, paypal: true });
		this.deviceData.paypal = dataCollector.deviceData;
		this.paypalCheckoutInstance = await createPaypalCheckout({ client: this.clientInstance });
		await this.paypalCheckoutInstance.loadPayPalSDK({ vault: true, intent: 'capture' });
		const paypalSDK: PayPalSDK = (window.paypal as unknown) as PayPalSDK;
		paypalSDK.Buttons({
			fundingSource: paypalSDK.FUNDING.PAYPAL,
			style: {
				height: 48,
				shape: 'rect',
				borderRadius: 4,
				tagline: false,
				color: 'gold',
				label: 'paypal',
			},
			createBillingAgreement: () => {
				return this.paypalCheckoutInstance.createPayment({
					flow: 'vault' as FlowType,
					billingAgreementDescription: 'Sololearn subscription payment'
				});
			},
			onApprove: (data) => {
				return this.paypalCheckoutInstance.tokenizePayment(data, this.submitPaypalPayment);
			}
		}).render('#paypal-button');
		this.store.paypalInitialized.next(true);
	};

	public initApplePay = async () => {
		const applePaySession: any = (window as any)?.ApplePaySession;
		if (applePaySession && applePaySession.supportsVersion(4) && applePaySession.canMakePayments()) {
			this.store.supportsApplePay.next(true);
			await this.getGatewayToken();
			await this.createBraintreeClient();
			this.applePayInstance = await createApplePay({
				client: this.clientInstance
			});
			const dataCollector: DataCollector = await createDataCollector({ client: this.clientInstance });
			if (dataCollector) this.deviceData.applepay = dataCollector.deviceData;
			this.store.applePayInitialized.next(true);
		}
	};

	public makeApplePayPayment = async () => {
		this.setProcessing();
		const paymentRequest = this.applePayInstance.createPaymentRequest({
			total: {
				label: this.store.selectedPaymentProduct.value.name,
				amount: this.store.selectedPaymentProduct.value.discountedAmount.toString()
			}
		});
		this.applePaySession = new (window as any).ApplePaySession(4, paymentRequest);
		this.applePaySession.onvalidatemerchant = async (event) => {
			try {
				const merchantSession = await this.applePayInstance.performValidation({
					validationURL: event.validationURL,
					displayName: 'Sololearn'
				});
				this.applePaySession.completeMerchantValidation(merchantSession);
			} catch (err) {
				console.log(err);
				this.onSubscriptionError();
				this.applePaySession.abort();
				return;
			}
		};
		this.applePaySession.onpaymentauthorized = async (event) => {
			try {
				await this.applePayInstance.tokenize({ token: event.payment.token }, this.submitApplePayPayment);
			} catch (err) {
				this.onSubscriptionError();
				return;
			}
		};
		this.applePaySession.oncancel = async () => {
			this.enableCardFields();
			this.store.transactionInProcess.next(false);
		};
		this.applePaySession.begin();
	};

	public changeZipCodeRequired = (isRequired: boolean) => {
		this.store.zipCodeRequired.next(isRequired);
	};

	public setSelectedProductKey = (productKey: string) => {
		this.store.selectedProductKey.next(productKey);
	};

	public setSelectedProduct = (product: PaymentProduct) => {
		this.store.selectedPaymentProduct.next(product);
	};

	public clearCardErrors = () => {
		this.store.cardError.next(null);
	};

	public submitCardPayment = async () => {
		this.setProcessing();
		const fieldsToTokenize = this.store.zipCodeRequired.value
			? CardFields
			: CardFields.filter(f => f !== 'postalCode');
		//@ts-ignore
		try {
			// @ts-ignore
			const result: HostedFieldsTokenizePayload = await this.hostedFieldsInstance.tokenize({ fieldsToTokenize });
			this.processCardPayment(result);
		} catch (err) {
			let errorMessage: string;
			switch (err.code) {
				case 'HOSTED_FIELDS_FIELDS_EMPTY':
					errorMessage = i18n.t('paywalls.web.card-error.fields-empty');
					break;
				case 'HOSTED_FIELDS_FIELDS_INVALID':
					errorMessage = i18n.t('paywalls.web.card-error.some-fields-empty-invalid');
					break;
				case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
					errorMessage = i18n.t('paywalls.web.card-error.cvv-invalid');
					break;
				case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
					errorMessage = i18n.t('paywalls.web.card-error.card-invalid');
					break;
				case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
					errorMessage = i18n.t('paywalls.web.card-error.network-issue');
					break;
				default:
					errorMessage = i18n.t('paywalls.web.card-error.other-issues');
			}
			this.onSubscriptionError(errorMessage);

		}
	};

	public processCardPayment = async (tokenizePayload: HostedFieldsTokenizePayload) => {
		const payload: IPaymentConfirmPayload = {
			nonce: tokenizePayload.nonce,
			method: 'card',
			deviceData: this.deviceData.card,
			productKey: this.store.selectedProductKey.value,
			userId: this.authService.getUser().id
		};
		try {
			const subscriptionResult = await firstValueFrom(this.api.subscribe(payload));
			if (!subscriptionResult || !subscriptionResult.success) {
				throw subscriptionResult;
			}
			this.onSubscriptionSuccess();
		} catch (err) {
			this.onSubscriptionError();
		}
	};

	public selectPaymentMethod = (method: PaymentMethods) => {
		this.store.selectedPaymentMethod.next(method);
	};

	public defineAvailableProducts = (metaProducts: LongPaywallProductMeta[], products: PaymentProduct[]) => {
		const availableProducts: LongPaywallProduct[] = metaProducts.map(meta => {
			return {
				meta,
				product: products.find(p => p.key === meta.key)
			};
		});
		this.store.availableProducts.next(availableProducts);
	};

	public enableLongPaywallCheckout = () => {
		this.store.isLongPaywallCheckout.next(true);
	};

	public disableLongPaywallCheckout = () => {
		this.store.isLongPaywallCheckout.next(false);
	}

	private submitPaypalPayment = async (err, paypalResponse) => {
		if (err) {
			this.onSubscriptionError();
			return;
		}
		this.setProcessing();
		const payload: IPaymentConfirmPayload = {
			nonce: paypalResponse.nonce,
			method: 'paypal',
			deviceData: this.deviceData.paypal,
			productKey: this.store.selectedProductKey.value,
			userId: this.authService.getUser().id
		};
		try {
			const subscriptionResult = await firstValueFrom(this.api.subscribe(payload));
			if (!subscriptionResult || !subscriptionResult.success) {
				throw subscriptionResult;
			}
			this.onSubscriptionSuccess();
		} catch (error) {
			this.onSubscriptionError();
		}
	};

	private submitApplePayPayment = async (err, applePayResponse) => {
		if (err) {
			this.applePaySession.completePayment((window as any).ApplePaySession.STATUS_FAILURE);
			this.onSubscriptionError();
			return;
		}
		const payload: IPaymentConfirmPayload = {
			nonce: applePayResponse.nonce,
			method: 'applepay',
			deviceData: this.deviceData.applepay,
			productKey: this.store.selectedProductKey.value,
			userId: this.authService.getUser().id
		};
		try {
			const subscriptionResult = await firstValueFrom(this.api.subscribe(payload));
			if (!subscriptionResult || !subscriptionResult.success) {
				throw subscriptionResult;
			}
			this.applePaySession.completePayment((window as any).ApplePaySession.STATUS_SUCCESS);
			this.onSubscriptionSuccess();
		} catch (err) {
			this.applePaySession.completePayment((window as any).ApplePaySession.STATUS_FAILURE);
			this.onSubscriptionError();
		}
	};

	private setProcessing = () => {
		this.store.cardError.next(null);
		this.store.transactionInProcess.next(true);
		this.disableCardFields();
	};

	private onSubscriptionSuccess = async () => {
		this.store.transactionInProcess.next(false);
		this.store.cardError.next(null);
		this.enableCardFields();
		const newSubscriptionConfig = await firstValueFrom(this.subscriptionApi.getSubscriptionPlan());
		this.subscriptionPlanService.subscriptionPlanConfigs.next(newSubscriptionConfig);
		if (this.store.isLongPaywallCheckout.value) {
			this.store.checkoutCompleted.next(true);
		} else {
			this.paywallService.navigateToCongrats();
		}
	};

	private onSubscriptionError = (errorMessage?: string) => {
		this.store.cardError.next(errorMessage || i18n.t('paywalls.web.card-error.other-issues'));
		this.enableCardFields();
		this.store.transactionInProcess.next(false);
	};

	private getGatewayToken = async () => {
		if (!this.gatewayToken) {
			const gatewayTokenResponse = await firstValueFrom(this.api.gatewayTokenStream());
			this.gatewayToken = gatewayTokenResponse.data.token;
		}
	};

	private createBraintreeClient = async () => {
		if (!this.clientInstance) {
			this.clientInstance = await create({ authorization: this.gatewayToken });
		}
	};

	private disableCardFields = () => {
		['number', 'cvv', 'expirationDate', 'postalCode'].forEach(field => {
			this.hostedFieldsInstance.setAttribute({
				field: field as HostedFieldsHostedFieldsFieldName,
				attribute: 'disabled',
				value: true
			});
		});
	};

	private enableCardFields = () => {
		['number', 'cvv', 'expirationDate', 'postalCode'].forEach(field => {
			this.hostedFieldsInstance.removeAttribute({
				field: field as HostedFieldsHostedFieldsFieldName,
				attribute: 'disabled'
			});
		});
	};
};

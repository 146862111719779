import { TrackingEventNames } from '../../../public/tracking/trackingEventNames';
import { TrackingEventsMappingType } from '../tracking.interface';

export const fbPixelEventsMapping: TrackingEventsMappingType = {
	[TrackingEventNames.trialStarted]: {
		eventName: 'StartTrial',
		params: [],
	},
	[TrackingEventNames.subscribe]: {
		eventName: 'DirectSubscription',
		params: [],
	},
	[TrackingEventNames.subscribedAfterTrial]: {
		eventName: 'SubscribeAfterTrial',
		params: [],
	},
};

import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaywallHeader.scss';
import { SlIcon } from "@sololearnorg/icons";
import { Container } from "../../../../../symphony";
import { PaywallsService } from "../../../services/paywalls.service";

type Props = {
	version?: 1 | 2;
};
export const PaywallHeader: FC<Props> = ({ version = 1 }) => {
	useStyles(s);

	const { closePaywall } = Container.take('paywalls', PaywallsService);

	const logo = <div className="paywalls-logo">
		<SlIcon iconId="sololearnMono" />
	</div>;
	const closeButton = <div className="paywalls-close" onClick={() => closePaywall()}>
		<SlIcon iconId="close" />
	</div>;
	const proIcon = <div className="paywalls-pro-icon">PRO</div>;

	const getComponentsByVersion = () => {
		switch (version) {
			case 1:
				return <>{logo}{closeButton}</>;
			case 2:
				return <>{closeButton}{proIcon}</>;
		}
	};
	return <div className="paywalls-header">
		{getComponentsByVersion()}
	</div>;
};
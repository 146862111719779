import React from 'react';

export const IconBit = () => (
	<svg
		width="14"
		height="16"
		viewBox="0 0 14 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1143_5011)">
			<mask
				id="mask0_1143_5011"
				style={{ maskType: 'alpha' }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="14"
				height="16"
			>
				<path
					d="M13.1765 4.85708C13.1765 4.18158 12.8145 3.55788 12.228 3.22274L7.52214 0.533662C6.94345 0.202978 6.23302 0.202978 5.65433 0.533662L0.948444 3.22274C0.36195 3.55788 0 4.18158 0 4.85708V11.1429C0 11.8184 0.36195 12.4421 0.948445 12.7773L5.65433 15.4663C6.23302 15.797 6.94345 15.797 7.52214 15.4663L12.228 12.7773C12.8145 12.4421 13.1765 11.8184 13.1765 11.1429L13.1765 4.85708Z"
					fill="#964DD5"
				/>
			</mask>
			<g mask="url(#mask0_1143_5011)">
				<path
					d="M13.1765 3.76471L6.58824 0L0 3.76471V12.2353L6.58824 16L13.1765 12.2353L13.1765 3.76471Z"
					fill="#8153E1"
				/>
				<path
					d="M13.1761 12.2352L6.58789 15.9999L6.58789 7.52935L13.1761 3.76465L13.1761 12.2352Z"
					fill="#AB88F3"
				/>
				<path
					d="M6.58755 7.52941L13.1758 3.76471L6.58755 -2.87981e-07L-0.000689671 3.7647L6.58755 7.52941Z"
					fill="#D4CCF5"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_1143_5011">
				<rect width="13.1765" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

import React, { FC, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaywallProduct.scss';
import { Container } from "../../../../../symphony";
import { PaywallsService } from "../../../services/paywalls.service";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../../paywalls.store";
import { PaymentProduct } from "../../../../../api/public/subscription-api";
import { i18n } from "../../../../../symphony";
import { injectPrice } from "../../../paywalls.utils";

type PaywallProductProps = {
	product: {
		productId: string;
		defaultSelected: boolean;
		badge: string;
		badgeBackgroundColor: string;
		badgeTextColor: string;
		name: string;
		description: string;
		selectedBorderColor: string;
		selectedBackgroundColor: string;
		offerProductId: string;
		customCTAText: string;
	};
};

export const PaywallProduct: FC<PaywallProductProps> = ({
	product
}) => {
	useStyles(s);

	const {
		selectedProductKey,
		paymentProducts
	} = useContainerData('paywalls', PaywallsStore, [
		'selectedProductKey',
		'paymentProducts'
	]);
	const { selectProduct } = Container.take('paywalls', PaywallsService);
	const [productDetails, setProductDetails] = useState<PaymentProduct>(null);

	useEffect(() => {
		if (product.defaultSelected && (!selectedProductKey || selectedProductKey !== product.productId)) {
			selectProduct({
				productId: product.productId,
				offerId: product.offerProductId,
				customCTAText: product.customCTAText
			});
		}
		setProductDetails(paymentProducts.find(p => product.productId === p.key));
	}, [product]);

	const monthlyPrice = () => {
		const price = productDetails?.discountedAmount === productDetails?.amount
			? productDetails?.amount : productDetails?.discountedAmount;
		return (price / productDetails?.period)?.toFixed(2);
	};

	const fullPrice = () => {
		const price = productDetails?.discountedAmount === productDetails?.amount
			? productDetails?.amount : productDetails?.discountedAmount;
		return (price)?.toFixed(2);
	};

	return <div
		onClick={() => selectProduct({
			productId: product.productId,
			offerId: product.offerProductId,
			customCTAText: product.customCTAText
		})}
		className={`paywall-product ${selectedProductKey === product.productId ? 'selected' : ''}`}
		style={selectedProductKey === product.productId ? {
			outlineColor: product.selectedBorderColor,
			backgroundColor: product.selectedBackgroundColor
		} : {}}
	>
		{product.badge && <div className="paywall-product-badge-container">
			<p
				className="paywall-product-badge"
				style={{
					backgroundColor: product.badgeBackgroundColor,
					color: product.badgeTextColor
				}}
			>{i18n.t(product.badge)}</p>
		</div>}
		<div className="paywall-product-details">
			<div className="paywall-product-names">
				<span className="paywall-product-title">{i18n.t(product.name)}</span>
				<span className="paywall-product-description">{injectPrice(fullPrice(), i18n.t(product.description))}</span>
			</div>
			<p className="paywall-product-price">
				{injectPrice(monthlyPrice(), i18n.t('paywalls.long-flow.view-all-plans-bottom-sheet.price.month'))}
			</p>
		</div>
	</div>;
};

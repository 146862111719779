import { BehaviorSubject } from 'rxjs';

import { Service } from '../../../../symphony';

import { IAction } from '../../global-interface';

@Service()
export class SlActions {
	public actions$ = new BehaviorSubject<IAction>(null);
}

import { Service } from '../../../symphony';
import { getApiHeaders } from '../utils/get-api-headers';

@Service()
export class SlFetchInstanceService {
	constructor() {
		this.initRequestInterceptor = this.initRequestInterceptor.bind(this);
	}

	private initRequestInterceptor(
		url: string,
		options: RequestInit = {},
	): RequestInit {
		const newOptions = { ...options };
		const headers = getApiHeaders();

		return {
			...newOptions,
			headers: new Headers({ ...options.headers, ...headers }),
		};
	}

	public async fetch(url: string, options: RequestInit = {}): Promise<any> {
		const config = this.initRequestInterceptor(url, options);
		const response = await fetch(url, config);
		return response;
	}
}

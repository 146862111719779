import React from 'react';

const i18n = {
	localizationData: {},
	language: 'en',
	pluralRules: {},
	init(localizationData: Record<string, string>, locale = 'en') {
		this.localizationData = localizationData;
		this.language = locale;
		this.pluralRules[locale] = new Intl.PluralRules(locale);
	},
	t(key: string, variables: Record<string, string | number> = {}) {
		let translationKey = key;
		if (typeof variables?.count === 'number') {
			const rule = this.pluralRules[this.language].select(
				variables.count,
			);
			if (this.localizationData[`${translationKey}_${rule}`]) {
				translationKey += `_${rule}`;
			}
		}

		let translation = this.localizationData[translationKey] || key;
		Object.keys(variables).forEach((varKey) => {
			translation = translation.replace(
				new RegExp(`\\{\\{${varKey}\\}\\}`, 'g'),
				variables[varKey],
			);
		});
		return translation;
	},
};

function Trans({ i18nKey, components }) {
	const translation = i18n.t(i18nKey);
	const regex = /<(\d+)>(.*?)<\/\1>/g;
	const parts: any = [];
	let lastIndex = 0;

	translation.replace(regex, (match, p1, p2, offset) => {
		parts.push(translation.slice(lastIndex, offset));
		parts.push(
			React.cloneElement(components[parseInt(p1, 10)], { key: p1 }, p2),
		);
		lastIndex = offset + match.length;
	});

	parts.push(translation.slice(lastIndex));

	return parts;
}

export { i18n, Trans };

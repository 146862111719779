import React, { FC } from "react";
import { i18n } from "../../../../../symphony";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaywallTitle.scss';

type PaywallTitleProps = {
	text: string;
};

export const PaywallTitle: FC<PaywallTitleProps> = ({
	text
}) => {
	useStyles(s);
	return <h2 className="paywall-title">
		{i18n.t(text)}
	</h2>;
};

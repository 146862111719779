import React, { useEffect, useState } from 'react';

import { IconChevronRightSmall } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, i18n } from '../../../../symphony';

import { SlActions } from '../../services/ActionsService/sl-actions';
import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlNavigationContext } from '../../global-constants';
import {
	ActionType,
	RequestType,
	ICourseCatalogItem,
} from '../../global-interface';

import s from './CoursesCatalog.scss';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';
import { SlLoader } from '../../../../shared/public/SlLoader/SlLoader';

export function CoursesCatalog(): JSX.Element {
	useStyles(s);
	const { coursesCatalog$: coursesCatalog } = useContainerData(
		SlNavigationContext,
		SlNavigationDataService,
		['coursesCatalog$'],
	);

	const [firstTabItem, setFirstTabItem] = useState<ICourseCatalogItem>(
		coursesCatalog?.items ? coursesCatalog.items[0] : null,
	);

	const [secondTabItem, setSecondTabItem] = useState<ICourseCatalogItem>(
		firstTabItem && firstTabItem.items[0].items
			? firstTabItem.items[0]
			: null,
	);

	useEffect(() => {
		const newFirstTabItem = coursesCatalog?.items
			? coursesCatalog.items[0]
			: null;
		setFirstTabItem(newFirstTabItem);
		setSecondTabItem(
			newFirstTabItem && newFirstTabItem.items[0].items
				? newFirstTabItem.items[0]
				: null,
		);
	}, [coursesCatalog]);

	const { actions$ } = Container.take(SlNavigationContext, SlActions);

	const setFirstTab = (firstTabTitle: string): void => {
		const firstTab = firstTabTitle
			? coursesCatalog.items.find(({ title }) => title === firstTabTitle)
			: null;
		const secondTabFirstItem = firstTab.items[0];
		const secondItem = secondTabFirstItem.items?.length
			? secondTabFirstItem
			: null;

		setFirstTabItem(firstTab);
		setSecondTabItem(secondItem);
	};

	const setSecondTab = (secondTabTitle: string): void => {
		const item = secondTabTitle
			? firstTabItem.items.find(({ title }) => title === secondTabTitle)
			: null;
		if (item?.items?.length) {
			setSecondTabItem(item);
		}
	};

	const onCatalogItemClick = (
		e: React.MouseEvent<HTMLLIElement, MouseEvent>,
	): void => {
		const id = e.currentTarget.id;
		if (id !== firstTabItem?.title) {
			setSecondTab(null);
		}
		setFirstTab(id);
	};

	const onCourseItemClick = (dropdownLevel: number, alias: string): void => {
		actions$.next({
			type: ActionType.OnCoursesItemClick,
			requestType: RequestType.Parallel,
			data: {
				dropdownLevel,
				alias,
			},
		});
	};

	const onSeeAllClick = (): void => {
		actions$.next({
			type: ActionType.OnCoursesSeeAllClick,
			requestType: RequestType.Parallel,
		});
	};

	const renderTab = (
		currentTabItem: ICourseCatalogItem,
		nextTabItem: ICourseCatalogItem,
		dropdownLevel: number,
		onTabClick: (tabTitle: string) => void,
	): React.JSX.Element => (currentTabItem ? (
		<div
			id={currentTabItem.title}
			className="sl-courses-catalog-dropdown__tab"
		>
			<ul>
				{currentTabItem.items.map((item) => (
					<li
						id={item.title}
						key={item.title}
						className={`sl-courses-catalog-dropdown__item${
							nextTabItem?.title === item.title
								? ' selected'
								: ''
						}`}
					>
						{item.courseData ? (
							<LocalizedLink
								to={item.courseData.path}
								onClick={() => onCourseItemClick(
									dropdownLevel,
									item.courseData.alias,
								)}
							>
								<span>{item.title}</span>
							</LocalizedLink>
						) : (
							<div onClick={() => onTabClick(item.title)}>
								<span>{item.title}</span>
								<IconChevronRightSmall />
							</div>
						)}
					</li>
				))}
			</ul>
		</div>
	) : null);

	return (
		<div
			sl-test-data="cmpCoursesCatalog"
			className="sl-courses-catalog-dropdown"
		>
			{coursesCatalog ? (
				<>
					<div className="sl-courses-catalog-dropdown__main">
						<div className="sl-courses-catalog-dropdown__catalog">
							<ul>
								{coursesCatalog.items.filter(({ items }) => items.length).map((item) => (
									<li
										onClick={onCatalogItemClick}
										id={item.title}
										key={item.title}
										className={`sl-courses-catalog-dropdown__item${
											firstTabItem?.title === item.title
												? ' selected'
												: ''
										}`}
									>
										<div>
											<span>{item.title}</span>
											<IconChevronRightSmall />
										</div>
									</li>
								))}
							</ul>
						</div>

						<div className="sl-courses-catalog-dropdown__expand">
							{renderTab(
								firstTabItem,
								secondTabItem,
								2,
								setSecondTab,
							)}
							{renderTab(secondTabItem, null, 3, null)}
						</div>
					</div>

					<div className="sl-courses-catalog-dropdown__footer">
						<LocalizedLink
							className="sl-courses-catalog-dropdown__footer__see-all"
							to="/learn"
							onClick={onSeeAllClick}
						>
							{i18n.t('web-navigation.view-full-catalog')}
						</LocalizedLink>
					</div>
				</>
			) : (
				<SlLoader backgroundColor="transparent" />
			)}
		</div>
	);
}

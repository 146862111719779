export type Environment = 'local' | 'dev' | 'preprod' | 'production';

export const getEnvironment = (): Environment => {
	if (typeof window === 'undefined') {
		let environment: Environment;
		if (process.argv.indexOf('--local') > -1) {
			environment = 'local';
		} else if (process.argv.indexOf('--dev') > -1) {
			environment = 'dev';
		} else if (process.argv.indexOf('--preprod') > -1) {
			environment = 'preprod';
		} else {
			environment = 'production';
		}

		return environment;
	}

	const { origin } = window.location;

	if (origin.includes('localhost')) {
		return 'local';
	}

	if (origin.includes('web-dev')) {
		return 'dev';
	}

	if (origin.includes('pre-prod')) {
		return 'preprod';
	}

	return 'production';
};

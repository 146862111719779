import React, { FC, RefObject, useEffect } from "react";
import s from "./LongPaywallAwards.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container, i18n } from "../../../../../../symphony";
import { AppleAwardForGenerativeAI, AppStoreEditorsChoice, FacebookAppOfTheYear, GooglePlayEditorsChoice, HolonIQAward } from "../../../ui/icons";
import { useInView } from "../../../../paywalls.utils";
import { PaywallsService } from "../../../../services/paywalls.service";

type Props = {
	data: {
		heading: string;
	};
	style: React.CSSProperties;
	sectionOrder: number;
};

export const LongPaywallAwards: FC<Props> = ({ data, style, sectionOrder }) => {
	useStyles(s);
	const { trackSectionView } = Container.take('paywalls', PaywallsService);
	const [ref, isInView] = useInView({
		threshold: 0.7
	});

	useEffect(() => {
		isInView && trackSectionView('longAwards', sectionOrder);
	}, [isInView]);

	return <div ref={ref as RefObject<HTMLDivElement>} style={style} className="awards">
		<h3>{i18n.t(data.heading)}</h3>
		<section>
			<AppleAwardForGenerativeAI />
			<GooglePlayEditorsChoice />
			<AppStoreEditorsChoice />
			<FacebookAppOfTheYear />
			<HolonIQAward />
		</section>
	</div>;
};
import React, { FC, useEffect } from "react";
import s from "./LongPaywallCheckout.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { SlIcon } from "@sololearnorg/icons";
import { MaxIcon, ProIcon } from "../../../ui/icons";
import { Select, Option } from '@sololearnorg/sol';
import { LongPaywallPaymentMethods } from "./LongPaywallPaymentMethods/LongPaywallPaymentMethods";
import { LongPaywallSummary } from "./LongPaywallSummary/LongPaywallSummary";
import { Container, i18n } from "../../../../../../symphony";
import { CheckoutService } from "../../../../services/checkout.service";
import { useContainerData } from "../../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../../checkout.store";
import { LongPaywallProductNames, PaywallClickTypes } from "../../../../paywalls.constants";
import { PaywallsService } from "../../../../services/paywalls.service";

type Props = {};

export const LongPaywallCheckout: FC<Props> = () => {
	useStyles(s);
	const { trackPaywallImpression, trackPaywallClick } = Container.take('paywalls', PaywallsService);
	const { setSelectedProductKey, setSelectedProduct } = Container.take('paywalls', CheckoutService);
	const {
		availableProducts,
		selectedProductKey
	} = useContainerData('paywalls', CheckoutStore, [
		'availableProducts',
		'selectedProductKey'
	]);

	useEffect(() => {
		trackPaywallImpression('CHECKOUT');
	}, []);

	useEffect(() => {
		if (availableProducts && selectedProductKey) {
			setSelectedProduct(availableProducts.find(p => p.product.key === selectedProductKey).product);
		}
	}, [availableProducts, selectedProductKey]);

	const getProductTitle = (name: LongPaywallProductNames) => {
		const names = {
			[LongPaywallProductNames.proMonthlyNoTrial]: <div className="checkout-product-select-option-title">Sololearn <ProIcon /> {i18n.t('web-paywall.long.product-period.monthly')}</div>,
			[LongPaywallProductNames.proAnnualNoTrial]: <div className="checkout-product-select-option-title">Sololearn <ProIcon /> {i18n.t('web-paywall.long.product-period.annual')}</div>,
			[LongPaywallProductNames.proAnnualTrial]: <div className="checkout-product-select-option-title">Sololearn <ProIcon /> {i18n.t('web-paywall.long.product-period.annual')}</div>,
			[LongPaywallProductNames.maxAnnualNoTrial]: <div className="checkout-product-select-option-title">Sololearn <MaxIcon /> {i18n.t('web-paywall.long.product-period.annual')}</div>,
			[LongPaywallProductNames.maxAnnualTrial]: <div className="checkout-product-select-option-title">Sololearn <MaxIcon /> {i18n.t('web-paywall.long.product-period.annual')}</div>
		};
		return names[name];
	};

	const onClose = () => {
		trackPaywallClick(PaywallClickTypes.close, null, 'CHECKOUT');
		setSelectedProductKey(null);
	};

	const onProductChange = (key: string) => {
		trackPaywallClick(PaywallClickTypes.productChange, key, 'CHECKOUT');
		setSelectedProductKey(key);
	};

	return availableProducts ? <div className="checkout">
		<div className="checkout-modal">
			<div onClick={onClose} className="checkout-close">
				<div><SlIcon iconId={'close'} /></div>
			</div>

			<div className="checkout-panel left">
				<h1 className="checkout-title desktop">{i18n.t('web-paywall.long.checkout.title')}</h1>
				<div className="checkout-payment-methods">
					<h6>{i18n.t('web-paywall.long.checkout.payment-method.title')}</h6>
					<LongPaywallPaymentMethods />
				</div>
			</div>
			<div className="checkout-panel right">
				<h1 className="checkout-title mobile">{i18n.t('web-paywall.long.checkout.title')}</h1>
				<Select
					className="checkout-product-select"
					onValueChange={(key: string) => onProductChange(key)}
					value={selectedProductKey}
					label={i18n.t('web-paywall.long.checkout.plan.title')}
				>
					<React.Fragment key=".0">
						{availableProducts?.map(p => <Option value={p.product.key} key={p.product.key}>
							<div className="checkout-product-select-option">
								{getProductTitle(p.meta.name)}
								{p.meta.secondaryTag && <div className="checkout-product-select-tag">{i18n.t(p.meta.secondaryTag)}</div>}
							</div>
						</Option>)}
					</React.Fragment>
				</Select>
				<LongPaywallSummary />
			</div>
		</div>
	</div> : null;
};

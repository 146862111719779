import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { Modal } from '@sololearnorg/sol';
import { HeartModalSource, i18n } from '../../../symphony';

import { HeartsInfo, IHeartsInfo } from './components/HeartsInfo/HeartsInfo';
import { ProSection, IProSection } from './components/ProSection/ProSection';
import {
	BitsSection,
	IBitsSection,
} from './components/BitsSection/BitsSection';

import s from './SlHearts.scss';

const getHeartsInfoData = (data: ISlHearts): IHeartsInfo => {
	const heartsInfo: IHeartsInfo = {
		source: data.source,
		heartsCount: data.heartsCount,
		maxHeartsCount: data.maxHeartsCount,
		hours: data.nextRefillHours,
		minutes: data.nextRefillMinutes,
		title: null,
		description: null,
	};

	if (data.heartsCount === -1) {
		heartsInfo.title = i18n.t('web-hearts.infinite-info-title');
		heartsInfo.description = data.source === HeartModalSource.AIInfiniteIconClick
			? i18n.t('web-hearts.ai-infinite-info-description')
			: i18n.t('web-hearts.infinite-info-description');
		heartsInfo.cta = [i18n.t('web-hearts.infinite-info-cta')];
		heartsInfo.onClick = data.closeBtnCallBack;
	} else if (data.heartsCount === 0) {
		heartsInfo.description = i18n.t('web-hearts.zero-description');
		if (
			data.source === HeartModalSource.SpentLastHeart
			|| data.source === HeartModalSource.SpentLastHeartInPassedLesson
			|| data.source === HeartModalSource.SpentLastHeartInLastQuiz
		) {
			heartsInfo.title = i18n.t('web-hearts.spent-last-heart-title');
			heartsInfo.description = i18n.t(
				'web-hearts.spent-last-heart-description',
			);
		} else if (data.source === HeartModalSource.LessonOpenFail) {
			heartsInfo.title = i18n.t('web-hearts.open-failed-title');
		} else {
			heartsInfo.title = i18n.t('web-hearts.zero-title');
		}
	} else {
		heartsInfo.description = i18n.t('web-hearts.info-description');
		heartsInfo.onClick = data.proBtnCallBack;
		heartsInfo.cta = [i18n.t('web-hearts.info-cta')];
		if (data.heartsCount === 1) {
			heartsInfo.title = i18n.t('web-hearts.count-info-for-1');
		} else if (data.heartsCount === 2) {
			heartsInfo.title = i18n.t('web-hearts.count-info-for-2');
		} else if (data.heartsCount === 3) {
			heartsInfo.title = i18n.t('web-hearts.count-info-for-3');
		} else {
			heartsInfo.title = i18n.t('web-hearts.count-info-for-more-than-3', {
				count: data.heartsCount,
			});
		}
	}

	return heartsInfo;
};

const getProSectionData = (data: ISlHearts): IProSection => {
	if (data.heartsCount !== 0) {
		return null;
	}
	const proSection: IProSection = {
		title: null,
		description: null,
		cta: null,
		onClick: data.proBtnCallBack,
	};

	proSection.title = i18n.t('web-hearts.was-user-pro-pro-section-title');
	proSection.description = [
		i18n.t('web-hearts.was-user-pro-pro-section-description'),
	];
	proSection.cta = [i18n.t('web-hearts.info-cta')];

	return proSection;
};

const getBitsSectionData = (data: ISlHearts): IBitsSection => ({
	bits: data.bits,
	price: data.price,
	onClick: data.refillBtnCallBack,
});

export interface ISlHearts {
	source?: HeartModalSource;
	heartsCount: number; // -1 for infinite
	maxHeartsCount: number;
	nextRefillHours?: number;
	nextRefillMinutes?: number;
	bits: number;
	price: number;
	proBtnCallBack: (from: 'course' | 'lesson', closeModal?: boolean) => void;
	refillBtnCallBack: () => void;
	closeBtnCallBack: () => void;
}
export const SlHearts: React.FC<ISlHearts> = React.memo((data: ISlHearts) => {
	useStyles(s);
	const heartsInfo = getHeartsInfoData(data);
	const proSection = getProSectionData(data);
	const bitsSection = getBitsSectionData(data);

	return (
		<Modal.Custom onClose={data.closeBtnCallBack} noPadding>
			<div className="sl-hearts">
				{data.heartsCount === 0 ? (
					<>
						<div className="sl-hearts__container-half">
							<HeartsInfo {...heartsInfo} />
						</div>
						<div className="sl-hearts__container-half">
							<ProSection {...proSection} />
							<BitsSection {...bitsSection} />
						</div>
					</>
				) : (
					<div className="sl-hearts__container">
						<HeartsInfo {...heartsInfo} />
					</div>
				)}
			</div>
		</Modal.Custom>
	);
});

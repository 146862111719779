const apiLocalUrls: IApiUrls = {
	blogApiHost: 'https://web-dev.sololearn.com',
	authApiHost: 'https://web-dev.sololearn.com',
	learnApiHost: 'https://dev.sololearn.com',
	authenticationApiHost: 'https://dev.sololearn.com/authentication',
	playgroundApiHost: 'https://dev.sololearn.com/codeplayground',
	codeCoachApiHost: 'https://dev.sololearn.com/judge',
	trackingApiHost: 'https://dev.sololearn.com/tracking',
	paymentApiHost: 'https://dev.sololearn.com/payment',
	onboardingApiHost: 'https://dev.sololearn.com/12443',
	experimentApiHost: 'https://dev.sololearn.com/12443',
	commentsApiHost: 'https://web-dev.sololearn.com',
	userInfoApiHost: 'https://dev.sololearn.com/userinfo',
	userInfoApiHostV2: 'https://dev-api2.sololearn.com/userinfo',
	achievementApiHost: 'https://dev.sololearn.com/achievement',
	codeRepoApiHost: 'https://dev.sololearn.com/coderepo',
	playgroundUrl: 'https://web-dev.sololearn.com/compiler-playground',
	soloLearnUrl: 'https://web-dev.sololearn.com',
	cdnApiHost: 'https://assets.sololearn.com',
	cdnApiHostV2: 'https://blob.sololearn.com',
	lookupApiHost: 'https://dev.sololearn.com/lookup',
	mailingApiHost: 'https://web-dev.sololearn.com',
	staticPagesApiHost: 'https://web-dev.sololearn.com',
	gamificationApiHost: 'https://dev.sololearn.com/gamification',
	homeApiHost: 'https://dev.sololearn.com/dynamicflow/api/flows/all',
	environmentUrl: 'https://dev.sololearn.com',
	userSettingsApiHost: 'https://dev.sololearn.com/dynamicflow',
	socketPath: '/notifications/connect',
	experimentsApiHost: 'https://dev.sololearn.com/dynamicflow',
	appsFlyerApiHost: 'https://dev.sololearn.com',
	kudosApiHost: 'https://dev.sololearn.com/kudos',
	abTestingApiHost: 'https://dev.sololearn.com/dynamicflow',
	leaderboardApi: 'https://dev.sololearn.com/leagues',
	xpApiHost: 'https://dev.sololearn.com/xp',
	learnEngineApiHost: 'https://dev.sololearn.com/learnEngine',
	monolithApiHost: 'https://dev.sololearn.com/monolith',
	dynamicTrackingHost:
		'https://dev.sololearn.com/tracking/api/dynamictracking',
	streakApiHost: 'https://dev.sololearn.com/streak',
	blobApiHost: 'https://sllearnenginedev.sololearn.com',
};

interface IApiUrls {
	blogApiHost: string;
	authApiHost: string;
	learnApiHost: string;
	authenticationApiHost: string;
	codeCoachApiHost: string;
	playgroundApiHost: string;
	trackingApiHost: string;
	paymentApiHost: string;
	onboardingApiHost: string;
	commentsApiHost: string;
	userInfoApiHost: string;
	userInfoApiHostV2: string;
	achievementApiHost: string;
	playgroundUrl: string;
	soloLearnUrl: string;
	cdnApiHost: string;
	cdnApiHostV2: string;
	lookupApiHost: string;
	mailingApiHost: string;
	staticPagesApiHost: string;
	gamificationApiHost: string;
	experimentApiHost: string;
	homeApiHost: string;
	environmentUrl: string;
	socketPath: string;
	codeRepoApiHost: string;
	userSettingsApiHost: string;
	experimentsApiHost: string;
	appsFlyerApiHost: string;
	kudosApiHost: string;
	abTestingApiHost: string;
	leaderboardApi: string;
	xpApiHost: string;
	learnEngineApiHost: string;
	monolithApiHost: string;
	dynamicTrackingHost: string;
	streakApiHost: string;
	blobApiHost: string;
}

export const ApiUrls: IApiUrls = ((): IApiUrls => {
	if (typeof window !== 'undefined') return (window as any).slConfig ? (window as any).slConfig.envURLs : apiLocalUrls;
	return apiLocalUrls;
})();

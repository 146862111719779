import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { Button } from '@sololearnorg/sol';
import {
	BitLocationType,
	Container,
	StreakService,
	i18n,
} from '../../../../symphony';

import {
	StreakIcon,
	StreakIconColor,
	StreakIconType,
} from '../StreakIcon/StreakIcon';

import s from './StreakSaver.scss';

interface IStreakSaver {
    freezeAmount: number;
    maxFreezeAmount: number;
    location: BitLocationType;
}
export const StreakSaver: React.FC<IStreakSaver> = React.memo(
	({ freezeAmount, maxFreezeAmount, location }) => {
		useStyles(s);
		const onClick = () => {
			const streakService = Container.take('global', StreakService);
			streakService.onGetStreakSaverClick(location);
		};

		return (
			<div className="streak-saver-container">
				<div className="streak-info-container">
					<div className="streak-info-header">
						{i18n.t('web-streak.saver-title')}
					</div>
					<div className="streak-info">
						<div className="streak-info__content">
							<StreakIcon
								id="streak_saver"
								type={StreakIconType.Saver}
								color={StreakIconColor.Saver}
								small
							/>
							<div className="streak-info-value">
								{freezeAmount}
							</div>
							<div className="streak-info-maxDay">
								of
								{' '}
								{maxFreezeAmount}
							</div>
						</div>
					</div>
				</div>
				<Button
					key="getStreakSaver"
					onClick={onClick}
					disabled={freezeAmount === maxFreezeAmount}
					buttonType="secondary"
					size="s"
					className="streak-saver-button"
				>
					{i18n.t('web-streak.saver-cta')}
				</Button>
			</div>
		);
	},
);

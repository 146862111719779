import { TrackingSource, TrackingSourceDetail } from '../../shared/public/globalInterfaces/trackingInterface';

export const FACEBOOK_ID = '153040644900826';
export const GOOGLE_ID = '870337102131-58k7sh5viu298qiu1e23ts8sqjclpm9h.apps.googleusercontent.com';

export const DEFAULT_ERROR_MESSAGES = {
	FB: 'Facebook seems to be having issues in your location. Please choose a different signup method or contact customer support if you’re trying to login.',
};

export const GSA_CLIENT_SCRIPT_SOURCE = '/scripts/gsa-client.js';

export const TRACKING_SOURCE_DETAIL_MAPPING = {
	[TrackingSourceDetail.Default]: 'DEFAULT',
	[TrackingSourceDetail.OnBoarding]: 'ONBOARDING',
	[TrackingSourceDetail.Home]: 'HOME',
	[TrackingSourceDetail.Codes]: 'CODES',
	[TrackingSourceDetail.LandingPage]: 'LANDING_PAGE',
	[TrackingSourceDetail.Blog]: 'BLOG',
	[TrackingSourceDetail.Profile]: 'PROFILE',
	[TrackingSourceDetail.Discuss]: 'DISCUSS',
	[TrackingSourceDetail.Payments]: 'PAYMENTS',
	[TrackingSourceDetail.Courses]: 'COURSES',
	[TrackingSourceDetail.Playground]: 'PLAYGROUND',
	[TrackingSourceDetail.TargetedLanding]: 'TARGETED_LANDING',
	[TrackingSourceDetail.LanguageLanding]: 'LANGUAGE_LANDING',
	[TrackingSourceDetail.AICourseEarlyBird]: 'AI COURSE EARLY BIRD',
	[TrackingSourceDetail.Other]: 'OTHER',
};

export const TRACKING_SOURCE_MAPPING = {
	[TrackingSource.GoogleWidget]: 'GOOGLE WIDGET',
	[TrackingSource.SignUpForm]: 'SIGNUP FORM',
	[TrackingSource.StartLearningNow1]: 'START LEARNING NOW 1',
	[TrackingSource.StartLearningNow2]: 'START LEARNING NOW 2',
	[TrackingSource.StartLearningLikeAPro]: 'START LEARNING LIKE A PRO',
	[TrackingSource.Register]: 'REGISTER',
	[TrackingSource.Login]: 'LOGIN',
	[TrackingSource.SignIn]: 'SIGNIN',
	[TrackingSource.SelectPlan]: 'SELECT PLAN',
	[TrackingSource.TryProForFree14Days]: 'TRY PRO FOR FREE FOR 14 DAYS',
	[TrackingSource.TargetLandingPage]: 'TARGET LANDING PAGE',
	[TrackingSource.NewQuestion]: 'NEW QUESTION',
	[TrackingSource.Upvote]: 'UPVOTE',
	[TrackingSource.Other]: 'OTHER',
	[TrackingSource.Google]: 'GOOGLE',
	[TrackingSource.Apple]: 'APPLE',
	[TrackingSource.Facebook]: 'FACEBOOK',
	[TrackingSource.Save]: 'SAVE',
	[TrackingSource.Public]: 'PUBLIC',
	[TrackingSource.Lesson]: 'LESSON',
	[TrackingSource.ChangeName]: 'CHANGE NAME',
	[TrackingSource.VoteCode]: 'UPVOTE',
};

import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import {
	StreakIcon,
	StreakIconColor,
	StreakIconType,
} from '../Streak/StreakIcon/StreakIcon';

import s from './SlUserStreak.scss';

interface IUserStreakProps {
    streakDay: number;
    onClick: () => void;
    isRiched?: boolean;
}

export const SlUserStreak: React.FC<IUserStreakProps> = React.memo(
	({ streakDay, onClick, isRiched }) => {
		useStyles(s);
		return (
			<div
				className="sl-user-streak__container"
				sl-test-data="cmpUserStreak"
				onClick={onClick}
			>
				<StreakIcon
					id={String(streakDay)}
					type={StreakIconType.Default}
					color={
						isRiched
							? StreakIconColor.Reached
							: StreakIconColor.NotReached
					}
					extraSmall
				/>
				<span className="sl-user-streak__streakDay">{streakDay}</span>
			</div>
		);
	},
);

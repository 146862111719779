import React, { FC, RefObject, useEffect } from "react";
import s from "./LongPaywallPartnerships.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container, i18n } from "../../../../../../symphony";
import { useInView } from "../../../../paywalls.utils";
import { PaywallsService } from "../../../../services/paywalls.service";

type Props = {
	data: {
		heading: string;
		partnerLogos: string[];
	};
	style: React.CSSProperties;
	sectionOrder: number;
};

export const LongPaywallPartnerships: FC<Props> = ({ data, style, sectionOrder }) => {
	useStyles(s);
	const [ref, isInView] = useInView({
		threshold: 0.7
	});
	const { trackSectionView } = Container.take('paywalls', PaywallsService);

	useEffect(() => {
		isInView && trackSectionView('longPartnerships', sectionOrder);
	}, [isInView]);

	return <div ref={ref as RefObject<HTMLDivElement>} style={style} className="partners">
		<h3>{i18n.t(data.heading)}</h3>
		<div className="partners-logos">
			{data.partnerLogos.map(l => <img key={l} src={l} />)}
		</div>
	</div>;
};
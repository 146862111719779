import React, { useEffect, useRef } from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Container } from '../../../symphony';
import s from './SlLoginActions.scss';
import { SlLoginActionsContext } from '../../private/login-context';
import { LoginActionsContainer } from '../../private/containers/LoginActions/LoginActions';
import { SlLoginActionsTrackingService } from '../../private/services/slLoginActions/sl-login-actions.tracking.service';

export interface ISlLoginActionsProps {
	mode: 'dark' | 'light';
	onLoginClick?: () => void;
	onSignUpClick?: () => void;
	trackingPrefix: string;
}

// Feel free to provide better sollution
let mountedComponentsCount = 0;

export const SlLoginActions: React.FC<ISlLoginActionsProps> = React.memo(
	({
		mode, trackingPrefix, onLoginClick, onSignUpClick,
	}) => {
		useStyles(s);
		const initConfigs = () => {
			Container.reset(SlLoginActionsContext);
			Container.register(
				SlLoginActionsContext,
				'trackingPrefix',
				trackingPrefix || null,
			);
			Container.register(
				SlLoginActionsContext,
				'onLoginClick',
				onLoginClick || null,
			);
			Container.register(
				SlLoginActionsContext,
				'onSignUpClick',
				onSignUpClick || null,
			);
			mountedComponentsCount++;
		};

		const isInit = useRef(true);

		if (isInit.current) {
			initConfigs();
			isInit.current = false;
		}

		useEffect(() => {
			const unsubscriber = new Subject<void>();

			const { loginActionsTrackActionHandler$ } = Container.take(
				SlLoginActionsContext,
				SlLoginActionsTrackingService,
			);
			loginActionsTrackActionHandler$
				.pipe(takeUntil(unsubscriber))
				.subscribe();

			return () => {
				unsubscriber.next();
				unsubscriber.complete();
				if (mountedComponentsCount === 1) {
					Container.reset(SlLoginActionsContext);
				}
				mountedComponentsCount--;
			};
		}, []);

		return <LoginActionsContainer mode={mode} />;
	},
);

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const charactersLength = characters.length;

function getFingerprintFromCookies(): string {
	return document.cookie.replace(/(?:(?:^|.*;\s*)fngprntId\s*\=\s*([^;]*).*$)|^.*$/, '$1');
}

/*
returns unique string for fingerprint...
commented out as we need unique id per device, might need the functionality later on
    function getUniqueString() {
        let result = '';
        for ( var i = 0; i < charactersLength; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result + Date.now().toString();
    }
*/
export function getFingerprint(setFingerprint: (fingerprint: string) => void): string {
	if (typeof window !== 'undefined') {
		const fingerPrint: string = getFingerprintFromCookies();
		if (!fingerPrint) {
			const cookieWatcher = setInterval(() => {
				if (getFingerprintFromCookies()) {
					setFingerprint(getFingerprintFromCookies());
					clearInterval(cookieWatcher);
				}
			}, 50);
		}
		return fingerPrint || 'placeholderFingerprint';
	}
	return 'placeholderFingerprint';
}

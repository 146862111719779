import { BehaviorSubject, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

import { Container, Service } from '../../../../symphony';

import { SlStaticPagesApiService } from './sl-static-pages-api.service';
import { StaticPagesContext } from '../../contexts';
import { StaticPagesActionHandlersService } from './sl-static-pages-action-handlers.service';
import { SlStaticPagesTrackingService } from './tracking/sl-static-pages-tracking.service';
import { IStaticPagesActionData } from '../../global-interfaces';

export interface IStaticPagesAction {
    type: SlStaticPagesActionTypes;
    requestType: 'cancelable' | 'parallel';
    data?: IStaticPagesActionData;
}

export enum SlStaticPagesActionTypes {
    getContactPageContent,
    getRefundPolicyPageContent,
    getFAQPageContent,
    getTermsOfUsePageContent,
    getPrivacyPolicyPageContent,
    getCopyrightPolicyPageContent,
    getContentCreationGuidlinesPageContent,
    redirectHandler,
    getCertificateData,
    getTargetedLandingPage,
    getUserData,
    trackCertificate,
    getLanguageLandingPageData,
    setLanguageLandingPageData,
    languageLandingSectionView,
    MobilePageStoreClick,
    YourYearWrapUpMount,
	SendAICourseWaitListEmail,
	TrackAILandingPageImpression,
	TrackAILandingPageClick,
	TrackJoinWaitlist,
}

@Service()
export class StaticPagesActionsService {
	public actions$ = new BehaviorSubject<IStaticPagesAction>(null);

	private apiHandlers = Container.take(
		StaticPagesContext,
		SlStaticPagesApiService,
	);

	private trackingService = Container.take(
		StaticPagesContext,
		SlStaticPagesTrackingService,
	);

	private actionHandlers = Container.take(
		StaticPagesContext,
		StaticPagesActionHandlersService,
	);

	private cancelable = this.actions$.pipe(
		filter((a) => a && a.requestType === 'cancelable'),
		switchMap((action) => {
			switch (action.type) {
			case SlStaticPagesActionTypes.getContactPageContent:
				return this.apiHandlers.getPageContentHandler('contact');
			case SlStaticPagesActionTypes.getFAQPageContent:
				return this.apiHandlers.getPageContentHandler('faq');
			case SlStaticPagesActionTypes.getRefundPolicyPageContent:
				return this.apiHandlers.getPageContentHandler(
					'refund-policy',
				);
			case SlStaticPagesActionTypes.getPrivacyPolicyPageContent:
				return this.apiHandlers.getPageContentHandler(
					'privacy-policy',
				);
			case SlStaticPagesActionTypes.getCopyrightPolicyPageContent:
				return this.apiHandlers.getPageContentHandler(
					'copyright-policy',
				);
			case SlStaticPagesActionTypes.getContentCreationGuidlinesPageContent:
				return this.apiHandlers.getPageContentHandler(
					'content-creation-guidelines',
				);
			case SlStaticPagesActionTypes.getTermsOfUsePageContent:
				return this.apiHandlers.getPageContentHandler(
					'terms-of-use',
				);
			default:
				return of(action);
			}
		}),
	);

	private parallel = this.actions$.pipe(
		filter((a) => a && a.requestType === 'parallel'),
		mergeMap((action) => {
			switch (action.type) {
			case SlStaticPagesActionTypes.redirectHandler:
				return this.actionHandlers.routeChangeHandler(
					action.data.url,
				);
			case SlStaticPagesActionTypes.getCertificateData:
				return this.apiHandlers.getCertificateDataHandler(
					action.data.certificateId,
				);
			case SlStaticPagesActionTypes.getTargetedLandingPage:
				return this.apiHandlers.getTargetedLandingPageHandler(
					action.data.landingPageAlias,
				);
			case SlStaticPagesActionTypes.getUserData:
				return this.apiHandlers.getUserDataHandler(
					action.data.userId,
				);
			case SlStaticPagesActionTypes.trackCertificate:
				this.trackingService.trackEventHandler(
					action.data.trackingData,
				);
				return of(null);
			case SlStaticPagesActionTypes.getLanguageLandingPageData:
				return this.apiHandlers.getLanguageLandingPageDataHandler(
					action.data.languageAlias,
				);
			case SlStaticPagesActionTypes.languageLandingSectionView:
				return this.actionHandlers.languageLandingSectionViewHandler(
					action.data,
				);
			case SlStaticPagesActionTypes.MobilePageStoreClick:
				return this.actionHandlers.mobilePageStoreClickHandler(
					action.data,
				);
			case SlStaticPagesActionTypes.YourYearWrapUpMount:
				return this.actionHandlers.yourYearWrapUpMountHandler();
			case SlStaticPagesActionTypes.SendAICourseWaitListEmail:
				return this.actionHandlers.sendAICourseWaitListHandler(
					action.data.waitlistId,
					action.data.email,
				);
			case SlStaticPagesActionTypes.TrackAILandingPageImpression:
				return this.trackingService.trackAILandingPageImpression(
					action.data.trackParams,
				);
			case SlStaticPagesActionTypes.TrackAILandingPageClick:
				return this.trackingService.trackAILandingPageClick(
					action.data.trackParams,
				);
			case SlStaticPagesActionTypes.TrackJoinWaitlist:
				return this.trackingService.trackJoinWaitlist(
					action.data.trackParams,
				);
			default:
				return of(action);
			}
		}),
	);

	public actionsStream = merge(this.cancelable, this.parallel).pipe(
		filter((a) => !!a),
	);
}

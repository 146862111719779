import {
	AuthService, Container, SubscriptionPlanService, TokenKey, i18n,
} from '../../../symphony';

const getTimezone = () => {
	const date = new Date();
	const timezoneOffsetHours = -date.getTimezoneOffset() / 60;
	const prefix = timezoneOffsetHours < 0 ? '' : '+';
	const timezoneString = prefix + timezoneOffsetHours.toString();

	return timezoneString;
};

export function getApiHeaders(shouldIncludeAuthorization = true) {
	const authService = Container.take('global', AuthService);
	const subscriptionPlanService = Container.take('global', SubscriptionPlanService);
	const timezone = getTimezone();
	let accessToken: string;

	if (typeof window === 'undefined') {
		accessToken = Container.take('global', 'jwtAuthPublicToken');
	} else {
		accessToken = authService.getToken(TokenKey.accessToken);
	}

	const headers = {
		'SL-Plan-Id': subscriptionPlanService.getId(),
		'SL-Locale':
			typeof window === 'undefined'
				? (Container.take('global', 'activeLanguage') as string)
				: i18n?.language,
		'SL-Time-Zone': timezone,
		'content-type': 'application/json',
		...(shouldIncludeAuthorization && {
			Authorization: `Bearer ${accessToken}`,
		}),
	};

	return headers;
}

import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Congrats.scss';
import { i18n } from '../../../../symphony';
import { SlIcon } from '@sololearnorg/icons';
import { PaywallPageButton } from '../PaywallPageButton/PaywallPageButton';
import { PaywallButtonAction, PaywallButtonType } from '../../../../api/public/subscription-api';

const items = [
	{
		title: 'web-checkout.congrats-item0-title',
		subtitle: 'web-checkout.congrats-item0-subtitle',
		icon: 'infiniteHearts'
	},
	{
		title: 'web-checkout.congrats-item1-title',
		subtitle: 'web-checkout.congrats-item1-subtitle',
		icon: 'noAds'
	},
	{
		title: 'web-checkout.congrats-item2-title',
		subtitle: 'web-checkout.congrats-item2-subtitle',
		icon: 'plusGradient'
	},
	{
		title: 'web-checkout.congrats-item3-title',
		subtitle: 'web-checkout.congrats-item3-subtitle',
		icon: 'practiceWithAI'
	},
];

export const Congrats: FC = () => {
	useStyles(s);
	return <div className="congrats">
		<div className="congrats-header">
			<div className="congrats-icon">
				<SlIcon iconId={'sparkleSmallStars'} />
			</div>
			<h4 className="congrats-title">
				{i18n.t('web-checkout.congrats-title')}
			</h4>
			<div className="congrats-icon">
				<SlIcon iconId={'sparkleSmallStars'} />
			</div>
		</div>
		<p className='congrats-subtitle'>{i18n.t('web-checkout.congrats-subtitle')}</p>
		<div className="congrats-values">
			{items.map(i => (
				<div key={i.title} className="congrats-value">
					<div className="congrats-value-icon">
						<SlIcon iconId={i.icon} />
					</div>
					<div>
						<p className='congrats-value-title'>{i18n.t(i.title)}</p>
						<p className='congrats-value-subtitle'>{i18n.t(i.subtitle)}</p>
					</div>
				</div>
			))}
		</div>
		<PaywallPageButton
			type={PaywallButtonType.primary}
			action={PaywallButtonAction.close}
			text='web-checkout.congrats-continue-button'
			order={23}
		/>
	</div>;
};
import { BehaviorSubject } from 'rxjs';
import { Service } from '../../../symphony';
import { ISlLoginResponse } from '../global.interface';

@Service()
export class SlDataService {
	public betaTestingModal$ = new BehaviorSubject<boolean>(false);

	public loginData$ = new BehaviorSubject<ISlLoginResponse>(null);

	public loginCallback$ = new BehaviorSubject<(res: ISlLoginResponse) => void
        	>(null);

	public addBetaTesterLoading$ = new BehaviorSubject<boolean>(false);
}

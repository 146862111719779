import React, { FC, RefObject, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WebToAppPaywallFAQ.scss';
import { useInView } from "../../paywalls.utils";
import { Container } from "../../../../symphony";
import { PaywallsService } from "../../services/paywalls.service";
import { PaywallClickTypes } from "../../paywalls.constants";

type Props = {
	faqs: any[];
	sectionOrder: number;
};

export const WebToAppPaywallFAQ: FC<Props> = ({ faqs, sectionOrder }) => {
	useStyles(s);
	const { trackSectionView, trackPaywallClick } = Container.take('paywalls', PaywallsService);
	const [expandedFaq, setExpandedFaq] = useState<number[]>([]);
	const [ref, isInView] = useInView({
		threshold: 0.7
	});

	const onFaqItemClick = (id: number) => {
		const expandedIds = expandedFaq.includes(id)
			? expandedFaq.filter(i => i !== id)
			: [...expandedFaq, id];
		setExpandedFaq(expandedIds);
		trackPaywallClick(PaywallClickTypes.faqItem, faqs.find(f => f.id === id).title);
	};

	useEffect(() => {
		isInView && trackSectionView('faq', sectionOrder);
	}, [isInView]);

	return <div ref={ref as RefObject<HTMLDivElement>} className="paywall-w2a-section paywall-w2a-faq">
		<h2 className="paywall-w2a-section-title">We are ready to answer your questions</h2>
		<div className="paywall-w2a-faq-list">
			{faqs.map(f => (<div className="paywall-w2a-faq-item" key={f.id}>
				<div
					className="paywall-w2a-faq-item-header"
					onClick={() => { onFaqItemClick(f.id); }}
				>
					<span className="paywall-w2a-faq-item-header-title">{f.title}</span>
					<div className="paywall-w2a-faq-item-header-icon-container">
						<span className={`paywall-w2a-faq-item-header-icon ${expandedFaq.includes(f.id) ? 'expanded' : ''}`}>+</span>
					</div>
				</div>
				<div className={`paywall-w2a-faq-item-body ${expandedFaq.includes(f.id) ? 'expanded' : ''}`}>
					{f.description}
				</div>
			</div>))}
		</div>
	</div>;
};
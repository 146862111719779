import React, { useEffect, useState } from 'react';
import { Subject, takeUntil } from 'rxjs';

import {
	Container,
	DataService,
	BitService,
	StreakService,
	ShopItemName,
} from '../../features/symphony';

import { SlBuyStreakSaverModal } from '../../features/shared/public/Streak/SlBuyStreakSaverModal/SlBuyStreakSaverModal';

export function BuyStreakSaverModal(): JSX.Element {
	const [isBuyingStreakSaver, setIsBuyingStreakSaver] = useState<boolean>(false);

	const {
		isBuyingStreakSaver$,
		onStreakSaverModalClose,
		onStreakSaverBuyClick,
	} = Container.take('global', StreakService);
	const { shopItemsList$ } = Container.take('global', DataService);
	const { bits$, trackStreakSaverModalImpression } = Container.take('global', BitService);

	const streakSaverPrice = shopItemsList$.value.find(
		(item) => item.name === ShopItemName.StreakFreeze,
	).price;

	useEffect(() => {
		trackStreakSaverModalImpression();

		const unsubscriber = new Subject<void>();

		isBuyingStreakSaver$
			.pipe(takeUntil(unsubscriber))
			.subscribe(setIsBuyingStreakSaver);

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	return (
		<SlBuyStreakSaverModal
			streakFreezePrice={streakSaverPrice}
			bits={bits$.value.bits}
			onClose={onStreakSaverModalClose}
			onStreakSaverBuy={onStreakSaverBuyClick}
			loading={isBuyingStreakSaver}
		/>
	);
}

import { useLocation } from 'react-router-dom';

import { Container, i18n } from '../../features/symphony';

import { useComponentWillMount } from '../../features/shared/public/SlUtils/useComponentWillMount';

export const useLocalization = (activeLanguage?: string) => {
	const { pathname } = useLocation();

	useComponentWillMount(() => {
		if (typeof window !== 'undefined') {
			const clientActiveLanguage = pathname?.split('/')?.[1] || 'en';
			i18n.init(window.initialData?.localizationData, clientActiveLanguage);
		} else {
			const localizationData = Container.take('global', 'localizationData');

			i18n.init(localizationData[activeLanguage], activeLanguage);
		}
	});
};

import { Observable } from 'rxjs';

import {
	IBits,
	IBitSource,
	AcknowledgementType,
	IShopItem,
} from '../../../symphony';

import { IResponse } from '../global.interface';

export interface IGamificationApi {
    getBits: () => Observable<IBitResponse>;
    getBitSources: () => Observable<IBitSourcesResponse>;
    getBitSourcesList: () => Observable<IBitSourcesListResponse>;
    getShopItemsList: () => Observable<IShopItemsListResponse>;
    getBitHistory: (
        activityType: ActivityTypes
    ) => Observable<IBitHistoryResponse>;
    setBitAcknowledgementStatus: () => Observable<IBitSetAcknowledgementStatusResponse>;
    setUnlockPopupSeen: () => Observable<IBitSetUnlockPopupSeenResponse>;
    getShopItems: (
        context: ContextTypes,
        contextId: number,
        itemTypes?: ShopItemTypes[]
    ) => Observable<IShopItemsResponse>;
    buyShopItem: (
        payload: IBuyShopItemPayload
    ) => Observable<IBuyShopItemResponse>;
}

export interface IGamificationApiConfig {
    environmentUrl: string;
    useMock?: boolean;
    mock?: IGamificationBitApiMock;
    versions?: IGamificationVersions;
}

export interface IGamificationBitApiMock {
    bits: IBitResponse;
    bitSources: IBitSourcesResponse;
    bitHistory: IBitHistoryResponse;
    bitAcknowledgementStatus: IBitSetAcknowledgementStatusResponse;
    bitSetUnlockPopupSeen: IBitSetUnlockPopupSeenResponse;
}

export interface IGamificationVersions {
    getBits?: string;
    getBitSources?: string;
    getBitSourcesList?: string;
    getShopItemsList?: string;
    getBitHistory?: string;
    setBitAcknowledgementStatus?: string;
    setUnlockPopupSeen?: string;
    getShopItems?: string;
    buyShopItem?: string;
}

export enum ActivityTypes {
    Earn = 1,
    Spend = 2,
}

export enum ContextTypes {
    profile = 'profile',
    course = 'course',
    lesson = 'lesson',
}

export enum ShopItemTypes {
    codeCoach = 'codecoach',
    quizAnswer = 'quizanswer',
    quizHint = 'quizhint',
    ccSolution = 'ccsolution',
    heartRefill = 'heartrefill',
    streakfreeze = 'streakfreeze',
}

export interface IUserBitSource {
    id: number;
    description: string;
    bit: number;
    isHotToday: boolean;
    multiplayer: number;
    order: number;
}

export interface IIntroToBitState {
    acknowledgement: AcknowledgementType;
    text: string;
    buttonText: string;
}

export interface IBitSourcesData {
    sources: IUserBitSource[];
    introToBitState: IIntroToBitState;
}

export interface IBitHistoryItem {
    bits: number;
    bitSourceId: number;
    entityId: number;
    date: string;
}

export interface IBitHistoryData {
    history: IBitHistoryItem[];
}

export interface IUserShopItem {
    itemId: number;
    isBought: boolean;
    price: number;
}

export interface IHeartShopItem {
    price: number;
}

export interface IUserShopItemsData {
    codeCoaches?: IUserShopItem[];
    quizAnswers?: IUserShopItem[];
    quizHints?: IUserShopItem[];
    ccSolutions?: IUserShopItem[];
    heartRefill?: IHeartShopItem;
}

export interface IBuyShopItemPayload {
    itemType: ShopItemTypes;
    itemId: number;
}

export interface IBitResponse extends IResponse {
    data: IBits;
}

export interface IBitSourcesResponse extends IResponse {
    data: IBitSourcesData;
}

export interface IBitSourcesListResponse extends IResponse {
    data: IBitSource[];
}

export interface IShopItemsListResponse extends IResponse {
    data: IShopItem[];
}

export interface IBitHistoryResponse extends IResponse {
    data: IBitHistoryData;
}

export interface IBitSetAcknowledgementStatusResponse extends IResponse {
    data: number;
}

export interface IBitSetUnlockPopupSeenResponse extends IResponse {
    data: boolean;
}

export interface IShopItemsResponse extends IResponse {
    data: IUserShopItemsData;
}

export interface IBuyShopItemResponse extends IResponse {
    data: IBits;
}

import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { BetaTesting } from './BetaTesting';

import s from './BetaTesting.scss';

export const ModalsWrapper = (): JSX.Element => {
	useStyles(s);

	return <BetaTesting />;
};

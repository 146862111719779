import { Observable, of } from 'rxjs';

import { Container, Service } from '../../../../symphony';

import { SlNavigationBusiness } from '../sl-navigation.business';
import { SlNavigationContext } from '../../global-constants';
import { SlNavigationTrackingService } from '../sl-navigation-tracking.service';
import { IActionData } from '../../global-interface';
import { IBitSourcesResponse } from '../../../../api/public/gamification-api';

@Service()
export class SlActionsController {
	private business = Container.take(
		SlNavigationContext,
		SlNavigationBusiness,
	);

	private tracking = Container.take(
		SlNavigationContext,
		SlNavigationTrackingService,
	);

	public onHeartsIconClickHandler = ({
		heartModalSource,
	}: IActionData): Observable<null> => this.business.handleHeartsIconClick(heartModalSource);

	public onStreakIconClickHandler = (): Observable<null> => this.business.handleStreakIconClick();

	public onStreakMenuMountHandler = (): Observable<null> => this.business.handleStreakMenuMount();

	public onBitsIconClickHandler = (): Observable<null> => this.business.handleBitsIconClick();

	public onBitsMenuMountHandler = (): Observable<IBitSourcesResponse> => this.business.handleBitsMenuMount();

	public setHeaderSignUpExperimentsHandler = (): Observable<null> => this.business.setHeaderSignUpExperiments();

	public trackNavigationElementsClickHandler = ({
		clickAlias,
	}: IActionData): Observable<null> => {
		this.tracking.trackNavigationElementsClick(clickAlias);
		return of(null);
	};

	public trackNavigationBarDropdownClickHandler = ({
		dropdownLevel,
		clickType,
		alias,
		elementType,
	}: IActionData): Observable<null> => {
		this.tracking.trackNavigationBarDropdownClick({
			dropdownLevel,
			clickType,
			alias,
			elementType,
		});
		return of(null);
	};

	public trackFooterElementsClickHandler = ({
		alias,
		elementType,
	}: IActionData): Observable<null> => {
		this.tracking.trackFooterElementsClick({ alias, elementType });
		return of(null);
	};

	public onNavbarMountHandler = (): Observable<null> => this.business.handleNavbarMount();

	public onNavbarCloseHandler = (): Observable<null> => this.business.closeNavbar();

	public onNavLinkClickHandler = ({
		navItem,
	}: IActionData): Observable<null> => this.business.handleNavLinkClick(navItem);

	public onNavLogoClickHandler = (): Observable<null> => this.business.handleNavLogoClick();

	public onNavCatalogClickHandler = ({
		navItem,
	}: IActionData): Observable<null> => this.business.handleNavCatalogClick(navItem);

	public onCoursesItemClickHandler = ({
		dropdownLevel,
		alias,
	}: IActionData): Observable<null> => this.business.handleCoursesItemClick(dropdownLevel, alias);

	public onCoursesSeeAllClickHandler = (): Observable<null> => this.business.handleCoursesSeeAllClick();

	public onCompilerItemClickHandler = (): Observable<null> => this.business.handleCompilerItemClick();
}

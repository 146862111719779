import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './BenefitsList.scss';
import { i18n } from "../../../../../symphony";
import { SlIcon } from "@sololearnorg/icons";

type BenefitsListProps = {
	benefits: {
		iconId: string;
		title: string;
		description: string;
	}[];
};

export const BenefitsList: FC<BenefitsListProps> = ({
	benefits
}) => {
	useStyles(s);
	return <div className="benefits">
		{benefits.map((b, i) => {
			return <div className="benefits-item" key={b.title + i}>
				<div className="benefits-icon">
					<SlIcon iconId={b.iconId} />
				</div>
				<div>
					<p className="benefits-title">{i18n.t(b.title)}</p>
					<p className="benefits-description">{i18n.t(b.description)}</p>
				</div>
			</div>;
		})}
	</div>;
};
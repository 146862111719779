import React, { FC, useEffect } from "react";
import s from "./LongPaywallPage.scss";
import useStyles from 'isomorphic-style-loader/useStyles';
import { LongPaywallHeadings } from "./components/LongPaywallHeadings/LongPaywallHeadings";
import { LongPaywallAwards } from "./components/LongPaywallAwards/LongPaywallAwards";
import { LongPaywallComparison } from "./components/LongPaywallComparison/LongPaywallComparison";
import { LongPaywallReviews } from "./components/LongPaywallReviews/LongPaywallReviews";
import { LongPaywallFAQ } from "./components/LongPaywallFAQ/LongPaywallFAQ";
import { LongPaywallPartnerships } from "./components/LongPaywallPartnerships/LongPaywallPartnerships";
import { PaywallComponent, PaywallComponentType, PaywallPage } from "../../../../api/public/subscription-api";
import { LongPaywallProducts } from "./components/LongPaywallProducts/LongPaywallProducts";
import { LongPaywallCheckout } from "./components/LongPaywallCheckout/LongPaywallCheckout";
import { useContainerData } from "../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../checkout.store";
import { Container } from "../../../../symphony";
import { CheckoutService } from "../../services/checkout.service";
import { PaywallsStore } from "../../paywalls.store";
import { LongPaywallHeader } from "./components/LongPaywallHeader/LongPaywallHeader";
import { LongPaywallCampaign } from "./components/LongPaywallCampaign/LongPaywallCampaign";
import { LongPaywallSuccess } from "./components/LongPaywallSuccess/LongPaywallSuccess";
import { SlFooter } from "../../../../navigation/public/SlFooter/SlFooter";

type Props = {
	page: PaywallPage;
};

export const LongPaywallPage: FC<Props> = ({
	page
}) => {
	useStyles(s);
	const {
		defineAvailableProducts
	} = Container.take('paywalls', CheckoutService);

	const {
		paymentProducts
	} = useContainerData('paywalls', PaywallsStore, [
		'paymentProducts'
	]);

	const {
		selectedProductKey,
		checkoutCompleted
	} = useContainerData('paywalls', CheckoutStore, [
		'selectedProductKey',
		'checkoutCompleted'
	]);

	useEffect(() => {
		if (paymentProducts) {
			const productsData = page.components.find(c => c.type === PaywallComponentType.lpProducts).data.products;
			defineAvailableProducts(productsData, paymentProducts);
		}
	}, [paymentProducts]);

	useEffect(() => {
		if (selectedProductKey) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [selectedProductKey]);

	const getComponent = (c: PaywallComponent, i: number) => {
		switch (c.type) {
			case PaywallComponentType.lpHeader:
				return <LongPaywallHeader
					key={`${c.type}+${i}`}
					sectionOrder={i + 1}
				/>;
			case PaywallComponentType.lpCampaign:
				return <LongPaywallCampaign
					style={
						{
							...c.style,
							marginBottom: '48px'
						}
					}
					data={c.data}
					key={`${c.type}+${i}`}
					sectionOrder={i + 1}
				/>;
			case PaywallComponentType.lpHeadings:
				return <LongPaywallHeadings
					style={{
						marginBottom: '84px'
					}}
					data={c.data}
					key={`${c.type}+${i}`}
					sectionOrder={i + 1}
				/>;
			case PaywallComponentType.lpProducts:
				return <LongPaywallProducts
					style={{
						marginBottom: '120px'
					}}
					data={c.data}
					key={`${c.type}+${i}`}
					sectionOrder={i + 1}
				/>;
			case PaywallComponentType.lpAwards:
				return <LongPaywallAwards
					style={{
						marginBottom: '120px'
					}}
					data={c.data}
					key={`${c.type}+${i}`}
					sectionOrder={i + 1}
				/>;
			case PaywallComponentType.lpComparison:
				return <LongPaywallComparison
					style={{
						marginBottom: '120px'
					}}
					data={c.data}
					key={`${c.type}+${i}`}
					sectionOrder={i + 1}
				/>;
			case PaywallComponentType.lpReviews:
				return <LongPaywallReviews
					style={{
						marginBottom: '120px'
					}}
					data={c.data}
					key={`${c.type}+${i}`}
					sectionOrder={i + 1}
				/>;
			case PaywallComponentType.lpFAQ:
				return <LongPaywallFAQ
					style={{
						marginBottom: '120px'
					}}
					data={c.data}
					key={`${c.type}+${i}`}
					sectionOrder={i + 1}
				/>;
			case PaywallComponentType.lpPartnership:
				return <LongPaywallPartnerships
					style={{
						marginBottom: '120px'
					}}
					data={c.data}
					key={`${c.type}+${i}`}
					sectionOrder={i + 1}
				/>;
			default:
				return null;
		}
	};

	return <div className="long-paywall">
		{page.components.map((c, i) => {
			return getComponent(c, i);
		})}
		{selectedProductKey && <LongPaywallCheckout />}
		{checkoutCompleted && <LongPaywallSuccess />}
		<SlFooter />
	</div>;
};
import React, { useEffect, useRef, useState } from 'react';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../symphony';

import { Overlay } from '../components/Overlay/Overlay';
import { NavBar } from '../components/Navbar/Navbar';
import { SlNavigationDataService } from '../services/sl-navigation-data.service';
import { SlActions } from '../services/ActionsService/sl-actions';
import { useNavbarOptions } from './useNavbarOptions';
import { SlNavbarState, ActionType, RequestType } from '../global-interface';
import { SlNavigationContext } from '../global-constants';

import s from './NavigationBarContainer.scss';
import { SlLogin } from '../../../login/public/SlLogin/SlLogin';
import { useOutsideClick } from '../../../shared/public/SlHooks/useOutsideClick';
import { useComponentWillMount } from '../../../shared/public/SlUtils/useComponentWillMount';
import { getFingerprintFromCookies } from '../../../shared/public/utilsPublic/utilsPublic';
import { SlNavBarPositions } from '../../../shared/public/globalInterfaces/globalInterfaces';
import { AICourseRibbon } from '../components/AICourseRibbon/AICourseRibbon';

export function NavigationBarContainer() {
	useStyles(s);
	const [isOpen, setOpen] = useState<boolean>(false);

	const { actions$ } = Container.take(SlNavigationContext, SlActions);

	const element = useRef(null);

	const {
		navbarInitialConfigs$,
		navBarOpen$,
		navbarTheme$,
		navbarState$,
		navbarType$,
	} = Container.take(SlNavigationContext, SlNavigationDataService);

	const {
		navbarState,
		setNavbarState,
		navbarMode,
		setNavbarMode,
		navbarPosition,
		setNavbarPosition,
		navbarType,
		setNavbarType,
	} = useNavbarOptions();

	useOutsideClick(element, isOpen, !isOpen, () => navBarOpen$.next(false));

	useComponentWillMount(() => {
		Container.register('global', 'isGoogleOneTapTriggered', false);
	});

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		const callBack = () => {
			actions$.next({
				type: ActionType.SetHeaderSignUpExperiments,
				requestType: RequestType.Parallel,
			});
		};
		if (getFingerprintFromCookies()) {
			actions$.next({
				type: ActionType.SetHeaderSignUpExperiments,
				requestType: RequestType.Parallel,
			});
		} else {
			document.addEventListener('fngprntDefined', callBack);
		}

		merge(
			navBarOpen$.pipe(tap(setOpen)),
			navbarState$.pipe(tap(setNavbarState)),
			navbarTheme$.pipe(tap(setNavbarMode)),
			navbarType$.pipe(tap(setNavbarType)),
			navbarInitialConfigs$.pipe(
				tap((c) => setNavbarPosition(
					c?.navbarPosition || SlNavBarPositions.relative,
				)),
			),
		)
			.pipe(takeUntil(unsubscriber))
			.subscribe();

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
			document.removeEventListener('fngprntDefined', callBack);
		};
	}, []);

	useEffect(() => {
		const parentElement = document.getElementById('main');
		if (parentElement) {
			parentElement.style.overflow = navbarPosition === SlNavBarPositions.sticky
				? 'visible'
				: 'hidden';
		}
	}, [navbarPosition]);

	if (navbarState !== SlNavbarState.shown) {
		return null;
	}

	return (
		<>
			<Overlay />
			<SlLogin
				loginType="googleOneTap"
				configs={{
					useMock: false,
					disableScrollToTop: true,
					isTitleH1: false,
				}}
				useRouter={false}
			/>
			<NavBar
				navbarPosition={navbarPosition}
				navbarMode={navbarMode}
				navbarType={navbarType}
			/>
		</>
	);
}

import Axios from 'axios-observable';
import { Observable } from 'rxjs';
import { Service } from '../../../symphony';

import { handleError, handleResponse } from '../handle-response';
import { HeadersAuthorizationType } from '../global.interface';
import { getApiHeaders } from '../utils/get-api-headers';
import { ssrApiPathMap } from '../constants';
import { getEnvironment } from '../../../../client/utils/environment';

export type AxiosInstanceType = Omit<
	Axios,
	'get' | 'post' | 'put' | 'patch' | 'delete'
> & {
	get: <T>(...a: Parameters<Axios['get']>) => Observable<T>;
	post: <T>(...a: Parameters<Axios['post']>) => Observable<T>;
	put: <T>(...a: Parameters<Axios['put']>) => Observable<T>;
	patch: <T>(...a: Parameters<Axios['patch']>) => Observable<T>;
	delete: <T>(...a: Parameters<Axios['delete']>) => Observable<T>;
};

@Service()
export class SlAxiosInstanceService {
	private axiosInstance: AxiosInstanceType;

	constructor() {
		this.axiosInstance = Axios.create({}) as unknown as AxiosInstanceType;
		this.initRequestInterceptor();
		this.initResponseInterceptor();
	}

	public get axios(): AxiosInstanceType {
		return this.axiosInstance;
	}

	private initResponseInterceptor() {
		this.axiosInstance.interceptors.response.use(
			(response) => {
				try {
					return handleResponse(response.data);
				} catch (error) {
					return handleError(error);
				}
			},
			(error) => {
				handleError(error);
			},
		);
	}

	private initRequestInterceptor(): void {
		this.axiosInstance.interceptors.request.use((config) => {
			const headers = getApiHeaders(
				config.headers?.authorization !== HeadersAuthorizationType.None,
			);

			if (
				typeof window === 'undefined'
				&& /https:\/\/.*api.*\.sololearn\.com\//.test(config.url)
			) {
				if (config.url.includes('/api2.sololearn.com/v2')) {
					config.url = config.url.replace('https://api2.sololearn.com/v2/', 'http://');
				} else if (config.url.includes('/api2.sololearn.com')) {
					config.url = config.url.replace('https://api2.sololearn.com/', 'http://');
				} else if (config.url.includes('/api3.sololearn.com')) {
					config.url = config.url.replace('https://api3.sololearn.com', 'http://monolithapi');
				} else if (config.url.includes('https://dev-api2.sololearn.com/monolithapi')) {
					config.url = config.url.replace('https://dev-api2.sololearn.com/monolithapi', 'http://monolithapi');
				} else if (config.url.includes('https://preprod-api2.sololearn.com/monolithapi')) {
					config.url = config.url.replace('https://preprod-api2.sololearn.com/monolithapi', 'http://monolithapi');
				} else {
					config.url = config.url.replace(/https:\/\/[^/]*\.sololearn\.com\//, 'http://');
				}

				config.url = config.url.replace(
					new RegExp(Object.keys(ssrApiPathMap).join('|'), 'i'),
					(matched) => ssrApiPathMap[matched],
				);
				config.url = config.url.replace(/^(http?:\/\/[^/]+)/, `$1${`.${getEnvironment()}`}`);
			}

			return config.headers?.authorization
				=== HeadersAuthorizationType.None
				? {
					...config,
					headers,
				}
				: {
					...config,
					headers: {
						...config.headers,
						...headers,
					},
				};
		});
	}
}

import { AxiosError } from 'axios';
import {
	catchError,
	distinctUntilKeyChanged,
	filter,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';

import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { ErrorCodeMessages, handleError } from './handle-response';
import { TokenApi } from './token-api';
import { IResponse, PublicInfoType } from './global.interface';
import {
	Container, AuthService, ITokenData, TokenKey,
} from '../../symphony';

export enum AuthLevel {
	'none',
	'public',
	'authenticated',
}

const tokenApi = Container.take('global', TokenApi);

function getPublicToken(descriptor: PropertyDescriptor, args: unknown[]) {
	const authService = Container.take('global', AuthService);
	const publicTokenLoading$: BehaviorSubject<boolean> = typeof window !== 'undefined'
		? Container.take('global', 'publicTokenLoading$')
		: null;
	const publicTokenInfo$: PublicInfoType = typeof window !== 'undefined'
		? Container.take('global', 'publicTokenInfo$')
		: null;

	const publicToken$ = new Subject();
	tokenApi
		.getPublicToken()
		.pipe(
			take(1),
			catchError((error: AxiosError) => handleError(error, '/publicToken')),
			tap((res: ITokenData | IResponse) => {
				if (!res || (res as IResponse).errors) {
					publicTokenInfo$.next({
						shouldShowCaptcha: true,
					});

					publicTokenInfo$
						.pipe(
							distinctUntilKeyChanged('newCaptchaToken'),
							filter(({ newCaptchaToken }) => !!newCaptchaToken),
							take(1),
						)
						.subscribe(({ newCaptchaToken }) => {
							if (newCaptchaToken) {
								tokenApi
									.getPublicToken(newCaptchaToken)
									.pipe(
										take(1),
										catchError((error: AxiosError) => handleError(error, '/publicToken')),
									)
									.subscribe((newRes) => {
										publicToken$.next(newRes);
									});
							}
						});

					return;
				}

				publicToken$.next(res);
			}),
		)
		.subscribe();

	publicTokenLoading$?.next(true);
	return publicToken$.pipe(
		take(1),
		mergeMap((res: ITokenData | IResponse) => {
			publicTokenLoading$?.next(false);
			publicTokenInfo$?.next({});

			authService.setTokens(res as ITokenData);
			this.accessToken = (res as ITokenData)?.accessToken;

			return descriptor.value.apply(this, args);
		}),
	);
}

export function Authorize(
	authLevel: AuthLevel = AuthLevel.authenticated,
): MethodDecorator {
	return function (
		target: Record<string, unknown>,
		propertyKey: string,
		descriptor: PropertyDescriptor,
	): PropertyDescriptor {
		const newDescriptor = { ...descriptor };
		newDescriptor.value = function (...args: unknown[]) {
			const authService = Container.take('global', AuthService);
			if (authLevel === AuthLevel.none) {
				return descriptor.value.apply(this, args);
			}
			if (authLevel === AuthLevel.public) {
				if (!authService.getToken(TokenKey.accessToken)) {
					const publicTokenLoading$: BehaviorSubject<boolean> = typeof window === 'undefined'
						? null
						: Container.take('global', 'publicTokenLoading$');

					if (publicTokenLoading$?.value) {
						return publicTokenLoading$.pipe(
							filter((isLoading) => !isLoading),
							take(1),
							mergeMap(() => descriptor.value.apply(this, args)),
						);
					}

					return getPublicToken.apply(this, [descriptor, args]);
				}
			} else if (!authService.isLoggedIn()) {
				return throwError([
					{ code: 401, message: ErrorCodeMessages[401] },
				]);
			}
			if (authService.isTokenExpired()) {
				return tokenApi
					.refreshToken(authService.getToken(TokenKey.refreshToken))
					.pipe(
						catchError((error: AxiosError) => {
							if (
								error?.response?.status === 403
								&& authService.isTokenExpired()
							) {
								authService.logout();
								return handleError({
									isThrownError: true,
									errors: [
										{
											code: 401,
											message:
												'Unauthorized. Please try again.',
											url: `${propertyKey} error in ${error?.config?.url}`,
										},
									],
								});
							}
							return handleError(error);
						}),
						mergeMap((data: ITokenData) => {
							authService.setTokens(data, data?.user);
							this.accessToken = data?.accessToken;
							return descriptor.value.apply(this, args);
						}),
					);
			}
			this.accessToken = authService.getToken(TokenKey.accessToken);
			return descriptor.value.apply(this, args);
		};
		return newDescriptor;
	};
}

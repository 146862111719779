import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './SlInfiniteHeart.scss';

export interface IInfiniteHeartProps {
    onClick: () => void;
}

export const SlInfiniteHeart: React.FC<IInfiniteHeartProps> = React.memo(
	({ onClick }) => {
		useStyles(s);
		return (
			<div className="sl-infinite-heart" sl-test-data="cmpInfiniteHeart">
				<button
					type="button"
					className="sl-infinite-heart-button"
					sl-test-data="cmpInfiniteHeartButton"
					onClick={onClick}
				>
					<img
						src="https://blob.sololearn.com/web-assets/infinite-heart.svg"
						alt="infinite-heart"
					/>
				</button>
			</div>
		);
	},
);

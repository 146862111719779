import React from 'react';

export const IconHeartEmpty = () => (
	<svg
		width="40"
		height="40"
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M36.4495 22.2324L21.6831 37.5004C20.7166 38.4998 19.135 38.4998 18.1685 37.5004L3.402 22.2324C-0.880853 17.8007 -0.644391 10.4718 4.10664 6.35942C8.25601 2.76792 14.6056 3.41584 18.4243 7.3581L19.9294 8.91189L21.4344 7.3581C25.2529 3.41592 31.6024 2.76792 35.7518 6.35919C40.5031 10.4715 40.7396 17.8005 36.4495 22.2324Z"
			fill="#C1C1C2"
		/>
	</svg>
);

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import {
	Container,
	Service,
	track,
	TrackingEventNames,
	TrackingService,
} from '../../../symphony';
import { AppStore } from '../../../shared/public/globalInterfaces/globalInterfaces';

declare const slTrack: (payload: ITrackAction) => void;

export interface ITrackAction {
	action: string;
	element: string;
	entityId?: number;
	redirectUrl?: string;
}

enum EventNames {
	WebNavigationBarClick = 'web_navigation_bar_click',
	WebNavigationBarDropdownClick = 'web_navigation_bar_dropdown_click',
	WebFooterClick = 'web_footer_click',
}

@Service()
export class SlNavigationTrackingService {
	private storeMap = {
		footer_appstore: AppStore.AppleStore,
		footer_googleplay: AppStore.PlayStore,
	};

	public navigationTrackActions$ = new BehaviorSubject<ITrackAction>(null);

	private tracking$ = Container.take('global', TrackingService).tracking$;

	public navigationTrackAction$: Observable<ITrackAction> = this.navigationTrackActions$.pipe(
		filter((a) => !!a),
		tap((action: ITrackAction) => {
			slTrack({
				action: action.action,
				element: action.element,
				entityId: action.entityId || null,
				redirectUrl: action.redirectUrl || null,
			});
		}),
	);

	public footerTrackAction$ = new BehaviorSubject<ITrackAction>(null);

	public footerTrackActions$: Observable<ITrackAction> = this.footerTrackAction$.pipe(
		filter((a) => !!a),
		tap((action: ITrackAction) => {
			this.tracking$.next({
				eventName: TrackingEventNames.appStoreClick,
				eventParams: {
					store: this.storeMap[action.element],
					source: 'footer',
				},
			});
			slTrack({
				action: action.action,
				element: action.element,
				entityId: action.entityId || null,
				redirectUrl: action.redirectUrl || null,
			});
		}),
	);

	public trackNavigationElementsClick = (clickAlias: string): void => {
		const url = window.location.href;
		track({
			event_name: EventNames.WebNavigationBarClick,
			page_url: url,
			click_alias: clickAlias,
		});
	};

	public trackFooterElementsClick = ({
		alias,
		elementType,
	}: {
		alias?: string;
		elementType?: string;
	}) => {
		const url = window.location.href;
		track({
			event_name: EventNames.WebFooterClick,
			page_url: url,
			click_element_alias: alias,
			click_element_type_id: elementType,
		});
	};

	public trackNavigationBarDropdownClick = ({
		dropdownLevel,
		clickType,
		alias,
		elementType,
	}: {
		dropdownLevel?: number;
		clickType?: string;
		alias?: string;
		elementType?: string;
	}) => {
		const url = window.location.href;
		track({
			event_name: EventNames.WebNavigationBarDropdownClick,
			page_url: url,
			dropdown_level: dropdownLevel,
			click_type: clickType,
			experience_alias: alias,
			experience_type_id: elementType,
		});
	};

	public trackGroupBanner = (
		eventName,
		clickType?: 'ANSWER' | 'CLOSE' | 'CTA',
	) => {
		const pageUrl = window.location.href;
		const version = '2-0-0';
		const bannerType = 'INVITE';

		this.tracking$.next({
			eventName,
			eventParams: {
				version,
				pageUrl,
				bannerType,
				...(clickType && { clickType }),
			},
		});
	};

	public trackRibbonCtaClick = () => {
		const url = window.location.href;
		const afWebUserId = document.cookie.replace(
			/(?:(?:^|.*;\s*)afUserId\s*=\s*([^;]*).*$)|^.*$/,
			'$1',
		);
		track({
			event_name: EventNames.WebNavigationBarClick,
			page_url: url,
			click_alias: 'LEARN HOW TO USE AI',
			version: '2-0-0',
			...afWebUserId && { af_web_user_id: afWebUserId },
		});
	};
}

import { Observable, of } from 'rxjs';

import {
	Container,
	history,
	Service,
	track,
	TrackingEventNames,
	TrackingService,
} from '../../../../symphony';

import { IStaticPagesActionData } from '../../global-interfaces';
import { SlStaticPagesApiService } from './sl-static-pages-api.service';

@Service()
export class StaticPagesActionHandlersService {
	private tracking$ = Container.take('global', TrackingService).tracking$;

	private api = Container.take('global', SlStaticPagesApiService);

	public routeChangeHandler = (url: string) => {
		history.push(url);
		return of(null);
	};

	public mobilePageStoreClickHandler = ({
		clickType,
	}: IStaticPagesActionData) => {
		this.tracking$.next({
			eventName: TrackingEventNames.mobilePromotionLandingPageClick,
			eventParams: {
				landingPageAlias: 'MOBILE',
				clickType,
			},
		});
		return of(null);
	};

	public yourYearWrapUpMountHandler = () => this.api.getUserStatsHandler();

	public languageLandingSectionViewHandler = ({
		sectionTitle,
		sectionType,
		languageAlias,
	}: IStaticPagesActionData): Observable<null> => {
		track({
			event_name: 'learning_exp_landing_impression',
			experience_type_id: '3',
			experience_alias: languageAlias,
			section_type: sectionType,
			section_title: sectionTitle,
			version: '3-0-0',
		});

		return of(null);
	};

	public sendAICourseWaitListHandler = (id: string, email: string): Observable<unknown> => this.api.sendAICourseWaitListEmail(id, email);
}

import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../../../symphony';

import { SlLoginContext } from '../../../login-context';
import { SlLoginErrorsService } from '../../../services/sl-login-errors.service';
import { IFlashMessage } from '../../../global.interface';

import s from './FlashMessage.scss';
import { SlFlashMessage } from '../../../../../shared/public/SlFlashMessage/SlFlashMessage';

export function FlashMessage(): JSX.Element {
	useStyles(s);

	const { flashMessage$ } = Container.take(
		SlLoginContext,
		SlLoginErrorsService,
	);
	const [flashMessage, setFlashMessage] = useState<IFlashMessage>(null);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		flashMessage$
			.pipe(
				filter((m) => !!m),
				takeUntil(unsubscriber),
			)
			.subscribe(setFlashMessage);

		return () => {
			flashMessage$.next(null);
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const didClosed = () => {
		flashMessage$.next(null);
		setFlashMessage(null);
	};

	const onClose = () => {
		flashMessage$.next(null);
		setFlashMessage(null);
	};

	return flashMessage ? (
		<div className="sl-flash-message">
			<SlFlashMessage
				data={{ ...flashMessage }}
				didClosed={didClosed}
				onClose={onClose}
			/>
		</div>
	) : null;
}

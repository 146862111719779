import { Observable, of } from 'rxjs';

import {
	DefaultCodeLanguages,
	ICodeAPI,
	ICodeApiMock,
	ICodeBitsDetailsResponse,
	ICodeBitsSearchParams,
	ICodeCompilationResponse,
	ICodeCompilePayload,
	ICodeDefaultResponse,
	ICodeDefaultsResponse,
	ICodeTemplateResponse,
	ICodeVersions,
	IDeleteCodeResponse,
	IFreeCodesResponse,
	ILanguagesCatalogResponse,
	IUserCodePayload,
	IUserCodeResponse,
	IVoteUserCodePayload,
	IVoteUserCodeResponse,
} from '../private/code-api/code-api.interface';

import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { constructUrl } from '../private/utils/httpParamsUtils';
import { IApiUrls, IHostsConfigs } from '../private/global.interface';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { Service, Container } from '../../symphony';

@Service()
export class CodeApi implements ICodeAPI {
	private environmentUrl: string;

	private useMock: boolean;

	private trendsApiHost: string;

	private mock: ICodeApiMock;

	private versions: ICodeVersions;

	private axiosInstance: AxiosInstanceType;

	constructor() {
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).playgroundApiHost;
		this.useMock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.codeApi?.useMock;
		this.mock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.codeApi?.mock;
		this.versions = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.codeApi?.version;

		this.trendsApiHost = (
			Container.take('global', 'envUrl') as IApiUrls
		)?.trendsApiHost;

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.public)
	public getDefaultCodes(): Observable<ICodeDefaultsResponse> {
		if (this.useMock) return of(this.mock.defaultCodes);
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				'codes',
				this.versions?.getDefaultCodes,
			),
		);
	}

	@Authorize(AuthLevel.public)
	public getDefaultCode(
		language: DefaultCodeLanguages,
	): Observable<ICodeDefaultResponse> {
		if (this.useMock) return of(this.mock.defaultCode);
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				`codetemplates/default/${language}`,
				this.versions?.getDefaultCode,
			),
		);
	}

	@Authorize(AuthLevel.public)
	public getUserCode(codeId: string): Observable<IUserCodeResponse> {
		if (this.useMock) return of(this.mock.userCode);
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				`usercodes/${codeId}`,
				this.versions?.getUserCode,
			),
		);
	}

	@Authorize(AuthLevel.authenticated)
	public saveUserCode(
		payload: IUserCodePayload,
	): Observable<IUserCodeResponse> {
		if (this.useMock) return of(this.mock.userCode);
		return this.axiosInstance.post(
			constructUrl(
				this.environmentUrl,
				'usercodes',
				this.versions?.saveUserCode,
			),
			payload,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public deleteUserCode(id: number): Observable<IDeleteCodeResponse> {
		return this.axiosInstance.delete(
			constructUrl(
				this.environmentUrl,
				`usercodes/${id}`,
				this.versions?.deleteUserCode,
			),
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getTemplate(
		templateId: number,
		courseId?: number,
	): Observable<ICodeTemplateResponse> {
		if (this.useMock) return of(this.mock.codeTemplate);
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				`codetemplates/${templateId}`,
				this.versions?.getTemplate,
			),
			{
				params: {
					courseId,
				},
			},
		);
	}

	@Authorize(AuthLevel.public)
	public compileCode(
		payload: ICodeCompilePayload,
	): Observable<ICodeCompilationResponse> {
		if (this.useMock) return of(this.mock.compiledCode);
		return this.axiosInstance.post(
			constructUrl(
				this.environmentUrl,
				'compile',
				this.versions?.compileCode,
			),
			payload,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getFreeCodes(): Observable<IFreeCodesResponse> {
		if (this.useMock) return of(this.mock.freeCodes);
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				'codetemplates/free',
				this.versions?.freeCodes,
			),
		);
	}

	@Authorize(AuthLevel.public)
	public getLanguagesCatalog(): Observable<ILanguagesCatalogResponse> {
		return this.axiosInstance.get(
			constructUrl(this.environmentUrl, 'supportedlanguages'),
		);
	}

	@Authorize(AuthLevel.public)
	public getCodeBits({
		query,
		filter,
		language,
		index,
		count,
		profileId = 0,
	}: ICodeBitsSearchParams): Observable<ICodeBitsDetailsResponse> {
		return this.axiosInstance.get(
			constructUrl(this.trendsApiHost, 'projects/detailed'),
			{
				params: {
					query,
					filter,
					language,
					index,
					count,
					profileId,
				},
			},
		);
	}

	@Authorize(AuthLevel.authenticated)
	public voteUserCode(
		payload: IVoteUserCodePayload,
	): Observable<IVoteUserCodeResponse> {
		const monolithApi = (Container.take('global', 'envUrl') as IApiUrls)
			.monolithApiHost;
		return this.axiosInstance.post(
			constructUrl(monolithApi, 'Playground/VoteCode'),
			payload,
		);
	}
}

export * from '../private/code-api/code-api.interface';

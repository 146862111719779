import { TrackingObjType } from '../../../public/tracking/tracking.interface';
import { Tracking } from '../tracking';
import { ITracking } from '../tracking.interface';
import { gtmEventsMapping } from './gtmEventsMapping';

export interface ITagManagerAction {
    event: string;
    [key: string]: string | number;
}

declare const dataLayer: Array<ITagManagerAction>;

export class GTMTracking extends Tracking implements ITracking {
	constructor() {
		super(gtmEventsMapping);
	}

	public handleTracking = (trackingObj: TrackingObjType): void => {
		if (typeof dataLayer === 'undefined') return;

		const { params, eventName } = this.getEventParams(trackingObj);

		if (!eventName) return;

		dataLayer.push({
			event: eventName,
			...params,
		});
	};
}

import { SlIcon } from '@sololearnorg/icons';
import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Comparison.scss';
import { i18n } from '../../../../../symphony';

type ComparisonProps = {
	table: {
		header: string[];
		rows: (string | boolean)[][];
	};
};

export const Comparison: FC<ComparisonProps> = ({ table }) => {
	useStyles(s);
	return <div className='comparison'>
		<div className="comparison-table">
			<div className="comparison-header">
				<div className='comparison-td'>{table.header[0]}</div>
				<div className='comparison-td'>{table.header[1]}</div>
				<div className='comparison-td'>
					<div className='comparison-badge'>{table.header[2]}</div>
				</div>
			</div>
			{table.rows.map(r => <div key={r[0] as string} className="comparison-row">
				<div className='comparison-td feature'>{i18n.t(r[0] as string)}</div>
				<div className='comparison-td checkmark'>{r[1] ? <div className='comparison-icon'><SlIcon iconId={'check'} /></div> : ''}</div>
				<div className='comparison-td checkmark'>{r[2] ? <div className='comparison-icon'><SlIcon iconId={'check'} /></div> : ''}</div>
			</div>)}
		</div>
	</div>;
};
import { AuthService, Container, history } from '../../features/symphony';

const authService: AuthService = Container.take('global', AuthService);

export const profileGuard = () => (): boolean => {
	if (typeof window === 'undefined') {
		return false;
	}
	if (!authService.isLoggedIn(true)) {
		const uri = encodeURIComponent('/profile');
		history.replace(`/users/login?returnUrl=${uri}`);
		return false;
	}
	history.replace(`/profile/${authService.getUser().id}${window.location.search}`);

	return true;
};

import { BehaviorSubject } from 'rxjs';
import { Service } from '../../../symphony';

@Service()
export class SlLoginViewService {
	public currentView$ = new BehaviorSubject<'login' | 'signup'>('login');

	public setLoginCurrentView(): void {
		this.currentView$.next('login');
	}

	public setSignupCurrentView(): void {
		this.currentView$.next('signup');
	}

	constructor() {
		this.currentView$.subscribe();
	}
}

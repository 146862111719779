import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "../../symphony";
import { DiscountCampaignService } from "../private/discountcampaign.service";

type Props = {};

export const DiscountCampaign: FC<Props> = () => {
	const { campaignId, discountHash } = useParams();

	const { showDiscountPaywall, closeDiscountPaywall } = Container.take('global', DiscountCampaignService);

	useEffect(() => {
		showDiscountPaywall(campaignId, discountHash);
		return () => {
			setTimeout(() => {
				closeDiscountPaywall();
			}, 0);
		};
	}, []);

	return null;
};

import { Service } from 'typedi';
import { BehaviorSubject } from 'rxjs';
import { Container } from './container.global';
import { ModalService } from './modal.service';
import { BlockerModalIds } from '../private/constants';
import { history } from './history';

@Service()
export class TooltipService {
	constructor() {
		history.listen(() => {
			const openedModalIds$ = Container.take(
				'global',
				ModalService,
			).openedModalIds$;

			if (!openedModalIds$.value.find((item) => BlockerModalIds[item])) document.body.style.overflow = 'auto';
		});
	}

	private tooltips: { [key: string]: (state: boolean) => void } = {};

	private openedTooltips: string[] = [];

	public openedTooltipIds$ = new BehaviorSubject<string[]>([]);

	public register = (
		id: string,
		openStateSetter: (state: boolean) => void,
	): void => {
		this.tooltips[id] = openStateSetter;
	};

	public open = (id: string): void => {
		if (this.tooltips[id]) {
			this.tooltips[id](true);
			this.openedTooltips = [...this.openedTooltips, id];
			this.openedTooltipIds$.next(this.openedTooltips);
			this.disableScroll();
		}
	};

	public openAtTime = (id: string, milliseconds = 100): void => {
		if (!this.openedTooltips.length) {
			this.open(id);
		} else if (!this.openedTooltips.includes(id)) {
			const subscription = this.openedTooltipIds$.subscribe(
				(tooltips: string[]) => {
					if (!tooltips.length) {
						setTimeout(() => {
							this.open(id);
							subscription.unsubscribe();
						}, milliseconds);
					}
				},
			);
		}
	};

	public close = (id: string): void => {
		if (this.tooltips[id]) {
			this.tooltips[id](false);
			this.removeTooltip(id);
		}
	};

	public removeTooltip = (id: string): void => {
		this.openedTooltips = this.openedTooltips.filter(
			(tooltipId: string) => tooltipId !== id,
		);
		this.openedTooltipIds$.next(this.openedTooltips);
		if (!this.openedTooltips.length) {
			this.enableScroll();
		}
	};

	public closeAll = (): void => {
		Object.keys(this.tooltips).forEach((id) => {
			this.tooltips[id](false);
		});
		this.openedTooltips.length = 0;
		this.openedTooltipIds$.next(this.openedTooltips);
		this.enableScroll();
	};

	private disableScroll = (): void => {
		document.body.style.overflow = 'hidden';
	};

	private enableScroll = (): void => {
		document.body.style.overflow = 'auto';
	};
}

import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useMatch } from 'react-router-dom';
import { Button } from '@sololearnorg/sol';

import { Container, HeartModalSource, HeartService } from '../../../../../symphony';
import { IconHeartFilled } from '../../icons/IconHeartFilled';
import { IconHeartEmpty } from '../../icons/IconHeartEmpty';
import { IconHeartInfinite } from '../../icons/IconHeartInfinite';
import { IconPro } from '../../icons/IconPro';
import { IconProSmall } from '../../icons/IconProSmall';

import s from './HeartsInfo.scss';

export interface IHeartsInfo {
	source: HeartModalSource;
	heartsCount: number;
	maxHeartsCount: number;
	hours?: number;
	minutes?: number;
	title: string;
	description: string;
	cta?: string[];
	onClick?: (from: 'course' | 'lesson') => void;
}
export const HeartsInfo: React.FC<IHeartsInfo> = React.memo(
	({
		source,
		heartsCount,
		maxHeartsCount,
		hours,
		minutes,
		title,
		description,
		cta,
		onClick,
	}) => {
		useStyles(s);
		const isLesson = useMatch(':language/learn/courses/:courseAlias/lesson/:id/*');
		const descriptionText = description
			.replace('{hours}', hours?.toString())
			.replace('{minutes}', minutes?.toString());

		const renderCtaText = (val: string) => (val === '<pro>' ? <IconPro /> : val);
		const onButtonClick = () => {
			onClick(isLesson ? 'lesson' : 'course');
		};

		return (
			<div className="hearts-info">
				<div className="hearts-info__title">{title}</div>
				{heartsCount === -1 ? (
					<div className="hearts-info__infinite-heart">
						<div className="hearts-info__infinite-heart__heart-icon">
							<IconHeartInfinite />
						</div>
						<div className="hearts-info__infinite-heart__pro-icon">
							<IconProSmall />
						</div>
					</div>
				) : (
					<div className="hearts-info__hearts">
						{[...Array(maxHeartsCount)].map((_v, i) => (
							<div key={i} className="hearts-info__heart">
								{i < heartsCount ? (
									<IconHeartFilled />
								) : (
									<IconHeartEmpty />
								)}
							</div>
						))}
					</div>
				)}
				<div className="hearts-info__description">
					{descriptionText}
				</div>
				{cta && (
					<Button
						className="hearts-info__cta"
						buttonType={
							heartsCount === -1 ? 'secondary' : 'primary'
						}
						onClick={onButtonClick}
					>
						{cta.map(renderCtaText)}
					</Button>
				)}
			</div>
		);
	},
);

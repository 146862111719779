import { Container } from '../../../symphony';
import { isNode } from '../utilsPublic/utilsPublic';

export function getInitialServerSideData(key: string, defaultValue = null) {
	if (isNode()) {
		return Container.take('global', key);
	}

	if ((window as any).initialData?.[key]) {
		const initialData = (window as any).initialData[key];
		delete (window as any).initialData[key];
		return initialData;
	}

	return defaultValue;
}

import { Observable } from 'rxjs';
import {
	ILeaderboardInfo,
	ILeaderboardUser,
	LeagueRank,
	ILeaderboardConfig,
} from '../../../symphony';

export interface ILeaderboardApiConfig {
    environmentUrl: string;
    versions?: ILeaderboardApiVersions;
}

export interface ILeaderboardApi {
    getLeaderboardLeagues: () => Observable<ILeaderboardLeague[]>;
    getLeaderboard: () => Observable<ILeaderboard>;
    getLeaderboardInfo: () => Observable<ILeaderboardInfo>;
    joinLeaderboard: () => Observable<IJoinLeaderboard>;
    toggleLeaderboard: (
        payload: IToggleLeaderboardPayload
    ) => Observable<unknown>;
}

export interface ILeaderboardApiVersions {
    getLeaderboardLeagues?: string;
    getLeaderboard?: string;
    getLeaderboardInfo?: string;
    joinLeaderboard?: string;
    toggleLeaderboard?: string;
    getLeaderboardPredictedState?: string;
}

export interface ILeaderboard {
    id: string;
    leagueRank: LeagueRank;
    startDate: string;
    endDate: string;
    config: ILeaderboardConfig;
    state: LeaderboardState;
    leaderboardUsers: ILeaderboardUser[];
}

export interface ILeaderboardLeague {
    id: string;
    rank: LeagueRank;
    name: ILeaderboardLeagueName[];
    strokeColor: string;
    backgroundColor: string;
    iconUrl: string;
}
export interface ILeaderboardLeagueName {
    locale: string;
    name: string;
}

export interface IJoinLeaderboard {
    responseCode?: JoinLeaderboardResponseCode;
}

export interface IToggleLeaderboardPayload {
    enable: boolean;
}

export enum JoinLeaderboardResponseCode {
    Joined = 1,
    Suspended = 2,
    SomethingWentWrong = 3,
    AlreadyJoined = 4,
}

export enum LeaderboardState {
    Open = 1,
    StartedAndOpen = 2,
    StartedAndClosed = 3,
    Ended = 4,
}

export enum UserState {
    Ok = 1,
    NotEnoughXp = 2,
    DoAction = 3,
}

export const appleConfigs = {
	clientId: 'com.sololearn.ios',
	scope: 'email name',
	state: 'Initial user authentication request',
	nonce: 'nonce',
	usePopup: true,
};

export const appleScriptConfigs = {
	src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
	type: 'text/javascript',
};

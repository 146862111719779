import { Observable } from 'rxjs';

import { IUserSetting } from '../../../symphony';

import { IResponse } from '../global.interface';

export interface IUserSettingsApiMock {
    userSettings: any;
}

export interface IUserSettingsVersions {
    getUserSettings?: string;
    getUserSettingsData?: string;
    updateUserSettings?: string;
    updateUserSettingsData?: string;
    getAppSettings?: string;
}

export interface IUserSettingsApiConfig {
    environmentUrl: string;
    useMock?: boolean;
    mock?: IUserSettingsApiMock;
    versions?: IUserSettingsVersions;
}

export interface IUserSettingsResponse extends IResponse {
    correlationID?: number;
    data: IUserSettingsData;
}

export interface IUserSettingsDataResponse extends IResponse {
    correlationID?: number;
    data: IUserSetting[];
}

export interface IAppSettingsResponse extends IResponse {
    correlationID?: number;
    data: IAppSettingData;
}

export interface IAppSetting {
    privacyPolicyVersion: number;
    termsAndConditionsVersion: number;
}

export interface IAppSettingData {
    appSetting: IAppSetting;
}

export interface IUserSettingsData {
    userSettings: IUserSetting[];
}

export enum AppSettingKeys {
    privacyPolicyVersion = 'privacyPolicyVersion',
    termsAndConditionsVersion = 'termsAndConditionsVersion',
}

export enum UserSettingKeys {
    joinedLeaderboard = 'joinedLeaderboard',
    navItemLeaderboardBadgeSeenOnWeb = 'navItemLeaderboardBadgeSeenOnWeb',
}

export interface IUserSettingsApi {
    getUserSettings: () => Observable<IUserSettingsResponse>;
    getUserSettingsData: () => Observable<IUserSettingsDataResponse>;
    updateUserSettings: (
        data: IUserSetting[]
    ) => Observable<IUserSettingsResponse>;
    updateUserSettingsData: (
        data: IUserSetting[]
    ) => Observable<IUserSettingsResponse>;
    getAppSettings: () => Observable<IAppSettingsResponse>;
}

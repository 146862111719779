import React from 'react';

export const IconBitSmall = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
	>
		<path
			d="M13.6003 5.48137C13.6001 4.90804 13.2926 4.37871 12.7944 4.09414C12.7943 4.09408 12.7945 4.0942 12.7944 4.09414L8.79414 1.81024C8.30226 1.5294 7.6984 1.5294 7.20652 1.81024L3.20656 4.09395C3.20649 4.094 3.20663 4.09391 3.20656 4.09395C2.70817 4.3786 2.40039 4.90834 2.40039 5.48193V10.8202C2.40039 11.3935 2.70759 11.9228 3.20546 12.2076C3.20583 12.2078 3.20619 12.208 3.20656 12.2082L7.20652 14.4919C7.20699 14.4922 7.20746 14.4925 7.20793 14.4927C7.69911 14.7725 8.30163 14.7725 8.7928 14.4927C8.79325 14.4924 8.7937 14.4922 8.79414 14.4919L12.7941 12.2082C12.7945 12.208 12.7949 12.2078 12.7953 12.2075C13.2931 11.9227 13.6003 11.3934 13.6003 10.8202L13.6003 5.48137C13.6003 5.48156 13.6003 5.48118 13.6003 5.48137Z"
			fill="#8153E1"
		/>
		<path
			d="M13.5999 5.48102V10.8208C13.5999 11.3945 13.2923 11.9242 12.7938 12.2088L8.79381 14.4925C8.54787 14.6329 8.27394 14.7032 8 14.7032V7.75203L13.383 4.67871C13.5225 4.91833 13.5997 5.19416 13.5999 5.48102Z"
			fill="#AB88F3"
		/>
		<path
			d="M13.3812 4.67811L7.99819 7.75143L2.61523 4.6781C2.75443 4.43902 2.95567 4.23598 3.20442 4.09395L7.20438 1.81024C7.69627 1.5294 8.30012 1.5294 8.79201 1.81024L12.792 4.09395C13.0407 4.23598 13.242 4.43902 13.3812 4.67811Z"
			fill="#D4CCF5"
		/>
	</svg>
);

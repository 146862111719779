import { Observable } from 'rxjs';
import { Container, Service } from '../../../symphony';
import {
	AuthApi, IAppleAuthorizationCredentials, IAppleLoginResponse, IAuthApi, ILoginCredentials, ILoginResponse, ISignUpCredentials, ISocialLoginCredentials,
} from '../../../api/public/auth-api';
import { ILearnEngineApi } from '../../../api/private/learn-engine-api/learn-engine-api.interface';
import { LearnEngineApi } from '../../../api/public/learn-engine/learn-engine-api';

@Service()
export class SlLoginApiService {
	private api: IAuthApi;

	private learnEngineApi: ILearnEngineApi;

	constructor() {
		this.api = Container.take('global', AuthApi);
		this.learnEngineApi = Container.take('global', LearnEngineApi);
	}

	public login(credentials: ILoginCredentials): Observable<ILoginResponse> {
		return this.api.login(credentials);
	}

	public signup(credentials: ISignUpCredentials): Observable<ILoginResponse> {
		return this.api.signup(credentials);
	}

	public w2aSignup(): Observable<ILoginResponse> {
		return this.api.w2aSignup();
	}

	public setPassword(oldPassword: string, newPassword: string): Observable<unknown> {
		return this.api.setPassword(oldPassword, newPassword);
	}

	public authorizeApple(
		credentials: IAppleAuthorizationCredentials,
	): Observable<IAppleLoginResponse> {
		return this.api.authorizeApple(credentials);
	}

	public socialLogin(
		credentials: ISocialLoginCredentials,
	): Observable<ILoginResponse> {
		return this.api.socialLogin(credentials);
	}

	public addBetaTester(): Observable<null> {
		return this.learnEngineApi.addBetaTester();
	}
}

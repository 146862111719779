import React, { useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, SubscriptionPlanService, UserTrackService } from '../../../symphony';

import {
	ISlFooterLinks,
	SlFooterLinks,
} from '../../private/components/SlFooterLinks/SlFooterLinks';
import {
	ILinkConfigs,
	SlFooterSocial,
} from '../../private/components/SlFooterSocial/SlFooterSocial';
import { SlNavigationTrackingService } from '../../private/services/sl-navigation-tracking.service';
import { getFooterLinks, getSocialLinks } from './constants';
import { FooterCatalog } from '../../private/components/FooterCatalog/FooterCatalog';
import { SlNavigationContext } from '../../private/global-constants';

import s from './SlFooter.scss';
import { PlanConfigKey } from '../../../api/public/subscription-api';
import { useContainerData } from '../../../shared/public/SlHooks/useContainerData';

export interface ISlFooter {
	socialLinks?: ILinkConfigs[];
	linksConfig?: ISlFooterLinks[];
}

export const SlFooter: React.FC<ISlFooter> = React.memo(
	({
		socialLinks = getSocialLinks().socialLinks,
		linksConfig = getFooterLinks().linksConfig,
	}) => {
		useStyles(s);

		const subscriptionPlanService = Container.take('global', SubscriptionPlanService);
		const { userTrack } = useContainerData('global', UserTrackService, [
			'userTrack'
		]);


		const processedLinks = (): ISlFooterLinks[] => {
			return linksConfig.filter(l => {
				if (l.link !== '/payments/pro') {
					return l;
				}
				return subscriptionPlanService.getConfig(PlanConfigKey.showNavigationGoPro);
			});
		};

		useEffect(() => {
			const unsubscriber = new Subject<void>();

			const { footerTrackActions$ } = Container.take(
				SlNavigationContext,
				SlNavigationTrackingService,
			);

			footerTrackActions$.pipe(takeUntil(unsubscriber)).subscribe();

			return () => {
				unsubscriber.next();
				unsubscriber.complete();
			};
		}, []);

		return (
			userTrack && <footer className="sl-footer">
				<FooterCatalog />
				<SlFooterSocial socialLinks={socialLinks} />
				<SlFooterLinks linksConfig={processedLinks()} />
			</footer>
		);
	},
);

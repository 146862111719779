import { matchPath } from 'react-router-dom';

// Todo - define and complete the list of pages
export const pageNames = {
	homepage: 'HOMEPAGE',
	singleBlog: 'SINGLE_BLOG',
	blogList: 'BLOG_LIST',
	faq: 'FAQ',
	privacyPolicy: 'PRIVACY AND POLICY',
	termsOfUse: 'TERMS OF USE',
	career: 'CAREER',
	contact: 'CONTACT',
	singleCoursePage: 'SINGLE_COURSE_PAGE',
	coursesCatalog: 'COURSES_CATALOG',
	playground: 'PLAYGROUND',
	playgroundCatalog: 'PLAYGROUND_CATALOG',
	onboarding: 'ONBOARDING',
	discuss: 'DISCUSS',
	discussQuestion: 'DISCUSS_QUESTION',
	languageLandingPage: 'LANGUAGE_LANDING_PAGE',
};

export const getPageName = (pathname: string) => {
	if (matchPath('', pathname)) {
		return pageNames.homepage;
	}

	if (matchPath('blog/:id', pathname)) {
		return pageNames.singleBlog;
	}

	if (matchPath('blog', pathname)) {
		return pageNames.blogList;
	}

	if (matchPath('faq', pathname)) {
		return pageNames.faq;
	}

	if (matchPath('privacy-policy', pathname)) {
		return pageNames.privacyPolicy;
	}

	if (matchPath('terms-of-use', pathname)) {
		return pageNames.termsOfUse;
	}

	if (matchPath('career', pathname)) {
		return pageNames.career;
	}

	if (matchPath('contact', pathname)) {
		return pageNames.contact;
	}

	if (
		matchPath('learn/courses/:courseAlias', pathname)
        || matchPath('learning/:courseId', pathname)
	) {
		return pageNames.singleCoursePage;
	}

	if (matchPath('learn', pathname)) {
		return pageNames.coursesCatalog;
	}

	if (matchPath('/learn/languages/:languageId', pathname)) {
		return pageNames.languageLandingPage;
	}

	if (matchPath('/compiler-playground/:languageId', pathname)) {
		return pageNames.playground;
	}

	if (matchPath('/compiler-playground', pathname)) {
		return pageNames.playgroundCatalog;
	}

	if (matchPath('onboarding/*', pathname)) {
		return pageNames.onboarding;
	}

	if (matchPath('discuss', pathname)) {
		return pageNames.discuss;
	}

	if (matchPath('discuss/:id/:alias', pathname)) {
		return pageNames.discussQuestion;
	}

	return '';
};

import { matchPath } from 'react-router-dom';

export const getGTMPageCategories = (pathname) => {
	const isHomeRoute = pathname === '/';
	const isCourseListRoute = matchPath('learn', pathname);
	const isCourseRoute = matchPath('learn/courses/:courseAlias', pathname) || matchPath('learning/:courseId', pathname);
	const isBlogRoute = matchPath('blog', pathname);
	const isSingleBlogRoute = matchPath('blog/:id', pathname);
	const isLoginRoute = matchPath('users/login', pathname);
	const isSignupRoute = matchPath('users/signup', pathname) || matchPath('onboarding/auth/signup', pathname);
	const isProRoute = matchPath('payments/pro', pathname);
	const isPrivacyPolicyRoute = matchPath('privacy-policy', pathname);
	const isTermsOfUseRoute = matchPath('terms-of-use', pathname);
	const isFAQRoute = matchPath('faq', pathname);
	const isContactRoute = matchPath('contact', pathname);
	const isCareerRoute = matchPath('career', pathname);
	const isPlaygroundRoute = matchPath('compiler-playground', pathname)
                              || matchPath('compiler-playground/:languageId', pathname)
                              || matchPath('compiler-playground/:languageId/*', pathname);
	const isLanguageRoute = matchPath('learn/languages/:languageAlias', pathname);
	const isDiscussRoute = matchPath('discuss', pathname);
	const isDiscussThreadRoute = matchPath('discuss/:id/:alias', pathname);

	if (isHomeRoute) {
		return {
			category: 'Home',
			subCategory: '',
		};
	}

	if (isCourseListRoute) {
		return {
			category: 'Learning',
			subCategory: 'Home',
		};
	}

	if (isCourseRoute) {
		return {
			category: 'Learning',
			subCategory: 'Courses',
		};
	}

	if (isBlogRoute) {
		return {
			category: 'Blog',
			subCategory: 'Home',
		};
	}

	if (isSingleBlogRoute) {
		return {
			category: 'Blog',
			subCategory: 'Article',
		};
	}

	if (isSignupRoute) {
		return {
			category: 'Register',
			subCategory: 'Signup',
		};
	}

	if (isLoginRoute) {
		return {
			category: 'Register',
			subCategory: 'Login',
		};
	}

	if (isProRoute) {
		return {
			category: 'Pro',
			subCategory: 'Plans',
		};
	}

	if (isPrivacyPolicyRoute) {
		return {
			category: 'Legal',
			subCategory: 'Privacy Policy',
		};
	}

	if (isTermsOfUseRoute) {
		return {
			category: 'Legal',
			subCategory: 'Terms of use',
		};
	}

	if (isFAQRoute) {
		return {
			category: 'Others',
			subCategory: 'FAQs',
		};
	}

	if (isContactRoute) {
		return {
			category: 'Others',
			subCategory: 'Contact',
		};
	}

	if (isContactRoute) {
		return {
			category: 'Others',
			subCategory: 'Contact',
		};
	}

	if (isCareerRoute) {
		return {
			category: 'Others',
			subCategory: 'Careers',
		};
	}

	if (isPlaygroundRoute) {
		return {
			category: 'Playground',
			subCategory: '',
		};
	}

	if (isLanguageRoute) {
		return {
			category: 'Learning',
			subCategory: 'Languages',
		};
	}

	if (isDiscussRoute) {
		return {
			category: 'Discuss',
			subCategory: 'Home',
		};
	}

	if (isDiscussThreadRoute) {
		return {
			category: 'Discuss',
			subCategory: 'Thread',
		};
	}

	return { category: '', subCategory: '' };
};

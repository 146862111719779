import React from 'react';
import { SlIcon } from '@sololearnorg/icons';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './Icon.scss';

export interface IIconProps {
	iconId: string;
	className?: string;
}

export const Icon: React.FC<IIconProps> = React.memo(
	({ iconId, className }) => {
		useStyles(s);

		return (
			<div
				className={`sl-login-icon ${className}`}
				sl-test-data="cmpIcon"
			>
				<SlIcon iconId={iconId} />
			</div>
		);
	},
);

import React from 'react';

export const IconProSmall = () => (
	<svg
		width="37"
		height="16"
		viewBox="0 0 37 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="37" height="16" rx="8" fill="#FFA310" />
		<path
			d="M11.2855 4.15596H8V12.344H9.62469V9.59668H11.2674C13.0365 9.59668 14.0595 8.44495 14.0595 6.88532C14.0595 5.34968 13.0486 4.15596 11.2855 4.15596ZM12.4047 6.88532C12.4047 7.99506 11.7247 8.32498 10.5935 8.32498H9.61867V5.44566H10.9726C11.8692 5.44566 12.4047 5.96754 12.4047 6.88532Z"
			fill="#000A23"
		/>
		<path
			d="M18.4882 4.15596H15.2088V12.344H16.8094V9.29075H18.2716L19.7098 12.344H21.527L19.8722 9.04481C20.498 8.79887 21.3706 8.09104 21.3706 6.74735C21.3706 5.30169 20.4379 4.15596 18.4882 4.15596ZM16.8094 8.07304V5.42766H18.2536C19.09 5.42766 19.6917 5.87756 19.6917 6.77735C19.6917 7.62315 19.1441 8.07304 18.2295 8.07304H16.8094Z"
			fill="#000A23"
		/>
		<path
			d="M29.5 8.80487V7.75512C29.5 5.43966 28.1882 4 25.9859 4C23.7955 4 22.4597 5.42766 22.4597 7.75512V8.80487C22.4597 11.1143 23.7775 12.5 25.9738 12.5C28.1702 12.5 29.5 11.1143 29.5 8.80487ZM27.8512 7.74312V8.81087C27.8512 10.3885 27.069 11.1503 25.9798 11.1503C24.8967 11.1503 24.1145 10.3885 24.1145 8.81087V7.74312C24.1145 6.16549 24.8967 5.34968 25.9798 5.34968C27.069 5.34968 27.8512 6.16549 27.8512 7.74312Z"
			fill="#000A23"
		/>
	</svg>
);

import { Service } from 'typedi';
import { Container } from '../../../symphony';

import { SlNavigationDataService } from './sl-navigation-data.service';
import { SlNavigationContext } from '../global-constants';
import { SlNavbarState } from '../global-interface';
import { ISlFooterService } from '../../../shared/public/globalInterfaces/globalInterfaces';
import { PaywallsService } from '../../../_paywalls/private/services/paywalls.service';
import { Touchpoint } from '../../../api/public/subscription-api';

@Service()
export class SlFooterService implements ISlFooterService {
	private paywallsService = Container.take('paywalls', PaywallsService);

	private dataService = Container.take(
		SlNavigationContext,
		SlNavigationDataService,
	);

	public show() {
		this.dataService.footerState$.next(SlNavbarState.shown);
	}

	public hide() {
		this.dataService.footerState$.next(SlNavbarState.hidden);
	}

	public openPaywall = () => {
		this.paywallsService.openPaywall({
			touchpoint: Touchpoint.footer,
			props: {}
		});
	};
}

import {
	BehaviorSubject,
	Observable,
	Subscription,
	tap,
	of,
	catchError,
	combineLatestWith,
} from 'rxjs';
import { Service } from 'typedi';

import { Container } from './container.global';
import {
	BitService,
	IBits,
	BitLocationType,
} from './bit.service';
import { track } from './tracking/data-tracking';

export enum WeekDay {
    Sunday = 'sunday',
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
}

export enum StreakSource {
    Lesson = 'LESSON',
    ModuleQuiz = 'MODULE_QUIZ',
    Booster = 'BOOSTER',
    CodeProject = 'CODE_PROJECT',
    OptionalCC = 'OPTIONAL_CC',
    Undefined = 'UNDEFINED',
}

enum StreakFreezeState {
    Used = 1,
    Bought = 2,
}

enum StreakPageType {
    Info = 'INFO',
    Celebration = 'CELEBRATION',
    LearningPath = 'LEARNING_PATH',
    Profile = 'PROFILE',
    Navigation = 'NAVIGATION',
}

export interface IStreakDTO {
    userId: number;
    startDate: string;
    lastReachDate: string;
    expirationUtcDate: string;
    todayReached: boolean;
    daysCount: number;
    maxDaysCount: number;
    freezeAmount: number;
    milestones: IStreakMilestone[];
    streakFreezeHistory: IStreakFreezeData[];
}

interface IStreakMilestone {
    date: string;
    isReached?: boolean;
    rewardAmount: number;
    dayCount: number;
}

interface IStreakFreezeData {
    date: string;
    state: StreakFreezeState;
    freezeAmount: number;
}

export interface IStreakConfigurationDTO {
    maxFreezeAmount: number;
    goalConfig: IStreakGoalConfig;
}

export interface IStreakGoalConfig {
    header: string;
    defaultFooter: string;
    ctaLabel: string;
    options: IStreakGoalOption[];
}

export interface IStreakGoalOption {
    daysAmount?: number;
    text?: string;
    footer: string;
    order?: number;
    selected?: boolean;
}

export interface IStreakWeekDay {
    id: number;
    weekDay: WeekDay;
    isReached?: boolean;
    isMilestone?: boolean;
    isFreeze?: boolean;
    isToday?: boolean;
    rewardAmount?: number | null;
}

export interface IStreak {
    weekDays: IStreakWeekDay[];
    daysCount: number;
    maxDaysCount: number;
    todayReached: boolean;
    freezeAmount: number;
    maxFreezeAmount: number;
}

interface IBuyStreakSaverResponse {
    data: IBits;
}

const WEEK_DAYS = [
	WeekDay.Sunday,
	WeekDay.Monday,
	WeekDay.Tuesday,
	WeekDay.Wednesday,
	WeekDay.Thursday,
	WeekDay.Friday,
	WeekDay.Saturday,
];

const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

const MAX_DAYS_DIF = 3;

@Service()
export class StreakService {
	public streakDTO$ = new BehaviorSubject<IStreakDTO>(null);

	public streak$ = new BehaviorSubject<IStreak>(null);

	public configuration$ = new BehaviorSubject<IStreakConfigurationDTO>(null);

	public openStreakSaverModal$ = new BehaviorSubject<boolean>(null);

	public isBuyingStreakSaver$ = new BehaviorSubject<boolean>(null);

	constructor() {
		this.streakDTO$
			.pipe(combineLatestWith(this.configuration$))
			.subscribe(([str, conf]) => this.setStreak(str, conf));
	}

	private bitService = Container.take('global', BitService);

	private lastAchieveSource: StreakSource = StreakSource.Undefined;

	private lastAchieveSucceeded: boolean = null;

	private firstAchieve: boolean = null;

	private courseAlias: string = null;

	private relationId: string = null;

	private location: BitLocationType = null;

	private buyStreakSaver: () => Observable<IBuyStreakSaverResponse>;

	private buyStreakSaverSubscription: Subscription = null;

	public setCourse = (courseAlias, relationId): void => {
		this.courseAlias = courseAlias;
		this.relationId = relationId;
	};

	public tryUpdateStreak = (
		newStreak: IStreakDTO,
		source: StreakSource,
	): void => {
		if (
			!newStreak
            || newStreak.daysCount === this.streakDTO$.value?.daysCount
		) {
			this.lastAchieveSucceeded = false;
			this.firstAchieve = false;
			return;
		}

		this.firstAchieve = this.streakDTO$.value?.maxDaysCount === 0;
		this.lastAchieveSucceeded = true;
		this.lastAchieveSource = source;
		this.streakDTO$.next(newStreak);
	};

	public getPredictedXPs = (): number => {
		const streak = this.streak$.value;
		if (!streak) {
			return null;
		}

		const today = streak.weekDays.find((d) => d.isToday);
		if (today && today.isMilestone && !today.isReached) {
			return today.rewardAmount;
		}
		return 0;
	};

	public wasLastAchieveSuccessful = (): boolean => this.lastAchieveSucceeded;

	public isFirstAchieve = (): boolean => this.firstAchieve;

	public setBuyStreakSaver = (
		buyStreakSaver: () => Observable<IBuyStreakSaverResponse>,
	): void => {
		this.buyStreakSaver = buyStreakSaver;
	};

	private setStreak = (
		streak: IStreakDTO,
		config: IStreakConfigurationDTO,
	): void => {
		if (!streak || !config) {
			this.streak$.next(null);
			return;
		}

		const today = this.getAbsoluteDate();
		const weekStartDate = this.getWeekStartDate(streak.startDate);
		const streakEndDate = this.getStreakEndDate(streak.lastReachDate);
		const milestones = this.getMilestones(streak.milestones);
		const freezeHistory = this.getFreezeHistory(streak.streakFreezeHistory);

		const weekDays: IStreakWeekDay[] = [];
		let isReached = streakEndDate > 0;

		for (
			let i = 0, date = weekStartDate;
			i < WEEK_DAYS.length;
			++i, date += MILLISECONDS_IN_DAY
		) {
			const milestone = milestones.find((m) => m.date === date);
			const rewardAmount = milestone?.rewardAmount || null;
			const isFreeze = freezeHistory?.some((m) => m.date === date);

			if (isReached && !isFreeze && date > streakEndDate) {
				isReached = false;
			}

			weekDays.push({
				id: i,
				weekDay: this.getWeekDay(date),
				isReached,
				isMilestone: !!milestone,
				isFreeze,
				isToday: date === today,
				rewardAmount,
			});
		}

		this.streak$.next({
			weekDays,
			daysCount: streak.daysCount,
			maxDaysCount: streak.maxDaysCount,
			todayReached: streak.todayReached,
			freezeAmount: streak.freezeAmount,
			maxFreezeAmount: config.maxFreezeAmount,
		});
	};

	private getWeekStartDate(startDate: string): number {
		const today = this.getAbsoluteDate();
		let weekStartDate = today;
		if (startDate) {
			weekStartDate = this.getAbsoluteDate(startDate);

			const daysDif = (today - weekStartDate) / MILLISECONDS_IN_DAY;
			if (daysDif > MAX_DAYS_DIF) {
				weekStartDate = today - MAX_DAYS_DIF * MILLISECONDS_IN_DAY;
			}
		}

		return weekStartDate;
	}

	private getStreakEndDate(lastReachDate: string): number {
		return lastReachDate ? this.getAbsoluteDate(lastReachDate) : 0;
	}

	private getAbsoluteDate(dateStr?: string): number {
		if (!dateStr) {
			return new Date().setHours(0, 0, 0, 0);
		}
		const year = Number(dateStr.substring(0, 4));
		const month = Number(dateStr.substring(5, 7)) - 1;
		const day = Number(dateStr.substring(8, 10));
		return new Date(year, month, day).setHours(0, 0, 0, 0);
	}

	private getWeekDay(date: number): WeekDay {
		return WEEK_DAYS[new Date(date).getDay()];
	}

	private getMilestones(milestones: IStreakMilestone[]): {
        date: number;
        rewardAmount: number;
    }[] {
		return milestones.map(({ date, rewardAmount }) => ({
			date: this.getAbsoluteDate(date),
			rewardAmount,
		}));
	}

	private getFreezeHistory(freezeHistory: IStreakFreezeData[]): {
        date: number;
    }[] {
		return freezeHistory
			? freezeHistory
				.filter(({ state }) => state === StreakFreezeState.Used)
				.map(({ date }) => ({
					date: this.getAbsoluteDate(date),
				}))
			: null;
	}

	public onGetStreakSaverClick = (location: BitLocationType): void => {
		this.location = location;
		this.openStreakSaverModal$.next(true);
		this.trackGetStreakSaverIconClick();
	};

	public onStreakSaverModalClose = (): void => {
		this.openStreakSaverModal$.next(false);
	};

	public onStreakSaverBuyClick = (): void => {
		if (this.buyStreakSaverSubscription) {
			this.buyStreakSaverSubscription.unsubscribe();
			this.buyStreakSaverSubscription = null;
		}

		this.isBuyingStreakSaver$.next(true);
		this.buyStreakSaverSubscription = this.buyStreakSaver()
			.pipe(
				tap(({ data }) => {
					this.isBuyingStreakSaver$.next(false);
					this.bitService.bits$.next(data);
					this.streak$.next({
						...this.streak$.value,
						freezeAmount: this.streak$.value.freezeAmount + 1,
					});
					this.openStreakSaverModal$.next(false);
				}),
				catchError(() => {
					this.isBuyingStreakSaver$.next(false);
					return of(null);
				}),
			)
			.subscribe(() => {
				this.buyStreakSaverSubscription.unsubscribe();
				this.buyStreakSaverSubscription = null;
			});
	};

	public trackStreakIconClick = (): void => {
		const trackData = {
			event_name: 'user_streak_click',
			version: '1-0-0',
			experience_alias: this.courseAlias,
			relation_id: String(this.relationId),
			streaks_amount: this.streak$.value.daysCount,
			page_type: StreakPageType.LearningPath,
			click_type: 'ICON',
		};
		track(trackData);
	};

	public trackStreakImpression = (): void => {
		const trackData = {
			event_name: 'user_streak_impression',
			version: '3-0-0',
			streak_value: this.streak$.value.daysCount,
			max_streak_value: this.streak$.value.maxDaysCount,
			streak_source: StreakSource.Undefined,
			page_type: StreakPageType.Info,
		};
		track(trackData);
	};

	public trackStreakCelebrationImpression = (): void => {
		const trackData = {
			event_name: 'user_streak_impression',
			version: '3-0-0',
			streak_value: this.streak$.value.daysCount,
			max_streak_value: this.streak$.value.maxDaysCount,
			streak_source: this.lastAchieveSource,
			page_type: StreakPageType.Celebration,
		};
		track(trackData);
	};

	public trackGetStreakSaverIconClick = (): void => {
		const isProfilePage = this.location === BitLocationType.Profile;

		const trackData = {
			event_name: 'user_streak_click',
			version: '1-0-0',
			experience_alias: isProfilePage ? 'undefined' : this?.courseAlias,
			relation_id: isProfilePage ? 'undefined' : String(this?.relationId),
			streaks_amount: this.streak$.value.daysCount,
			page_type: isProfilePage
				? StreakPageType.Profile
				: StreakPageType.Info,
			click_type: 'ADD_STREAK_FREEZE',
		};
		track(trackData);
	};
}

import React, { FC, RefObject, useEffect } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WebToAppPaywallReviews.scss';
import FiveStarsIcon from "../../../../staticPages/public/SlAILiteracy/components/Reviews/icons/FiveStarsIcon";
import { AppstoreLogo } from "../ui/AppstoreLogo/AppstoreLogo";
import { SlIcon } from "@sololearnorg/icons";
import { GooglePlayLogo } from "../ui/GooglePlayLogo/GooglePlayLogo";
import { GoogleAward } from "../ui/GoogleAward/GoogleAward";
import { Container } from "../../../../symphony";
import { PaywallsService } from "../../services/paywalls.service";
import { useInView } from "../../paywalls.utils";

type Props = {
	reviews: any[];
	sectionOrder: number;
};

export const WebToAppPaywallReviews: FC<Props> = ({ reviews, sectionOrder }) => {
	useStyles(s);
	const { trackSectionView } = Container.take('paywalls', PaywallsService);
	const [ref, isInView] = useInView({
		threshold: 0.2
	});

	useEffect(() => {
		isInView && trackSectionView('reviews', sectionOrder);
	}, [isInView]);

	return <div ref={ref as RefObject<HTMLDivElement>} className="paywall-w2a-section paywall-w2a-reviews">
		<h2 className="paywall-w2a-section-title">People love Sololearn</h2>
		<p className="paywall-w2a-reviews-subtext">
			Become a member of our global community of <span>30 million people</span>
		</p>
		<div className="paywall-w2a-reviews-list">
			{reviews.map(r => (<div key={r.reviewer} className="paywall-w2a-reviews-item">
				<div className="paywall-w2a-reviews-stars">
					<FiveStarsIcon />
				</div>
				<p className="paywall-w2a-reviews-text">{r.review}</p>
				<div className="paywall-w2a-reviews-reviewer">
					{r.avatarUrl && <img src={r.avatarUrl} className="paywall-w2a-reviews-avatar" />}
					<div>
						<p className="paywall-w2a-reviews-name">{r.reviewer}</p>
						<p className="paywall-w2a-reviews-reviewer-info">{r.reviewerInfo}</p>
					</div>
				</div>
			</div>))}
		</div>
		<div className="paywall-w2a-reviews-ratings">
			<p className="paywall-w2a-reviews-ratings-title">More than <strong>800K</strong> ratings</p>
			<div className="paywall-w2a-reviews-stores">
				<div className="paywall-w2a-reviews-stores-item">
					<AppstoreLogo />
					<div className="paywall-w2a-reviews-stores-rating">
						<span>4.8</span>
						<div className="paywall-w2a-reviews-stores-star-icon">
							<SlIcon iconId={'simpleStar'} />
						</div>
					</div>
				</div>
				<div className="paywall-w2a-reviews-stores-item">
					<GooglePlayLogo />
					<div className="paywall-w2a-reviews-stores-rating">
						<span>4.6</span>
						<div className="paywall-w2a-reviews-stores-star-icon">
							<SlIcon iconId={'simpleStar'} />
						</div>
					</div>
				</div>
			</div>
			<div className="paywall-w2a-reviews-awards">
				<div style={{ width: 'calc(60px * 3.25)', height: '60px' }}><SlIcon iconId={'appleAward'} /></div>
				<div style={{ width: 'calc(60px * 3.25)', height: '60px' }}><GoogleAward /></div>
			</div>
		</div>

	</div>;
};
import { PaywallsService } from "../../_paywalls/private/services/paywalls.service";
import { Touchpoint } from "../../api/public/subscription-api";
import { Container, Service, history } from "../../symphony";

@Service()
export class PricingService {
	private paywallService = Container.take('paywalls', PaywallsService);

	public showPricingPaywall = () => {
		this.paywallService.openPaywall({
			touchpoint: Touchpoint.pricing,
			props: {},
			styles: {
				container: {
					padding: 0,
					background: 'transparent',
					position: 'unset'
				},
				header: {
					display: 'none'
				},
				inner: {
					maxWidth: 'unset'
				},
				loader: {
					display: 'none'
				},
				loaderContainer: {
					width: '100vw',
					height: '100vh',
					maxWidth: '100%',
					borderRadius: 0
				}
			}
		}, {
			onClose: () => {
				history.push('/');
			}
		});
	};

	public closePricingPaywall = () => {
		this.paywallService.closePaywall(false);
	}
}
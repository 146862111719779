import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthService, Container, Service } from '../../../symphony';

declare const slTrack: (payload: {
    action: string;
    element: string;
    experiment?: string;
    redirectUrl?: string;
}) => void;
declare const AF: (
    pba: 'pba',
    event: 'event',
    data: {
        eventType: string;
        eventName: string;
        eventValue?: unknown;
    }
) => void;

export interface ITrackAction {
    action: string;
    element: string;
    experiment?: string;
    redirectUrl?: string;
    entityId?: number;
}

declare const dataLayer: Array<unknown>;

export interface ITagManagerAction {
    event: string;
    userId?: number;
}

export interface IAppsFlyerAction {
    value?: Record<string, unknown>;
    name: string;
}

@Service()
export class SlPaymentTrackingService {
	public trackAction$ = new BehaviorSubject<ITrackAction>(null);

	public tagManager$ = new BehaviorSubject<ITagManagerAction>(null);

	public appsFlyer$ = new BehaviorSubject<IAppsFlyerAction>(null);

	private authService = Container.take('global', AuthService);

	private fingerprint = Container.take('global', 'fingerprint');

	public trackActionStream: Observable<ITrackAction> = this.trackAction$.pipe(
		filter((a) => !!a),
		tap((action: ITrackAction) => {
			slTrack(action);
		}),
	);

	public tagManagerStream: Observable<unknown> = this.tagManager$.pipe(
		filter((a) => !!a),
		tap((action: ITagManagerAction) => {
			dataLayer.push({
				...action,
				userId: this.authService.getUser()?.id,
			});
		}),
	);

	public appsFlyerStream: Observable<IAppsFlyerAction> = this.appsFlyer$.pipe(
		filter((a) => !!a),
		tap((action: IAppsFlyerAction) => {
			if (typeof AF !== 'undefined') {
				AF?.('pba', 'event', {
					eventType: 'EVENT',
					eventValue: {
						platform_name: 'web',
						user_id: this.authService.getUser()?.id.toString(),
						locale_name: 'en',
						fingerprint: this.fingerprint,
						...action.value,
					},
					eventName: action.name,
				});
			}
		}),
	);
}

import { Service } from 'typedi';

export type StorageType = 'local' | 'session';

@Service()
export class StorageService {
	public save = (
		key: string,
		value: unknown,
		storageType: StorageType,
	): void => {
		if (typeof window !== 'undefined') {
			const storage = storageType === 'local' ? localStorage : sessionStorage;
			try {
				storage.setItem(key, JSON.stringify({ data: value }));
				this.dispatchStorageEvent(key, JSON.stringify({ data: value }));
			} catch (e) {
				throw new Error(
					`StorageWriteFault: ${e.message} D: ${e.description}`,
				);
			}
		}
	};

	public load = <T>(key: string, storageType: StorageType): T => {
		if (typeof window !== 'undefined') {
			const storage = storageType === 'local' ? localStorage : sessionStorage;
			try {
				const item = storage.getItem(key);
				return item === null ? null : JSON.parse(item).data;
			} catch (e) {
				throw new Error(
					`StorageReadFault: ${e.message} D: ${e.description}`,
				);
			}
		}
		return null;
	};

	public remove = (key: string, storageType: StorageType): void => {
		if (typeof window !== 'undefined') {
			const storage = storageType === 'local' ? localStorage : sessionStorage;
			try {
				storage.removeItem(key);
				this.dispatchStorageEvent(key, null);
			} catch (e) {
				throw new Error(
					`StorageRemoveFault: ${e.message} D: ${e.description}`,
				);
			}
		}
	};

	public clear = (storageType: StorageType): void => {
		if (typeof window !== 'undefined') {
			const storage = storageType === 'local' ? localStorage : sessionStorage;
			try {
				storage.clear();
			} catch (e) {
				throw new Error(
					`StorageClearFault: ${e.message} D: ${e.description}`,
				);
			}
		}
	};

	private dispatchStorageEvent = (key: string, newValue: string): void => {
		if (typeof window !== 'undefined') {
			window.dispatchEvent(
				new StorageEvent('storage', {
					key,
					newValue,
				}),
			);
		}
	};
}

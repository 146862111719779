import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
	AuthService,
	Container,
	BitService,
	HeartService,
	IUser,
} from '../features/symphony';

import {
	ISocketApi, SocketApi, BitChangeModel, HeartChangeModel,
} from '../features/api/public/socket-api';

export class SocketService {
	private socketConnected$ = new BehaviorSubject<boolean>(false);

	private bitChangeSubscription: Subscription = null;

	private heartChangeSubscription: Subscription = null;

	constructor() {
		const socketApi: ISocketApi = new SocketApi({
			environmentUrl: Container.take('global', 'environmentUrl'),
			socketPath: Container.take('global', 'socketPath'),
			callbacks: {
				onConnect: () => this.socketConnected$.next(true),
				onDisconnect: () => this.socketConnected$.next(false),
				onError: () => this.socketConnected$.next(false),
			},
			options: {
				reconnectionAttempts: 4,
				reconnectionDelay: 2000,
			},
		});

		const { bits$ } = Container.take('global', BitService);
		const { hearts$ } = Container.take('global', HeartService);
		const { userInfo$ } = Container.take('global', AuthService);

		(userInfo$ as BehaviorSubject<IUser>).subscribe((user: IUser) => {
			if (!user) {
				socketApi.disconnect();
			} else if (!socketApi.isConnected()) {
				socketApi.connect();
			}
		});

		this.socketConnected$.subscribe((connected: boolean) => {
			if (connected) {
				if (!this.bitChangeSubscription) {
					this.bitChangeSubscription = socketApi
						.getBitChangeStream()
						.pipe(filter((bi) => !!bi))
						.subscribe(({ CurrentValue }: BitChangeModel) => {
							if (Number(CurrentValue) !== bits$.value.bits) {
								bits$.next({
									...bits$.value,
									bits: Number(CurrentValue),
								});
							}
						});
				}

				if (!this.heartChangeSubscription) {
					this.heartChangeSubscription = socketApi
						.getHeartChangeStream()
						.pipe(filter((h) => !!h))
						.subscribe((hearts: HeartChangeModel) => {
							hearts$.next({
								heartsCount: hearts.HeartsCount,
								previousHeartsCount: hearts.PreviousHeartsCount,
								lastUpdateDate: hearts.LastUpdateDate,
								hasInfiniteHearts: hearts.HasInfiniteHearts,
								maxHeartsCount: hearts.MaxHeartsCount,
								configurations: hearts.Configurations,
								deductionUnits: hearts.DeductionUnits,
							});
						});
				}
			} else {
				// Disconnected
				if (this.bitChangeSubscription) {
					this.bitChangeSubscription.unsubscribe();
					this.bitChangeSubscription = null;
				}

				if (this.heartChangeSubscription) {
					this.heartChangeSubscription.unsubscribe();
					this.heartChangeSubscription = null;
				}
			}
		});
	}
}

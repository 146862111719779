import { Observable } from 'rxjs';

import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { constructUrl } from '../private/utils/httpParamsUtils';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { IApiUrls, IHostsConfigs } from '../private/global.interface';
import {
	ICertificatApiVersions,
	ICertificate,
	ICertificatesApi,
} from '../private/certificates-api/certificates-api.interface';
import { Service, Container } from '../../symphony';

@Service()
export class CertificatesApi implements ICertificatesApi {
	private environmentUrl: string;

	private axiosInstance: AxiosInstanceType;

	private versions: ICertificatApiVersions;

	constructor() {
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).certificateApiHost;
		this.versions = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.certificateApi?.version;
		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.public)
	public getCertificate(id: string): Observable<ICertificate> {
		return this.axiosInstance.get(
			constructUrl(this.environmentUrl, id, this.versions?.getCertificate),
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getCertificateByCourseAlias(
		alias: string,
	): Observable<ICertificate> {
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				`course/${alias}`,
				this.versions?.getCertificate,
			),
		);
	}
}

export * from '../private/certificates-api/certificates-api.interface';

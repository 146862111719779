import { Observable, of } from 'rxjs';

import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { constructUrl } from '../private/utils/httpParamsUtils';
import {
	IProfileApi,
	UserProfileSections,
	IProfile,
	IProfileApiMock,
	IProfileVersions,
	IProfileFollowResponse,
	IProfileUpdatePayload,
	IProfileUpdateResponse,
	IProfileAvatarResponse,
	IProfileChangePasswordPayload,
	IProfileChangePasswordResponse,
	IProfileOnboardingDataUpdatePayload,
	IProfileConnectAccountResponse,
	IProfileUpdateConnectedAccountResponse,
	IProfileUpdateConnectedAccountPayload,
	IProfileDeleteConnectedAccountResponse,
	IProfileConnectAccountPayload,
	IProfileCommunityResponse,
	ISetUserGoalPayload,
	IUserGoalProgress,
	IUserData,
	IUpdateUserInfoPayload,
} from '../private/profile-api/profile-api.interface';
import { deleteFromCache } from '../private/get-from-cache';
import {
	IPaginationParams,
	IApiUrls,
	IHostsConfigs,
} from '../private/global.interface';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { Service, Container } from '../../symphony';

@Service()
export class ProfileApi implements IProfileApi {
	private environmentUrl: string;

	private useMock: boolean;

	private mock: IProfileApiMock;

	private versions: IProfileVersions;

	private axiosInstance: AxiosInstanceType;

	constructor() {
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).userInfoApiHost;
		this.useMock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.profileApi?.useMock;
		this.mock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.profileApi?.mock;
		this.versions = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.profileApi?.version;

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.public)
	public getProfile(
		userId: number,
		sections: UserProfileSections[],
	): Observable<IProfile> {
		if (this.useMock) return of(this.mock.profile);
		deleteFromCache('getProfile');
		const url = constructUrl(
			this.environmentUrl,
			`profile/${userId}`,
			this.versions?.getProfile,
		);
		return this.axiosInstance.get(url, {
			params: {
				sections: sections.toString(),
			},
		});
	}

	@Authorize(AuthLevel.authenticated)
	public deleteAccount(userId: number): Observable<IProfile> {
		return this.axiosInstance.delete(
			constructUrl(
				this.environmentUrl,
				`profile/${userId}`,
				this.versions?.deleteAccount,
			),
		);
	}

	@Authorize(AuthLevel.public)
	public getFollowers(
		userId: number,
		params?: IPaginationParams,
	): Observable<IProfileCommunityResponse> {
		deleteFromCache('getFollowers');
		const url = constructUrl(
			this.environmentUrl,
			`profile/${userId}/followers`,
			this.versions?.getFollowers,
		);
		return this.axiosInstance.get(url, {
			params,
		});
	}

	@Authorize(AuthLevel.public)
	public getFollowing(
		userId: number,
		params?: IPaginationParams,
	): Observable<IProfileCommunityResponse> {
		deleteFromCache('getFollowing');
		const url = constructUrl(
			this.environmentUrl,
			`profile/${userId}/following`,
			this.versions?.getFollowing,
		);
		return this.axiosInstance.get(url, {
			params,
		});
	}

	@Authorize(AuthLevel.authenticated)
	public follow(profileId: number): Observable<IProfileFollowResponse> {
		const url = constructUrl(
			this.environmentUrl,
			`profile/follow/${profileId}`,
			this.versions?.follow,
		);
		return this.axiosInstance.post(url, null);
	}

	@Authorize(AuthLevel.authenticated)
	public unfollow(profileId: number): Observable<IProfileFollowResponse> {
		const url = constructUrl(
			this.environmentUrl,
			`profile/unfollow/${profileId}`,
			this.versions?.unfollow,
		);
		return this.axiosInstance.post(url, null);
	}

	@Authorize(AuthLevel.authenticated)
	public updateInfo(
		profileId: number,
		payload: IProfileUpdatePayload,
	): Observable<IProfileUpdateResponse> {
		const url = constructUrl(
			this.environmentUrl,
			`profile/${profileId}`,
			this.versions?.updateInfo,
		);
		return this.axiosInstance.put(url, payload);
	}

	@Authorize(AuthLevel.authenticated)
	public updateUserInfo(
		profileId: number,
		payload: IUpdateUserInfoPayload,
	): Observable<IProfileUpdateResponse> {
		const url = constructUrl(
			this.environmentUrl,
			`profile/${profileId}`,
			this.versions?.updateUserInfo,
		);

		return this.axiosInstance.patch(url, payload);
	}

	@Authorize(AuthLevel.authenticated)
	public uploadAvatar(avatar: File): Observable<IProfileAvatarResponse> {
		const formData = new FormData();
		if (avatar) {
			formData.append('file', avatar);
		}
		return this.axiosInstance.post(
			constructUrl(
				this.environmentUrl,
				'profile/uploadavatar',
				this.versions?.uploadAvatar,
			),
			formData,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public changePassword(
		passwords: IProfileChangePasswordPayload,
	): Observable<IProfileChangePasswordResponse> {
		return this.axiosInstance.post(
			constructUrl(
				this.environmentUrl,
				'profile/changepassword',
				this.versions?.changePassword,
			),
			passwords,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public updateOnboardingInfo(
		payload: IProfileOnboardingDataUpdatePayload[],
	): Observable<IProfileUpdateResponse> {
		return this.axiosInstance.post(
			constructUrl(this.environmentUrl, 'onboardingquestionary/answers'),
			payload,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public setUserGoal(
		payload: ISetUserGoalPayload,
	): Observable<IProfileUpdateResponse> {
		return this.axiosInstance.post(
			constructUrl(this.environmentUrl, 'usergoal'),
			payload,
			{
				params: {
					...payload.queryParams,
				},
			},
		);
	}

	@Authorize(AuthLevel.authenticated)
	public connectAccount(
		payload: IProfileConnectAccountPayload,
	): Observable<IProfileConnectAccountResponse> {
		return this.axiosInstance.post(
			constructUrl(
				this.environmentUrl,
				'connectedAccounts',
				this.versions?.connectedAccounts,
			),
			payload,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public updateConnectedAccount(
		socialConnectionId: number,
		payload: IProfileUpdateConnectedAccountPayload,
	): Observable<IProfileUpdateConnectedAccountResponse> {
		return this.axiosInstance.patch(
			constructUrl(
				this.environmentUrl,
				`connectedAccounts/${socialConnectionId}`,
				this.versions?.connectedAccounts,
			),
			null,
			{
				params: payload,
			},
		);
	}

	@Authorize(AuthLevel.authenticated)
	public deleteConnectedAccount(
		socialConnectionId: number,
	): Observable<IProfileDeleteConnectedAccountResponse> {
		return this.axiosInstance.delete(
			constructUrl(
				this.environmentUrl,
				`connectedAccounts/${socialConnectionId}`,
				this.versions?.connectedAccounts,
			),
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getUserData(): Observable<IUserData> {
		return this.axiosInstance.get<IUserData>(
			constructUrl(
				this.environmentUrl,
				'userdata',
				this.versions?.getUserData,
			),
			{
				params: {
					platform: 1,
					language: 'en',
				},
			},
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getUserGoalProgress(): Observable<IUserGoalProgress[]> {
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				'usergoal/progress',
				this.versions?.getUserGoalProgress,
			),
			{
				params: {
					daysbefore: 0,
					timezone: -(new Date().getTimezoneOffset() / 60),
				},
			},
		);
	}
}

export * from '../private/profile-api/profile-api.interface';

import { BehaviorSubject } from 'rxjs';
import { Service } from 'typedi';
import { AuthService } from './auth.service';
import { Container } from './container.global';
import {
	LeaderBoardMessages,
	LeaderboardState,
	LeagueRank,
	UserPromotion,
	UserState,
} from './global-interfaces';

export interface ILeaderboardExperimentPageData {
	isExperiment: boolean;
}

export interface IBackToSchoolMessages {
	locale: string;
	body: string;
}

export interface ILeaderboardConfig {
	levelUpIndex: number;
	levelDownIndex: number;
	minStartingCount: number;
	capacity: number;
	rewards: number[];
	minJoinXp: number;
}

export interface ILeaderboardUserConfig {
	isLeaderboardEnabled: boolean;
	state: UserState;
	lastLeaderboardPosition: number;
	lastLeaderboardRank: LeagueRank;
	showResult: boolean;
	promotion: UserPromotion;
	reward: number;
}

export interface ILeaderboardUser {
	id: string;
	userId: number;
	userName: string;
	level: number;
	badge: string;
	isPro: boolean;
	isFakeUser: boolean;
	leaderboardXp: number;
	previousLeaderboardXp: number;
	totalXp: number;
	userAvatar: string;
	previousPosition?: number;
	userConfig?: ILeaderboardUserConfig;
}

export interface ILevelZone {
	isLevelUpZone?: boolean;
	isLevelDownZone?: boolean;
}
export interface IleaderboardMessagesTexts {
	locale: string;
	header: string;
	button: string;
	body: string;
	rewardText: string;
}

export interface IleaderboardMessages {
	screenName: LeaderBoardMessages;
	texts: IleaderboardMessagesTexts[];
}

export interface ILeaderboardUserScreen extends ILeaderboardUser {
	position: number;
}

export interface ILeaderboardInfo {
	backToSchoolMessages: {
		backToSchoolExtraXp: IBackToSchoolMessages[];
		leaderBoardlevelUpZoneText: IBackToSchoolMessages[];
		leaderboardHeaderText: IBackToSchoolMessages[];
	};
	leaderBoardMessages: IleaderboardMessages[];
	id: string;
	leagueRank: LeagueRank;
	startDate: string;
	endDate: string;
	config?: ILeaderboardConfig;
	state: LeaderboardState;
	leaderboardUsers?: ILeaderboardUser[];
	isBackToSchoolEnabled: boolean;
}

export interface ILeaderboard {
	id: string;
	leagueRank: LeagueRank;
	startDate: string;
	endDate: string;
	config: ILeaderboardConfig;
	state: LeaderboardState;
	leaderboardUsers: ILeaderboardUser[];
}

const LEADERBOARD_USERS_COUNT = 5;

@Service()
export class LeaderboardService {
	public leaderboardExperiment$ = new BehaviorSubject<ILeaderboardExperimentPageData>(null);

	public leaderboardInfo$ = new BehaviorSubject<ILeaderboardInfo>(null);

	public leaderboard$ = new BehaviorSubject<ILeaderboard>(null);

	public leaderboardUsers$ = new BehaviorSubject<(ILeaderboardUserScreen | ILevelZone)[]
	>(null);

	private authService = Container.take('global', AuthService);

	private findUserIndex(leaderboardUsers: ILeaderboardUser[]) {
		const authUserId = this.authService?.getUser()?.id;
		return leaderboardUsers?.findIndex(
			(user) => user.userId === authUserId,
		);
	}

	private getLeaderboardUsersStartIndex(
		leaderboardUsers: ILeaderboardUser[],
	): number {
		const userIndex = this.findUserIndex(leaderboardUsers);
		const gap = Math.floor(LEADERBOARD_USERS_COUNT / 2);
		if (userIndex < gap) return 0;
		if (leaderboardUsers.length - userIndex < gap) {
			return leaderboardUsers.length - LEADERBOARD_USERS_COUNT;
		}
		return userIndex - gap;
	}

	private generateUsersWithPosition(
		leaderboardUsers: ILeaderboardUser[],
	): ILeaderboardUserScreen[] {
		return leaderboardUsers.map((user, index) => ({ ...user, position: index + 1 }));
	}

	private findUser(leaderboardData: ILeaderboard): ILeaderboardUser {
		const authUserId = this.authService?.getUser()?.id;
		return leaderboardData?.leaderboardUsers?.find(
			(user) => user.userId === authUserId,
		);
	}

	public findUserConfig(
		leaderboardData: ILeaderboard,
	): ILeaderboardUserConfig {
		return this.findUser(leaderboardData)?.userConfig;
	}

	public isLeaderboardAvailable(
		leaderboardUsers: ILeaderboard,
		lessonNumber: number,
		isLessonCompleted: boolean,
	): boolean {
		const userConfig = this.findUserConfig(leaderboardUsers);

		const leaderboardState = leaderboardUsers.state;

		const userConfigState = userConfig?.state;

		const prevLeadrboardUsersState = this.leaderboard$.value.leaderboardUsers;

		const previousPosition = this.findUserIndex(prevLeadrboardUsersState);

		const actualPosition = this.findUserIndex(
			leaderboardUsers.leaderboardUsers,
		);
		this.leaderboard$.next(leaderboardUsers);

		if (isLessonCompleted) {
			return false;
		}
		if (
			(userConfigState === UserState.Ok
				&& leaderboardState === LeaderboardState.StartedAndOpen)
			|| leaderboardState === LeaderboardState.StartedAndClosed
		) {
			if (actualPosition < previousPosition && lessonNumber !== 1) {
				return true;
			}
		}

		return false;
	}

	public setLeaderboardUsers(leaderboardUsers: ILeaderboardUser[]): void {
		const startIndex = this.getLeaderboardUsersStartIndex(leaderboardUsers);
		const levelUpZoneIndex = this.leaderboard$.value.config.levelUpIndex;
		const levelDownZoneIndex = this.leaderboard$.value.config.levelDownIndex;
		const leaderboardUsersWithPos = this.generateUsersWithPosition(leaderboardUsers);
		let userListWithZones = [...leaderboardUsersWithPos] as (
			| ILeaderboardUserScreen
			| ILevelZone
		)[];
		if (levelUpZoneIndex >= leaderboardUsers.length) {
			userListWithZones.push({
				isLevelUpZone: true,
			});
		} else {
			userListWithZones = leaderboardUsersWithPos.reduce(
				(list, current, index) => {
					if (index === levelUpZoneIndex) {
						list.push({
							levelUpZone: true,
						});
					} else if (index + 1 === levelDownZoneIndex) {
						list.push({
							levelDownZone: true,
						});
					}
					list.push(current);
					return list;
				},
				[],
			);
			if (levelDownZoneIndex > leaderboardUsersWithPos.length) {
				userListWithZones.push({
					isLevelDownZone: true,
				});
			}
		}
		this.leaderboardUsers$.next(
			userListWithZones.splice(startIndex, LEADERBOARD_USERS_COUNT),
		);
	}
}

import React, { useEffect, useRef, useState } from 'react';
import { tap } from 'rxjs/operators';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, IUser } from '../../../../symphony';

import { SlUserSettings } from '../SlUserSettings/SlUserSettings';
import { SlNavigationTrackingService } from '../../services/sl-navigation-tracking.service';
import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { IconAvatarFill } from '../../../../shared/public/assets/icons/IconAvatarFill';
import { SlNavigationContext } from '../../global-constants';

import s from './SlUserMenu.scss';
import { SlNavbarThemes } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { useOutsideClick } from '../../../../shared/public/SlHooks/useOutsideClick';

interface ISlUserMenu {
	user: IUser;
	classNameMenu?: string;
	classNameSelect?: string;
	mode?: SlNavbarThemes;
}

export const SlUserMenu: React.FC<ISlUserMenu> = React.memo(
	({
		user, classNameMenu, classNameSelect, mode,
	}) => {
		useStyles(s);
		const [isUserMenuOpen, setUserMenuOpen] = useState<boolean>(false);

		const { userMenuOpen$, bitsMenuOpen$, streakMenuOpen$ } = Container.take(SlNavigationContext, SlNavigationDataService);

		useEffect(() => {

			const subscription = userMenuOpen$
				.pipe(
					tap((val: boolean) => {
						setUserMenuOpen(val);
					}),
				)
				.subscribe();

			return () => {
				subscription.unsubscribe();
			};
		}, []);

		const onNavigationUser = (): void => {
			const { navigationTrackActions$ } = Container.take(
				SlNavigationContext,
				SlNavigationTrackingService,
			);
			navigationTrackActions$.next({
				action: 'click',
				element: 'navigation_profile',
				entityId: null,
			});
		};

		const element = useRef<HTMLDivElement>(null);

		useOutsideClick(element, isUserMenuOpen, !isUserMenuOpen, () => userMenuOpen$.next(false));

		const onAvatarClick = (e) => {
			e.stopPropagation();
			userMenuOpen$.next(!isUserMenuOpen);
			bitsMenuOpen$.next(false);
			streakMenuOpen$.next(false);
		};

		const userPhotoWrapper = () => (
			<div
				className={`sl-user__image-wrapper ${isUserMenuOpen ? 'sl-user__image-wrapper--active' : ''
					}`}
				onClick={onNavigationUser}
			>
				{user?.avatarUrl ? (
					<img
						className="sl-user__select__image"
						src={user?.avatarUrl}
						alt="user-avatar"
					/>
				) : (
					<div className="sl-user__select__image">
						<IconAvatarFill
							style={{
								fill: mode === SlNavbarThemes.dark
									? '#C8D2DB'
									: '#2D3846',
							}}
						/>
					</div>
				)}
			</div>
		);

		const desktopView = () => (
			<>
				<div onMouseDown={onAvatarClick}>{userPhotoWrapper()}</div>
				{isUserMenuOpen && (
					<SlUserSettings
						user={user}
						classNameMenu={classNameMenu}
					/>
				)}
			</>
		);

		const mobileView = () => (!isUserMenuOpen ? (
			<div
				onMouseDown={onAvatarClick}
				className={`sl-user__select ${classNameSelect || ''}`}
			>
				{userPhotoWrapper()}
				<span className="sl-user__mobile-title">{user?.name}</span>
			</div>
		) : (
			<SlUserSettings
				user={user}
				userPhotoWrapper={userPhotoWrapper}
				classNameMenu={classNameMenu}
			/>
		));

		return (
			<div ref={element} className="sl-user">
				<div className="sl-user__desktop">{desktopView()}</div>
				<div className="sl-user__mobile">{mobileView()}</div>
			</div>
		);
	},
);

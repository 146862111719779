import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import {
	ActionButtonTypes,
	SlActionButton,
} from '../SlActionButton/SlActionButton';

import s from './SlBlockerModal.scss';

interface ISlBlockerModalProps {
    title: string;
    subtitle: string;
    pageContent: string;
    actionButtonText: string;
    onAccept: () => void;
}

export function SlBlockerModal({
	title,
	subtitle,
	pageContent,
	actionButtonText,
	onAccept,
}: ISlBlockerModalProps): JSX.Element {
	useStyles(s);
	return (
		<div className="sl-blocker-modal" sl-test-data="blockerModal">
			<div className="sl-blocker-modal__container">
				<div className="sl-blocker-modal__main-block">
					<h3 className="sl-blocker-modal__title">{title}</h3>
					<p className="sl-blocker-modal__description">{subtitle}</p>
				</div>
				<div
					className="sl-blocker-modal__content"
					dangerouslySetInnerHTML={{
						__html: pageContent,
					}}
				/>
				<SlActionButton
					className="sl-blocker-modal__action-button"
					buttonType={ActionButtonTypes.primary}
					onClick={onAccept}
				>
					{actionButtonText}
				</SlActionButton>
			</div>
		</div>
	);
}

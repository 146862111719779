import React, { FC, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WebToAppPaywallPage.scss';
import { WebToAppProducts } from "./WebToAppProducts";
import { WebToAppPaywallReviews } from "./WebToAppPaywallReviews";
import { WebToAppPaywallWhatYouGet } from "./WebToAppPaywallWhatYouGet";
import { WebToAppPaywallFAQ } from "./WebToAppPaywallFAQ";
import { PaywallComponentType, PaywallPage } from "../../../../api/public/subscription-api";
import { WebToAppPaywallDiscount } from "./WebToAppPaywallDiscount";
import { Container } from "../../../../symphony";
import { PaywallsService } from "../../services/paywalls.service";
import { PaywallClickTypes } from "../../paywalls.constants";

type Props = {
	page: PaywallPage;
};

export const WebToAppPaywallPage: FC<Props> = ({ page }) => {
	useStyles(s);
	const { navigateToCheckout, selectProduct, trackPaywallClick } = Container.take('paywalls', PaywallsService);
	const [selectedProductKey, setSelectedProductKey] = useState<string>();
	const [plans, setPlans] = useState([]);
	const [plansInfo, setPlansInfo] = useState(null);
	const [values, setValues] = useState([]);
	const [faqs, setFaqs] = useState([]);
	const [reviews, setReviews] = useState();

	const onProductClick = (key: string, duration: number, theme: 'dark' | 'light') => {
		const clickType: PaywallClickTypes = ({
			null: PaywallClickTypes.freeBlock,
			30: PaywallClickTypes.monthlyBlock,
			90: PaywallClickTypes.quarterlyBlock,
			365: PaywallClickTypes.annualBlock
		})[duration];
		trackPaywallClick(clickType, theme === 'dark' ? 'dark_background_list' : 'light_background_list');
		setSelectedProductKey(key);
		selectProduct({ productId: key });
	};

	const onContinue = (sectionName: string) => {
		navigateToCheckout(sectionName);
	};

	useEffect(() => {
		const plans = page.components.find(c => c.type === PaywallComponentType.w2aProducts).data.plans;
		const plansInfo = page.components.find(c => c.type === PaywallComponentType.w2aProducts).data.info;
		const values = page.components.find(c => c.type === PaywallComponentType.w2aWhatYouGet).data;
		const faqs = page.components.find(c => c.type === PaywallComponentType.w2aFAQ).data;
		const reviews = page.components.find(c => c.type === PaywallComponentType.w2aReviews).data;
		setPlans(plans);
		setPlansInfo(plansInfo);
		setValues(values);
		setFaqs(faqs);
		setReviews(reviews);
		const popularPlanKey = plans.find(p => p.isMostPopular).key;
		setSelectedProductKey(popularPlanKey);
		selectProduct({ productId: popularPlanKey });
	}, [page]);

	return plans.length ? <div className="paywall-w2a">
		<WebToAppPaywallDiscount
			discount={plans.reduce((max, current) => {
				return current.discount > max.discount ? current : max;
			}, plans[0]).discount}
			onContinue={() => onContinue('discount')}
		/>
		<WebToAppProducts
			plans={plans}
			info={plansInfo}
			theme="light"
			selectedProductKey={selectedProductKey}
			onProductClick={onProductClick}
			onContinue={() => onContinue('light_background_list')}
			sectionOrder={1}
		/>
		<WebToAppPaywallWhatYouGet values={values} sectionOrder={2} />
		<WebToAppPaywallFAQ faqs={faqs} sectionOrder={3} />
		<WebToAppPaywallReviews reviews={reviews} sectionOrder={4} />
		<WebToAppProducts
			plans={plans}
			info={plansInfo}
			theme="dark"
			selectedProductKey={selectedProductKey}
			onProductClick={onProductClick}
			onContinue={() => onContinue('dark_background_list')}
			sectionOrder={5}
		/>
	</div> : null;
};
import React, { ElementRef, FC, RefObject, useEffect, useRef } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WebToAppPaywallWhatYouGet.scss';
import { useInView } from "../../paywalls.utils";
import { Container } from "../../../../symphony";
import { PaywallsService } from "../../services/paywalls.service";

type Props = {
	values: string[];
	sectionOrder: number;
};

export const WebToAppPaywallWhatYouGet: FC<Props> = ({ values, sectionOrder }) => {
	useStyles(s);
	const [ref, isInView] = useInView({ threshold: 0.7 });
	const { trackSectionView } = Container.take('paywalls', PaywallsService);

	useEffect(() => {
		isInView && trackSectionView('what-you-get', sectionOrder);
	}, [isInView]);

	return <div ref={ref as RefObject<HTMLDivElement>} className="paywall-w2a-section paywall-w2a-what-you-get">
		<h2 className="paywall-w2a-section-title">What you get with Sololearn</h2>
		<div className="paywall-w2a-what-you-get-list">
			{values.map(v => (<div className="paywall-w2a-what-you-get-item" key={v}>
				<span>✨</span>
				<span>{v}</span>
			</div>))}
		</div>
	</div>;
};
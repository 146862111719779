import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { i18n } from '../../../symphony';

// all keys should be lowercase, as they are taken from search params
// and toLowerCase() method is applied on them
export interface IQuery {
    search: string;
    returnurl?: string;
    source?: string;

    [key: string]: string;
}

export const shuffleArray = <T>(arr: Array<T>): Array<T> => {
	const arrLength = Array.isArray ? arr.length : null;
	if (!arrLength) return arr;

	const newArr = [...arr];

	for (let i = arrLength - 1; i > 0; i--) {
		const j = Math.round(Math.random() * i);
		if (i !== j) [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
	}

	return newArr;
};

export const getShuffledOptions = <T extends { id?: number }>(
	previousOrder: Array<T>,
): Array<T> => {
	if (Array.isArray(previousOrder) && previousOrder.length === 0) {
		return [];
	}

	let shuffledOptions: Array<T>;
	do {
		shuffledOptions = shuffleArray(previousOrder);
	} while (
		shuffledOptions.map((current) => current.id).toString()
        === previousOrder.map((previous) => previous.id).toString()
	);
	return shuffledOptions;
};

export function getFingerprintFromCookies(): string {
	const regexp = /(?:(?:^|.*;\s*)fngprntId\s*=\s*([^;]*).*$)|^.*$/;
	return document.cookie.replace(regexp, '$1');
}

export function getAfUserIdFromCookies(): string {
	const regexp = /(?:(?:^|.*;\s*)afUserId\s*=\s*([^;]*).*$)|^.*$/;
	return document.cookie.replace(regexp, '$1');
}

export const useQuery = (): IQuery => {
	const location = useLocation();
	const returnValue: IQuery = {
		search: location.search,
	};
	new URLSearchParams(location.search.toLowerCase()).forEach((value, key) => {
		returnValue[key] = value;
	});
	return returnValue;
};

export const deleteParam = (item: string, query: URLSearchParams): void => {
	if (item == null || !query) {
		throw Error('Incorrect arguments');
	}
	const lowercaseParam = item.toLocaleLowerCase();
	if (query.has(lowercaseParam)) {
		query.delete(lowercaseParam);
	}
};

export const validateUserName = (): yup.StringSchema => yup
	.string()
	.trim('Required')
	.required('Required')
	.matches(
		/^[^@_.]+$/,
		'Name is invalid. It should not contain any of these special characters @._',
	)
	.min(3, i18n.t('web-auth.username-min-char-count-validation'))
	.max(50, i18n.t('web-auth.username-max-char-count-validation'));

export const validateName = (): yup.StringSchema => yup
	.string()
	.trim('Required')
	.required('Required')
	.matches(
		/^[^@_.0-9]+$/,
		'Name is invalid. It should not contain numbers or any of these special characters @._',
	)
	.max(50, 'Maximum 50 characters are allowed');

export const validateEmail = (): yup.StringSchema => yup
	.string()
	.required('Required')
	.email(i18n.t('web-auth.email-validation'));

export const validatePhoneNumber = (): yup.StringSchema => yup.string().matches(/^[0-9-()+\s]*$/, 'Invalid phone number');

export const isNode = () => typeof window === 'undefined';

export const getObjectKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

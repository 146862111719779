import loadable, {
	DefaultComponent,
	LoadableComponent,
} from '@loadable/component';

type RouteComponentProps = any;

const isSSREnabled = !(typeof window === 'undefined' && process.argv.indexOf('--local') > -1);
// const isSSREnabled = true;

// MicroFrontends

export const Home: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../MicroFrontends/Home/Home') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.Home,
	},
);

export const WTA: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../MicroFrontends/WTA/WTA'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.WTA
	}
);

export const AttributionTestDevice: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../MicroFrontends/AttributionTestDevice/AttributionTestDevice'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.AttributionTestDevice
	}
);

export const LearnEngine: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../MicroFrontends/LearnEngine/LearnEngine'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.LearnEngine,
	},
);

export const Payments: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../MicroFrontends/Payments/Payments') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.Payments,
	},
);

export const User: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../MicroFrontends/User/User') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.User,
	},
);

export const Error: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../MicroFrontends/Error/Error') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.Error,
	},
);

export const NotFound: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../MicroFrontends/PageNotFound/PageNotFound'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.PageNotFound,
	},
);

export const DynamicOnboarding: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../MicroFrontends/Onboarding/Onboarding'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.DynamicOnboarding,
	},
);

export const Playground: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../MicroFrontends/Playground/Playground') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.Playground,
	},
);

export const Profile: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../MicroFrontends/Profile/Profile') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.Profile,
	},
);

export const LeaderboardLeague: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../MicroFrontends/LeaderboardLeague/LeaderboardLeague'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.LeaderboardLeague,
	},
);

export const GroupLeaderboard: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../MicroFrontends/LeaderboardLeague/LeaderboardLeague'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.GroupLeaderboard,
	},
);

// Pages
export const NotAllowedFeature: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../Pages/NotAllowedFeature/NotAllowedFeature'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.NotAllowedFeature,
	},
);

export const Subscriptions: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/Subscriptions/Subscriptions') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.Subscriptions,
	},
);

export const PrivacyRequest: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/PrivacyRequest/PrivacyRequest') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.PrivacyRequest,
	},
);

export const FAQ: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/FAQ/FAQ') as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.FAQ,
	},
);

export const TermsOfUse: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/TermsOfUse/TermsOfUse') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.TermsOfUse,
	},
);

export const AILandingPage: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/AILandingPage/AILandingPage') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.AILandingPage,
	},
);

export const CopyrightPolicy: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../Pages/CopyrightPolicy/CopyrightPolicy'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.CopyrightPolicy,
	},
);

export const ContentCreationGuidlines: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../Pages/ContentCreationGuidlines/ContentCreationGuidlines'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.ContentCreationGuidlines,
	},
);

export const TermsOfUseForMobile: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../Pages/TermsOfUseForMobile/TermsOfUseForMobile'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.TermsOfUseForMobile,
	},
);

export const TermsOfUseForModal: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../Pages/TermsOfUseForModal/TermsOfUseForModal'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.TermsOfUseForModal,
	},
);

export const Contact: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/Contact/Contact') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.Contact,
	},
);

export const BugBounty: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/BugBounty/BugBounty') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.BugBounty,
	},
);

export const MobilePage: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/MobilePage/MobilePage') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.MobilePage,
	},
);

export const NotifiedPage: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/NotifiedPage/NotifiedPage') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.NotifiedPage,
	},
);

export const PrivacyPolicy: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/PrivacyPolicy/PrivacyPolicy') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.PrivacyPolicy,
	},
);

export const MoneyBackPolicy: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/MoneyBackPolicy/MoneyBackPolicy') as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.MoneyBackPolicy
	}
);

export const PrivacyPolicyForMobile: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../Pages/PrivacyPolicyForMobile/PrivacyPolicyForMobile'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.PrivacyPolicyForMobile,
	},
);

export const PrivacyPolicyForModal: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../Pages/PrivacyPolicyForModal/PrivacyPolicyForModal'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.PrivacyPolicyForModal,
	},
);

export const Career: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/Career/Career') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.Career,
	},
);

export const ConnectAccount: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/ConnectAccount/ConnectAccount') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.ConnectAccount,
	},
);

export const DeleteAccountCompletion: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../Pages/DeleteAccountCompletion/DeleteAccountCompletion'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: false,
		resolveComponent: (mod: any) => mod.DeleteAccountCompletion,
	},
);

export const CertificateShare: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../Pages/CertificateShare/CertificateShare'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.CertificateShare,
	},
);

export const Discuss: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../MicroFrontends/Discuss/Discuss') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.Discuss,
	},
);

export const CodeBits: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../MicroFrontends/CodeBits/CodeBits') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.CodeBits,
	},
);

export const LanguageLandingPage: LoadableComponent<RouteComponentProps> = loadable(
	() => import(
		'../Pages/LanguageLandingPage/LanguageLandingPage'
	) as unknown as Promise<DefaultComponent<any>>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.LanguageLandingPage,
	},
);

export const Teams: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/Teams/Teams') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.Teams,
	},
);

export const AILiteracy: LoadableComponent<RouteComponentProps> = loadable(
	() => import('../Pages/AILiteracy/AILiteracy') as unknown as Promise<
		DefaultComponent<any>
	>,
	{
		ssr: isSSREnabled,
		resolveComponent: (mod: any) => mod.AILiteracy,
	},
);

import React, { useEffect } from 'react';

import {
	Container,
	BitService,
	HeartService,
	IHeartData,
} from '../../features/symphony';
import { SlHearts } from '../../features/shared/public/SlHearts/SlHearts';
import { useContainerData } from '../../features/shared/public/SlHooks/useContainerData';

export function HeartModal({
	source,
	price,
	proBtnCallback,
	refillBtnCallback,
	onClose,
}: IHeartData): JSX.Element {
	const {
		hearts$: hearts,
		hoursToRefill$: nextRefillHours,
		minutesToRefill$: nextRefillMinutes,
	} = useContainerData(
		'global',
		HeartService,
		['hearts$', 'hoursToRefill$', 'minutesToRefill$'],
	);

	const { bits$: bits } = useContainerData(
		'global',
		BitService,
		['bits$'],
	);

	useEffect(() => {
		const { trackHeartModalImpression } = Container.take('global', HeartService);
		trackHeartModalImpression();
	}, []);

	return (
		hearts && (
			<SlHearts
				source={source}
				heartsCount={hearts.hasInfiniteHearts ? -1 : hearts.heartsCount}
				maxHeartsCount={hearts.maxHeartsCount}
				nextRefillHours={nextRefillHours}
				nextRefillMinutes={nextRefillMinutes}
				bits={bits?.bits}
				price={price}
				proBtnCallBack={proBtnCallback}
				refillBtnCallBack={refillBtnCallback}
				closeBtnCallBack={onClose}
			/>
		)
	);
}

import React from 'react';

import { IconHeart } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, history, i18n } from '../../../../symphony';

import { SlNavigationTrackingService } from '../../services/sl-navigation-tracking.service';
import { SlNavigationContext } from '../../global-constants';

import s from './SlFooterLinks.scss';
import { SlFeatureToggle, SlMicroFrontends } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';
import { SlFooterService } from '../../services/sl-footer.service';

export interface ISlFooterLinks {
	name: string;
	link: string;
	navigationType: 'internal' | 'external';
	trackElement?: string;
	highlitedText?: string;
}

export interface ISlFooterLinksProps {
	linksConfig: ISlFooterLinks[];
}

export const SlFooterLinks: React.FC<ISlFooterLinksProps> = React.memo(
	({ linksConfig }) => {
		useStyles(s);
		const footerService = Container.take(SlNavigationContext, SlFooterService);
		const onLinksClick = (element: string): void => {
			const { footerTrackAction$ } = Container.take(
				SlNavigationContext,
				SlNavigationTrackingService,
			);
			footerTrackAction$.next({
				action: 'click',
				element: `footer_${element}`,
				entityId: null,
			});
		};

		const defineReturnUrl = () => {
			const featureTogglingConfigs = Container.take(
				'global',
				'featureTogglingConfigs',
			);

			const nonLoggedInToggle = featureTogglingConfigs?.[SlMicroFrontends.mfNavigation]?.[
				SlFeatureToggle.nonLoggedInProPage
			]?.state;

			if (nonLoggedInToggle === 'enabled') {
				return '';
			}

			return `&returnUrl=${location.pathname}`;
		};

		const navLink = (navItem: ISlFooterLinks): React.ReactNode => {
			if (navItem.navigationType === 'external') {
				return (
					<LocalizedLink
						to={navItem.link}
						onClick={() => onLinksClick(navItem.trackElement)}
						reloadDocument
					>
						{navItem.name}

						{navItem.highlitedText && (
							<span className="sl-footer-links__highlited-text">
								{navItem.highlitedText}
							</span>
						)}
					</LocalizedLink>
				);
			}
			if (navItem.link === '/payments/pro') {
				return <span onClick={() => {
					footerService.openPaywall();
				}}>
					{navItem.name}
				</span>;
			}
			return (
				<span
					onClick={() => {
						onLinksClick(navItem.trackElement);
						history.push(
							navItem.link === '/payments/pro'
								? `/payments/pro?source=footer${defineReturnUrl()}`
								: navItem.link,
						);
					}}
				>
					{navItem.name}

					{navItem.highlitedText && (
						<span className="sl-footer-links__highlited-text">
							{navItem.highlitedText}
						</span>
					)}
				</span>
			);
		};

		return (
			<div className="sl-footer-links">
				<div className="sl-footer-links__container">
					<div className="sl-footer-links__links-block">
						{linksConfig.map((el) => (
							<span
								className="sl-footer-links__item"
								key={el.name}
							>
								{navLink(el)}
							</span>
						))}
					</div>
					<div className="sl-footer-links__copyright">
						{i18n.t('web-footer.made-by-before-heart')}
						<div className="sl-footer-links__heart-icon">
							<IconHeart size={22} />
						</div>
						{i18n.t('web-footer.made-by-after-heart')}
						{' '}
						&nbsp;
						<span className="sl-footer-links__marked-text">
							Sololearn
						</span>
						&nbsp;| ©
						{new Date().getFullYear()}
					</div>
				</div>
			</div>
		);
	},
);

import { BehaviorSubject } from 'rxjs';

import { Service } from '../../../../symphony';

import { ILPTracking, IProfileData } from '../../global-interfaces';
import { ILanguageLandingPageResponse, IStaticPageData } from '../../../../api/public/static-pages-api';
import { ICertificate } from '../../../../api/public/certificates-api';
import { IUserStatsResponse } from '../../../../api/public/subscription-api';

@Service()
export class SlStaticPagesDataService {
	public content$ = new BehaviorSubject<IStaticPageData>(null);

	public navBarOpen$ = new BehaviorSubject<boolean>(false);

	public lpTracking$ = new BehaviorSubject<ILPTracking>(null);

	public certificateData$ = new BehaviorSubject<ICertificate>(null);

	public targetedLandingPage$ = new BehaviorSubject<unknown>(null);

	public profileData$ = new BehaviorSubject<IProfileData>(null);

	public isProfileDataLoading$ = new BehaviorSubject<boolean>(false);

	public languageLandingPageData$ = new BehaviorSubject<ILanguageLandingPageResponse>(null);

	public userStats$ = new BehaviorSubject<IUserStatsResponse>(null);

	public currentWaitlistSenderId$ = new BehaviorSubject<string>(null);

	// TODO: remove
	public aiCourseWaitlistEmailResult$ = new BehaviorSubject<{
		id: string,
		email: string,
		result: boolean,
	}>(null);
}

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
	IAuthApi,
	ILoginCredentials,
	IAppleAuthorizationCredentials,
	ILoginResponse,
	IAppleLoginResponse,
	ISignUpCredentials,
	IForgetPasswordCredentials,
	IAuthApiMock,
	ISocialLoginCredentials,
	IResetPasswordCredentials,
} from '../private/auth-api/auth-api.interface';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { BaseApi } from '../private/base-api';
import {
	IApiUrls,
	IHostsConfigs,
	IResponse,
} from '../private/global.interface';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { Service, AuthService, Container } from '../../symphony';

@Service()
export class AuthApi extends BaseApi implements IAuthApi {
	private environmentUrl: string;

	private authenticationEnvUrl: string;

	private useMock: boolean;

	private mock: IAuthApiMock;

	private authService: AuthService;

	private axiosInstance: AxiosInstanceType;

	constructor() {
		super();
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).authApiHost;

		this.authenticationEnvUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		).authenticationApiHost; // todo make separated class

		this.useMock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.authApi?.useMock;
		this.mock = (
			Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.authApi?.mock;
		this.authService = Container.take('global', AuthService);

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.public)
	public sendResetCode(email: string): Observable<IResponse> {
		return this.axiosInstance.post(
			`${this.authenticationEnvUrl}/user/password:sendResetCode`,
			{ email },
		);
	}

	@Authorize(AuthLevel.public)
	public resetPassword(
		credentials: IResetPasswordCredentials,
	): Observable<IResponse> {
		return this.axiosInstance.post(
			`${this.authenticationEnvUrl}/user/password:reset`,
			credentials,
		);
	}

	@Authorize(AuthLevel.public)
	public login(credentials: ILoginCredentials): Observable<ILoginResponse> {
		if (this.useMock) {
			return of(this.mock.loginResponse).pipe(
				tap((data: ILoginResponse) => {
					const { accessToken, expiresIn, refreshToken } = data;

					this.authService.setTokens(
						{
							accessToken,
							expiresIn: expiresIn - 120,
							refreshToken,
						},
						data.user,
					);
				}),
			);
		}
		return this.axiosInstance
			.post(`${this.authenticationEnvUrl}/user:login`, credentials)
			.pipe(
				tap((data: ILoginResponse) => {
					if (data) {
						const {
							accessToken, refreshToken, expiresIn, user,
						} = data;

						this.authService.setTokens(
							{
								accessToken,
								expiresIn: expiresIn - 120,
								refreshToken,
							},
							user,
						);
						this.authService.userInfo$.next(user); // todo remove
					}
				}),
			);
	}

	@Authorize(AuthLevel.public)
	public socialLogin(
		credentials: ISocialLoginCredentials,
	): Observable<ILoginResponse> {
		if (this.useMock) {
			return of(this.mock.loginResponse).pipe(
				tap((data: ILoginResponse) => {
					const { accessToken, expiresIn, refreshToken } = data;
					this.authService.setTokens(
						{
							accessToken,
							expiresIn: expiresIn - 120,
							refreshToken,
						},
						data.user,
					);
				}),
			);
		}
		return this.axiosInstance
			.post(
				`${this.authenticationEnvUrl}/user:externallogin`,
				credentials,
			)
			.pipe(
				tap((data: ILoginResponse) => {
					if (data) {
						const {
							accessToken, refreshToken, expiresIn, user,
						} = data;

						this.authService.setTokens(
							{
								accessToken,
								expiresIn: expiresIn - 120,
								refreshToken,
							},
							user,
						);
						this.authService.userInfo$.next(user); // todo remove
					}
				}),
			);
	}

	@Authorize(AuthLevel.public)
	public signup(credentials: ISignUpCredentials): Observable<ILoginResponse> {
		if (this.useMock) {
			return of(this.mock.loginResponse).pipe(
				tap((data: ILoginResponse) => {
					const { accessToken, expiresIn, refreshToken } = data;
					this.authService.setTokens(
						{
							accessToken,
							expiresIn: expiresIn - 120,
							refreshToken,
						},
						data.user,
					);
				}),
			);
		}
		return this.axiosInstance
			.post(`${this.authenticationEnvUrl}/user`, credentials)
			.pipe(
				tap((data: ILoginResponse) => {
					if (data) {
						const {
							accessToken, refreshToken, expiresIn, user,
						} = data;

						this.authService.setTokens(
							{
								accessToken,
								expiresIn: expiresIn - 120,
								refreshToken,
							},
							user,
						);
						this.authService.userInfo$.next(user); // todo remove
					}
				}),
			);
	}

	@Authorize(AuthLevel.public)
	public w2aSignup(): Observable<ILoginResponse> {
		return this.axiosInstance
			.post(`${this.authenticationEnvUrl}/user/w2a`)
			.pipe(
				tap((data: ILoginResponse) => {
					if (data) {
						const {
							accessToken, refreshToken, expiresIn, user,
						} = data;

						this.authService.setTokens(
							{
								accessToken,
								expiresIn: expiresIn - 120,
								refreshToken,
							},
							user,
						);
						this.authService.userInfo$.next(user);
					}
				})
			);
	}

	@Authorize(AuthLevel.authenticated)
	public setPassword(oldPassword: string, newPassword: string) {
		return this.axiosInstance
			.post(`${this.authenticationEnvUrl}/user/password`, { oldPassword, newPassword })
			.pipe(
				tap(data => { })
			);
	}

	@Authorize(AuthLevel.public)
	public authorizeApple(
		credentials: IAppleAuthorizationCredentials,
	): Observable<IAppleLoginResponse> {
		return this.axiosInstance
			.post(
				`${this.authenticationEnvUrl}/user:externalLogin`,
				credentials,
			)
			.pipe(
				tap((data: IAppleLoginResponse) => {
					if (data) {
						const {
							accessToken, expiresIn, refreshToken, user,
						} = data;

						const tokens = {
							accessToken,
							expiresIn: expiresIn - 120,
							refreshToken,
						};
						const userData = {
							...user,
							isNewRegistered: user.isNewRegisteredUser,
						};

						this.authService.setTokens(tokens, userData);
						this.authService.userInfo$.next(userData); // todo remove
					}
				}),
			);
	}

	@Authorize(AuthLevel.public)
	public forgetPassword(
		credentials: IForgetPasswordCredentials,
	): Observable<unknown> {
		return this.axiosInstance.post(
			`${this.environmentUrl}/user/registerv2`,
			credentials,
		);
	}

	@Authorize(AuthLevel.none)
	public logout(): Observable<unknown> {
		this.authService.logout();
		return this.axiosInstance.post(
			`${this.environmentUrl}/user/signout`,
			{},
		);
	}
}

export * from '../private/auth-api/auth-api.interface';

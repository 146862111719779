import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Container } from '../../../symphony';

export interface ISlMetadata {
	name: string;
	content: string;
	customProperties?: { [key: string]: string; };
}

const defaultTags = {
	title: 'Sololearn: Learn to Code',
	description: 'Sololearn is the world\'s largest community of people learning to code. With over 25 programming courses, choose from thousands of topics to learn how to code, brush up your programming knowledge, upskill your technical ability, or stay informed about the latest trends.',
	imgUrl: 'https://blob.sololearn.com/avatars/sololearn.png',
};

interface IMetaTag {
	name?:
	| 'application-name'
	| 'author'
	| 'description'
	| 'generator'
	| 'keywords'
	| 'viewport'
	| 'orientation';
	'http-equiv'?:
	| 'content-security-policy'
	| 'content-type'
	| 'default-style'
	| 'refresh'
	| 'ScreenOrientation';
	content?: string;
	charset?: string;
}

export interface ISlHelmet {
	title?: string;
	description?: string;
	imgUrl?: string;
	additionalMetaTags?: IMetaTag[]; // if you want to add additional meta tags
	customCanonical?: string;
	structuredDataMarkup?: string;
}

export function SlHelmet({
	title,
	description,
	imgUrl,
	structuredDataMarkup,
	additionalMetaTags,
	customCanonical
}: ISlHelmet): JSX.Element {
	const { pathname } = useLocation();
	const location = `${Container.take('global', 'host')}${pathname}`;
	const titleTag = typeof window !== "undefined" ? document?.querySelector('meta[name="title"]') : null;
	const finalTitle = title || (titleTag ? titleTag.getAttribute('content') : defaultTags.title);
	const descriptionTag = typeof window !== "undefined" ? document?.querySelector('meta[name="description"]') : null;
	const finalDescription = description || (descriptionTag ? descriptionTag.getAttribute('content') : defaultTags.description);

	return (
		<Helmet>
			{/* Primary Meta Tags */}
			<title>{finalTitle}</title>
			<meta name="title" content={finalTitle} />
			<meta name="description" content={finalDescription} />

			{/* Open Graph / Facebook */}
			<meta property="og:type" content="website" />
			<meta property="og:url" content={location} />
			<meta property="og:title" content={finalTitle} />
			<meta property="og:description" content={finalDescription} />
			<meta property="og:image" content={imgUrl || defaultTags.imgUrl} />

			{/* Twitter */}
			<meta property="twitter:card" content="summary_large_image" />
			<meta property="twitter:url" content={location} />
			<meta property="twitter:title" content={finalTitle} />
			<meta property="twitter:description" content={finalDescription} />
			<meta property="twitter:image" content={imgUrl || defaultTags.imgUrl} />

			{/* Canonical */}
			<link rel="canonical" href={customCanonical || location} />

			{/* Structured Data Markup */}
			{structuredDataMarkup && (
				<script type="application/ld+json">
					{structuredDataMarkup}
				</script>
			)}

			{/* Additional Meta Tags */}
			{additionalMetaTags
				&& additionalMetaTags.map((t) => <meta {...t} />)}
		</Helmet>
	);
}

import { TrackingEventNames } from '../../../public/tracking/trackingEventNames';
import { TrackingEventsMappingType } from '../tracking.interface';

export const appsFlyerEventsMapping: TrackingEventsMappingType = {
	[TrackingEventNames.lessonComplete]: {
		eventName: 'sl_lesson_complete',
		params: [
			'sl_lesson_id',
			'sl_lesson_name',
			'sl_lesson_order',
			'sl_course_id',
			'sl_course_name',
			'sl_module_id',
			'sl_module_name',
			'sl_module_order',
		],
	},
	[TrackingEventNames.proPageView]: {
		eventName: 'sl_pro_page_view',
		params: ['sl_source'],
	},
	[TrackingEventNames.courseComplete]: {
		eventName: 'sl_course_complete',
		params: ['sl_course_name', 'sl_course_id'],
	},
};

import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { SlIcon, close as closeIcon, useIcons } from '@sololearnorg/icons';
import { Container, ModalService } from '../../../symphony';

import s from './SlModal.scss';

export interface ISlModalProps {
    id: string;
    closeBtnCallback?: () => void;
    cssClass?: string;
    onOverlay?: () => void;
    keepOnOverlay?: boolean;
    children: React.ReactNode;
}

export const SlModal: React.FC<ISlModalProps> = React.memo(
	({
		id,
		children,
		closeBtnCallback,
		cssClass,
		onOverlay,
		keepOnOverlay,
	}) => {
		useStyles(s);
		const [isOpen, setIsOpen] = useState<boolean>();
		const modalService = Container.take('global', ModalService);

		const close = (e: React.MouseEvent): void => {
			e.stopPropagation();
			if (closeBtnCallback) closeBtnCallback();
			modalService.close(id);
		};

		useIcons([closeIcon]);

		useEffect(() => {
			modalService.register(id, setIsOpen);
			return () => {
				modalService.removeModal(id);
			};
		}, []);

		const handleOverlayMouseDown = () => {
			if (onOverlay) onOverlay();
			if (!keepOnOverlay) modalService.close(id);
		};

		const handleModalBoxMouseDown = (e) => {
			e.stopPropagation();
		};

		return isOpen ? (
			<div
				onMouseDown={handleOverlayMouseDown}
				className={`sl-modal ${cssClass || ''}`}
			>
				<div
					className={`sl-modal__container ${cssClass || ''}`}
					onMouseDown={handleModalBoxMouseDown}
				>
					<div
						onClick={close}
						className={`sl-modal__close ${cssClass || ''}`}
					>
						<div className="sl-modal__close__icon">
							<SlIcon iconId="close" />
						</div>
					</div>
					<div className={`sl-modal__content ${cssClass || ''}`}>
						{children}
					</div>
				</div>
			</div>
		) : null;
	},
);

import { history } from '../../features/symphony';

import { detectDevice } from '../../features/shared/public/SlUtils/detectDevice';

export const mobileGuard = () => () => {
	const deviceInfo = detectDevice();
	if (deviceInfo?.isMobile) {
		history.replace('/notAllowed');
		return false;
	}
	return true;
};

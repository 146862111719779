import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import { AuthService, Container } from '../../../../symphony';

import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlActions } from '../../services/ActionsService/sl-actions';
import { SlNavigationContext } from '../../global-constants';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';
import {
	ClickElementTypeId,
	ActionType,
	RequestType,
} from '../../global-interface';

import s from './FooterCatalog.scss';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';

export function FooterCatalog(): JSX.Element {
	useStyles(s);
	const { actions$ } = Container.take(SlNavigationContext, SlActions);

	const { footerCatalog$: footerCatalog } = useContainerData(
		SlNavigationContext,
		SlNavigationDataService,
		['footerCatalog$'],
	);

	const authService = Container.take('global', AuthService);

	const handleListItemClick = (
		alias: string,
		elementType: string | ClickElementTypeId,
	) => {
		actions$.next({
			type: ActionType.TrackFooterElementsClick,
			requestType: RequestType.Parallel,
			data: {
				alias,
				elementType,
			},
		});
	};

	return (
		footerCatalog && (
			<div sl-test-data="cmpFooterCatalog" className="sl-footer-catalog">
				<div className="sl-footer-catalog__container">
					{footerCatalog.map((list, i) => (
						<div key={i} className="sl-footer-catalog__list">
							<p className="sl-footer-catalog__title">
								{list.title}
							</p>
							<ul>
								{list.items.map((listItem, idx) => {
									if (
										!authService?.isLoggedIn()
										&& listItem.alias === 'leaderboard'
									) {
										return null;
									}

									return (
										<li
											key={idx}
											className="sl-footer-catalog__list__item"
										>
											{listItem.isExternalNavigation ? (
												<LocalizedLink
													to={`${Container.take(
														'global',
														'host',
													)}${listItem.path}`}
													onClick={() => handleListItemClick(
														listItem.alias,
														listItem.typeId,
													)}
													reloadDocument
												>
													{listItem.title}
												</LocalizedLink>
											) : (
												<LocalizedLink
													to={listItem.path}
													onClick={() => handleListItemClick(
														listItem.alias,
														listItem.typeId,
													)}
												>
													{listItem.title}
												</LocalizedLink>
											)}
										</li>
									);
								})}
							</ul>
						</div>
					))}
					<LanguageSwitcher />
				</div>
			</div>
		)
	);
}

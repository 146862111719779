import Container, { Service } from 'typedi';
import { BehaviorSubject } from 'rxjs';
import { history } from './history';

import { StorageService } from './storage.service';
import {
	cleanupStorage,
	shouldBeRemovedAfterLogout,
} from './utils/cleanUpStorage';

export interface ITokenData {
	accessToken: string;
	refreshToken: string;
	expiresIn: number;
	user?: IUser;
}

export interface IUser {
	id: number;
	email: string;
	name: string;
	avatarUrl: string;
	accessLevel: number;
	badge: string;
	level: number;
	xp: number;
	isPro: boolean;
	isActivated: boolean;
	registerDate: string;
	countryCode: string;
	isIterableUser: boolean;
	isNewRegisteredUser?: boolean;
}

export enum TokenKey {
	accessToken = 'accessToken',
	refreshToken = 'refreshToken',
	expiresIn = 'expiresIn',
	user = 'user',
}

declare global {
	interface Window {
		FB: any;
	}
}

@Service()
export class AuthService {
	private storage = Container.get(StorageService);

	public getUser(): IUser {
		return this.storage.load(TokenKey.user, 'local');
	}

	public userInfo$ = new BehaviorSubject<IUser>(this.getUser());

	private getExpirationDateISO(secondsToAdd: number): string {
		const date = new Date();
		date.setSeconds(date.getSeconds() + secondsToAdd);
		return date.toISOString();
	}

	public setTokens(tokenData: ITokenData, user?: IUser): void {
		const dataToSet = {
			[TokenKey.accessToken]: tokenData?.accessToken,
			[TokenKey.refreshToken]: tokenData?.refreshToken,
			[TokenKey.expiresIn]: tokenData?.expiresIn
				? this.getExpirationDateISO(tokenData.expiresIn)
				: null,
		};
		Object.keys(dataToSet).forEach((key) => {
			if (dataToSet[key]) {
				this.storage.save(key, dataToSet[key], 'local');
			}
		});
		if (user) {
			this.storage.save(TokenKey.user, user, 'local');
			this.userInfo$.next(user);
		} else {
			this.userInfo$.next(null);
		}

		if (this.getUser()) {
			// < Temporary solution
			const d = new Date();
			d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000);
			const expires = `expires=${d.toUTCString()}`;
			document.cookie = `slAccessToken=${tokenData?.accessToken};${expires};path=/`;
			// Temporary solution />
		}
	}

	public logout(navigationOptions?: {
		pathname: string;
		type: 'internal' | 'external';
	}): void {
		// < Temporary solution
		document.cookie = 'slAccessToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
		// Temporary solution />

		cleanupStorage(Object.keys(TokenKey), 'local');

		const FB = window.FB;
		if (FB) {
			FB.getLoginStatus((res: { status: string; }) => {
				if (res?.status === 'connected') FB.logout();
			});
		}

		cleanupStorage(shouldBeRemovedAfterLogout, 'session');

		this.userInfo$.next(null);

		if (navigationOptions) {
			if (navigationOptions.type === 'internal') {
				history.push(navigationOptions.pathname);
			} else {
				window.location.href = navigationOptions.pathname;
			}
		}
	}

	public isLoggedIn(updateUserData?: boolean): boolean {
		const isLoggedIn = !!this.storage.load(TokenKey.accessToken, 'local')
			&& !!this.storage.load(TokenKey.refreshToken, 'local')
			&& !!this.storage.load(TokenKey.user, 'local');

		if (updateUserData) {
			const userInfoValue = this.userInfo$.getValue();
			if (isLoggedIn && !userInfoValue) {
				this.userInfo$.next(this.getUser());
			} else if (!isLoggedIn && userInfoValue) {
				this.userInfo$.next(null);
			}
		}

		return isLoggedIn;
	}

	public isTokenExpired(): boolean {
		const expireDateISO: string = this.storage.load(
			TokenKey.expiresIn,
			'local',
		);
		const expireDate = new Date(expireDateISO);
		return isNaN((<unknown>expireDate) as number)
			? true
			: expireDate <= new Date();
	}

	public getToken(tokenKey: TokenKey): string {
		return this.storage.load(tokenKey, 'local');
	}

	public updateUserInfo(updateInfo: { [key: string]: unknown; }): IUser {
		const user: IUser = this.getUser();
		if (!user) {
			this.userInfo$.next(null);
			return null;
		}
		const updatedUserData = { ...user, ...updateInfo };
		this.storage.save(TokenKey.user, updatedUserData, 'local');
		this.userInfo$.next(updatedUserData);
		return updatedUserData;
	}
}

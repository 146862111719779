import React, { ReactElement } from 'react';

import { IconHelpCircle, IconLogout, IconSettings } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	AuthService,
	Container,
	IUser,
	history,
	i18n,
} from '../../../../symphony';

import { ILogOutConfig } from '../../global-interface';
import { SlNavigationTrackingService } from '../../services/sl-navigation-tracking.service';
import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlNavigationContext } from '../../global-constants';

import s from './SlUserSettings.scss';

interface ISlUserSettingsProps {
	user: IUser;
	classNameMenu?: string;
	userPhotoWrapper?: () => ReactElement;
}

export const SlUserSettings: React.FC<ISlUserSettingsProps> = React.memo(
	({ user, classNameMenu, userPhotoWrapper }) => {
		useStyles(s);
		const authService = Container.take('global', AuthService);

		const { userMenuOpen$, navBarOpen$, bitsMenuOpen$ } = Container.take(
			SlNavigationContext,
			SlNavigationDataService,
		);

		const onNavigationSettings = (element: string): void => {
			const { navigationTrackActions$ } = Container.take(
				SlNavigationContext,
				SlNavigationTrackingService,
			);
			navigationTrackActions$.next({
				action: 'click',
				element: `navigation_profile_${element}`,
				entityId: null,
			});
		};

		const goToProfile = (): void => {
			userMenuOpen$.next(false);
			navBarOpen$.next(false);
			bitsMenuOpen$.next(false);
			onNavigationSettings('gotoprofile');
			history.push('/profile');
		};

		const goToSettings = (id: number): void => {
			userMenuOpen$.next(false);
			navBarOpen$.next(false);
			bitsMenuOpen$.next(false);
			onNavigationSettings('settings');
			history.push(`/profile/${id?.toString()}?settings=true`);
		};

		const logOutHandler = (): void => {
			const { navbarInitialConfigs$ } = Container.take(
				SlNavigationContext,
				SlNavigationDataService,
			);
			const logoutConfig: ILogOutConfig = navbarInitialConfigs$.value?.logoutConfig;

			userMenuOpen$.next(false);

			if (logoutConfig?.callback) {
				logoutConfig?.callback();
			}
			authService.logout({
				pathname: logoutConfig?.pathname || '/',
				type: logoutConfig?.type || 'internal',
			});
			onNavigationSettings('logout');
		};

		const goToHelp = () => {
			userMenuOpen$.next(false);
			navBarOpen$.next(false);
			bitsMenuOpen$.next(false);
			onNavigationSettings('help');
			history.push('/contact');
		};

		return (
			<div
				onClick={(e) => e.stopPropagation()}
				className={`sl-user-settings ${classNameMenu || ''}`}
			>
				<div className="sl-user__mobile-menu">
					{userPhotoWrapper && userPhotoWrapper()}
					<div
						onClick={goToProfile}
						className="sl-user-settings__name"
					>
						<span className="sl-user-settings__name__title">
							{user?.name}
						</span>
						<p className="sl-user-settings__name__goto">
							{i18n.t('web-navigation.go-to-profile')}
						</p>
					</div>
				</div>
				<div className="sl-user-settings__line" />
				<div className="sl-user-settings__profile">
					<div
						onClick={() => goToSettings(user?.id)}
						className="sl-user-settings__profile__settings sl-user-settings__profile--item"
					>
						<div className="sl-user-settings__profile__settings__icon">
							<IconSettings />
						</div>
						<p>{i18n.t('web-navigation.settings')}</p>
					</div>
					<div
						onClick={goToHelp}
						className="sl-user-settings__profile__help sl-user-settings__profile--item"
					>
						<div className="sl-user-settings__profile__help__icon">
							<IconHelpCircle />
						</div>
						<p>{i18n.t('web-navigation.help')}</p>
					</div>
					<div
						onClick={logOutHandler}
						className="sl-user-settings__profile__logout sl-user-settings__profile--item"
					>
						<div className="sl-user-settings__profile__logout__icon">
							<IconLogout />
						</div>
						<p>{i18n.t('web-navigation.log-out')}</p>
					</div>
				</div>
			</div>
		);
	},
);

/* eslint-disable camelcase */
// Todo: implement one approach to tracking in this mf
import { Service, track } from '../../../symphony';
import { getPageName, pageNames } from '../../../shared/public/SlUtils/getPageName';

@Service()
export class SlOneTapTrackingService {
	private source = 'GOOGLE WIDGET';

	private getSourceDetail() {
		const pageName = getPageName(location.pathname);

		return (
			{
				[pageNames.homepage]: 'HOMEPAGE',
				[pageNames.blogList]: 'BLOG',
				[pageNames.singleBlog]: 'BLOG',
				[pageNames.faq]: 'FAQ',
				[pageNames.privacyPolicy]: 'PRIVACY AND POLICY',
				[pageNames.termsOfUse]: 'TERMS OF USE',
				[pageNames.career]: 'CAREERS',
				[pageNames.contact]: 'CONTACT',
				[pageNames.singleCoursePage]: 'COURSE PAGE',
				[pageNames.coursesCatalog]: 'COURSES CATALOG',
			}[pageName] || ''
		);
	}

	public trackOneTapImpression() {
		track({
			event_name: 'google_onetap_widget_impression',
			source: this.source,
			source_detail: this.getSourceDetail(),
		});
	}

	public trackOneTapSignInCompletion() {
		track({
			event_name: 'signin_completed',
			source: this.source,
			source_detail: this.getSourceDetail(),
		});
	}
}

export const TrackingEventNames = {
	slCodeCoachComplete: 'sl_codecoach_complete',
	slEomComplete: 'sl_eom_complete',
	slCompleteCodeCoach: 'CompleteCodeCoach', // This one for gtm and duplicates with af, need to fix
	slCodeCoachView: 'CodeCoachView',
	slCelebCCComplete: 'celeb_cc_complete', // This one for slTrack and maybe duplicates with af, need to check
	slContCelebCCComplete: 'cont_celeb_cc_complete',
	slCCPromptQuitAttempted: 'ccprompt_quit_attempted',
	slCCPromptQuitNotAttempted: 'ccprompt_quit_notattempted',
	slCCPromptQuitAttemptedNextTime: 'ccprompt_quit_attemptedcc_nexttime',
	slCCPromptQuitNotAttemptedNextTime: 'ccprompt_quit_notattemptedcc_nexttime',
	slCCPromptQuitAttemptedGoBack: 'ccprompt_quit_attemptedcc_goback',
	slCCPromptQuitNotAttemptedGoBack: 'ccprompt_quit_notattemptedcc_goback',
	slCCPromptQuitAttemptedCC: 'ccprompt_quit_attemptedcc',
	slCCPromptQuitNotAttemptedCC: 'ccprompt_quit_notattemptedcc',
	slCCPromptStartLetsGo: 'ccprompt_start_letsgo',
	slCCPromptStart: 'ccprompt_start',
	slCCCourseView: 'codecoach_course_view', // this one for slTrack and duplicates with gtm
	slCCLessonView: 'codecoach_lesson_view',
	slCCProfileView: 'codecoach_profile_view',
	slEomProjectView: 'eom_project_view',
	slCCCodeSolution: 'cc_code_Solution',
	slCCFailSolution: 'cc_fail_Solution',
	slCCSolveSolution: 'cc_solve_Solution',
	slCCCodeSeeSolutionBlock: 'cc_code_seeSolution_block',
	slCCFailSeeSolutionBlock: 'cc_fail_seeSolution_block',
	slCCSolveSeeSolutionBlock: 'cc_solve_seeSolution_block',
	slCCCodeSeeSolutionBlockUnlock: 'cc_code_seeSolution_block_unlock',
	slCCFailSeeSolutionBlockUnlock: 'cc_fail_seeSolution_block_unlock',
	slCCSolveSeeSolutionBlockUnlock: 'cc_solve_seeSolution_block_unlock',
	slCCCodeSeeSolution: 'cc_code_seeSolution',
	slCCFailSeeSolution: 'cc_fail_seeSolution',
	slCCSolveSeeSolution: 'cc_solve_seeSolution',
	slBitPopupCCSolutionUnlock: 'bitpopup_ccSolution_unlock',
	slBitPopupCCSolution: 'bitpopup_ccSolution',
	slBitPopupCCSolutionUnlockBits: 'bitpopup_ccSolution_unlock_bits',
	slBitPopupCCSolutionUnlockPro: 'bitpopup_ccSolution_unlock_pro',
	slBitPopupCCSolutionPro: 'bitpopup_ccSolution_pro',
	codecoachRun: 'codecoachRun',
	lessonComplete: 'lessonComplete',
	lessonProgressUpdate: 'lessonProgressUpdate',
	heartLost: 'heartLost',
	coursePageView: 'coursePageView',
	codeCoachStart: 'codecoach_start',
	eomStart: 'eomStart',
	goalComplete: 'goalComplete',
	slTermOfConditionsPopUpView: 'term_of_conditions_popup_view',
	slTermOfConditionsAccept: 'term_of_conditions_accept',
	proPageView: 'proPageView',
	courseComplete: 'courseComplete',
	courseProgressUpdate: 'courseProgressUpdate',
	slPrivacyPolicyPopUpView: 'privacy_policy_popup_view',
	slPrivacyPolicyAccept: 'privacy_policy_accept',
	courseStart: 'courseStart',
	materialGroupComplete: 'material_group_complete',

	// leaderboard events
	leaderboardWelcome: 'leaderboard_welcome',
	leaderboardWelcomeIamstar: 'leaderboard_welcome_iamstar',
	leaderboardTab: 'leaderboard_tab',
	leaderboardPageState: 'leaderboard_page_state',
	leaderboardDisabled: 'leaderboard_disabled',
	leaderboardDisabledJoin: 'leaderboard_disabled_join',
	leaderboardResult: 'leaderboard_result',
	leaderboardEarnxpItem: 'leaderboard_earnxp_item',
	leaderboardCtaViewed: 'leaderboard_cta_viewed',
	leaderboardCtaClicked: 'leaderboard_cta_clicked',
	leaderboardViewed: 'leaderboard_viewed',
	leaderboardTestUser: 'leaderboard_test_user',

	// Authentication
	clickToSignUp: 'clickToSignUp',
	signup: 'signup',
	signupCompleted: 'signup_completed',
	signinCompleted: 'signin_completed',
	signinPageImpression: 'signin_page_impression',
	signupPageImpression: 'signup_page_impression',
	signupPageWebImpression: 'signup_page_web_impression',

	// Other events
	appStoreClick: 'app_store_click',
	downloadNowBannerClick: 'download_now_banner_click',
	downloadNowBannerImpression: 'download_now_banner_impression',
	featureUnavailabilityClick: 'feature_unavailability_click',
	featureUnavailabilityImpression: 'feature_unavailability_impression',

	// Payment events
	trialStarted: 'trialStarted',
	subscribe: 'subscribe',
	subscribedAfterTrial: 'subscribedAfterTrial',

	// Discuss events
	discussPageView: 'discuss_impression',
	onDiscussPostClick: 'discuss_post_click',
	onDiscussTagClick: 'discuss_tag_click',
	onDiscussSearchClick: 'discuss_home_search_click',
	onDiscussCTAClick: 'discuss_cta_click',
	onDiscussPaginationClick: 'discuss_pagination_click',
	onDiscussHomeSectionClick: 'discuss_home_section_click',
	onDiscussJumpLinkClick: 'discuss_jump_link_click',

	// Code Bits events
	userCodeBitsImpression: 'user_code_bits_impression',
	userCodeBitsPostClick: 'user_code_bits_post_click',
	userCodeBitsSearchClick: 'user_code_bits_search_click',
	userCodeBitsCTAClick: 'user_code_bits_cta_click',
	userCodeBitsPaginationClick: 'user_code_bits_pagination_click',
	userCodeBitsSectionClick: 'user_code_bits_section_click',

	// Landing pages
	landingPageTargetedClick: 'landing_page_targeted_click',
	landingPageTargetedImpression: 'landing_page_targeted_impression',
	mobilePromotionLandingPageClick: 'mobile_promotion_landing_page_click',
	landingPageImpression: 'landing_page_impression',
	landingPageClick: 'landing_page_click',
	joinWaitlist: 'join_waitlist',

	// Onboarding
	onboardingClick: 'onboarding_click',
	onboardingImpression: 'onboarding_impression',

	// Home
	onHomeStartLearningCTAClick: 'landing_page_click',
	homeHeaderSectionView: 'landing_page_impression',

	// Group Subscription
	subscriptionGroupSettingsImpression:
        'subscription_group_settings_impression',
	subscriptionGroupSettingsActionClick:
        'subscription_group_settings_action_click',
	subscriptionGroupBannerClick: 'subscription_group_banner_click',
	subscriptionGroupBannerImpression: 'subscription_group_banner_impression',

	// Playground
	compilerLanguagesCatalogImpression: 'compiler_languages_catalog_impression',
	compilerLanguagesCatalogClick: 'compiler_languages_catalog_click',
	playgroundScreenClick: 'playground_screen_click',

	// Streak
	userStreakGoalPopupImpression: 'user_streak_goal_popup_impression',

	// Common
	onCourseClick: 'course_page_click',

	// Learning
	courseCatalogImpression: 'course_catalog_impression',

	// AI
	solotutorImpression: 'solotutor_impression',
	solotutorSendMessage: 'solotutor_send_message',
	solotutorCourseLinkClick: 'solotutor_click_link',
	kodiePlaygroundClick: 'kodie_playground_click',
	kodiePlaygroundFeedback: 'kodie_playground_feedback',
	kodieLessonClick: 'kodie_lesson_click',
	kodieLessonFeedback: 'kodie_lesson_feedback',
	kodieLessonImpression: 'kodie_lesson_impression',
	aiPlaygroundScreenClick: 'ai_playground_screen_click',
	aiPopupWarning: 'ai_popup_warning',
	aiResetChat: 'ai_reset_chat',
	aiSettingsUpdated: 'ai_settings_updated',

	// Translation
	dynamicTranslationButtonClick: 'dynamic_translation_button_click',
	dynamicTranslationCTAClick: 'dynamic_translation_cta_click',
	dynamicTranslationTipCTAClick: 'dynamic_translation_tip_cta_click',

	// Paywall
	aiPaywallsImpression: 'paywalls_impression',
	aiPaywallsClick: 'paywalls_click',
	paywallImpression: 'paywalls_impression',
	paywallClick: 'paywalls_click',
	paywallSectionView: 'paywalls_section_view',

	// WebToApp
	webToAppOpen: 'web_to_app_open',
	webToAppStart: 'web_to_app_start',
	webToAppPageImpression: 'web_to_app_page_impression',
	webToAppPageComplete: 'web_to_app_page_complete',
	webToAppQuizAnswer: 'web_to_app_quiz_answer',
	webToAppClick: 'web_to_app_click',
	webToAppCheckpointCompleted: 'web_to_app_checkpoint_completed'
};

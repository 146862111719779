import { useEffect } from 'react';

import {
	catchError, EMPTY, filter, interval, map, mergeMap, of, retry,
} from 'rxjs';
import {
	AuthService, Container, dataTrackingLSKey, getStorageValue, history, IBaseDataTracking,
} from '../../features/symphony';

import { getGTMPageCategories } from './getGTMPageCategories';
import { DataTrackingApi } from '../../features/api/public/data-tracking';
import { IExtTrackingOrchestratorApi, ExtTrackingOrchestratorApi } from '../../features/api/public/ext-tracking-orchestrator-api';

declare global {
	interface Window { dataLayer: any[]; }
}

export const useTracking = () => {
	const authService: AuthService = Container.take('global', AuthService);
	const trackingIntervalTime = 10000;
	const errorRetryTime = 5000;
	const retryAttempts = 3;

	useEffect(() => {
		// Sends tracking data to our backend
		const dataTrackingApi = Container.take('global', DataTrackingApi);

		interval(trackingIntervalTime).pipe(
			map(() => getStorageValue<IBaseDataTracking>()),
			filter((data) => !!data.length),
			mergeMap((data) => {
				localStorage.removeItem(dataTrackingLSKey);
				return dataTrackingApi.track(data).pipe(
					retry({
						count: retryAttempts,
						delay: errorRetryTime,
					}),
					catchError(() => of(EMPTY)),
				);
			}),
		).subscribe();
	}, []);

	useEffect(() => {
		// Sends tracking data to GTM
		history.listen((data) => {
			trackGTMPageView(data.location.pathname);
		});
	}, []);

	useEffect(() => {
		trackSessionStart();
	}, []);

	const trackSessionStart = () => {
		const isStartSessionEventSendedKey = 'isStartSessionEventSended';
		const extTrackingOrchestratorApi: IExtTrackingOrchestratorApi = Container.take(
			'global',
			ExtTrackingOrchestratorApi,
		);
		const userId = authService.userInfo$.value?.id;
		const userEmail = authService.userInfo$.value?.email;

		if (!userId) return;

		const isStartSessionEventSended = sessionStorage.getItem(isStartSessionEventSendedKey);

		if (isStartSessionEventSended) return;

		extTrackingOrchestratorApi.startSessionEvent(userId, userEmail, (window.initialData as any).clientIp).pipe(
			catchError(() => of(null)),
		).subscribe();
		sessionStorage.setItem(isStartSessionEventSendedKey, '1');
	};

	const trackGTMPageView = (pathname: string) => {
		const isLoggedIn = !!authService.userInfo$.value?.id;
		window.dataLayer = window.dataLayer || [];
		const { subCategory, category } = getGTMPageCategories(pathname);

		window.dataLayer.push({
			event: 'history_url_change',
			logged_in: isLoggedIn,
			...(category && { category }),
			...(subCategory && { subcategory: subCategory }),
			...(isLoggedIn && {
				user_id: authService.userInfo$.value?.id,
				logged_in: !!authService.userInfo$.value?.id,
				monetization_status: 'free',
			}),
		});
	};
};

import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, i18n } from '../../../../../symphony';

import { SlLoginContext } from '../../../login-context';

import s from './SwitchPageLink.scss';
import { LocalizedLink } from '../../../../../shared/public/LocalizedLink/LocalizedLink';

export interface ISwitchPageLinkProps {
	page: 'login' | 'signup';
	isLoading: boolean;
	onBeforeViewChange?: () => void;
	darkMode?: boolean;
	primaryColor?: string;
}

export const SwitchPageLink: React.FC<ISwitchPageLinkProps> = React.memo(
	({
		page, isLoading, onBeforeViewChange = () => {}, darkMode, primaryColor,
	}) => {
		useStyles(s);
		const useRouter: string = Container.take(SlLoginContext, 'useRouter');
		const appName = Container.take('global', 'appName');
		const [searchParams] = useSearchParams();
		const isLoginPage = page === 'login';

		const getRedirectUrl = (isLogin: boolean): string => (
			`../${isLogin ? 'signup' : 'login'}?${searchParams.toString()}`
		);

		const renderRouterButton = () => (useRouter ? (
			<LocalizedLink
				className="sl-login-switch-link__link"
				onClick={onBeforeViewChange}
				to={getRedirectUrl(isLoginPage)}
				replace
				style={{ color: primaryColor }}
			>
				{isLoginPage
					? i18n.t('web-auth.create-account')
					: i18n.t('web-auth.signin')}
			</LocalizedLink>
		) : (
			<button
				type="button"
				className={`sl-login-switch-link__link ${
					useRouter ? '' : 'sl-login-switch-link__button'
				} `}
				onClick={onBeforeViewChange}
			>
				{isLoginPage
					? i18n.t('web-auth.create-account')
					: i18n.t('web-auth.signin')}
			</button>
		));

		return (
			<div className="sl-login-switch-link">
				<span
					className={`sl-login-switch-link__text${
						darkMode ? ' dark-mode' : ''
					}`}
				>
					{isLoginPage
						? `${i18n.t('web-auth.new-to-solo')} ${appName}?`
						: i18n.t('web-auth.signup-already-have-account')}
				</span>
				{isLoading ? (
					<span
						className="sl-login-switch-link__link"
					>
						{isLoginPage
							? i18n.t('web-auth.create-account')
							: i18n.t('web-auth.signin')}
					</span>
				) : (
					renderRouterButton()
				)}
			</div>
		);
	},
);

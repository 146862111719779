import { useEffect } from 'react';
import { ILearningExperience, LearningExperienceType, i18n } from '../../../symphony';
import {
	ILearningPathSubtree, IMaterial, IMaterialInfo, UIComponentType,
} from '../../../api/private/learn-engine-api/learn-engine-api.interface';

export const formatNumber = (num: number): string | number => {
	if (!num) return 0;

	const numLength = num.toString().length;

	if (numLength <= 3) return num;
	if (numLength <= 6) {
		return `${(num / 1000).toFixed(1)}K`;
	}
	if (numLength <= 9) {
		return `${(num / 1000000).toFixed(1)}M`;
	}
	return `${(num / 1000000000).toFixed(1)}B`;
};

export const formatDate = (date: string): string => {
	const locale = `${i18n.language}-${i18n.language.toUpperCase()}`;
	return new Date(date).toLocaleDateString(locale, { month: 'long', day: 'numeric' });
};

// Todo: move to shared
export const disableBodyScroll = (): void => {
	document.body.style.overflow = 'hidden';
	document.body.style.height = '100vh';
	document.body.style.width = '100vw';
	document.body.style.position = 'fixed';
};

export const enableBodyScroll = (): void => {
	document.body.style.overflow = 'auto';
	document.body.style.height = 'auto';
	document.body.style.width = 'auto';
	document.body.style.position = 'static';
};

export const getRandomInt = (min: number, max: number): number => {
	const randomBuffer = new Uint32Array(1);
	window.crypto.getRandomValues(randomBuffer);
	const randomNumber = randomBuffer[0] / (0xffffffff + 1);
	return Math.floor(randomNumber * (max - min + 1)) + min;
};

export const getCorrectAnswerResultText = (
	quizFeedbackTexts: string[],
): string => quizFeedbackTexts[getRandomInt(0, quizFeedbackTexts.length - 1)];

export const hasImageInMaterial = (material: IMaterial) => {
	if (material?.materialInfo?.body) {
		for (const component of material.materialInfo.body) {
			if (component.componentTypeId === UIComponentType.Image) {
				return true;
			}
		}
	}

	if (material?.children) {
		for (const child of material.children) {
			if (hasImageInMaterial(child)) {
				return true;
			}
		}
	}

	return false;
};

export const getLETypeFromRoute = ({
	courseAlias,
	pathAlias,
}: {
	courseAlias?: string;
	pathAlias?: string;
}): LearningExperienceType => {
	if (courseAlias) {
		return LearningExperienceType.Course;
	}
	if (pathAlias) {
		return LearningExperienceType.LearningPath;
	}
	return null;
};

export const useDisableKeyPress = () => {
	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			event.stopPropagation();
		};
		window.addEventListener('keypress', handleKeyPress, true);
		return () => {
			window.removeEventListener('keypress', handleKeyPress, true);
		};
	}, []);
};

export const getMaterialFromCourse = (course: IMaterial, relationId: number): IMaterialInfo => {
	for (
		let index = 0;
		index < course.children.length;
		++index
	) {
		const module = course.children[index];

		if (
			module.materialInfo.header.materialRelationId
			=== relationId
		) {
			return module.materialInfo;
		}

		const material = module.children?.find(
			(el) => el.materialInfo.header.materialRelationId
				=== relationId,
		);
		if (material) {
			return material.materialInfo;
		}
	}
	return null;
};

export const getMaterialFromPath = (path: ILearningPathSubtree, relationId: number): IMaterialInfo => {
	for (
		let index = 0;
		index < path.units.length;
		++index
	) {
		const material = getMaterialFromCourse(path.units[index].courseSubtree, relationId);
		if (material) {
			return material;
		}
	}
	return null;
};

export const getLEStatus = (learningExperience: ILearningExperience): 'default' | 'early-bird' | 'launched' => {
	if (!learningExperience || !learningExperience.startDate) {
		return 'default';
	}

	if (new Date(learningExperience.startDate).getTime() <= new Date().getTime()) {
		return 'launched';
	}

	return 'early-bird';
};

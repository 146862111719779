import React, { FC } from "react";
import { i18n } from "../../../../../symphony";
import { SlIcon } from "@sololearnorg/icons";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './FiveStarReviews.scss';

type FiveStarReviewsProps = {
	title: string;
};

export const FiveStarReviews: FC<FiveStarReviewsProps> = ({ title }) => {
	useStyles(s);
	return <div className="five-star-reviews">
		<div className="five-star-reviews-stars">
			<SlIcon iconId="stars" />
		</div>
		<p
			className="five-star-reviews-text"
			dangerouslySetInnerHTML={{
				__html: i18n.t(title)
			}}
		/>
	</div>;
};
import { Observable } from 'rxjs';

import { AuthLevel, Authorize } from '../private/authorize.decorator';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { IApiUrls, IHostsConfigs } from '../private/global.interface';
import {
	ILeaderboardApi,
	ILeaderboardApiVersions,
	ILeaderboardLeague,
	ILeaderboard,
	IJoinLeaderboard,
	IToggleLeaderboardPayload,
} from '../private/leaderboard-api/leaderboard-api.interface';
import { constructUrl } from '../private/utils/httpParamsUtils';
import { Service, Container, ILeaderboardInfo } from '../../symphony';

@Service()
export class LeaderboardApi implements ILeaderboardApi {
	private environmentUrl: string;

	private axiosInstance: AxiosInstanceType;

	private versions: ILeaderboardApiVersions;

	constructor() {
		this.environmentUrl = (
            Container.take('global', 'envUrl') as IApiUrls
		)?.leaderboardApi;

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;

		this.versions = (
            Container.take('global', 'hostsConfigs') as IHostsConfigs
		)?.leaderboardApi?.version;
	}

    @Authorize(AuthLevel.authenticated)
	public getLeaderboardLeagues(): Observable<ILeaderboardLeague[]> {
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				'api/leaderboard/leagues',
				this.versions?.getLeaderboardLeagues,
			),
		);
	}

    @Authorize(AuthLevel.authenticated)
    public getLeaderboard(): Observable<ILeaderboard> {
    	return this.axiosInstance.get(
    		constructUrl(
    			this.environmentUrl,
    			'api/leaderboard',
    			this.versions?.getLeaderboard,
    		),
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public getLeaderboardPredictedState(xp: number): Observable<ILeaderboard> {
    	return this.axiosInstance.get(
    		constructUrl(
    			this.environmentUrl,
    			`api/leaderboard/prediction?xpchange=${xp}`,
    			this.versions?.getLeaderboardPredictedState,
    		),
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public getLeaderboardInfo(): Observable<ILeaderboardInfo> {
    	return this.axiosInstance.get(
    		constructUrl(
    			this.environmentUrl,
    			'api/leaderboard/lbinfo',
    			this.versions?.getLeaderboardInfo,
    		),
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public joinLeaderboard(): Observable<IJoinLeaderboard> {
    	return this.axiosInstance.post(
    		constructUrl(
    			this.environmentUrl,
    			'api/leaderboard/join',
    			this.versions?.joinLeaderboard,
    		),
    		null,
    	);
    }

    @Authorize(AuthLevel.authenticated)
    public toggleLeaderboard(
    	payload: IToggleLeaderboardPayload,
    ): Observable<unknown> {
    	return this.axiosInstance.put(
    		constructUrl(
    			this.environmentUrl,
    			'api/leaderboard/toggle',
    			this.versions?.toggleLeaderboard,
    		),
    		payload,
    	);
    }
}

export * from '../private/leaderboard-api/leaderboard-api.interface';

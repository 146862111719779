import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';

import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlNavigationContext } from '../../global-constants';

import s from './Overlay.scss';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';

export function Overlay(): JSX.Element {
	useStyles(s);
	const { isExpandedCatalog$: isExpandedCatalog } = useContainerData(
		SlNavigationContext,
		SlNavigationDataService,
		['isExpandedCatalog$'],
	);

	return <div className={`overlay ${isExpandedCatalog ? 'show' : ''}`} />;
}

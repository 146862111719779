import { useEffect, useRef } from 'react';
import { BehaviorSubject } from 'rxjs';
import { Container } from '../../features/symphony';

type PublicTokenInfoType = BehaviorSubject<{ shouldShowCaptcha?: boolean, newCaptchaToken?: string; }>;

export const usePublicTokenCaptchaHandler = () => {
	const isInitRef = useRef(true);

	if (isInitRef.current) {
		Container.register('global', 'publicTokenInfo$', new BehaviorSubject({ shouldShowCaptcha: false }));
		Container.register('global', 'publicTokenLoading$', new BehaviorSubject(false));
	}

	const showCaptcha = () => {
		if (document.querySelector('#g-recaptcha')) {
			return;
		}

		const publicTokenInfo$ = (Container.take('global', 'publicTokenInfo$') as PublicTokenInfoType);
		const recaptchaKey = Container.take('global', 'recaptchaKey');

		window.onDataCallback = (newCaptchaToken: string) => {
			publicTokenInfo$.next({ newCaptchaToken });
			document.querySelector('#g-recaptcha-wrapper').remove();
		};
		document.querySelector('body').insertAdjacentHTML(
			'beforeend',
			`<div
                id="g-recaptcha-wrapper" 
                style="pointer-events: all; display: flex; align-items: center; justify-content: center; position: fixed; width: 100%; height: 100%; left: 0; top: 0; background-color: #fffffff5; z-index: 200000000; flex-direction: column; align-items: center; font-family: 'Fira Sans'; gap: 20px;"
            >
                <h2>Please confirm you're not a robot.</h2>
                <div 
                    id="g-recaptcha" 
                    data-sitekey="${recaptchaKey}" 
                    data-action="LOGIN" 
                    data-callback="onDataCallback"
                ></div>
            </div>`,
		);
		window.onloadCallback = () => {
			window.grecaptcha.enterprise.render('g-recaptcha', {
				sitekey: recaptchaKey,
			});
		};
		const script = document.createElement('script');
		script.src = 'https://www.google.com/recaptcha/enterprise.js?onload=onloadCallback&render=explicit';
		document.body.appendChild(script);
	};

	useEffect(() => {
		isInitRef.current = false;
	}, []);

	useEffect(() => {
		const publicTokenInfo$ = (Container.take('global', 'publicTokenInfo$') as PublicTokenInfoType);
		publicTokenInfo$.subscribe(({ shouldShowCaptcha }) => {
			if (shouldShowCaptcha) {
				showCaptcha();
			}
		});
	}, []);
};

import React, { FC, useEffect } from "react";
import { Container } from "../../symphony";
import { GetProService } from "../private/getpro.service";

type Props = {};

export const GetPro: FC<Props> = () => {

	const { openPaywall } = Container.take('global', GetProService);

	useEffect(() => {
		openPaywall();
	}, []);

	return <div></div>;
};
import { BehaviorSubject } from 'rxjs';

import { Service } from '../../../symphony';

import {
	ICoursesCatalog,
	IFooterCatalog,
	INavConfig,
	SlNavbarState,
} from '../global-interface';
import { ISlNavbarInitialConfigs, SlNavbarThemes, SlNavbarType } from '../../../shared/public/globalInterfaces/globalInterfaces';
import { IBitSourcesData } from '../../../api/public/gamification-api';

@Service()
export class SlNavigationDataService {
	public navBarMode$ = new BehaviorSubject<SlNavbarThemes>(
		SlNavbarThemes.light,
	);

	// Menu settings
	public navBarOpen$ = new BehaviorSubject<boolean>(false);

	public userMenuOpen$ = new BehaviorSubject<boolean>(false);

	public bitsMenuOpen$ = new BehaviorSubject<boolean>(false);

	public streakMenuOpen$ = new BehaviorSubject<boolean>(false);

	public bitsModalOpen$ = new BehaviorSubject<boolean>(false);

	// Data streams
	public bitSources$ = new BehaviorSubject<IBitSourcesData>(null);

	public bitSourcesLoading$ = new BehaviorSubject<boolean>(false);

	public experiments$ = new BehaviorSubject<{
		[experimentName: string]:
		| {
			[flowName: string]: boolean;
		}
		| boolean;
	}>(null);

	public navbarState$ = new BehaviorSubject<SlNavbarState>(
		SlNavbarState.hidden,
	);

	public footerState$ = new BehaviorSubject<SlNavbarState>(
		SlNavbarState.shown,
	);

	public navbarTheme$ = new BehaviorSubject<SlNavbarThemes>(
		SlNavbarThemes.light,
	);

	public navbarType$ = new BehaviorSubject<SlNavbarType>(
		SlNavbarType.default,
	);

	public navbarInitialConfigs$ = new BehaviorSubject<ISlNavbarInitialConfigs>(
		null,
	);

	public navConfig$ = new BehaviorSubject<INavConfig>(null);

	public coursesCatalog$ = new BehaviorSubject<ICoursesCatalog>(null);

	public footerCatalog$ = new BehaviorSubject<IFooterCatalog[]>(null);

	public isExpandedCatalog$ = new BehaviorSubject<boolean>(false);
}

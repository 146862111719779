import {
	BehaviorSubject, Observable, Subject, of,
} from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import {
	Service,
	Container,
	track,
	TrackingService,
	TrackingEventNames,
} from '../../../../../symphony';
import { ITrackParams, ITrackingData } from '../../../global-interfaces';

declare const slTrack: (payload: ITrackAction) => void;

export interface ITrackAction {
    action: string;
    element: string;
    entityId?: number;
    redirectUrl?: string;
    experiment?: string;
}

export interface IAppsFlyerAction {
    value?: Record<string, unknown>;
    name: string;
}

@Service()
export class SlStaticPagesTrackingService {
	public trackAction$ = new Subject<ITrackAction>();

	public appsFlyer$ = new BehaviorSubject<IAppsFlyerAction>(null);

	private fingerprint: string = Container.take('global', 'fingerprint');

	private tracking$ = Container.take('global', TrackingService).tracking$;

	public trackActionStream: Observable<ITrackAction> = this.trackAction$.pipe(
		filter((a) => !!a),
		tap((action: ITrackAction) => {
			slTrack({
				action: action.action,
				element: action.element,
				entityId: action.entityId || null,
				redirectUrl: action.redirectUrl || null,
			});
		}),
	);

	// new tracking --->
	private getDefaultTrackingProps = () => ({
		id: uuidv4(),
		device_id: this.fingerprint,
	});

	public trackEventHandler = (trackingData: ITrackingData) => {
		track({
			...this.getDefaultTrackingProps(),
			...trackingData,
		});
	};

	public trackAILandingPageImpression = ({ sectionTitle }: ITrackParams): Observable<null> => {
		this.tracking$.next({
			eventName: TrackingEventNames.landingPageImpression,
			eventParams: {
				version: '3-0-0',
				experienceTypeId: '6',
				experienceAlias: 'ai-course',
				sectionType: 'CUSTOM',
				sectionTitle,
				urlText: window.location.pathname,
				urlQuery: window.location.search,
			},
		});
		return of(null);
	};

	public trackAILandingPageClick = ({
		sectionTitle,
		buttonId,
		buttonLabel,
		clickType,
	}: ITrackParams): Observable<null> => {
		this.tracking$.next({
			eventName: TrackingEventNames.landingPageClick,
			eventParams: {
				version: '3-0-0',
				experienceTypeId: '6',
				experienceAlias: 'ai-course',
				sectionType: 'CUSTOM',
				sectionTitle,
				clickType,
				buttonId,
				buttonLabel,
				urlText: window.location.pathname,
				urlQuery: window.location.search,
			},
		});
		return of(null);
	};

	public trackJoinWaitlist = ({
		sectionTitle,
		buttonId,
		buttonLabel,
		email,
	}: ITrackParams): Observable<null> => {
		this.tracking$.next({
			eventName: TrackingEventNames.joinWaitlist,
			eventParams: {
				sectionType: 'CUSTOM',
				sectionTitle,
				buttonId,
				buttonLabel,
				email,
				urlText: window.location.pathname,
				urlQuery: window.location.search,
			},
		});
		return of(null);
	};
}

import React, { FC, RefObject, useEffect } from "react";
import s from "./LongPaywallReviews.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container, i18n } from "../../../../../../symphony";
import { SlIcon } from "@sololearnorg/icons";
import { useInView } from "../../../../paywalls.utils";
import { PaywallsService } from "../../../../services/paywalls.service";

type Props = {
	data: {
		heading: string;
		reviews: {
			reviewer: string;
			review: string;
			avatarUrl: string;
		}[];
	};
	style: React.CSSProperties;
	sectionOrder: number;
};

export const LongPaywallReviews: FC<Props> = ({ data, style, sectionOrder }) => {
	useStyles(s);
	const { trackSectionView } = Container.take('paywalls', PaywallsService);
	const [ref, isInView] = useInView({
		threshold: 0.7
	});

	useEffect(() => {
		isInView && trackSectionView('longReviews', sectionOrder);
	}, [isInView]);

	return <div ref={ref as RefObject<HTMLDivElement>} style={style} className="reviews">
		<h3>{i18n.t(data.heading)}</h3>
		<div className="reviews-list">
			{data.reviews.map(r => (<div className="reviews-review" key={r.reviewer}>
				<div className="reviews-review-user">
					<img src={r.avatarUrl} />
					<h6>{i18n.t(r.reviewer)}</h6>
				</div>
				<div className="reviews-review-stars">
					<SlIcon iconId="stars" />
				</div>
				<p>{i18n.t(r.review)}</p>
			</div>))}
		</div>
	</div>;
};
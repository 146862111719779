import React from 'react';

export const IconHeartRefill = () => (
	<svg
		width="56"
		height="56"
		viewBox="0 0 56 56"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1143_5726)">
			<path
				d="M51.029 31.125L30.3559 52.5002C29.0028 53.8993 26.7885 53.8993 25.4355 52.5002L4.7624 31.125C-1.23359 24.9205 -0.902539 14.6601 5.74891 8.9028C11.558 3.8747 20.4475 4.78179 25.7936 10.301L27.9007 12.4763L30.0078 10.301C35.3537 4.78189 44.243 3.8747 50.0521 8.90248C56.7039 14.6597 57.0351 24.9203 51.029 31.125Z"
				fill="#E93A79"
			/>
			<g clipPath="url(#clip1_1143_5726)">
				<path
					d="M33.6493 23.35C32.0193 21.72 29.7093 20.78 27.1693 21.04C23.4993 21.41 20.4793 24.39 20.0693 28.06C19.5193 32.91 23.2693 37 27.9993 37C31.1893 37 33.9293 35.13 35.2093 32.44C35.5293 31.77 35.0493 31 34.3093 31C33.9393 31 33.5893 31.2 33.4293 31.53C32.2993 33.96 29.5893 35.5 26.6293 34.84C24.4093 34.35 22.6193 32.54 22.1493 30.32C21.3093 26.44 24.2593 23 27.9993 23C29.6593 23 31.1393 23.69 32.2193 24.78L30.7093 26.29C30.0793 26.92 30.5193 28 31.4093 28H34.9993C35.5493 28 35.9993 27.55 35.9993 27V23.41C35.9993 22.52 34.9193 22.07 34.2893 22.7L33.6493 23.35Z"
					fill="white"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_1143_5726">
				<rect width="56" height="56" fill="white" />
			</clipPath>
			<clipPath id="clip1_1143_5726">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(16 17)"
				/>
			</clipPath>
		</defs>
	</svg>
);

import React, { useState, useEffect, useRef } from 'react';
import { takeUntil, tap } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, IStreak, StreakService } from '../../../../symphony';

import { StreakMenu } from '../StreakMenu/StreakMenu';
import { SlActions } from '../../services/ActionsService/sl-actions';
import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlNavigationContext } from '../../global-constants';
import { ActionType, RequestType } from '../../global-interface';

import s from './UserStreak.scss';
import { useOutsideClick } from '../../../../shared/public/SlHooks/useOutsideClick';
import { SlUserStreak } from '../../../../shared/public/SlUserStreak/SlUserStreak';

export function UserStreak(): JSX.Element {
	useStyles(s);
	const [streak, setStreak] = useState<IStreak>(null);
	const [streakMenuOpen, setStreakMenuOpen] = useState<boolean>(false);

	const { streakMenuOpen$ } = Container.take(
		SlNavigationContext,
		SlNavigationDataService,
	);

	useEffect(() => {
		const unsubscriber = new Subject<void>();
		const { streak$ } = Container.take('global', StreakService);

		merge(
			streakMenuOpen$.pipe(tap(setStreakMenuOpen)),
			streak$.pipe(tap(setStreak)),
		)
			.pipe(takeUntil(unsubscriber))
			.subscribe();

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const element = useRef<HTMLDivElement>(null);

	useOutsideClick(element, streakMenuOpen, !streakMenuOpen, () => {
		streakMenuOpen$.next(false);
	});

	const onClick = () => {
		const { actions$ } = Container.take(SlNavigationContext, SlActions);
		actions$.next({
			type: ActionType.OnStreakIconClick,
			requestType: RequestType.Cancelable,
		});
	};

	return streak ? (
		<>
			{' '}
			<div ref={element} className="user-streak__container">
				<div
					className={`user-streak__streak-icon ${
						streakMenuOpen ? 'user-streak__streak-icon--open' : ''
					}`}
				>
					<SlUserStreak
						isRiched={streak.todayReached}
						streakDay={streak.daysCount}
						onClick={onClick}
					/>
				</div>
				<div className="user-streak__menu">
					{streakMenuOpen && streak && <StreakMenu streak={streak} />}
				</div>
			</div>
		</>
	) : null;
}

import {
	ITrackingEventParams, TrackingSearchParam, TrackingSource, TrackingSourceDetail,
} from '../../../shared/public/globalInterfaces/trackingInterface';
import {
	TRACKING_SOURCE_DETAIL_MAPPING,
	TRACKING_SOURCE_MAPPING,
} from '../constants';

export const generateTrackingEventParams = (
	params: ITrackingEventParams = null,
) => {
	const queryParams = new URLSearchParams(window.location.search);
	let result = {} as ITrackingEventParams;

	if (params) {
		const { source, sourceDetail, ...restParams } = params;
		result = {
			...restParams,
			source: TRACKING_SOURCE_MAPPING[source] as TrackingSource,
			sourceDetail: TRACKING_SOURCE_DETAIL_MAPPING[
				sourceDetail
			] as TrackingSourceDetail,
		};
	}

	queryParams.forEach((value, key) => {
		if (key === TrackingSearchParam.Source) {
			result.source = TRACKING_SOURCE_MAPPING[value];
			return;
		}

		if (key === TrackingSearchParam.SourceDetail) {
			result.sourceDetail = TRACKING_SOURCE_DETAIL_MAPPING[value];
			return;
		}

		result[key] = value;
	});

	if (!result.source) {
		result.source = TRACKING_SOURCE_MAPPING[
			TrackingSource.Other
		] as TrackingSource;
	}

	if (!result.sourceDetail) {
		result.sourceDetail = TRACKING_SOURCE_DETAIL_MAPPING[
			TrackingSourceDetail.Other
		] as TrackingSourceDetail;
	}

	return result;
};

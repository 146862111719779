import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PlanDetails.scss';
import { PaymentProduct } from "../../../../../api/public/subscription-api";
import { i18n } from "../../../../../symphony";
import { SlIcon } from "@sololearnorg/icons";
import { getDayFromToday, injectPrice, injectTrialDays } from "../../../paywalls.utils";

type PlanDetialsProps = {
	paymentProduct: PaymentProduct;
};

const getMonthNames = () => [
	i18n.t('web-checkout.january'),
	i18n.t('web-checkout.february'),
	i18n.t('web-checkout.march'),
	i18n.t('web-checkout.april'),
	i18n.t('web-checkout.may'),
	i18n.t('web-checkout.june'),
	i18n.t('web-checkout.july'),
	i18n.t('web-checkout.august'),
	i18n.t('web-checkout.september'),
	i18n.t('web-checkout.october'),
	i18n.t('web-checkout.november'),
	i18n.t('web-checkout.december'),
];

export const PlanDetails: FC<PlanDetialsProps> = ({
	paymentProduct
}) => {
	useStyles(s);

	const getMonthName = () => {
		const date = new Date();
		date.setDate(date.getDate() + paymentProduct.trialDays);
		return getMonthNames()[date.getMonth()];
	};

	return <div className="plan-details">
		<div className="plan-details-summary">
			{paymentProduct.discountedAmount !== paymentProduct.amount && <>
				<div className="plan-details-summary-row">
					<span className='plan-details-summary-title'>{i18n.t('paywalls.web.checkout.full-price')}</span>
					<span className='plan-details-summary-value'>{injectPrice(paymentProduct.amount.toString(), '{{price}}')}</span>
				</div>
				<div className="plan-details-summary-row">
					<span className='plan-details-summary-title discount'>{i18n.t('web-checkout.price-info-yearly-discount')}</span>
					<span className='plan-details-summary-value discount'>
						-{injectPrice((paymentProduct.amount - paymentProduct.discountedAmount).toFixed(2), '{{price}}')}
					</span>
				</div>
			</>}
			{paymentProduct.trialDays !== 0 && <div className="plan-details-summary-row">
				<span className='plan-details-summary-title'>{
					i18n.t('web-checkout.charged-on', {
						month: getMonthName(),
						days: getDayFromToday(paymentProduct.trialDays),
					})
				}</span>
				<span className='plan-details-summary-value'>{injectPrice(paymentProduct.discountedAmount.toString(), '{{price}}')}</span>
			</div>}

			<div className="plan-details-summary-row">
				<span className='plan-details-summary-title'>{i18n.t('web-checkout.price-info-total')}</span>
				<span className='plan-details-summary-value'>
					{injectPrice(paymentProduct.trialDays !== 0 ? '0' : paymentProduct.discountedAmount.toString(), '{{price}}')}
				</span>
			</div>
		</div>
		{paymentProduct.trialDays > 0 && <div className="plan-details-trial-info">
			<div className="plan-details-trial-info-row">
				<div className="plan-details-trial-info-icon">
					<SlIcon iconId={'thunderFilled'} />
				</div>
				<div>
					<p className="plan-details-trial-info-title">{i18n.t('web-checkout.trial-instruction0-title')}</p>
					<p className="plan-details-trial-info-subtitle">{i18n.t('web-checkout.trial-instruction0-text')}</p>
				</div>
			</div>
			<div className="plan-details-trial-info-row">
				<div className="plan-details-trial-info-icon">
					<SlIcon iconId={'checkmarkFilled'} />
				</div>
				<div>
					<p className="plan-details-trial-info-title">{injectTrialDays(paymentProduct.trialDays.toString(), i18n.t('web-checkout.trial-instruction1-title'))}</p>
					<p className="plan-details-trial-info-subtitle">
						{injectPrice(paymentProduct.discountedAmount.toString(), i18n.t('web-checkout.trial-instruction1-text'))}
					</p>
				</div>
			</div>
			<div className="plan-details-trial-info-timeline"></div>
		</div>}
		{paymentProduct.trialDays === 0 && <div className="plan-details-no-trial-info">
			<div className="plan-details-no-trial-info-row">
				<div className="plan-details-no-trial-info-icon"><SlIcon iconId={'correctRound'} /></div>
				<span className="plan-details-no-trial-info-text">
					{injectPrice(
						paymentProduct.discountedAmount.toString(),
						i18n.t(({
							1: 'paywalls.web.checkout.no-trial.monthly.instruction',
							3: 'paywalls.web.checkout.no-trial.quarterly.instruction',
							12: 'paywalls.web.checkout.no-trial.annual.instruction'
						})[paymentProduct.period])
					)}
				</span>
			</div>
			<div className="plan-details-no-trial-info-row">
				<div className="plan-details-no-trial-info-icon"><SlIcon iconId={'correctRound'} /></div>
				<span className="plan-details-no-trial-info-text">{i18n.t('web-checkout.instruction1-text')}</span>
			</div>
			{paymentProduct.key === 'max' && <div className="plan-details-no-trial-info-row">
				<div className="plan-details-no-trial-info-icon"><SlIcon iconId={'correctRound'} /></div>
				<span className="plan-details-no-trial-info-text">
					{i18n.t('paywalls.web.checkout.max-refund')}
				</span>
			</div>}
		</div>}
	</div>;
};
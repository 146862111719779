import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { history } from '../../../features/symphony';

const Redirect = ({ to, condition = true }) => {
	const { language } = useParams();

	useEffect(() => {
		if (condition) {
			history.replace(`/${language}${to}`);
		}
	}, []);

	return null;
};

export default Redirect;

import React, { ReactNode } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './SlActionButton.scss';

export enum ActionButtonTypes {
    primary = 'primary',
    secondary = 'secondary',
    pro = 'pro',
}

export interface ISlActionButtonProps {
    children: string | ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    className?: string;
    buttonType?: ActionButtonTypes;
    type?: 'submit' | 'button' | 'reset';
}

/**
 * @deprecated in favor of `@sololearnorg/sol`
 */
export const SlActionButton: React.FC<ISlActionButtonProps> = React.memo(
	({
		children,
		disabled = false,
		onClick,
		className,
		buttonType = ActionButtonTypes.primary,
		type = 'button',
	}) => {
		useStyles(s);
		return (
			<button
				onClick={onClick}
				disabled={disabled}
				className={`sl-action-btn ${buttonType === ActionButtonTypes.primary
					? 'sl-action-btn--primary'
					: 'sl-action-btn--secondary'
				} ${className || ''}`}
				// eslint-disable-next-line
                type={type}
				tabIndex={0}
				sl-test-data={`actionButton${buttonType}`}
			>
				{children}
			</button>
		);
	},
);

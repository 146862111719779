import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, i18n } from '../../../../../symphony';

import { SlLoginContext } from '../../../login-context';

import s from './TermsOfUseLink.scss';

export interface ITermsOfUseLinks {
	onClick?: () => void;
	darkMode?: boolean;
	primaryColor?: string;
}

export const TermsOfUseLink: React.FC<ITermsOfUseLinks> = React.memo(
	({ onClick = () => {}, darkMode, primaryColor }) => {
		useStyles(s);
		const redirectTarget: '_self' | '_blank' = Container.take(
			SlLoginContext,
			'redirectTarget',
		);

		return (
			<div className={`sl-login-terms${darkMode ? ' dark-mode' : ''}`}>
				<span>{i18n.t('web-auth.terms-message')}</span>
				<a
					className="sl-login-terms__link"
					onClick={onClick}
					target={redirectTarget}
					style={{ color: primaryColor }}
				>
					{i18n.t('web-auth.terms-of-use')}
				</a>
			</div>
		);
	},
);

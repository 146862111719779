import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../../symphony';

import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlNavigationContext } from '../../global-constants';

import s from './SlHamburger.scss';
import { SlNavbarThemes } from '../../../../shared/public/globalInterfaces/globalInterfaces';

export interface ISlHamburgerProps {
	mode: SlNavbarThemes;
}
export const SlHamburger: React.FC<ISlHamburgerProps> = React.memo(
	({ mode }) => {
		useStyles(s);
		const [openMode, setOpenMode] = useState<boolean>(false);
		const { navBarOpen$ } = Container.take(
			SlNavigationContext,
			SlNavigationDataService,
		);
		useEffect(() => {
			const unsubscriber = new Subject<void>();

			navBarOpen$.pipe(takeUntil(unsubscriber)).subscribe(setOpenMode);

			return () => {
				unsubscriber.next();
				unsubscriber.complete();
			};
		}, []);

		return (
			<div className={`sl-hamburger ${mode}`}>
				Menu
				<div
					sl-test-data="slHamburgerButton"
					className={`sl-hamburger__button${
						openMode ? ' active' : ''
					}`}
					onClick={(e) => {
						e.stopPropagation();
						navBarOpen$.next(!openMode);
					}}
				>
					<span className="arrow first" />
					<span className="arrow second" />
					<span className="arrow third" />
				</div>
			</div>
		);
	},
);

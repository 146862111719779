import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Timeline.scss';
import { SlIcon } from "@sololearnorg/icons";
import { i18n } from "../../../../../symphony";

type TimelineProps = {
	milestones: {
		iconId: string;
		title: string;
		description: string;
	}[];
};

export const Timeline: FC<TimelineProps> = ({
	milestones
}) => {
	useStyles(s);
	return <div className="timeline">
		<div className="timeline-ribbon"></div>
		<div className="timeline-content">
			{milestones.map(m => (
				<div key={m.title} className="timeline-milestone">
					<div className="timeline-icon">
						<SlIcon iconId={m.iconId} />
					</div>
					<div className="timeline-milestone-info">
						<p className="timeline-title">{i18n.t(m.title)}</p>
						<p className="timeline-description">{i18n.t(m.description)}</p>
					</div>
				</div>
			))}
		</div>
	</div>;
};

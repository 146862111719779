import React from 'react';

import { SocialLogin } from '../SocialLogin/SocialLogin';
import { Social } from '../../../../api/private/global.interface';

export interface ISlSocialLoginProps {
	darkMode?: boolean;
	isGoogleNewVersion?: boolean;
	socials: Social[]
}
export const SlSocialLogin: React.FC<ISlSocialLoginProps> = ({ darkMode, socials, isGoogleNewVersion }) => (
	<div className="sl-social-login__container">
		<SocialLogin type="signup" darkMode={darkMode} socials={socials} isGoogleNewVersion={isGoogleNewVersion} />
	</div>
);

import React, { FC, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Countdown.scss';
import { Container, i18n } from "../../../../../symphony";
import { PaywallsService } from "../../../services/paywalls.service";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../../paywalls.store";

type Props = {
	text: string;
	countdownSeconds: number;
};

export const Countdown: FC<Props> = ({ text, countdownSeconds }) => {
	useStyles(s);
	const { countdown } = useContainerData('paywalls', PaywallsStore, ['countdown']);
	const { countdownSetup } = Container.take('paywalls', PaywallsService);
	const [numbers, setNumbers] = useState<number[]>([]);

	useEffect(() => {
		countdownSetup(countdownSeconds).subscribe();
	}, []);

	useEffect(() => {
		const hours = Math.floor(countdown / 3600);
		const minutes = Math.floor((countdown % 3600) / 60);
		const secs = countdown % 60;
		const pad = (num: number) => num.toString().padStart(2, '0');
		const padString = `${pad(hours)}${pad(minutes)}${pad(secs)}`;
		setNumbers(padString.split('').map(char => +char));
	}, [countdown]);

	return <div className="paywall-countdown">
		<p className="paywall-countdown-text">{i18n.t(text)}</p>
		<div className="paywall-countdown-timer">
			<div className="paywall-countdown-number">{numbers[0]}</div>
			<div className="paywall-countdown-number">{numbers[1]}</div>
			<div className="paywall-countdown-separator">:</div>
			<div className="paywall-countdown-number">{numbers[2]}</div>
			<div className="paywall-countdown-number">{numbers[3]}</div>
			<div className="paywall-countdown-separator">:</div>
			<div className="paywall-countdown-number">{numbers[4]}</div>
			<div className="paywall-countdown-number">{numbers[5]}</div>
		</div>
	</div>;
};
import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './DiscountUnlocked.scss';
import { i18n } from '../../../../../symphony';

type Props = {
	discountData: {
		title: string;
		description: string;
		originalPrice: string;
		discountedPrice: string;
	};
};

export const DiscountUnlocked: FC<Props> = ({ discountData }) => {
	useStyles(s);

	return <div className='paywalls-discount-unlocked'>
		<h4 className='paywalls-discount-unlocked-title'>{discountData.title}</h4>
		<div className='paywalls-discount-unlocked-details'>
			<span className='paywalls-discount-unlocked-discount'>{discountData.originalPrice}</span>
			<span>{discountData.discountedPrice} {i18n.t(discountData.description)}</span>
		</div>

	</div>;
};
import { BehaviorSubject } from 'rxjs';
import { Service } from 'typedi';

import {
	ILearningExperience,
	IBitSource,
	NavItemBadgeConfig,
	IUserSetting,
	IXpSources,
	IShopItem,
	LearningExperienceType,
} from './global-interfaces';

@Service()
export class DataService {
	public learningExperiences$ = new BehaviorSubject<ILearningExperience[]>(null);

	public learningExperience$ = new BehaviorSubject<ILearningExperience>(null);

	public bitSourcesList$ = new BehaviorSubject<IBitSource[]>(null);

	public xpSourcesList$ = new BehaviorSubject<IXpSources>(null);

	public shopItemsList$ = new BehaviorSubject<IShopItem[]>(null);

	public userSettings$ = new BehaviorSubject<IUserSetting[]>(null);

	public navItemBadgeConfig$ = new BehaviorSubject<NavItemBadgeConfig>(null);

	public showNavItems$ = new BehaviorSubject<boolean>(false);

	public getLEAsync = async (): Promise<ILearningExperience> => {
		if (this.learningExperience$.value) {
			return Promise.resolve(this.learningExperience$.value);
		}
		return new Promise((resolve) => {
			const subscription = this.learningExperience$.subscribe({
				next: (le) => {
					if (le) {
						resolve(le);
						subscription.unsubscribe();
					}
				},
			});
		});
	};

	public getLETypeAsync = async (): Promise<LearningExperienceType> => {
		if (this.learningExperience$.value) {
			return Promise.resolve(this.learningExperience$.value.typeId);
		}
		return new Promise((resolve) => {
			const subscription = this.learningExperience$.subscribe({
				next: (le) => {
					if (le) {
						resolve(le.typeId);
						subscription.unsubscribe();
					}
				},
			});
		});
	};
}

import React from 'react';

interface IIconAvatarFillProps {
	style?: React.CSSProperties;
}

export function IconAvatarFill({ style }: IIconAvatarFillProps) {
	return (
		<svg
			viewBox="0 0 80 80"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path d="M67.3411 61.85C63.7911 56.13 55.9761 50 40.0011 50C24.0261 50 16.2161 56.125 12.6611 61.85C15.9365 65.9562 20.0957 69.271 24.8292 71.5477C29.5627 73.8244 34.7486 75.0044 40.0011 75C45.2537 75.0044 50.4396 73.8244 55.1731 71.5477C59.9066 69.271 64.0657 65.9562 67.3411 61.85V61.85Z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M40 45C43.9782 45 47.7936 43.4196 50.6066 40.6066C53.4196 37.7936 55 33.9782 55 30C55 26.0218 53.4196 22.2064 50.6066 19.3934C47.7936 16.5804 43.9782 15 40 15C36.0218 15 32.2064 16.5804 29.3934 19.3934C26.5804 22.2064 25 26.0218 25 30C25 33.9782 26.5804 37.7936 29.3934 40.6066C32.2064 43.4196 36.0218 45 40 45V45Z"
			/>
		</svg>
	);
}

import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import withStyles from 'isomorphic-style-loader/withStyles';

import { SlNavBarPositions, SlNavbarThemes } from '../../../features/shared/public/globalInterfaces/globalInterfaces';
import { SlNavbarState } from '../../../features/navigation/private/global-interface';
import { SlFooter } from '../../../features/navigation/public/SlFooter/SlFooter';
import { setNavigationConfigs } from '../../utils/navigationManager';
import { ApiUrls } from '../../shared/api-urls';

import styles from './RefundPolicy.scss';
import { SLPrivacyPolicy } from '../../../features/staticPages/public/SlPrivacyPolicy';

const refundPolicyProps = {
	staticPagesApiHost: ApiUrls.staticPagesApiHost,
};

export const RefundPolicy = withStyles(styles)(
	React.memo(() => {
		const [isReady, setIsReady] = useState<boolean>(false);

		useEffect(() => {
			const unsubscriber = new Subject<void>();

			setNavigationConfigs({
				state: SlNavbarState.shown,
				theme: SlNavbarThemes.light,
				position: SlNavBarPositions.relative,
			});
			setIsReady(true);
			return () => {
				setNavigationConfigs({
					state: SlNavbarState.hidden,
				});
				unsubscriber.next();
				unsubscriber.complete();
			};
		}, []);
		return isReady ? (
			<>
				<div className="page-container">
					<SLPrivacyPolicy {...refundPolicyProps} />
				</div>
				<SlFooter />
			</>
		) : null;
	}),
);

import { BraintreeErrorCodes, IProPlanTextInfo, IProPlansLayoutInfo } from './global.interface';
import { ISlAwardsProps } from '../../shared/public/SlAwards/SlAwards';
import { IPaymentPlan } from '../../api/public/payment-api';
import { i18n } from '../../symphony';

export const getMainPlans = (): IPaymentPlan[] => [
	{
		id: 0,
		rechargePeriodMonths: 0,
		trialDays: 0,
		amount: 0,
		discountedAmount: 0,
		type: 'basic',
		order: 1,
	},
	{
		id: 7,
		rechargePeriodMonths: 12,
		trialDays: 14,
		amount: 155.88,
		discountedAmount: 69.99,
		type: 'annual',
		order: 2,
	},
	{
		id: 8,
		rechargePeriodMonths: 1,
		trialDays: 0,
		amount: 12.99,
		discountedAmount: 12.99,
		type: 'monthly',
		order: 3,
	},
];

export const getAICoursePlan = (): IPaymentPlan => ({
	type: 'ai-course',
	discountedAmount: 47.99,
});

export const getMainPlansLayoutData = (): IProPlansLayoutInfo => ({
	title: i18n.t('web-paywall.title'),
	subtitle: i18n.t('web-paywall.main-subtitle'),
	icon: {
		text: 'PRO',
		class: 'pro',
	},
});

export const getAIPlanLayoutData = (): IProPlansLayoutInfo => ({
	title: i18n.t('web-paywall.ai-course.title'),
	subtitle: i18n.t('web-paywall.ai-course.subtitle'),
	icon: {
		text: 'AI Courses',
		class: 'ai-courses',
	},
});

export const getCheckoutConfiguration = () => ({
	trialInstructions: [
		{
			order: 1,
			title: i18n.t('web-checkout.trial-instruction0-title'),
			text: i18n.t('web-checkout.trial-instruction0-text'),
		},
		{
			order: 2,
			title: i18n.t('web-checkout.trial-instruction1-title'),
			text: i18n.t('web-checkout.trial-instruction1-text'),
		},
	],
	instructions: [
		{
			order: 1,
			text: i18n.t('web-checkout.instruction0-text'),
		},
		{
			order: 2,
			text: i18n.t('web-checkout.instruction1-text'),
		},
	],
});

// braintree: processing error codes
export const braintreeProcessingErrors = {
	2000: {
		title: 'Your bank is unwilling to accept the transaction at the moment.',
		info: 'Please contact your bank and try again.',
	},
	2001: {
		title: 'Insufficient funds to cover the transaction.',
		info: '',
	},
	2002: {
		title: 'Withdrawal limit is exceeded.',
		info: 'Please change the account limits and try again.',
	},
	2003: {
		title: 'Activity limit is exceeded.',
		info: 'Please change the account limits and try again.',
	},
	2004: {
		title: 'Card is expired.',
		info: 'Please use a different payment method.',
	},
	2005: {
		title: 'Invalid card number.',
		info: 'Please try again or contact your bank.',
	},
	2006: {
		title: 'Invalid expiration date.',
		info: 'Please try again or contact your bank.',
	},
	2007: {
		title: 'The account doesn’t exist.',
		info: 'Please contact your bank.',
	},
	2010: {
		title: 'Invalid card information.',
		info: 'Please try again or contact your bank.',
	},
	2012: {
		title: 'The card used has likely been reported as lost.',
		info: 'Please contact your bank.',
	},
	2013: {
		title: 'The card used has likely been reported as stolen.',
		info: 'Please contact your bank.',
	},
	2014: {
		title: 'Fraud suspected.',
		info: 'Please contact your bank.',
	},
	2053: {
		title: 'The card used was reported lost or stolen.',
		info: 'PLease contact your bank.',
	},
	2021: {
		title: 'Fraud suspected.',
		info: 'Please contact your bank.',
	},
};

// braintree: processing error types
export const errorMessageGlobal = {
	SOFT_DECLINED: {
		title: 'Transaction failed.',
		info: 'Please try again.',
	},
	HARD_DECLINED: {
		title: 'Transaction failed.',
		info: 'Please try again or contact your bank.',
	},
	UNRECOGNIZED: {
		title: 'Transaction failed.',
		info: 'Please try again or contact your bank.',
	},
};

// braintree: input field validation error types
export const inputFieldErrors = {
	[BraintreeErrorCodes.HOSTED_FIELDS_FAILED_TOKENIZATION]:
        'Your credit card was declined. Please use a different card.',
	[BraintreeErrorCodes.HOSTED_FIELDS_FIELDS_EMPTY]:
        'All fields are empty. Please provide valid card details for the required fields.',
	[BraintreeErrorCodes.HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR]:
        'There was a network error. Please try again',
	[BraintreeErrorCodes.HOSTED_FIELDS_FIELDS_INVALID]:
        'Please provide valid card details for the required fields.',
};

export const awards: ISlAwardsProps[] = [
	{
		icon: 'stars',
		title: '4.8 from 5',
		description: 'Stars on the AppStore',
		iconStyle: {
			height: '24px',
			width: '152px',
			color: '#ECAA00',
		},
	},
	{
		icon: 'fbCircle',
		title: 'App Of The Year',
		description: 'FB Start by Facebook',
	},
	{
		icon: 'playMarket',
		title: 'Editors\' Choice',
		description: 'Google Play',
	},
	{
		icon: 'edTech',
		title: 'Best Computer Coding Education Solution',
		description: 'EdTech Breakthrough Award 2021',
		iconStyle: {
			height: '62px',
			width: '62px',
		},
	},
];

export const planBenefits: { text: string; plans: string[] }[] = [
	{
		text: 'On-the-go, bitesize lessons',
		plans: ['annual', 'monthly', 'basic'],
	},
	{
		text: 'Help from the community',
		plans: ['annual', 'monthly', 'basic'],
	},
	{
		text: 'Unlimited practice',
		plans: ['annual', 'monthly'],
	},
	{
		text: 'Interactive code demos',
		plans: ['annual', 'monthly'],
	},
	{
		text: 'Setting and tracking goals',
		plans: ['annual', 'monthly'],
	},
];

export enum HostedFieldValidityStatus {
    Empty = 0,
    Valid = 1,
    Invalid = 2,
}

export const forExperimentsMockedData = {
	success: true,
	correlationID: null,
	errors: [],
	data: [
		{
			flowId: 168,
			flowName: 'pro_banner_toggle_web',
			experimentId: 62,
			experimentName: 'pro_banner_toggle',
			experimentDate: '2021-12-23T00:00:00',
			language: 'en',
			platform: 'web',
			version: 0,
			pages: [
				{
					pageId: 157,
					pageName: 'pro_banner_toggle',
					version: 'v1',
					data: {
						title: 'Become a',
						proIconId: 'proGoldBlack',
						offers: [
							{
								iconId: 'checkV2',
								title: 'Master coding with hands-on practice',
							},
							{
								iconId: 'checkV2',
								title: 'See working code in action',
							},
							{
								iconId: 'checkV2',
								title: 'Smash your goals with progress tracking',
							},
							{
								iconId: 'checkV2',
								title: 'Remove the ads and interruptions',
							},
						],
						plans: [
							{
								name: 'Annual',
								id: 7,
								discount: 'Save 55%',
								billedMonthly: '5.83',
								billedAnnually: '69.99',
								description: '14 days of free trial included',
								actionText: 'START FREE TRIAL AND SUBSCRIBE',
								isExperiment: false,
							},
							{
								name: 'Monthly',
								id: 8,
								discount: '',
								billedMonthly: '12.99',
								billedAnnually: '',
								description: 'Pay monthly, cancel anytime',
								actionText: 'Subscribe Now',
								isExperiment: false,
							},
							{
								name: 'Annual',
								id: 38,
								discount: 'Save 55%',
								billedMonthly: '5.83',
								billedAnnually: '69.99',
								description:
                                    'Pay for a full year, cancel anytime',
								actionText: 'Subscribe Now',
								isExperiment: true,
							},
						],
						toggler: {
							title: 'Activate free trial',
							description:
                                'Free cancellation up to 24 hours before trial ends',
						},
					},
				},
			],
		},
		{
			flowId: 207,
			flowName: 'new_checkout_page_web',
			experimentId: 82,
			experimentName: 'new_checkout_page',
			experimentDate: '2022-05-24T09:42:51.65',
			language: 'en',
			platform: 'web',
			version: 0,
			pages: [
				{
					pageId: 193,
					pageName: 'checkout_page',
					version: 'v1',
					data: {
						showNewCheckout: true,
						configuration: {
							head: 'Become',
							trialInstructions: [
								{
									order: 1,
									title: 'Now',
									text: 'Enjoy full access, no payment needed.',
								},
								{
									order: 2,
									title: 'Day {trial_days}',
									text: 'Your account is charged {price}. Cancel anytime before this day.',
								},
							],
							instructions: [
								{
									order: 1,
									text: 'Your subscription begins today. You will be charged {price} {period}.',
								},
								{
									order: 2,
									text: 'You can cancel your PRO subscription anytime from your profile settings section.',
								},
							],
							paymentMethods: [
								{
									order: 1,
									type: 'creditCard',
								},
								{
									order: 2,
									type: 'others',
									items: ['paypal'],
								},
							],
						},
					},
				},
			],
		},
		{
			flowId: 214,
			flowName: 'dynamic_pro_page_web',
			experimentId: 85,
			experimentName: 'dynamic_pro_page',
			experimentDate: '2022-06-21T07:31:42.103',
			language: 'en',
			platform: 'web',
			version: 0,
			pages: [
				{
					pageId: 196,
					pageName: 'pro_page',
					version: 'v1',
					data: {
						configuration: {
							header: {
								title: {
									text: 'Invest in your learning with Sololearn',
								},
								subtitle: {
									text: 'Choose your learning plan and join our global community of coders',
								},
							},
							plans: [
								{
									order: 1,
									main: false,
									type: 'basic',
									plan: {
										name: {
											text: 'Basic Plan',
										},
										price: {
											text: '$0',
										},
										payment: {
											text: 'Free',
										},
									},
									options: [
										{
											text: 'On-the-go, bitesize lessons',
											active: true,
										},
										{
											text: 'Help from the community',
											active: true,
										},
										{
											text: 'Unlimited practice',
											active: false,
										},
										{
											text: 'Interactive code demos',
											active: false,
										},
										{
											text: 'Setting and tracking goals',
											active: false,
										},
									],
									button: {
										cta: {
											text: 'Current Plan',
										},
										color: 'the color',
										borderColor: 'the border color',
										placement: 'top',
									},
								},
								{
									order: 2,
									main: true,
									type: 'annual',
									plan: {
										name: {
											text: 'Annual',
										},
										price: {
											text: 'Billed annually {price}',
										},
										payment: {
											text: '{annual_price_per_month}/mo',
										},
										trialInfo: {
											text: 'Try {trial_days} Days For Free',
										},
									},
									options: [
										{
											text: 'Unlimited practice',
											active: true,
										},
										{
											text: 'Infinite Hearts',
											active: true,
										},
										{
											text: 'Bonus exercises',
											active: true,
										},
										{
											text: 'Advanced goals and stats',
											active: true,
										},
										{
											text: 'Priority support and more',
											active: true,
										},
									],
									button: {
										cta: {
											text: 'Try PRO for free for {trial_days} days',
										},
										placement: 'top',
									},
								},
								{
									order: 3,
									main: false,
									type: 'monthly',
									plan: {
										name: {
											text: 'Monthly',
										},
										price: {
											text: 'Billed monthly',
										},
										payment: {
											text: '{price}/mo',
										},
										trialInfo: {
											text: 'No Trial Included',
										},
									},
									options: [
										{
											text: 'Unlimited practice',
											active: true,
										},
										{
											text: 'Infinite Hearts',
											active: true,
										},
										{
											text: 'Bonus exercises',
											active: true,
										},
										{
											text: 'Advanced goals and stats',
											active: true,
										},
										{
											text: 'Priority support and more',
											active: true,
										},
									],
									button: {
										cta: {
											text: 'Select Plan',
										},
										placement: 'top',
									},
								},
							],
						},
					},
				},
			],
		},
		{
			flowId: 189,
			flowName: 'serious_learner_web',
			experimentId: 73,
			experimentName: 'serious_learner',
			experimentDate: '2022-02-23T21:42:24.937',
			language: 'en',
			platform: 'web',
			version: 0,
			pages: [
				{
					pageId: 172,
					pageName: 'proPromotion',
					version: 'v1',
					data: {
						isSeriousLearner: true,
						paymentPlanId: 39,
						productId: 'sl_pro_annual_52_no_trial',
						discount: 25,
						discountedPriceText: 'discounted_monthly_price/m',
						titleText: 'Pay now, get 25% off',
						bodyText:
                            'Serious about coding? Invest in your learning now for just discounted_monthly_price a month (discounted_annualy_price annually)',
						primaryButtonText: 'SUBSCRIBE NOW (25%OFF)',
						secondaryButtonText: 'TRY FOR FREE',
					},
				},
			],
		},
	],
};

import React from 'react';

export function GoogleColoredIcon({ className }: { className?: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} width="25" height="24" viewBox="0 0 25 24" fill="none">
			<path d="M22.3869 10.0415H21.5814V10H12.5814V14H18.2329C17.4084 16.3285 15.1929 18 12.5814 18C9.26792 18 6.58142 15.3135 6.58142 12C6.58142 8.6865 9.26792 6 12.5814 6C14.1109 6 15.5024 6.577 16.5619 7.5195L19.3904 4.691C17.6044 3.0265 15.2154 2 12.5814 2C7.05892 2 2.58142 6.4775 2.58142 12C2.58142 17.5225 7.05892 22 12.5814 22C18.1039 22 22.5814 17.5225 22.5814 12C22.5814 11.3295 22.5124 10.675 22.3869 10.0415Z" fill="#FFC107" />
			<path d="M3.73474 7.3455L7.02024 9.755C7.90924 7.554 10.0622 6 12.5817 6C14.1112 6 15.5027 6.577 16.5622 7.5195L19.3907 4.691C17.6047 3.0265 15.2157 2 12.5817 2C8.74074 2 5.40974 4.1685 3.73474 7.3455Z" fill="#FF3D00" />
			<path d="M12.4842 21.973C15.0672 21.973 17.4142 20.9845 19.1887 19.377L16.0937 16.758C15.0559 17.5472 13.7879 17.974 12.4842 17.973C9.88317 17.973 7.67467 16.3145 6.84267 14L3.58167 16.5125C5.23667 19.751 8.59767 21.973 12.4842 21.973Z" fill="#4CAF50" />
			<path d="M22.3869 10.0415H21.5814V10H12.5814V14H18.2329C17.8385 15.1082 17.1281 16.0766 16.1894 16.7855L16.1909 16.7845L19.2859 19.4035C19.0669 19.6025 22.5814 17 22.5814 12C22.5814 11.3295 22.5124 10.675 22.3869 10.0415Z" fill="#4286DE" />
		</svg>
	);
}

import { HostedFieldsTokenizePayload } from 'braintree-web';
import { ITrackAction } from './services/sl-payment-tracking.service';
import { IQuery } from '../../shared/public/utilsPublic/utilsPublic';
import { SubscriptionPlanId } from '../../shared/public/globalInterfaces/globalInterfaces';
import { IError, IExperimentResponse } from '../../api/private/global.interface';
import { PaymentPlanType } from '../../api/public/payment-api';

export interface IPaymentError extends IError {
	errorInfo: {
		title: string;
		info?: string;
	};
}

export interface IPayPalNonce {
	nonce: string;
	details: unknown;
	type: string;
}

export enum PaymentTypes {
	creditCard = 'creditCard',
	paypal = 'paypal',
	applePay = 'applePay',
	googlePay = 'googlePay',
}

export interface IPayPalActionData {
	payPalNonce: IPayPalNonce;
	deviceData: string;
}

export enum PaymentProcessingErrors {
	tryAgain = 0,
	alreadyPro = 12001,
	alreadySubscribed = 12002,
}

export enum PaymentProcessingErrorsModals {
	tryAgain = 'tryAgain',
	alreadyPro = 'alreadyPro',
	alreadySubscribed = 'alreadySubscribed',
}

export interface ISummaryField {
	key: string;
	value: string;
	emphasize?: boolean;
}

export enum PaymentActions {
	ProductCheckoutMounted,
	GetSubscriptionPlans,
	SubscriptionPlanIdDefined,
	PayPalNonceReceived,
	CreditCardNonceReceived,
	ContinueOnWeb,
	ContinueWithFree,
	GetAllExperimentsData,
	SelectProPlan,
	SelectAICoursePlan,
	ViewProPlan,
	OpenModal,
	CloseModal,
	Tracking,
	TrackProPageView,
	RedirectForMobile,

	// Component Mounts
	MountOfferModal,

	// Callbacks
	CloseBtnCBOfferModal,

	// Clicks
	ClickBackBtnNonLoggedProPlans,
}

export interface IPaymentAction {
	type: PaymentActions;
	paypalData?: IPayPalActionData;
	subscriptionPlanId?: number;
	entityId?: number;
	returnUrl?: string;
	alias?: string;
	redirectUrl?: string;
	creditCardNonce?: HostedFieldsTokenizePayload;
	trackAction?: ITrackAction;
	source?: string;
	isShownOffer?: boolean;
	data?: {
		query?: IQuery;
		isSkippedOffer?: boolean;
		pageName?: string;
		isCompleted?: boolean;
		modalName?: string;
		queryParams?: {
			[key: string]: string;
		};
		productId?: string;
	};
}

export const defaultReturnUrlAfterCongrats = '/learning';
export const defaultReturnUrlForContinue = '/learning';
export const defaultReturnUrlAfterPayment = '/learning';

export interface ICongratsPageInfo {
	pageName?: string;
	isCompleted?: boolean;
}

export interface ICongratsPagesInfo {
	welcome?: ICongratsPageInfo;
	certificate?: ICongratsPageInfo;
	codecoach?: ICongratsPageInfo;
	endofmodule?: ICongratsPageInfo;
	tiy?: ICongratsPageInfo;
}

export enum CongratesPageNames {
	welcome = 'welcome',
	tiy = 'tiy',
	codecoach = 'codecoach',
	endofmodule = 'endofmodule',
	certificate = 'certificate',
}

export interface ISubscriptionHistory {
	cancelDate: string;
	paymentPlanId: SubscriptionPlanId;
	subscriptionDate: string;
	trialExpiredDate: string;
}

export enum BraintreeInputFields {
	number = 'number',
	cvv = 'cvv',
	expirationDate = 'expirationDate',
	postalCode = 'postalCode',
}

export interface ICardValidationError {
	message: string;
	invalidFields?: {
		[BraintreeInputFields.number]?: HTMLDivElement;
		[BraintreeInputFields.cvv]?: HTMLDivElement;
		[BraintreeInputFields.expirationDate]?: HTMLDivElement;
		[BraintreeInputFields.postalCode]?: HTMLDivElement;
	};
	code?: string;
}

export enum BraintreeErrorCodes {
	HOSTED_FIELDS_FAILED_TOKENIZATION = 'HOSTED_FIELDS_FAILED_TOKENIZATION',
	HOSTED_FIELDS_FIELDS_EMPTY = 'HOSTED_FIELDS_FIELDS_EMPTY',
	HOSTED_FIELDS_FIELDS_INVALID = 'HOSTED_FIELDS_FIELDS_INVALID',
	HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR = 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR',
}

export enum AppsFlyerEventNames {
	slProCheckoutPage = 'sl_pro_checkout_page',
}

interface IExperimentPage {
	pageId: number;
	pageName: string;
	version: string;
	data: unknown;
}

// serious learner experiment response interfaces
export interface ISeriousLearnerExperiment extends IExperimentResponse {
	pages: ISeriousLearnerExperimentPage[];
}

export interface ISeriousLearnerExperimentPage extends IExperimentPage {
	data: ISeriousLearnerExperimentInfo;
}

export interface ISeriousLearnerExperimentInfo {
	isSeriousLearner: boolean;
	paymentPlanId: number;
	productId: string;
	discount: number;
	discountedPriceText: string;
	titleText: string;
	bodyText: string;
	primaryButtonText: string;
	secondaryButtonText: string;
}

export enum SlModalIds {
	offer = 'offer',
}

export interface INewCheckoutPageExperiment extends IExperimentResponse {
	pages: INewCheckoutPageExperimentPage[];
}

export interface INewCheckoutPageExperimentPage extends IExperimentPage {
	data: INewCheckoutPageExperimentInfo;
}

export interface INewCheckoutPageExperimentInfo {
	showNewCheckout: boolean;
	configuration?: {
		head: string;
		paymentMethods: { order: number; type: string; }[];
		instructions: { order: number; text: string; }[];
		trialInstructions: { order: number; title: string; text: string; }[];
	};
}

export interface IDynamicProPageExperiment extends IExperimentResponse {
	pages: IDynamicProPageExperimentPage[];
}

export interface IDynamicProPageExperimentPage extends IExperimentPage {
	data: IDynamicProPageExperimentInfo;
}

export interface IDynamicProPageExperimentInfo {
	configuration: { header: IProPlansHeader; plans: IProPlanTextInfo[]; };
}

export interface IProPlansHeader {
	title: { text: string; };
	subtitle: { text: string; };
}

export interface IProPlansLayoutInfo {
	title: string;
	icon: {
		text: string;
		class: string;
	};
	subtitle: string;
}

export interface IProPlanTextInfo {
	order?: number;
	type: PaymentPlanType;
	planData: {
		label?: boolean;
		circle?: { text: string; };
		name: { text: string; };
		payment: { text: string; };
		price: { text: string; };
		trialInfo?: { text: string; };
		proIcon?: boolean;
	};
	options: { text: string; active: boolean; color?: string; }[];
	link?: { text: string; href: string; };
	button: {
		text: string;
		type: string;
		disableIfLoggedIn?: boolean;
	};
}

export interface IPaypalButtonStyle {
	color?: string;
	height?: number;
	shape?: string;
	tagline?: boolean;
	label?: string;
}

export enum BackendPatterns {
	price = '{price}',
	trialDays = '{trial_days}',
	period = '{period}',
	faqLink = '{faq_link}',
	annualPricePerMonth = '{annual_price_per_month}',
	pricePerPerson = '{price_per_person}',
}

export interface IProduct {
	productId: string,
	type: number,
	price: number,
	createdAt: string,
	id: number,
	isPurchased: boolean,
	discount?: number,
	totalPrice?: number;
}

export enum ZipState {
	Hide = '0',
	Show = '1',
}

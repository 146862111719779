import React, { FC, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WebToAppPaywallDiscount.scss';
import { PaywallsStore } from "../../paywalls.store";
import { PaywallsService } from "../../services/paywalls.service";
import { useContainerData } from "../../../../shared/public/SlHooks/useContainerData";
import { Container } from "../../../../symphony";

type Props = {
	discount: number;
	onContinue: () => void;
};
export const WebToAppPaywallDiscount: FC<Props> = ({
	discount, onContinue
}) => {
	useStyles(s);
	const { countdown } = useContainerData('paywalls', PaywallsStore, ['countdown']);
	const { countdownSetup } = Container.take('paywalls', PaywallsService);
	const [numbers, setNumbers] = useState<number[]>([]);

	useEffect(() => {
		const subscription = countdownSetup(600).subscribe();

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		const hours = Math.floor(countdown / 3600);
		const minutes = Math.floor((countdown % 3600) / 60);
		const secs = countdown % 60;
		const pad = (num: number) => num.toString().padStart(2, '0');
		const padString = `${pad(hours)}${pad(minutes)}${pad(secs)}`;
		setNumbers(padString.split('').map(char => +char));
	}, [countdown]);

	return <div className="paywall-w2a-discount-header">
		<div className="paywall-w2a-discount-header-inner">
			<div>
				<p className="paywall-w2a-discount-header-info">{discount}% discount reserved for:</p>
				<p className="paywall-w2a-discount-header-timer">
					<span>{numbers[2]}</span>
					<span>{numbers[3]}</span>
					<span>:</span>
					<span>{numbers[4]}</span>
					<span>{numbers[5]}</span>
				</p>
			</div>
			<button
				className="paywall-w2a-discount-header-button"
				onClick={onContinue}
			>
				Continue
			</button>
		</div>
	</div>;
};
import React, { useEffect, useRef, useState } from 'react';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { IconColorBit } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, IBits, BitService } from '../../../../symphony';

import { UserBitsMenu } from '../UserBitsMenu/UserBitsMenu';
import { SlActions } from '../../services/ActionsService/sl-actions';
import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlNavigationContext } from '../../global-constants';
import { ActionType, RequestType } from '../../global-interface';

import s from './UserBits.scss';
import { useOutsideClick } from '../../../../shared/public/SlHooks/useOutsideClick';

export function UserBits() {
	useStyles(s);
	const [bits, setBits] = useState<IBits>(null);
	const [bitsMenuOpen, setBitsMenuOpen] = useState<boolean>(false);

	const { bitsMenuOpen$ } = Container.take(
		SlNavigationContext,
		SlNavigationDataService,
	);

	const element = useRef<HTMLDivElement>(null);

	useOutsideClick(element, bitsMenuOpen, !bitsMenuOpen, () => {
		bitsMenuOpen$.next(false);
	});

	useEffect(() => {
		const bitService = Container.take('global', BitService);
		const unsubscriber = new Subject<void>();

		merge(
			bitService.bits$.pipe(tap(setBits)),
			bitsMenuOpen$.pipe(tap(setBitsMenuOpen)),
		)
			.pipe(takeUntil(unsubscriber))
			.subscribe();

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const onClick = () => {
		const { actions$ } = Container.take(SlNavigationContext, SlActions);
		actions$.next({
			type: ActionType.OnBitsIconClick,
			requestType: RequestType.Cancelable,
		});
	};

	const bitsDisplay = () => {
		const value = bits.bits;
		if (!value || value < 10000) {
			return value;
		}
		if (value >= 1000000000) {
			return `${Math.trunc(value / 100000000) / 10}B`;
		}
		if (value >= 1000000) {
			return `${Math.trunc(value / 100000) / 10}M`;
		}
		return `${Math.trunc(value / 100) / 10}K`;
	};

	return bits ? (
		<div ref={element} className="sl-navigation-actions__bits-wrapper">
			<button
				type="button"
				className={`sl-navigation-actions__bits-button ${
					bitsMenuOpen
						? 'sl-navigation-actions__bits-button--open'
						: ''
				}`}
				onClick={(e) => {
					e.stopPropagation();
					onClick();
				}}
			>
				<div className="sl-navigation-actions__bits-icon">
					<IconColorBit />
				</div>
				<div className="sl-navigation-actions__bits">
					{bitsDisplay()}
				</div>
			</button>
			<div>{bitsMenuOpen && <UserBitsMenu bits={bits.bits} />}</div>
		</div>
	) : null;
}

import { Observable } from 'rxjs';

import { IPaymentsVersions } from '../payment-api/payment-api.interface';
import { IPaymentExperimentResponse } from './pro-banner.interface';
import { IProOnboardingExperimentResponse } from './pro-onboarding.interface';
import { IWelcomeBackExperimentResponse } from './welcome-back.interface';
import { IStartPromptExperimentResponse } from './start-prompt.interface';
import { IQuitPromptExperimentResponse } from './quit-prompt.interface';
import { ILessonLandingExperimentResponse } from './lessons-landing.interface';
import { IHighlightCCExperimentResponse } from './highlight-cc.interface';
import { IDailyDoseExperimentResponse } from './daily-dose.interface';
import { ISurveyCoursesExperimentResponse } from './survey-courses.interface';
import { IFreeCodeRepoExperimentResponse } from './free-code-repos.interface';
import { ICRProgressHintExperimentResponse } from './cr-progress-hint.interface';
import { IExperimentsDataResponse } from '../global.interface';
import { ICodeCoachCommentsExperimentResponse } from './cc-comments.interface';
import { IUserGuidanceExperimentResponse } from './user-guidance.interface';
import { ICelebrationScreenExperimentResponse } from './celebration-screen.interface';
import { ICCMandatoryExperimentResponse } from './cc-mandatory.interface';
import { ICCSolutionExperimentResponse } from './cc-solution.interface';
import { IExperimentalCoursesExperimentResponse } from './experimental-courses.interface';
import { IDynamicProPageExperimentResponse } from './dynamic-pro.interface';

export interface IExperimentApi {
    getDynamicProPageExperimentData: () => Observable<IDynamicProPageExperimentResponse>;
    getPaymentExperimentData: () => Observable<IPaymentExperimentResponse>;
    getWelcomeBackExperimentData: (
        userId: number
    ) => Observable<IWelcomeBackExperimentResponse>;
    getProOnboardingExperimentData: () => Observable<IProOnboardingExperimentResponse>;
    getCCSolutionExperimentData: (
        userId: number
    ) => Observable<ICCSolutionExperimentResponse>;
    getStartPromptExperimentData: () => Observable<IStartPromptExperimentResponse>;
    getQuitPromptExperimentData: () => Observable<IQuitPromptExperimentResponse>;
    getLessonLandingExperimentData: () => Observable<ILessonLandingExperimentResponse>;
    getHighlightCCExperimentData: () => Observable<IHighlightCCExperimentResponse>;
    getDailyDoseExperimentData: (
        userId: number
    ) => Observable<IDailyDoseExperimentResponse>;
    getSurveyCoursesExperimentData: (
        userId: number
    ) => Observable<ISurveyCoursesExperimentResponse>;
    getFreeCRExperimentData: () => Observable<IFreeCodeRepoExperimentResponse>;
    getCRProgressHintExperimentData: () => Observable<ICRProgressHintExperimentResponse>;
    getExperimentsData: (
        userId: number,
        experiments: string[]
    ) => Observable<IExperimentsDataResponse>;
    getCCCommentsExperimentData: (
        userId?: number
    ) => Observable<ICodeCoachCommentsExperimentResponse>;
    getUserGuidanceExperimentData: (
        userId: number
    ) => Observable<IUserGuidanceExperimentResponse>;
    getCelebrationScreenExperimentData: () => Observable<ICelebrationScreenExperimentResponse>;
    getCCMandatoryExperimentData: (
        userId?: number
    ) => Observable<ICCMandatoryExperimentResponse>;
    getExperimentalCoursesData: (
        userId?: number
    ) => Observable<IExperimentalCoursesExperimentResponse>;
    exposeExperiment: (
        experimentKey: string
    ) => Observable<IExposeExperimentResponse>;
    resolveExperiment: (
        experimentKey: string
    ) => Observable<IResolveExperimentResponse>;
}

export interface IExposeExperimentResponse {
    message: string;
    data: string;
}

export interface IResolveExperimentResponse {
    key: string;
    payload: any;
    experimentKey: string;
}

export interface IExperimentApiConfig {
    environmentUrl: string;
    versions?: IPaymentsVersions;
}

export enum ExperimentNames {
    payments = 'payments_experiment',
    ccSolution = 'cc_solution',
    welcomeBack = 'welcomeback',
    proOnboarding = 'onboarding_pro_web',
    startPrompt = 'first_cc_start_prompt',
    proBannerToggle = 'pro_banner_toggle',
    quitPrompt = 'first_cc_quit_prompt',
    highlightCC = 'first_cc_lesson_highlight',
    courseLanding = 'course_landing',
    dailyDose = 'daily_dose',
    surveyCourses = 'onboarding_survey_courses',
    freeCRCount = 'free_cr_count',
    crProgressHint = 'cr_progress_hint',
    ccComments = 'cc_comments',
    celebrationScreen = 'celebration_screen',
    userGuidance = 'user_guidance',
    coderepoComments = 'coderepo_comments',
    ccMandatory = 'cc_mandatory',
    onBoardingWebFromAd = 'onboarding_web_ad',
    onBoardingWeb = 'onboarding_web',
    lessonProgressBarRedesign = 'lesson_progress_bar_redesign',
    quizBackToTheory = 'quiz_back_to_theory',
    seriousLearner = 'serious_learner',
    experimentalCourses = 'experimental_courses',
    newCheckoutPage = 'new_checkout_page',
    trialTimelineVariation = 'trial_timeline_variation',
    dynamicProPage = 'dynamic_pro_page',
    newCancellationFlow = 'new_cancellation_flow',
    betaTestingLearnEngine = 'betaTestingLearnEngine',
    lessonQuitPrompt = 'lesson_quit_prompt',
}

export enum PageNames {
    ccSolution = 'cc_solution',
    payments = 'payments',
    freeCCCount = 'freeCCCount',
    startPrompt = 'first_cc_start_prompt',
    quitPrompt = 'first_cc_quit_prompt',
    highlightCC = 'firstCCLessonHighlight',
    courseLanding = 'courseLanding',
    dailyDose = 'dailyDose',
    surveyCourses = 'onboardingSurveyCourses',
    freeCRCount = 'freeCRCount',
    crProgressHint = 'crProgressHint',
    ccComments = 'cc_comments',
    celebrationScreen = 'celebrationScreen',
    userGuidance = 'userGuidance',
    coderepoComments = 'coderepo_comments',
    ccMandatory = 'cc_mandatory',
    heartSystem = 'heartSystem',
    proPromotion = 'proPromotion',
    proPage = 'pro_page',
    checkoutPage = 'checkout_page',
    lessonQuitPrompt = 'lesson_quit_prompt',
}
